import { ChangeEvent, useEffect, useRef, useState } from 'react';
import IconUpload from 'assets/icons/icon_upload_multimedia.svg';
import IconDelete from 'assets/icons/icon_trash.svg';
import { onAlert } from 'components/Alert';

const InputFile = ({value = '', ...props}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [hasValue, setHasValue] = useState<boolean>(false);

  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onHasValue(value);
    setInputValue(value);
  }, [value])
  
  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    onHasValue(e.target.value);

    if (e.target.files) {
      if (e.target.files[0].size < 10000000) {
        setInputValue(e.target.files[0].name);
        props.handleChange(e);
      } else {
        onAlert.warning('El archivo seleccionado no puede exceder los 10 MB');
      }
    }
  }
  
  const onHasValue = (val: any) => {
    if (val) setHasValue(val.length > 0);
  }

  const handleClick = (e: any) => {
    refInput.current?.click();
  };
    
  return (
    // ADD MODAL ERROR with the message: 
    <div className={props.className}>
    
      <div
        className='
          min-w-[243px] max-w-full h-[56px] text-body1 relative 
          flex items-center justify-start
        '
      >
        <input
          data-testid="input_upload"
          type="file"
          className='hidden'
          ref={refInput}
          onChange={onChangeField}
          accept={props.accept}
        />

        <input
          type="text"
          className={`
            ${props.classNameInput}
            peer 
            px-[16px] w-full h-full rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none 
            hover:px-[calc(16px-1px)] hover:border-solid hover:border-[1px] hover:border-grey-400 
            focus:px-[calc(16px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73] 
            ${true ? '' : 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'} 
          `}
          readOnly={true}
          onClick={handleClick}
          value={inputValue}
        />

        <label
          className={`
            px-[16px] absolute text-gray-600 pointer-events-none 
            ${hasValue ? 'mb-6 text-caption text-neutral-600' : ''} 
            peer-focus:text-caption peer-focus:mb-6 
            ${true ? '' : 'text-error-main'}
          `}
        >{props.label}{props.required && '*'}</label>
        {
          (props.isClosable) && inputValue ?
          <img
            alt='action delete'
            src={IconDelete}
            onClick={props.onClickIconDelete}
            className={`
              w-[24px] h-[24px] absolute right-[10px] pointer cursor-pointer
            `}
          />
          :
          <img
            alt='action upload'
            src={IconUpload}
            onClick={props.onClickIcon}
            className={`
              w-[24px] h-[24px] absolute right-[10px] pointer-events-none 
            `}
          />
        }
      </div>
      {
        true ? (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-gray-600 
          '>
            {props.helperText}
          </p>
        ) : (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-error-main 
          '>
            {hasValue ? 'Este campo es obligatorio' : props.errorText }
          </p>
        )
      }
    </div>
  )
};

export default InputFile
