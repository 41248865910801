import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import InputSelect from 'components/InputSelect'
import { useTranslation } from 'react-i18next'
import { useGetClinicNetwork } from 'features/UserManagement/hooks/useClinicNetwork';
import { useQueryClient } from 'react-query'

interface Props {
  isRequired?: boolean
  classNameWrapper?: string
  control: any
  countryId?: string
}

const SelectTypeCountry: React.FC<Props> = ({
  isRequired,
  classNameWrapper,
  control,
  countryId
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data } = useGetClinicNetwork(countryId)
  const clinicNetworks = data?.data

  useEffect(() => {
    if (countryId) {
      queryClient.invalidateQueries('clinicNetworks')
    }
  }, [countryId])

  return (
    <Controller
      control={control}
      name='clinicNetworkId'
      render={
        ({ field: { onChange, value, name, ref } }) => (
          <InputSelect
            label={t('name_clinic_network')}
            ref={ref}
            name={'clinicNetworkId'}
            value={value}
            onSelect={e => onChange(e)}
            placeholder='Rol'
            required={isRequired}
            classNameWrapper={classNameWrapper}
          >
         {/*    {console.log('clinicNetwork',clinicNetworks )} */}
            {
              clinicNetworks && clinicNetworks.length > 0 && clinicNetworks.map((clinicNetwork: any, i: number) => (
                <option key={i} value={clinicNetwork?.id}>{clinicNetwork?.name}</option>
              ))
            }
          </InputSelect>
        )
      }
    />
)}

export default SelectTypeCountry