import IconHeart from '../../../../assets/icons/icon_heart_bg.svg'
import IconBreath from '../../../../assets/icons/icon_breathing_bg.svg'
import IconPressure from '../../../../assets/icons/icon_pressure_bg.svg'
import IconTemperature from '../../../../assets/icons/icon_temperature_bg.svg'
import IconSaturation from '../../../../assets/icons/icon_saturation_bg.svg'
import IconPerson from '../../../../assets/icons/icon_person_bg.svg'

interface Item {
  icon: any
  text: string
}

type Items = { [key: string]: Item}

export const VITALS_CONFIG: Items = {
  'heart': {
    'icon': () => IconHeart,
    'text': 'Frecuencia cardiaca'
  },
  'pressure': {
    'icon': () => IconPressure,
    'text': 'Presión arterial'
  },
  'saturation': {
    'icon': () => IconSaturation,
    'text': 'Saturación'
  },
  'breath': {
    'icon': () => IconBreath,
    'text': 'Frecuencia respiratoria'
  },
  'temperature': {
    'icon': () => IconTemperature,
    'text': 'Temperatura'
  },
  'position': {
    'icon': () => IconPerson,
    'text': 'Posición / Caída'
  }
}