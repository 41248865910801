import React, { useEffect, useState } from 'react'
import IconList from 'assets/icons/icon_list.svg'
import LabelForm from 'components/LabelForm'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import SelectTypeRole from 'features/UserManagement/components/SelectTypeRole/Input';
import SelectCountry from 'features/UserManagement/components/SelectCountry';
import SelectClinic from 'features/UserManagement/components/SelectClinic';
import SelectClinicNetwork from 'features/UserManagement/components/SelectClinicNetwork';
import InputSelect from 'components/InputSelect'
import { ROLE } from 'global/constants/roles'
import useUser from 'features/shared/hooks/useUser'

interface Props {
  watch: any
  control: any
  isCountry?: boolean
  isClinicNetwork?: boolean
  isClinic?: boolean
  isZone?: boolean
  setValue:  (e: any,val: any) => void;
}

const FormProfileSection: React.FC<Props> = ({
  watch,
  control,
  isCountry,
  isClinicNetwork,
  isClinic,
  isZone,
  setValue,
}) => {
  const { t } = useTranslation()
  const { userCurrent } = useUser();

  const [selected, setSelected] = useState<any>()
  const roleSelected = selected?.slug

  const handleSelectedItem = (item: any) => {
    setSelected(item)
  }

  const handleChangeRole = (val: string) => {
    setValue('roleId', val)
  }

  useEffect(() => {
    if (roleSelected === ROLE.SUPER_ADMIN) {
      setValue('countryId', null)
      setValue('clinicNetworkId', null)
    }
  }, [selected])

  return (
    <>
      <LabelForm icon={IconList} label="Perfil" className='mb-6' />
      <div className={`grid sm:grid-cols-3 gap-2`}>
        <div className="col">
          <Controller
            control={control}
            name='roleId'
            render={({ field: { onChange, value, name, ref } }) => (
              <SelectTypeRole
                getItem={handleSelectedItem}
                onChange={handleChangeRole}
                inputRef={ref}
                value={value}
                isRequired
              />
            )}
          />
        </div>
        {
          (isCountry && roleSelected === ROLE.COUNTRY_ADMIN ) &&
          <div className="col">
            <SelectCountry control={control} />
          </div>
        }
        {
          (isClinicNetwork && watch('countryId')) &&
          <div className="col">
            <SelectClinicNetwork control={control} countryId={watch('countryId')} />
          </div>
        }
        {
          (isClinic) &&
          <div className="col">
            <SelectClinic control={control} clinicNetworkId={watch('clinicNetworkId')} />
          </div>
        }
        {/* {
          (isZone) &&
          <div className="col">
            <InputSelect
              label={t('type_region')}
              name="regionType"
              onSelect={e => console.log(e)}
              placeholder='Región'
            >
              <option>Región 1</option>
              <option>Región 2</option>
            </InputSelect>
          </div>
        } */}
      </div>
    </>
  )
}

export default FormProfileSection