import { useQuery, useMutation } from 'react-query';
import { getClinicNetworks, getClinicNetworksSearchResults, createClinicNetwork, getClinicsNetworkById, updateClinicNetwork } from '../services/clinicNetwork';

export const useGetClinicNetwork = (countryId?: string) => {
  return useQuery(['clinicNetworks', countryId], () => getClinicNetworks(countryId));
}

export function useGetClinicNetworkSearchResults() {
  return useMutation((query: string) => getClinicNetworksSearchResults(query));
}

export function useCreateClinicNetwork() {
  return useMutation((data: any) => createClinicNetwork(data));
}

export function useGetClinicNetworkById() {
  return useMutation((id: any) => getClinicsNetworkById(id))
}

export function useUpdateClinicNetwork() {
  return useMutation((data: any) => updateClinicNetwork(data));
}