import { useMutation } from 'react-query'
import { getDailyEvolution, createDailyEvolution} from '../services/dailyEvolution'
import { DailyEvolutionPost } from '../types/dailyEvolution'

export function useGetDailyEvolution() {
  return useMutation((serviceId: string) => getDailyEvolution(serviceId))
}

export function useCreateDailyEvolution() {
  return useMutation((data: DailyEvolutionPost) => createDailyEvolution(data));
}