import fetcher from 'features/shared/utils/fetcher'

export async function uploadFile(data: any) {
  let formData: any = new FormData();
  for (let key in data) {
  if (data[key]) {
    formData.append(key, data[key]);
  }
  }
  return await fetcher.post('upload/file', formData)
}