import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import Loader from 'components/Loader'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import {
  useCreateUser,
  useUpdateUser,
  useGetUserById,
} from 'features/UserManagement/hooks/useUser'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import FormSpecialitySection from 'features/UserManagement/components/FormSpecialitySection'
import FormPersonalSection from 'features/UserManagement/components/FormPersonalSection'
import FormProfileSection from 'features/UserManagement/components/FormProfileSection'
import UploadProfilePhoto from 'features/UserManagement/components/UploadProfilePhoto'
import useUser from 'features/shared/hooks/useUser'
import { ROLE } from 'global/constants/roles'
import { onAlert } from 'components/Alert'

const FormUserCreate: React.FC = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const history = useHistory()
  const location = useLocation()
  const params: any = useParams()
  const state = location?.state as any
  const userStorage = state?.userStorage
  const { register, watch, control, handleSubmit, setValue } = useForm()
  const { userCurrent } = useUser()
  const role = userCurrent?.roleSlug

  const [userRole, setUserRole] = useState(null as any)
  const [imgProfile, setImgProfile] = useState(null as any)
  const [imgSignature, setImgSignature] = useState(null as any)
  const [isEdit, setEdit] = useState(false)
  const [gender, setGender] = useState(null)


  const { mutateAsync: asyncCreateUser, isLoading: isLoadingCreateUser } =
    useCreateUser()
  const { mutateAsync: asyncUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser()
  const { mutateAsync: asyncGetUserById, isLoading: isLoadingGetUserById } =
    useGetUserById()

  const validateForm = () => {
    if (
      watch('name') &&
      watch('lastname') &&
      watch('email') &&
      watch('roleId')
    ) {
      return true
    } else {
      return false
    }
  }

  const onSubmit = async (sendData: any) => {
    const formatData = { ...sendData }
    formatData.roleId = Number(sendData.roleId)
    formatData.ipressId = userCurrent?.ipress?.id
    formatData.signatureFile = imgSignature?.source
    formatData.photoFile = imgProfile?.source
    formatData.gender = gender
    formatData.typeUser = role
    let response = null

    if (isEdit) {
      formatData.id = userStorage.id;

      if (role === ROLE.SERVICE_ADMIN) {
        formatData.clinicId = null // ¡Ups! "clinicId" is not allowed
      }
      if (role === ROLE.COUNTRY_ADMIN) {
        formatData.ipressId = watch('clinicId')
        formatData.clinicId = null // ¡Ups! "clinicId" is not allowed
        formatData.clinicNetworkId = null // ¡Ups! "clinicNetworkId" is not allowed
      }

      response = await asyncUpdateUser(formatData, role)
    } else {
      response = await asyncCreateUser(formatData, role)
    }

    if (Number.isInteger(response?.data)) {
      if (isEdit) {
        onAlert.success('Usuario actualizado');

      } else {
        onAlert.success('Usuario creado');
        
        setTimeout(() => {
          history.push(`/user-management`)
          
        }, 500)

      }
    } else {
      if (response?.data) {
        onAlert.error(response?.data);
      } else {
        onAlert.error(response);
      }
    }
  }

  const buildImage = (id?: string, file?: any) => {
    const img = new Image()
    const objectUrl = URL.createObjectURL(file)
    img.onload = function () {
      URL.revokeObjectURL(objectUrl)
    }
    img.src = objectUrl
    return img
  }

  const handleUploadMultimedia = (e: any) => {
    e.preventDefault()
    const img = buildImage('preview', e.target.files[0])
  /*   console.log(e.target.files[0].size) */

    if (e.target.files[0].size < 2000000) {
      setImgProfile({
        img,
        source: e.target.files[0],
      })
    } else {
      onAlert.warning('El archivo seleccionado no puede exceder los 2 MB')
    }
  }

  const handleUploadMultimediaSignature = (e: any) => {
    e.preventDefault()
    const img = buildImage('previewSignature', e.target.files[0])
    if (e.target.files[0].size < 2000000) {
      setImgSignature({
        img,
        source: e.target.files[0],
      })
    } else {
      onAlert.warning('El archivo seleccionado no puede exceder los 2 MB')
    }
  }

  const handleClickDeletePhoto = (e: any) => {
    e.preventDefault()
    setImgProfile(null)
  }

  const handleClickDeleteSignature = (e: any) => {
    e.preventDefault()
    setImgSignature(null)
  }

  const handleClickCancel = (e: any) => {
    e.preventDefault()
    history.goBack()
  }

  const onGetUserData = async (mounted?: boolean) => {
    if (mounted) return
    const userRole = await asyncGetUserById({
      id: params.userId,
      typeUser: role,
    })
    if (userRole?.data) setUserRole(userRole.data)
  }

  useEffect(() => {
    if (userStorage) {
      setEdit(true)
      onGetUserData()
    }
  }, [])

  useEffect(() => {
    if (userRole) {
      setValue('name', userRole?.name)
      setValue('countryId', userRole?.countryId)
      setValue('lastname', userRole?.lastname)
      setValue('email', userRole?.email)
      setValue('specialty', userRole?.specialty)
      setValue('rne', userRole?.rne)
      setValue('cmp', userRole?.cmp)
      setValue('roleId', userRole?.roleId)
      setValue(
        'clinicNetworkId',
        userRole?.clinicNetworkId || userCurrent?.clinicNetworkId
      )
      setValue('clinicId', userRole?.ipressId)
      setValue('gender', userRole?.gender)
      setValue('phone', userRole?.phone)
      setGender(userRole?.gender)
      setImgProfile({
        img: {
          src: userRole?.photoUrl,
          name: userRole?.photoFilename,
        },
      })
      setImgSignature({
        img: {
          src: userRole?.signatureUrl,
          name: userRole?.signatureFilename,
        },
      })
    }
  }, [userRole])

  useEffect(() => {
    if (userCurrent) {
      setValue('clinicNetworkId', userCurrent?.clinicNetworkId)
    }
    if (userCurrent?.ipress?.id) {
      setValue('ipressId', userCurrent?.ipress?.id)
      setValue('clinicNetworkId', userCurrent?.ipress?.clinicNetworkId)
    }
  }, [userCurrent])

  return (
    <React.Fragment>
      {(isLoadingCreateUser || isLoadingUpdateUser || isLoadingGetUserById) && (
        <Loader />
      )}
      <form onSubmit={handleSubmit(onSubmit)} data-testid="user__create">
        <UploadProfilePhoto
          handleUploadMultimedia={handleUploadMultimedia}
          handleClickDeletePhoto={handleClickDeletePhoto}
          isEditable={role === ROLE.SERVICE_ADMIN}
          imgProfile={imgProfile}
          watch={watch}
        />
        {role === ROLE.SUPER_ADMIN && (
          <FormProfileSection
            setValue={setValue}
            isCountry={watch('roleId')}
            isClinicNetwork={watch('roleId') && watch('countryId')}
            watch={watch}
            control={control}
          />
        )}
        {role === ROLE.COUNTRY_ADMIN && (
          <FormProfileSection
            isClinicNetwork={watch('roleId')}
            isClinic={watch('roleId')}
            setValue={setValue}
            watch={watch}
            control={control}
          />
        )}
        {role === ROLE.SERVICE_ADMIN && (
          <FormProfileSection
            //isClinic={watch('roleId')}
            isZone
            setValue={setValue}
            watch={watch}
            control={control}
          />
        )}
        <FormPersonalSection
          handleSelect={(val: any) => {
            setGender(val)
          }}
          gender={gender}
          register={register}
          watch={watch}
        />
        {role !== ROLE.SUPER_ADMIN && role !== ROLE.COUNTRY_ADMIN && (
          <FormSpecialitySection
            imgSignature={imgSignature}
            handleUploadMultimediaSignature={handleUploadMultimediaSignature}
            handleClickDeleteSignature={handleClickDeleteSignature}
            register={register}
            watch={watch}
            control={control}
          />
        )}
        <div className="flex justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleClickCancel}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="large"
            disabled={!validateForm()}
          >
            Guardar
          </Button>
        </div>
      </form>


    </React.Fragment>
  )
}

export default FormUserCreate
