import { FC, useContext } from "react"
import { useTranslation } from "react-i18next"

import { WorkListContext } from "features/PatientDashboard/PatientWorkPlan/WorkPlanList"
import { PlanTodayMedicamentsModel  } from "features/PatientDashboard/hooks/userPlanToday"
import ModalRelative from "./ModalRelative"
import MedicineQuestionCard from "./MedicineQuestionCard"

const getMedicineName = (
  description: PlanTodayMedicamentsModel['description']
) => description.replace(/tomar /gi, '')

const MedicineModal: FC = () => {
  const { t } = useTranslation()

  const {
    medicineToCheckList,
    onMedicineNotCheckedClickYes,
    onCloseMedicineModal,
    setShowWhyNotTakeMedicineModal,
    setMedicineNotTakenSelected,
  } = useContext(WorkListContext)

  const getOnClickYes = (medicine: PlanTodayMedicamentsModel) => () => {    
    onMedicineNotCheckedClickYes(medicine)
  }

  const getOnClickNo =  (medicine: PlanTodayMedicamentsModel) => () => {   
    setShowWhyNotTakeMedicineModal(true)
    onCloseMedicineModal()
    setMedicineNotTakenSelected(medicine)
  }

  return (
    <>
      <ModalRelative>
        <p className="text-left text-subtitle2 mb-3">
          {t('remember_to_take_your_medicine')}
        </p>
        <div className="flex flex-col gap-4">
          {medicineToCheckList.map((medicine, index) => (
            <MedicineQuestionCard
              key={medicine.supplyId}
              medicineName={getMedicineName(medicine.description)}
              onClickYes={getOnClickYes(medicine)}
              onClickNo={getOnClickNo(medicine)}
            />
          ))}
        </div>
      </ModalRelative>
    </>
  )
}

export default MedicineModal
