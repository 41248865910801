import React from "react";
import IconClose from 'assets/icons/icon_close.svg';
import Button from "components/Button";

interface ConfirmModalProps {
  onCloseModal: () => any;
  onAcceptProcess: () => any;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative py-8 px-6 w-full max-w-[400px]">
          <div
            className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4"
          >
            <h4 className='text-headline4 font-bold text-center'>
              ¡Recuerda!
            </h4>

            <p className="mb-4 text-body2 text-center my-6 text-gray-600">
            Si guardas todas las demás pestañas se deshabilitarán y no podrás realizar ningún cambio, revisa bien antes de seguir. <br />
              <span className='text-subtitle1 text-primary-main'>¿Deseas continuar?</span>
            </p>
            <div className="grid grid-cols-2 gap-4">
              <Button
                onClick={() => props.onCloseModal()}
                variant='secondary'
                size="small"
                type="button"
              >
                No
              </Button>
              <Button
                onClick={() => props.onAcceptProcess()}
                variant='primary'
                size="small"
                type="button"
              >
                Si
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  )
};

export default ConfirmModal;
