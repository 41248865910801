import fetcher from 'features/shared/utils/fetcher'

async function putRegisterService(value: any ) {
  return await fetcher.put(
    `/patient/${value?.patientId}/service/${value?.serviceId}/edit`,
    value?.data
  )
}

export default putRegisterService
