import IconMapPin from 'assets/icons/icon_map_pin.svg';

const OptionMapAddress = (props: any) => (
  <div className='hover:cursor-pointer hover:bg-grey-50 ' onClick={props.onClick} data-testid={props['data-testid']}>
    <div className="flex flex-column items-center py-4 mx-4 border-b-[1px] border-solid">
      <img src={IconMapPin} alt="place icon"  className='w-[24px] h-[24px] mr-4'/>
      <p className='w-full text-body2 text-text-primary'>
        {props.main} <br />
        <span className='text-caption'>{props.secondary}</span>
      </p>
    </div>
  </div>
);

export default OptionMapAddress;
