import { useEffect, useState } from 'react';
import IconChevronDown from 'assets/icons/icon_chevron_down.svg';
import IconChevronUp from 'assets/icons/icon_chevron_up.svg';

interface GroupMenuProps {
  isActive: boolean;
  icon?: string;
  label: string;
  children: React.ReactNode;
  type: string;
}

const GroupMenu: React.FC<GroupMenuProps> = ({
  isActive,
  icon,
  children,
  label,
  type
}) => {
  const [toggle, setToggle] = useState<boolean>(isActive);

  return (
    <div className='my-2'>
      <div
        className={`
          w-full flex justify-between items-center hover:bg-grey-50 rounded-[8px] p-2 cursor-pointer 
          ${toggle && type === 'group' ? 'bg-grey-50' : ''}
        `}

        onClick={() => setToggle(!toggle)}
      >
        <div className='grow flex items-center gap-1 text-grey-600 text-body1'>
          { icon && 
            <img 
              src={icon} 
              alt='icon'
              className='w-4 h-4'
            />
          }

          {label}
        </div>

        <img src={toggle ? IconChevronUp : IconChevronDown} alt='icon' className='w-4 h-4'/>
      </div>
      
      { toggle && 
        <div className='py-2'>
          {children}
        </div>
      }
    </div>
  )
}

interface GroupMenuLightProps {
  isActive: boolean
  icon?: string
  label: string
  children: React.ReactNode
  type: string
}

export const GroupMenuLight: React.FC<GroupMenuLightProps> = ({
  isActive,
  icon,
  children,
  label,
  type
}) => {

  return (
    <div className='my-2'>
      {
        type === 'section' &&
        <div className='w-8 h-8 mx-auto text-center my-6'>
          <span className='inline-block w-[3px] h-[3px] bg-[#141414] rounded-full m-[1px]'></span>
          <span className='inline-block w-[3px] h-[3px] bg-[#141414] rounded-full m-[1px]'></span>
          <span className='inline-block w-[3px] h-[3px] bg-[#141414] rounded-full m-[1px]'></span>
          <span className='inline-block w-[3px] h-[3px] bg-[#141414] rounded-full m-[1px]'></span>
          <span className='inline-block w-[3px] h-[3px] bg-[#141414] rounded-full m-[1px]'></span>
        </div>
      }
      {
        type === 'group' &&
        <div className='bg-grey-50 p-2 rounded w-8 h-8 mx-auto'>
          { icon && 
            <img 
              src={icon} 
              alt='icon'
              className='w-4 h-4'
            />
          }
        </div>
      }
      <div>
        {children}
      </div>
    </div>
  )
}

        


export default GroupMenu
