

const Loader = () => {

  return (
    <div className="relative">
    <div
      className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none "
    >
      <div className="w-24 h-24 border-l-4  border-bg4 rounded-full animate-spin"></div>
    </div>
    <div className="opacity-25 fixed inset-0 z-10 bg-black"></div>
    </div>
  )
}

export default Loader
