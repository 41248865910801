import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BehaviorSubject, debounceTime } from 'rxjs'

import { useGetAttentsListPatients , useGetListPatients } from 'features/Patients/hooks/usePatient'
import { PatientsModel } from 'features/shared/types/userType'
import useUser from 'features/shared/hooks/useUser'

import InputSelect from 'components/InputSelect'
import Pagination from 'components/Pagination'
import Input from 'components/input'
import Breadcrumbs from 'components/Breadcrumbs'

import Loader from '../../../components/Loader'
import { routes } from '../../../routes/routing'

import IconSearch from 'assets/icons/icon_search.svg'
import IconHeart from 'assets/icons/icon_heart.svg'
import IconBloodPressure from 'assets/icons/icon_bloodPressure.svg'
import IconBreathing from 'assets/icons/icon_breathing.svg'
import IconSaturation from 'assets/icons/icon_saturation.svg'
import IconTemperature from 'assets/icons/icon_temperature.svg'
import IconPerson from 'assets/icons/icon_person.svg'
import { ROLE } from 'global/constants/roles'

const pageSize = 10
const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

const MonitoringListPatients: React.FC = () => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState('')
  const [selectValue, setSelectValue] = useState<any>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchText, setSearchText] = useState('')
  const { userCurrent } = useUser()

  const [listPatients, setListPatients] = useState<PatientsModel>({
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1,
  })

  const {
    isLoading: listAttentsPatientLoading,
    mutateAsync: mutateAsyncAttentsListPatients,
  } = useGetAttentsListPatients() 
  const {
    isLoading: listPatientLoading,
    mutateAsync: mutateAsyncListPatients,
  } = useGetListPatients()

  useEffect(() => {
    onGetListPatients(searchValue, currentPage)

    const searchSubscription = inputChange$
      .pipe(debounceTime(500))
      .subscribe((value) => {
        setSearchValue(value)
      })

    return () => {
      return searchSubscription.unsubscribe()
    }
  }, [userCurrent?.ipress])

  useEffect(() => {
    if (currentPage) {
      onGetListPatients(searchValue, currentPage)
    }
  }, [currentPage])

  useEffect(() => {
    onGetListPatients(searchValue, 1)
  }, [searchValue])

  const getHeartRate = (index: number) => {
    const base = {
      pressure: '90/60 mmHg',
      saturation: '95%',
      breath: '20/min',
      temperature: '37°',
    }
    if (index % 4 === 0) return { hr: '58/min', position: 'Caminando', ...base }
    if (index % 4 === 1) return { hr: '45/min', position: 'Parado', ...base }
    if (index % 4 === 2) return { hr: '54/min', position: 'Echado', ...base }
    if (index % 4 === 3) return { hr: '64/min', position: 'Caminando', ...base }
  }

  const hydrateData = (response: any) => {
    const { data } = response
    const formatted = { ...response }
    const newData = data.map((patient: any, index: number) => ({
      ...patient,
      ...getHeartRate(index),
    }))
    formatted['data'] = newData
    return formatted
  }

  const onGetListPatients = async (
    searchValue?: string,
    currentPage?: number
  ) => {
    const searchText = searchValue !== '' ? `&searchText=${searchValue}` : ''
    const query = `?country=${userCurrent?.ipress?.countryId}&page=${currentPage}${searchText}`
    const response = userCurrent?.roleSlug === ROLE.PROFESSIONAL_HEALTH? await mutateAsyncAttentsListPatients(query): await mutateAsyncListPatients(query);
    setListPatients(response.data)
  }

  const onClickItem = (item: any) => {
    history.push(`${routes.monitoring.url}/patient/${item.id}`)
    sessionStorage.setItem('patient', JSON.stringify(item))
  }

  return (
    <React.Fragment>
      {(listPatientLoading || listAttentsPatientLoading) && <Loader />}
      <Breadcrumbs
        name={'Monitoreo'}
        currentPathName={'Monitoreo'}
      />
      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        <div className="px-4 pb-[24px] flex items-center flex-wrap justify-between">
          <Input
            label="Buscar paciente"
            value={searchText}
            icon={IconSearch}
            onChange={(e: any) => {
              inputChange.next(e.target.value)
              setSearchText(e.target.value)
            }}
            classNameWrapper="flex-[0_0_100%] max-w-[416px]"
          />

        {/*   <InputSelect
            label="Posición/ Caída"
            name="position"
            required={false}
            onSelect={(val) => {
              setSelectValue(val)
            }}
            value={selectValue}
            classNameWrapper="block flex-[0_0_100%] max-w-[260px]"
          >
            <option value={1}>Caminando</option>
            <option value={1}>Parado</option>
          </InputSelect> */}
        </div>

        {(!listPatientLoading || !listAttentsPatientLoading) && (
          <React.Fragment>
            <section data-testid="Table" className="w-full overflow-auto scrollbar">
              <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className="even:border-t-[1px] even:border-t-grey-200">
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Nombre y Apellidos
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      <div className="flex pt-[8px] pb-[8px]">
                        <img
                          className="mr-2"
                          src={IconHeart}
                          alt="icono frecuencia cardiaca"
                          width="22"
                          height={'22'}
                        />
                        Fr Cardiaca
                        <br />
                        (HR)
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      <div className="flex pt-[8px] pb-[8px]">
                        <img
                          className="mr-2"
                          src={IconBloodPressure}
                          alt="icono presión arterial"
                          width="22"
                          height={'22'}
                        />
                        Presión Arterial
                        <br />
                        (BP)
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      <div className="flex pt-[8px] pb-[8px]">
                        <img
                          className="mr-2"
                          src={IconSaturation}
                          alt="icono saturación"
                          width="22"
                          height={'22'}
                        />
                        Saturación
                        <br />
                        (Sat O2)
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      <div className="flex pt-[8px] pb-[8px]">
                        <img
                          className="mr-2"
                          src={IconBreathing}
                          alt="icono frecuencia respiratoria"
                          width="22"
                          height={'22'}
                        />
                        Fr Respiratoria
                        <br />
                        (RR)
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      <div className="flex pt-[8px] pb-[8px]">
                        <img
                          className="mr-2"
                          src={IconTemperature}
                          alt="icono temperatura"
                          width="22"
                          height={'22'}
                        />
                        Temp.
                        <br />
                        °C
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      <div className="flex pt-[8px] pb-[8px]">
                        <img
                          className="mr-2"
                          src={IconPerson}
                          alt="icono Posición - caída"
                          width="22"
                          height={'22'}
                        />
                        Posición /<br />
                        Caída
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {listPatients?.data?.length > 0 ? (
                    hydrateData(listPatients)?.data?.map(
                      (item: any, index: any) => (
                        <tr
                          key={index}
                          className="h-[54px] hover:bg-grey-50 cursor-pointer"
                          onClick={() => onClickItem(item)}
                        >
                          <td className="px-4 py-2 ">
                            <div className="text-body2">
                              {item.name} {item?.lastname}
                            </div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">{item.hr}</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">60/90</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">98%</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">123/min</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">36°C</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">{item.position}</div>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr className="h-[54px]">
                      <td colSpan={5} className="px-4 text-center">
                        No se encontraron resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>

            <div className="flex justify-end mt-[24px] px-4">
              <Pagination
                currentPage={listPatients?.currentPage}
                totalCount={listPatients?.total}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default MonitoringListPatients

