import fetcher from 'features/shared/utils/fetcher';
import { useMutation, useQuery } from 'react-query';

export interface PatientClinicHistory {
  secondary_diagnostic_description: string;
  principal_diagnostic_description: string;
  id: string;
  drug_allergies: string;
  other_drug_allergies: string;
  medical_history: string;
  other_medical_history: string;
  surgical_history: string;
  other_surgical_history: string;
  family_background: string;
  usual_treatment: string;
  sick_time: string;
  principal_diagnostic: string;
  secondary_diagnostic: string;
  medical_condition: string;
  serviceId: number;
}

export function useGetPatientClinicHistory() {
  return useMutation((serviceId: any) => getPatientClinictHistory(serviceId));
}

async function getPatientClinictHistory(id: any) {
  return await fetcher.get('/service/' + id + '/medical-history');
}

export function usePostPatientClinicHistory() {
  return useMutation((value: object)=> postPatientClinicHistory(value));
}

async function postPatientClinicHistory(value: object ) {
  return await fetcher.post( `/medical-history/create`, value );
}

export function useUpdatePatientClinicHistory() {
  return useMutation((body: any)=> putPatientClinicHistory(body));
}

async function putPatientClinicHistory(body: any ) {
  return await fetcher.put( `/medical-history/${body.id}/edit`, body.data );
}

