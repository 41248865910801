import React, { useEffect, useState } from "react"
import Button from "components/Button"
import CheckButton from 'components/CheckButton'
import LabelForm from 'components/LabelForm'
import Input from 'components/input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useCreateUserRole, useRolePermissions, useUpdateUserRole } from 'features/UserManagement/hooks/useUserRole'

import ScrollToBottom from 'react-scroll-to-bottom'
import IconClose from 'assets/icons/icon_close.svg'
import { onAlert } from "components/Alert"

interface Props {
  showModal: boolean;
  onCloseModal: (isOkay?: boolean) => any;
  onSubmitForm: () => any;
  dataStorage?: any
  isLoading?: boolean
}

const ModalRole: React.FC<Props> = ({
  onCloseModal,
  showModal,
  isLoading,
  dataStorage
}) => {
  const { t } = useTranslation()
  const { register, watch, handleSubmit, setValue } = useForm()
  
  const [permissions, setPermissions] = useState(null as any)
  const [isEdit, setIsEdit] = useState(false)

  const { mutateAsync: asyncCreateUserRole } = useCreateUserRole()
  const { mutateAsync: asyncUpdateUserRole } = useUpdateUserRole()
  const { data: permissionData } = useRolePermissions()
  const permissionsList = permissionData?.data

  const hydrateData = (data: any) => data?.map((item: any) => ({
    permissionSlug: item.slug,
    name: item.name,
    actionRead: 0,
    actionWrite: 0
  }))

  const handleChangeCheckboxRead = (name: string) => {
    const pos = permissions.findIndex((item: any) => item.permissionSlug === name)
    const duplicated = [...permissions]
    duplicated[pos] = {
      ...permissions[pos],
      actionRead: !permissions[pos]['actionRead']
    }
    setPermissions(duplicated)
  }

  const handleChangeCheckboxWrite = (name: string) => {
    const pos = permissions.findIndex((item: any) => item.permissionSlug === name)
    const duplicated = [...permissions]
    duplicated[pos] = {
      ...permissions[pos],
      actionWrite: !permissions[pos]['actionWrite']
    }
    setPermissions(duplicated)
  }

  const handleClickCancel = () => {
    onCloseModal()
  }

  const onSubmit = async (send: any) => {
    const formatData = {...send}
    formatData['permissions'] = [...permissions]
    let response
    if (isEdit) {
      formatData.id = dataStorage.id
      response = await asyncUpdateUserRole(formatData)
    } else {
      response = await asyncCreateUserRole(formatData)
    }
    
    if (response?.data) {
      onAlert.success(isEdit? 'Rol actualizado' : 'Nuevo rol creado');
      onCloseModal(true)

    } else {
      onAlert.error(response);
    }
  }

  const validateForm = () => {
    let isFoundSelected = false
    if (permissions && permissions.length > 0) {
      for ( let item of permissions) {
        if (item.actionWrite == true || item.actionRead == true) {
          isFoundSelected = true
          break
        }
      }
      if(watch('name') && isFoundSelected ) {
        return true
      } else {
        return false
      }
    }
    return false
  }

 
  
  const buildOptions = (a: any[], b: any[], prop: string) => {
    const newArr = a.map(aitem => {
      const item = b.find(bitem => aitem[prop] === bitem[prop])
      if (item) return {...item}
      return aitem
    })
    return newArr;
  }

  useEffect(() => {
    if (permissionsList && permissionsList.length > 0) {
      const currentPermissions = hydrateData(permissionsList)
      if (dataStorage) {
        const optionsBuilded = buildOptions(currentPermissions, dataStorage?.permissions, 'permissionSlug');
        setIsEdit(true)
        setPermissions(optionsBuilded)
        setValue('name', dataStorage?.name)
      } else {
        setIsEdit(false)
        setPermissions(currentPermissions)
        setValue('name', '')
      }
    }
  }, [permissionsList, dataStorage])


  if (!showModal) {
    return null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative p-4 flex flex-col  w-full max-w-[90%] max-h-[80vh] 2xl:h-[600px]	 bg-white rounded-lg ">
            <div className="w-full flex items-center justify-between pb-6">
              <LabelForm label="Agregar nuevo rol" className="font-semibold"/>
              <button
                className="close bg-transparent border-0 text-black text-xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => onCloseModal()}
              >
                <img src={IconClose} alt={IconClose} />
              </button>
            </div>
            <div className="mb-6 lg:w-[333px]">
              <Input
                type="text"
                typeValidation=''
                label={t('name_role')}
                value={watch('name')}
                {...register('name')}
              />
            </div>
            <LabelForm label="Permisos" className="font-semibold" />
            <ScrollToBottom mode={'top'} followButtonClassName='ScrollToBottom__btn__top'	 scrollViewClassName=' overflow-y-scroll scrollbar' className=" bg-white rounded-lg  w-full h-[240px] mb-8">

            <div className=" pr-[16px]">
            <table className="min-w-full divide-y divide-gray-200 border-collapse ">
              <tbody className="bg-white divide-y divide-gray-200">
                  {
                    permissions && permissions.length > 0 && permissions.map((permission: any, i: number) => (
                      <tr key={i} className="h-[54px] hover:bg-grey-50 cursor-pointer">
                        <td className="px-4 py-2 whitespace-nowrap">
                          <div className="text-body2">
                            {permission?.name}
                          </div>
                        </td>
                        <td className="px-4 py-2 ">
                          <div
                            className="text-body2"
                          >
                            <div onClick={() => handleChangeCheckboxRead(permission.permissionSlug)} className='flex'>
                              <CheckButton selected={permission.actionRead == 1 }>
                              </CheckButton>
                              <span className="ml-2">Leer</span>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-2 ">
                          <div
                            className="text-body2"
                          >
                            <div className="text-body2" >
                              <div onClick={() => handleChangeCheckboxWrite(permission.permissionSlug)} className='flex'>
                                <CheckButton selected={permission.actionWrite == 1}/>
                                <span className="ml-2">Escribir</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        </tr>
                    ))
                  }
              </tbody>
            </table>
            </div>
            </ScrollToBottom>
            <div className="flex justify-center gap-4 mt-6">
              <Button type="button" variant='secondary' size="large" onClick={handleClickCancel} >Cancelar</Button>
              <Button
                disabled={!validateForm()}
                type="submit"
                variant='primary'
                size="large"
              >{isEdit ? 'Actualizar' : 'Guardar'}</Button>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        
    </form>
  )
};

export default ModalRole;
