import { Controller, useForm } from 'react-hook-form';
import InputSelect from 'components/InputSelect';
import { useEffect, useState } from 'react';
import CheckBox from 'components/CheckBox';
import IconDelete from 'assets/icons/icon_delete.svg';
import { SuppliesOptionsModel, SuppliesServiceModel } from '../hooks/useSupplies';
import IconButton from 'components/IconButton';
import InputDatePicker from 'components/InputDatePicker';
import Moment from "moment";
import Input from 'components/input';
import InputSelectSearch from 'components/InputSelectSearch'

interface PrescriptionElementProps {
  data: SuppliesServiceModel,
  supplyOptions: SuppliesOptionsModel[],
  onChange: (el: SuppliesServiceModel) => void;
  onDelete: () => void;
  isPlainForm: boolean;
  onChangeSearch: (value: string) => void;
  isLoadingSearch: boolean;
}

const PrescriptionElement: React.FC<PrescriptionElementProps> = ({
  data,
  supplyOptions,
  onChange,
  onDelete,
  isPlainForm,
  onChangeSearch,
  isLoadingSearch
}) => {
  const { control, setValue, watch } = useForm();
  const [currentTherapy, setCurrentTherapy] = useState<boolean>(false);
  const [medicationStartTime, setMedicationStartDate] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>({ value: '', label: '', via: '', type: '' })
  const [daysOfHospitalization, setdaysOfHospitalization] = useState(' ');

  useEffect(() => {
    if (data) {
          loadValues();

    }
  }, []);

  const loadValues = () => {
    setValue('supplyId', data?.supplyId);
    setValue('quantity', data?.quantity);

   setdaysOfHospitalization(data?.daysOfTreatment);
    setValue('schedule', data?.schedule);
/*     console.log('data?.type',data);
 */    
    setSearchValue({
      value: data?.supplyId,
      label: data?.supplyName,
      via: data?.via,
      type:  data?.type ?  data?.type:data?.supplyType
    })
    setCurrentTherapy(!!data?.current_therapeutics);
    if (data?.medicationStartTime) {
      const medTime = data?.medicationStartTime.split(':');
      const time = new Date();
      time.setHours(Number(medTime[0]) + Number(medTime[1].split(' ')[1].toLowerCase() === 'pm' ? 12 : 0))
      time.setMinutes(Number(medTime[1].split(' ')[0]))
      setMedicationStartDate(time);
    }
  }

  const handleChange = (field: any, value: any) => {
    const changeData = { ...data };

    switch (field) {
      case 'supplyIdSearch':
        changeData.supplyId = value.value;
        changeData.supplyName = value.label;
        changeData.via = value.via;
        changeData.type = value.type;
        break;
      case 'quantity':
        changeData.quantity = Number(value);
        break;
      case 'medicationStartTime':
        changeData.medicationStartTime = Moment(value).format('h:mm a').toUpperCase();
        break;
      case 'schedule':
        changeData.schedule = value;
        break;
      case 'current_therapeutics':
        changeData.current_therapeutics = value;
        break;
      case 'daysOfTreatment':
        setdaysOfHospitalization(value);
        changeData.daysOfTreatment = value;
        break;
    }

    onChange(changeData);
  }

  const handleSelectChangeInfo = (e: any) => {
    handleChange('supplyIdSearch', e)
  }

  const handleInputSelectChange = (value: any) => {
    if (value.length > 0) {
      onChangeSearch(value)
    }
  };
/*  console.log(data);
 */ 
return (
    <div className=' mt-[20px] border-b-solid border-b-[1px] pb-[20px] '>
      <div className='  items-center flex  flex-col-reverse xl:flex-row	 xl:flex gap-2'>
        <div className='flex w-full'>
          <div className='w-full flex flex-col xl:flex-row	 xl:flex gap-2  '
          >
            <div className="w-full xl:w-[20%]"
            data-testid="prescription_medicine"
            >
              <InputSelectSearch
                className={"w-full xl:min-w-[20%]"}
                label={isPlainForm ? 'Medicamento' : 'Medicamento / insumo'}
                value={searchValue}
                options={supplyOptions}
                required
                onSearch={handleInputSelectChange}
                onSelect={handleSelectChangeInfo}
                isLoading={isLoadingSearch}
              />
            </div>
            {searchValue?.type !== 2 &&
            <div className="w-full xl:w-[20%]"
            >
              <Input
                label='Vía de administración'
                value={searchValue?.via}
                readOnly
              />
            </div>
            }
            <div className={`w-full ${( searchValue?.type !== 2 ) ? 'xl:w-[13%]' : 'xl:w-[18%]'}`}>
              <Controller
                control={control}
                name='quantity'
                render={({ field: { onChange, value, name, ref } }) => (
                  <InputSelect
                    label="Cantidades"
                    name="quantity"
                    required={false}
                    onSelect={val => {
                      onChange(val);
                      handleChange(name, val)
                    }}
                    value={value}
                    ref={ref}
                    data-testid="selector-quantity"
                  >
                    <option value={1} >1</option>
                    <option value={2} >2</option>
                    <option value={3} >3</option>
                    <option value={4} >4</option>
                    <option value={5} >5</option>
                    <option value={6} >6</option>
                    <option value={7} >7</option>
                    <option value={8} >8</option>
                    <option value={9} >9</option>
                    <option value={10} >10</option>
                  </InputSelect>
                )}
              />
            </div>
            <div className={`w-full ${( searchValue?.type !== 2 ) ? 'xl:w-[13%]' : 'xl:w-[18%]'}`}>

              <Controller
                control={control}
                name='schedule'
                render={({ field: { onChange, value, name, ref } }) => (
                  <InputSelect
                    label="Horario"
                    name="schedule"
                    required={false}
                    onSelect={val => {
                      onChange(val);
                      handleChange(name, val)
                    }}
                    value={value}
                    ref={ref}
                    data-testid="prescription-schedule"
                  >
                    <option value='every_2h'>Cada 2 horas</option>
                    <option value='every_4h'>Cada 4 horas</option>
                    <option value='every_6h'>Cada 6 horas</option>
                    <option value='every_8h'>Cada 8 horas</option>
                    <option value='every_12h'>Cada 12 horas</option>
                    <option value='every_24h'>Cada 24 horas</option>
                  </InputSelect>
                )}
              />
            </div>
            <div className={`w-full ${searchValue?.type !== 2 ? 'xl:w-[17%]' : 'xl:w-[22%]'}`} data-testid="prescription-date">

              <InputDatePicker
                label={"Hora de Inicio"}
                onChange={(date: any) => {
                  setMedicationStartDate(date);
                  handleChange('medicationStartTime', date);
                }}
                selected={medicationStartTime}
                dateFormat="h:mm aa"
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Hora"
                
              />
            </div>
            <div className={`w-full ${searchValue?.type !== 2 ? 'xl:w-[17%]' : 'xl:w-[22%]'}`} data-testid="treatment-duration">
              <Input
                label="D.Tratamiento"
                type="text"
                typeValidation='number'
                required
                value={data.daysOfTreatment}
                onChange={event =>{  handleChange('daysOfTreatment', event.target.value)}}
              />
            </div>
          </div>

        </div>
        <div className="w-full xl:w-max	 justify-start">
          <IconButton
            data-testid="button_delete_treatment"
            isActivable={true}
            icon={IconDelete}
            className="bg-error-main"
            onClick={onDelete}
            type="button"
          />
        </div>
      </div>
      <div className=" items-center flex  justify-between mt-[8px] 	">
        <CheckBox
          classNameCheck='h-[18px] w-[18px] min-w-[18px]'
          className='pl-[0px] text-caption text-text-primary'
          name="Terapéutica actual"
          selected={currentTherapy}
          onSelected={() => {
            setCurrentTherapy(!currentTherapy);
            handleChange('current_therapeutics', !currentTherapy);
          }}
        />
        <div className="text-body1 ">
          Cant Total: {(parseInt(watch('quantity')) * (24 / parseInt((watch('schedule')?.replace('every_', '')?.replace('h', '')))) * parseInt(daysOfHospitalization)) ? (parseInt(watch('quantity')) * (24 / parseInt((watch('schedule')?.replace('every_', '')?.replace('h', '')))) * parseInt(daysOfHospitalization)) : 0}
        </div>
      </div>

    </div>

  )
}

export default PrescriptionElement;
