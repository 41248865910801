import fetcher from 'features/shared/utils/fetcher'
import { useMutation } from 'react-query'

export interface RecommendationModel {
  id: number;
  userId: number;
  message: string;
  name: string;
  lastname: string;
  speciality: string;
  createdAt: string;
}

export interface GetRecommendationModel {
  planId?: number;
  endDate?: string;
  startDate?: string;
}

export interface RegisterRecommendationModel {
  planId?: number;
  userId?: number;
  message?: string;
}

export function useGetLastRecomendation() {
  return useMutation((id: any) => getLastRecomendation(id));
}

async function getLastRecomendation(id: any) {
  return await fetcher.get(`/plans/${id}/recommendations`);
}

export function useGetRecommendations() {
  return useMutation((body: any) => getRecommendations(body));
}

async function getRecommendations(body: any) {
  return await fetcher.get(`/plans/${body.planId}/recommendations?startDate=${body.startDate}&endDate=${body.endDate}`);
}

export function useRegisterNewRecommendation() {
  return useMutation((data: any) => registerNewRecommendation(data));
}

async function registerNewRecommendation(data: any) {
  return await fetcher.post(`/plan/recommendations`, data);
}

export function useDeleteRecommendation() {
  return useMutation((id: any) => deleteRecommendation(id));
}

async function deleteRecommendation(id: any) {
  return await fetcher.delete(`/plan/recommendations/${id}`);
}