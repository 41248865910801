import fetcher from 'features/shared/utils/fetcher'
import { useMutation } from 'react-query'

export interface SpecialityModel {
  id: number;
  label: string;
  value: string;
}

export interface SpecialistModel {
  id: number;
  name: string;
  lastname: string;
  email: string;
  specialty: string;
}
export function useGetAssignedPersonnelList()  {
  return useMutation((serviceId: any) => getAssignedPersonnelList(serviceId));
}

async function getAssignedPersonnelList(serviceId: any) {
  return await fetcher.get( `/service/${serviceId}/specialists`);
}

export function useGetListSpeciality()  {
  return useMutation(() => getListSpeciality());
}

async function getListSpeciality() {
  return await fetcher.get( `/specialties`);
}

export function useGetListSpecialist()  {
  return useMutation((data: any) => getListSpecialist(data));
}

async function getListSpecialist(data: any) {
  return await fetcher.get( `/specialists?specialty=${data.typeSpecialistSelected}&serviceId=${data.serviceId}`);
}

export function useAddSpecialist()  {
  return useMutation((data: any) => addSpecialist(data));
}

async function addSpecialist(data: any) {
  return await fetcher.post( `service/${data.serviceId}/specialist`, data.body);

}

export function useDeleteSpecialist()  {
  return useMutation((data: any) => deleteSpecialist(data));
}

async function deleteSpecialist(data: any) {
  return await fetcher.delete( `service/${data?.serviceId}/specialist/${data?.specialistId}`);

}

export function useChangeSpecialist()  {
  return useMutation((data: any) => changeSpecialist(data));
}

async function changeSpecialist(data: any) {
  return await fetcher.patch( `service/${data?.serviceId}/specialist/${data?.specialistId}`,data.body);

}
export function useGetListSpecialityRequest()  {
  return useMutation((serviceId:any) => getListSpecialityRequest(serviceId));
}

async function getListSpecialityRequest(serviceId: any) {
  return await fetcher.get( `service/${serviceId}/specialty-request`);
}
export function usePostListSpecialityRequest()  {
  return useMutation((data:any) => postListSpecialityRequest(data));
}

async function postListSpecialityRequest(data: any) {
  return await fetcher.post( `service/${data?.serviceId}/specialty-request`,data.body);
}
export function useDeleteSpecialistRequest()  {
  return useMutation((data: any) => deleteSpecialistRequest(data));
}

async function deleteSpecialistRequest(data: any) {
  return await fetcher.delete( `service/${data?.serviceId}/specialty-request/${data?.specialistId}`);

}

export function useSpecialityRequestStatus()  {
  return useMutation((data:any) => postSpecialityRequestStatus(data));
}

async function postSpecialityRequestStatus(data: any) {
  return await fetcher.patch( `service/${data?.serviceId}/specialty-request/${data?.specialtyRequestId}/status`,data.body);
}