import { useMutation,useQuery } from 'react-query'
import { getListDevicesAndMedications,postDevicesAndMedications,getClinicNetworks } from './devicesAndMedications.service'


export function useGetListDevicesAndMedications() {
  return useMutation((query: string) => getListDevicesAndMedications(query));
}


export function usePostDevicesAndMedications() {

  return useMutation((value:object)=>postDevicesAndMedications(value) );
}

export const useGetClinicNetwork = (countryId?: string) => {
  return useQuery(['clinicNetworks', countryId], () => getClinicNetworks(countryId));
}