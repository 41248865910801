import React, { useEffect } from 'react'
import InputSelect from 'components/InputSelect'
import { useTranslation } from 'react-i18next'
import { useGetClinic } from 'features/UserManagement/hooks/useClinic';
import { useQueryClient } from 'react-query'

interface Props {
  isRequired?: boolean
  classNameWrapper?: string
  control: any,
  clinicNetworkId?: number
  inputRef?: any
  onChange?: (val: any) => any
  value?: any
  getSpecialities?: Function
}

const SelectClinic: React.FC<Props> = ({
  isRequired,
  classNameWrapper,
  clinicNetworkId,
  inputRef,
  onChange,
  value,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: clinics } = useGetClinic(clinicNetworkId)

  useEffect(() => {
    queryClient.invalidateQueries('clinics')
  }, [clinicNetworkId])

  return (
    <InputSelect
    label={t('name_clinic')}
    ref={inputRef}
    name={'clinicId'}
    value={value}
    onSelect={onChange}
    placeholder='Rol'
    required={isRequired}
    classNameWrapper={classNameWrapper}
    >
    {
        clinics && clinics.length > 0 && clinics.map((clinicItem: any, i: number) => (
        <option key={i} value={clinicItem?.id}>{clinicItem?.name}</option>
        ))
    }
    </InputSelect>
)}

export default SelectClinic