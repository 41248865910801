import { ChangeEvent, useEffect, useState } from 'react';
import { BehaviorSubject, debounceTime } from 'rxjs';

const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

interface InputSelectSearchProps {
  label?: string;
  errorText?: string;
  helperText?: string;
  value?: any;
  options: any[];
  required?: boolean;
  onClickIcon?: () => void;
  onSearch: (value: string) => void;
  onSelect: (value: any) => void;
  isLoading: boolean;
  className?: string;
  
}

interface SelectedValueModel {
  value: number;
  label: string
}

const InputSelectSearch: React.FC<InputSelectSearchProps> = ({
  label,
  errorText,
  helperText,
  value = "",
  onClickIcon,
  onSearch,
  onSelect,
  required,
  options,
  isLoading,
  className,
  ...props
}) => {
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState<SelectedValueModel>();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isHoverOptions, setIsHoverOptionns] = useState<boolean>(false);

  useEffect(() => {
    onHasValue(value?.value);
    setSelectedValue(value);
  }, [value?.value])

  useEffect(() => {

    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value: any) => {
      onSearch(value);
    });

    return () => {
      return searchSubscription.unsubscribe();
    }
  }, [])

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    onHasValue(e.target.value);
    inputChange.next(e.target.value);
    setSearchValue(e.target.value);
  }
  
  const onHasValue = (val: any) => {
    val? setHasValue(val.toString().length > 0) : setHasValue(false);
  }

  const onSelecValue = (val: SelectedValueModel) => {
    setSelectedValue(val);
    setIsFocus(false);
    setSearchValue("");
    onHasValue(val?.value);
    onSelect(val);
  }

  return (
    <div className='relative'>
      <div
        className={`
          min-w-[243px] max-w-full h-[56px] text-body1 relative 
          flex items-center justify-start
          ${className}
          `}
      >
        <input
          className={`
            peer 
            px-[16px] w-full h-full rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none 
            hover:px-[calc(16px-1px)] hover:border-solid hover:border-[1px] hover:border-grey-400 
            focus:px-[calc(16px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73] 
            ${isValid ? '' : 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'} 
            text-body1 text-ellipsis overflow-hidden whitespace-nowrap 
            

          `}
         
            // ${icon !== undefined ? '!pr-[48px]' : ''} 
          value={isFocus ? searchValue : selectedValue?.label}
          onChange={onChangeField}
          onFocus={() => {setIsFocus(true)}}
          onBlur={() => {
            if (!isHoverOptions) {
              setIsFocus(false);
              setSearchValue("");
            }
          }}
        />
        <label
          className={`
            px-[16px] absolute text-gray-600 pointer-events-none 
            ${hasValue ? 'mb-6 text-caption text-neutral-600' : ''} 
            peer-focus:text-caption peer-focus:mb-6 
            ${isValid ? '' : 'text-error-main'}
          `}
        >{label}
                  {required ? (<span className="font-semibold">*</span>) : ''}

        </label>
        {
          isFocus && searchValue.length === 0 && (
            <span
              className={`px-[16px] pt-[16px] w-full absolute text-body1 text-gray-600 pointer-events-none text-ellipsis overflow-hidden whitespace-nowrap`}
            >
              {selectedValue?.label}
            </span>
          )
        }
        <div
          className={`
            absolute z-50 top-[calc(100%+8px)] w-full 
            ${isFocus ? "visible" : "invisible"}
          `}
          onMouseEnter={() => setIsHoverOptionns(true)}
          onMouseLeave={() => setIsHoverOptionns(false)}
        >
          <ul 
            className='shadow-lg min-h-120px max-h-[200px] w-full overflow-y-scroll bg-white border-[1px] border-solid border-grey-400 rounded-[4px]'
          >
            {!isLoading && options.length > 0 ? options?.map((item: any, index: number) => 
              <li
                key={index+1}
                className={`
                  cursor-pointer text-body2 overflow-x-hidde px-2 py-4 hover:bg-grey-200 w-full
                `}
                onClick={() => onSelecValue(item)}
              >
                {item.label}
              </li>
            ) : (<li className='text-body2 overflow-x-hidde px-2 py-4 w-full text-center'>{isLoading ? 'Buscando...' : 'No se encontraron resultados'}</li>)}
          </ul>
        </div>
      </div>
      {
        isValid ? (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-gray-600 
          '>
            {helperText}
          </p>
        ) : (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-error-main 
          '>
            {hasValue && required ? 'Este campo es obligatorio' : errorText }
          </p>
        )
      }
    </div>
  )
};

export default InputSelectSearch
