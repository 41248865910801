import IconMoreVertical from 'assets/icons/icon_more_vertical.svg';
import { useState } from 'react';

interface VerticalMenuProps {

}

const VerticalMenu: React.FC<VerticalMenuProps> = (props) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <div className='relative'>
      <button type='button' className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' onClick={() => setShowOptions(!showOptions)}
        // onBlur={() => {if(!isHover) setShowOptions(!showOptions)}}
      >
        <img src={IconMoreVertical} alt="" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
      </button>
      {
        showOptions && 
        <ul 
          className='absolute z-10 shadow-lg bg-white rounded-[4px] py-2 text-body2 w-auto right-[0px]'
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          // onBlur={() => setIsHover(false)}
        >
          {props.children}
        </ul>
      }
    </div>
  )
}

export default VerticalMenu;