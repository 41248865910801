import React from 'react'

interface Props {
  imgSrc?: string
  vitalValue?: string
  vitalName?: string
}

const CardVitalSign: React.FC<Props> = (props) => (
  <div
    className="flex rounded-2xl min-h-[92px] w-full xl:max-w-[327px] p-3
    border border-solid border-color-[rgba(20, 20, 20, 0.15)]
  ">
    <span>
      <img src={props.imgSrc} alt="frecuencia cardiaca" />
    </span>
    <div className="pl-4">
      <p>{props.vitalValue}</p>
      <p className="mt-2">{props.vitalName}</p>
    </div>
  </div>
)

export default CardVitalSign