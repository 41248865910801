const constants = {
  IS_MOCK_ENV:
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_ENV === 'mock',
  ENV_STAGE: process.env.REACT_APP_ENV_STAGE,
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
  API_URL: process.env.REACT_APP_API_URL,
  AWS_USER_POOLS_ID: process.env.REACT_APP_AWS_USER_POOLS_ID,
  AWS_USER_POOLS_WEB_CLIENT_ID:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  URL_SOCKET: process.env.REACT_APP_SOCKET || '',
  REACT_APP_GOOGLE: process.env.REACT_APP_GOOGLE || 'AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc' ,
}

export default constants

