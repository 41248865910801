import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { TEMP_COUNTRY_DEFAULT } from 'global/constants'
import { usePostRegisterPatient } from 'features/shared/hooks/usePostRegisterPatient'
import getDiagnosticos from 'features/shared/hooks/getDiagnosticos'
import useGetSelectOptions from 'features/shared/hooks/useGetSelectOptions'
import useUser from 'features/shared/hooks/useUser'

import ButtonToggle from 'components/ButtonToggle'
import Breadcrumbs from 'components/Breadcrumbs'

import Loader from '../../../components/Loader'
import '../../../features/shared/css/index.scss'
import FormPatient from '../../../components/FormPatient'

import { routes } from '../../../routes/routing'

import IconFile from 'assets/icons/icon_file.svg'
import IconInfo from 'assets/icons/icon_info.svg'
import 'react-step-progress/dist/index.css'
import { onAlert } from 'components/Alert';

const AddPatient: React.FC<any> = (props: any) => {
  const { mutateAsync, isLoading } = usePostRegisterPatient()
  const { userCurrent } = useUser()
  const [sectionStepFormPatient, setSectionStepFormPatient] = React.useState(0)

  let TEMP_COUNTRY = userCurrent?.ipress?.countryId
    ? userCurrent?.ipress?.countryId
    : TEMP_COUNTRY_DEFAULT
  const getSelectOptions = useGetSelectOptions(TEMP_COUNTRY)
  const selectOptions = getSelectOptions?.data?.data
  const history = useHistory()
  const diagnostico = getDiagnosticos()
  const { t } = useTranslation()

  const [idPatientRegistered, setIdPatientRegistered] = useState<number>()

  const onSubmit = async (data: any) => {
    const patientDataSend = await mutateAsync(data)

    if (Number.isInteger(patientDataSend?.data)) {
      setIdPatientRegistered(patientDataSend.data)
      onAlert.success('Registro exitoso');
      history.push(
        `${routes.patients.url}${routes.patient.url}/${patientDataSend.data}`
      )
    } else {
      if (patientDataSend?.data) {
        onAlert.error(patientDataSend?.data);
      } else {
        onAlert.error(patientDataSend);
      }
    }
  }

  return (
    <React.Fragment>
      {(isLoading || diagnostico.isLoading || getSelectOptions.isLoading) && (
        <Loader />
      )}

      <Breadcrumbs
        name={'Añadir paciente'}
        prevPathName={'Lista de pacientes'}
        onClickPrev={() => history.push(routes.patients.url)}
        currentPathName={'Añadir paciente'}
      />

      <div className="w-full bg-white py-6 px-4 rounded-[16px] shadow-md mt-6">
        <div className="flex flex-wrap gap-2">
          <ButtonToggle
            icon={IconInfo}
            isActive={sectionStepFormPatient === 0}
            onClick={() => setSectionStepFormPatient(0)}
          >
            Información Personal
          </ButtonToggle>

          <ButtonToggle
            icon={IconFile}
            isActive={sectionStepFormPatient === 1}
            onClick={() => setSectionStepFormPatient(1)}
          >
            Información de Contacto
          </ButtonToggle>
        </div>
        <FormPatient
          setSectionStepFormPatient={setSectionStepFormPatient}
          formStep={sectionStepFormPatient}
          isEdit={false}
          selectOptions={selectOptions}
          dataPatient={null}
          onSubmit={onSubmit}
        />
      </div>
    </React.Fragment>
  )
}

export default AddPatient
