import { useMutation } from 'react-query'
import fetcher from 'features/shared/utils/fetcher'

export interface LocationModel {
  id: number;
  name: string;
  country: string;
  parentId: number;
}

export function useGetLocations_(countryId: string, parentId: any, queryKey: string) {
  return fetcher.get('/locations/' + countryId + "/" + parentId);
}

export function useGetLocations() {

  return useMutation((params: any) => {
    return fetcher.get('/locations/' + params?.countryId + "/" + params?.parentId);
  });
}


export default useGetLocations
