import React, { useEffect, useState } from "react";
import IconClose from 'assets/icons/icon_close.svg';
import Moment from "moment";
import IconDownload from 'assets/icons/icon_download.svg';
import CheckButton from 'components/CheckButton';
import ButtonToggle from 'components/ButtonToggle';
import { DevicesSuppliesPatientIdModel, DevicesSuppliesPatientModel, useGetDevicesSuppliesPatient } from '../hooks/usePharmacy';
import { usePostDevicesSupplie } from '../hooks/useReportPharmacy';
import { onAlert } from "components/Alert";

const mockData = [
  { product: 'Paracetamol', type: 'Medicamento', quantity: '10 unid', fecha: new Date(), available: false },
  { product: 'Jeringa', type: 'Medicamento', quantity: '10 unid', fecha: new Date(), available: false },
  { product: 'Biobeat parche', type: 'Medicamento', quantity: '10 unid', fecha: new Date(), available: true },
]

interface ReportPharmacyProps {
  patientSelected?: DevicesSuppliesPatientModel;
  listData?: DevicesSuppliesPatientIdModel[];
  onCloseModal: () => any;
  onDownload: () => any;
}

const ReportPharmacy: React.FC<ReportPharmacyProps> = (props) => {
  const [devicesSupplies, setDevicesSupplies] = useState<string[]>([]);
  const [startChange, setstartChange] = useState<boolean>(false);
  const { mutateAsync: postDevicesSupplies } = usePostDevicesSupplie();

  const onChangeSupplices = async (value: any) => {
    setstartChange(true)
    let obj = {
      patientId: props.patientSelected?.patientId,
      body: {
        "planId": value.planId,
        "deviceId": value.deviceId? value.deviceId: null,
        "supplyId": value.supplyId? value.supplyId: null,
        "deliveryStatus": !devicesSupplies.includes(value.code),
        "serial": value.serial
      }
    }

    const responsePostDevicesAndMedications = await postDevicesSupplies(obj)
  
    if (Number.isInteger(responsePostDevicesAndMedications?.data)) {
      onAlert.success('Registro exitoso');
      setDevicesSupplies([...devicesSupplies]?.includes(value.code) ? [...devicesSupplies].filter(item => item !== value.code) : [...devicesSupplies, value.code])
    } else {
      if (responsePostDevicesAndMedications?.data) {
        onAlert.error(responsePostDevicesAndMedications?.data);
      } else {
        onAlert.error(responsePostDevicesAndMedications);
      }
    }
  }


  const statusonChangeSupplices = (value: any) => {

    if (startChange) {
      return devicesSupplies.includes(value.code)
    }
    else {
      if (value.deliveryStatus == 1) {
        return true
      }
      else {
        return devicesSupplies.includes(value.code)
      }

    }

  }
  return (
    <>
      <div className="justify-end items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative h-full w-full max-w-[85%] sm:max-w-[60%]">
          <div
            className="border-0 shadow-lg relative w-full h-full bg-white outline-none focus:outline-none overflow-hidden p-4 flex flex-col"
          >
            <div className='border-b-[1px] border-[#14141426] pb-[24px] flex justify-between items-start mb-4'>
              <p className='text-subtitle1'>
                Productos solicitados de {props.patientSelected?.patient}
              </p>
              <button
                onClick={() => props.onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
              </button>
            </div>

            <div className='grow w-full overflow-auto scrollbar'>
              <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className='even:border-t-[1px] even:border-t-grey-200'>
                    <th className="text-left text-body2 text-text-primary font-medium h-[38px] px-4" >
                      Producto
                    </th>
                    <th className="text-left text-body2 text-text-primary font-medium h-[38px] px-4" >
                      Tipo
                    </th>
                    <th className="text-left text-body2 text-text-primary font-medium h-[38px] px-4" >
                      Cantidad
                    </th>
                    <th className="text-left text-body2 text-text-primary font-medium h-[38px] px-4" >
                      Fecha
                    </th>
                    <th className="text-left text-body2 text-text-primary font-medium h-[38px] px-4" >
                      Disponible
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {
                    props.listData && props?.listData?.length > 0 ? (
                      props?.listData?.map((item: DevicesSuppliesPatientIdModel, index) => (
                        <tr className="h-[54px] hover:bg-grey-50 cursor-pointer" key={index}>
                          <td className="text-body2">{item.product}</td>
                          <td className="text-body2">{item.type}</td>
                          <td className="text-body2">{item.quantity}</td>
                          {/* <td className="text-body2">{Moment(new Date()).format('DD/MM/YYYY')}</td> */}
                          <td className="text-body2">{item.createdAt}</td>
                          <td className="text-body2">
                            <CheckButton
                              selected={statusonChangeSupplices(item)}
                              onSelected={() => onChangeSupplices(item)}
                              className="h-4 w-4"
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2} className="text-caption">Lista vacia</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>

            <div className="flex justify-end w-full mt-6" >
              {
                props.listData && props?.listData?.length > 0 && (
                  <ButtonToggle icon={IconDownload} isActive={true} onClick={() => {
                    props.onDownload();
                  }} >
                    Descargar Excel
                  </ButtonToggle>
                )
              }
            </div>

          </div>


        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  )
};

export default ReportPharmacy;
