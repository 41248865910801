import React from 'react'
import { toast } from 'react-toastify';
import IconInfo from 'assets/icons/icon_alert_info.svg';
import IconSuccess from 'assets/icons/icon_alert_success.svg';
import IconError from 'assets/icons/icon_alert_error.svg';
import IconWarning from 'assets/icons/icon_alert_warning.svg';
import moment from 'moment';

const toadDefaultOptions = {
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
};

export const onAlert = {
  info: (message: any, options?: object) => toast(
    <AlertBody
      message={message}
      srcImage={IconInfo}
      typeAlert={TypeAlert.ALERT_INFO}
    />,
    {
      toastId: TypeAlert.ALERT_INFO,
      ...toadDefaultOptions,
      ...options
    }
  ),
  success: (message: string, options?: object) => toast(
    <AlertBody
      message={message}
      srcImage={IconSuccess}
      typeAlert={TypeAlert.ALERT_SUCCESS}
    />,
    {
      toastId: TypeAlert.ALERT_SUCCESS,
      ...toadDefaultOptions,
      ...options
    }
  ),
  warning: (message: string, options?: object) => toast(
    <AlertBody
      message={message}
      srcImage={IconWarning}
      typeAlert={TypeAlert.ALERT_WARNING}
    />,
    {
      toastId: TypeAlert.ALERT_WARNING,
      ...toadDefaultOptions,
      ...options
    }
  ),
  error: (message: string, options?: object) => toast(
    <AlertBody
      message={message}
      srcImage={IconError}
      typeAlert={TypeAlert.ALERT_ERROR}
    />,
    {
      toastId: TypeAlert.ALERT_ERROR,
      ...toadDefaultOptions,
      ...options
    }
  ),
};

interface AlertBodyProps {
  message: string,
  srcImage: string,
  typeAlert: TypeAlert
}

const AlertBody: React.FC<AlertBodyProps> = ({
  message,
  srcImage,
  typeAlert
}) => {
  return (
    <div 
      className='flex items-start'
    >
      <img src={srcImage} alt={typeAlert} className="h-6 w-6 mr-[18px]" />
      <div className='grow'>
        <p className='text-headline6 text-gray-600 font-semibold'>
          {message}
        </p>
        <span className='text-body2 text-gray-400 mt-2' >
          Hoy {moment(new Date(), 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')}
        </span>
      </div>
    </div>
  )
};

export enum TypeAlert {
  ALERT_INFO = 'alert-info',
  ALERT_SUCCESS = 'alert-success',
  ALERT_WARNING = 'alert-warning',
  ALERT_ERROR = 'alert-error',
}

export interface AlertModal {
  message: string
  options: any
}
