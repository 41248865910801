import fetcher from 'features/shared/utils/fetcher'

async function postRegisterEconomic(value: any) {
  let form_data = new FormData();
  for (let key in value) {
    form_data.append(key, value[key]);
  }
  return await fetcher.post(
    '/patient/economic-data/store',
    form_data
  )
}

export default postRegisterEconomic
