import fetcher from 'features/shared/utils/fetcher'
import { useMutation } from 'react-query'

export interface PostChecklistModel {
  donde_vives: { value: string; description: string; };
  hogar_indicacion: string;
  incomodidad_visitas: string;
  tiene_movil_internet: string;
  hogar_servicios: string[];
  mascotas_libres: string;
  mascotas_mover: string;
  equipos_medicos: string[];
  cuidador_24h: string;
}

export function usePostChecklist() {
  return useMutation((data: any) => postChecklist(data));
}

export async function postChecklist(data: any) {
  return await fetcher.post(`/service/${data.serviceId}/checklist`, data.body);
}

export interface ChecklistModel {
  homeEnabled: boolean,
  checklistResponses: {
    donde_vives: { value: string; description: string; };
    hogar_indicacion: string;
    incomodidad_visitas: string;
    tiene_movil_internet: string;
    hogar_servicios: string[];
    mascotas_libres: string;
    mascotas_mover: string;
    equipos_medicos: string[];
    cuidador_24h: string;
  }[];
}

export function useGetChecklist() {
  return useMutation((serviceId: any) => getChecklist(serviceId));
}

export async function getChecklist(serviceId: any) {
  return await fetcher.get(`/service/${serviceId}/checklist`);
}
