import { rolMenuList } from '../../routes/routing';
import React from 'react';
import Logo from 'assets/images/logo_home_hospital.svg';
import IconMenu from 'assets/icons/icon_menu_blue.svg';
import LogoLight from 'assets/images/logo_home_hospital_vertical.svg';
import IconDoubleArrow from 'assets/icons/icon_arrow_double.svg';
import useUser from 'features/shared/hooks/useUser';
import { useHistory, useLocation } from 'react-router-dom';
import MenuItem, { MenuItemLight } from './MenuItem';
import GroupMenu, { GroupMenuLight } from './GroupMenu';
import { ROLE } from 'global/constants/roles';

enum TypeMenu {
  section = 'section',
  group = 'group',
  menu = 'menu',
}

interface menuModel {
  name: any;
  type: any;
  url?: any;
  icon?: any;
  permissionSlug?: any;
  list?: menuModel[];
  principal?: boolean
}

const Sidebar: React.FC = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState(false)
  const { signOut, userCurrent } = useUser()
  const menuList = {...rolMenuList};
  const [isMainListFull, setIsMainListFull] = React.useState(false)
  const [isBlock, setIsBlock] = React.useState(false)


  const handleCollapsedChange = (value: boolean) => setCollapsed(value);

  const userPermissions: string[] = userCurrent?.permissions.map((permission: any) => permission.permissionSlug);

  const getMenuRole = (): menuModel[] => {
    if (userCurrent?.permissions) {
      switch (userCurrent.roleSlug) {
        case ROLE.SUPER_ADMIN:
          return filterMenu(menuList.superAdmin);
        case ROLE.COUNTRY_ADMIN:
          return filterMenu(menuList.countryAdmin);
        case ROLE.SERVICE_ADMIN:
          return filterMenu(menuList.serviceAdmin);
        case ROLE.DOCTOR:
          return filterMenu(menuList.treatingMedical);
        case ROLE.PROFESSIONAL_HEALTH:
          return filterMenu(menuList.professionalHealth);
        case ROLE.PATIENT:
          return filterMenu(menuList.patient);
        default:
          return [];
      };
    } else {
      return [];
    }
  }

  const filterMenu = (listToFilter: menuModel[]): menuModel[] => {
    return listToFilter.filter((item: menuModel) => {
      if (item?.list && item?.list?.length > 0) {
        return filterMenu(item?.list);
      } else {
        return item?.permissionSlug ? item?.permissionSlug?.map((permission: any) => userPermissions?.includes(permission)).includes(true)  : true;
      }
    });
  }

  const onMenu = () => {
    if (userCurrent?.permissions) {
      let menu: menuModel[] = getMenuRole();
  
      return buildMenuItems(menu);
    }
  }

  const onMenuResponsive = () => {
    if (userCurrent?.permissions) {
      let menu: menuModel[] = getMenuRole();
  
      return buildMenuItemsResponsive(menu);
    }
  }

  const buildMenuItemsResponsive = (menu: menuModel[]): any => {
    return menu.map((item: menuModel) => {
      if (item?.list && item?.list?.length > 0) {
        let isActiveGroup: boolean = false;

        if (item.type === TypeMenu.group) {
          isActiveGroup = item.list.map(subItem => location.pathname.includes(subItem.url)).includes(true);
        } else {
          isActiveGroup = true;
        }
        return (
          <GroupMenuLight
            key={item.name}
            isActive={isActiveGroup}
            icon={item.icon}
            label={'aaa'}
            type={item.type}
          >
            {buildMenuItemsResponsive(item.list)}
          </GroupMenuLight>
        )
      } else {
        if (item?.type !== TypeMenu.menu || !item.principal) return <></>

        const url = getUrl(item);

        return <MenuItemLight
          key={item.name}
          isActive={url === '/' ? url === location.pathname : location.pathname.includes(url)}
          icon={item.icon}
        />
      }
    })
  }

  const buildMenuItems = (menu: menuModel[]): any => {
    return menu.map((item: menuModel) => {
      if (item?.list && item?.list?.length > 0) {
        let isActiveGroup: boolean = false;

        if (item.type === TypeMenu.group) {
          isActiveGroup = item.list.map(subItem => location.pathname.includes(subItem.url)).includes(true);
        } else {
          isActiveGroup = true;
        }

        return (
          <GroupMenu
            key={item.name}
            isActive={isActiveGroup}
            type={item.type}
            icon={item.icon}
            label={item.name}
          >
            {buildMenuItems(item.list)}
          </GroupMenu>
        )
      } else {
        if (item?.type !== TypeMenu.menu) return <></>

        const url = getUrl(item);

        return <MenuItem
          key={item.name}
          isActive={url === '/' ? url === location.pathname : `${location.pathname}/`.includes(url+'/')}
          icon={item.icon}
          label={item.name}
          onClick={() => { history.push(url); }}
        />
      }
    })
  }

  const getUrl = (item: menuModel) => {
    const clinicNetworkId = userCurrent?.clinicNetworkId
    
    return item.name === 'Clínicas' && clinicNetworkId ? item.url.replace(':clinicNetworkId', String(clinicNetworkId)) : item.url
  }

  const handleClickArrow = (e: any) => {
    e.stopPropagation()
    setIsMainListFull(true)
  }

  const handleClickArrowInFull = (e: any) => {
    e.stopPropagation()
    setIsMainListFull(true)
    setIsBlock(!isBlock)
  }

  const handleMouseLeave = () => {
    if (!isBlock) {
      setIsMainListFull(false)
    }
  }

  const handleMouseOver = () => {
    if (window.innerWidth > 768 && window.innerWidth < 1280) {
    /*   console.log('jol') */
      setIsMainListFull(true)
    }
  }

  return (
    <div className=" relative min-h-screen md:flex w-full">
      <div className="bg-white text-gray-100 flex justify-between md:hidden">
        <a href="/" className="block p-2 px-4 text-white font-bold">
          <img className="w-[auto] h-8" src={Logo} alt={"Home Hospital"} />
        </a>
        <button
          onClick={() => handleCollapsedChange(!collapsed)}
          className="relative z-50 mobile-menu-button p-4 focus:outline-none focus:bg-bg9"
        >
          <img className="w-30 h-[auto]" src={IconMenu} alt={IconMenu} />
        </button>
      </div>
      <div
        className={`
        pointer-events-auto
          shadow-md xl:static md:h-full z-20
          ${(isMainListFull && isBlock) ? 'md:static' : 'md:absolute'}
        `}
        onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}
      >
        <div
          className={`
            ${((collapsed || isMainListFull) && !isBlock) ? 'left-0' : 'left-[-320px]'}
              sidebar bg-white w-[320px] px-6 h-full top-0 h-full
              ${(isMainListFull && isBlock) ? 'static' : 'absolute'}
              xl:static z-[1000]
              transition-all duration-200 linear
          `}
        >
          <div className='flex pt-6 justify-between'>
            <img className="w-30 h-[auto]" src={Logo} alt={Logo} />
            <div className={`
              bg-white rounded-full w-10 h-10 flex justify-center items-center inline-block
              cursor-pointer ml-6
              hidden md:block xl:hidden
              ${isBlock && 'rotate-180'}
            `}
              onClick={handleClickArrowInFull}
            >
              <img className="" src={IconDoubleArrow} alt={IconDoubleArrow} />
            </div>
          </div>
          <nav className='mt-20'>
            {onMenu()}
  
            <div className='mt-6' >
              <MenuItem
                isActive={false}
                label={'Salir'}
                onClick={signOut}
              />
            </div>
          </nav>
        </div>
        
        {
          !isMainListFull &&
          <div className={`
            hidden md:w-[120px] md:block xl:hidden h-full
            sidebar z-100 bg-white px-6
            md:relative md:translate-x-0 transition duration-200 ease-in-out 
          `}>
            <img className="w-30 h-[auto] pt-6" src={LogoLight} alt={LogoLight} />
            <div className='bg-white rounded-full absolute w-10 h-10 flex justify-center items-center right-[-14px] top-[11px] cursor-pointer'
              onClick={handleClickArrow}
            >
              <img className="" src={IconDoubleArrow} alt={IconDoubleArrow} />
            </div>
            <nav className='mt-8'>
              {onMenuResponsive()}
            </nav>
          </div>
        }
      </div>
      <div className={`
        ${(isMainListFull && isBlock) ? 'md:pl-0' : 'md:pl-[120px]'}

        w-full  xl:w-[calc(100%-320px)] xl:pl-0
      `}>{props.children}</div>
    </div>
  )
}

export default Sidebar
