// @ts-nocheck
import React, { FC } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom'
import Patient from 'features/Patient/views'

import AddPatient from 'features/RegisterPatient/views'
import ListPatients from 'features/Patients/views'
import { routes } from './routing'

import PrivateRoute from './PrivateRoute/PrivateRoute'
import NotFound from './NotFound'
import useUser from 'features/shared/hooks/useUser'
import Layout from '../layout'
import ClinicHistoryPatients from 'features/ClinicHistory/ListPatients'
import PatientClinicHistory from 'features/ClinicHistory/PatientClinicHistory'
import MonitoringListPatients from 'features/Monitoring/MonitoringListPatients'
import MonitoringPatient from 'features/Monitoring/MonitoringPatient'
import PatientDashboard from 'features/PatientDashboard'
import ListCallCenter from 'features/CallCenter/ListCallCenter'
import { useEffect } from 'react'
import swDev from 'configs/swDev'
import ListDevices from 'features/Devices/ListDevices'
import ListPharmacyPatient from 'features/Pharmacy/PharmacyPatient'
import ListPharmacyProduct from 'features/Pharmacy/PharmacyProduct'
import UserManagement from 'features/UserManagement'
import UserManagementCreate from 'features/UserManagement/views/UserCreate'
import UserManagementCalendar from 'features/UserManagement/views/UserCalendar'
import UserManagementCreateRol from 'features/UserManagement/views/RoleCreate'
import UserManagementDevicesAndMedications from 'features/DevicesAndMedications/views/index'
import ClinicNetworks from 'features/UserManagement/views/ClinicNetworks'
import Clinics from 'features/UserManagement/views/Clinics'
import PatientServiceHistory from 'features/PatientServiceHistory'
import NotificationsDashboard from 'features/Notifications/NotificationsDashboard'
import ReadNotificationsDashboard from 'features/Notifications/ReadNotificationsDashboard'
import UI from 'features/ui'
import { ROLE } from 'global/constants/roles'

const initPage = (role) =>
  (role === ROLE.SERVICE_ADMIN && <Redirect to={routes.patients.url} />) ||
  ((role === ROLE.PROFESSIONAL_HEALTH || role === ROLE.DOCTOR) && (
    <Redirect to={routes.clinicHistory.url} />
  )) ||
  (role === ROLE.PATIENT && <Redirect to={routes.patientDashboard.url} />) ||
  ((role === ROLE.SUPER_ADMIN || role === ROLE.COUNTRY_ADMIN) && (
    <Redirect to={routes.userManagement.url} />
  )) ||
  null

const Routes: React.FC = () => {
  const { userCurrent } = useUser()
  const role = userCurrent?.roleSlug

  useEffect(() => {
    if (userCurrent) {
      swDev(userCurrent.id)
    }
  }, [userCurrent])

  return (
    <Router>
      <Switch>
        <PrivateRoute path={routes.home.url} exact>
          {initPage(role)}
        </PrivateRoute>
        <PrivateRoute path={`${routes.patientDashboard.url}`}>
          <PatientDashboard />
        </PrivateRoute>
        {(role === ROLE.SUPER_ADMIN || role === ROLE.SERVICE_ADMIN) && (
          <PrivateRoute
            path={`${routes.userManagementDevicesAndMedications.url}`}
            exact
          >
            <UserManagementDevicesAndMedications />
          </PrivateRoute>
        )}
        {role === ROLE.COUNTRY_ADMIN && (
          <PrivateRoute path={`${routes.clinics.url}`} exact>
            <Clinics />
          </PrivateRoute>
        )}
        {role === ROLE.SUPER_ADMIN && (
          <PrivateRoute path={`${routes.clinicNetwork.url}`} exact>
            <ClinicNetworks />
          </PrivateRoute>
        )}
        <PrivateRoute path={`${routes.userManagement.url}`} exact>
          <UserManagement />
        </PrivateRoute>

        <PrivateRoute path={`${routes.userManagementNewRol.url}`} exact>
          <UserManagementCreateRol />
        </PrivateRoute>

        <PrivateRoute path={`${routes.userManagementNewUser.url}`} exact>
          <UserManagementCreate />
        </PrivateRoute>

        <PrivateRoute path={`${routes.userManagementEditUser.url}`} exact>
          <UserManagementCreate />
        </PrivateRoute>

        <PrivateRoute path={`${routes.userManagementCalendar.url}`} exact>
          <UserManagementCalendar />
        </PrivateRoute>

        <PrivateRoute exact path={`${routes.devices.url}`}>
          <ListDevices />
        </PrivateRoute>

        <PrivateRoute path={routes.patients.url} exact>
          <ListPatients />
        </PrivateRoute>

        <PrivateRoute
          path={`${routes.patients.url}${routes.newPatient.url}`}
          exact
        >
          <AddPatient />
        </PrivateRoute>

        <PrivateRoute
          path={`${routes.patients.url}${routes.patient.url}/:doumentPatient`}
          exact
        >
          <Patient />
        </PrivateRoute>

        <PrivateRoute
          path={`${routes.patients.url}${routes.patientHistory.url}/:doumentPatient`}
          exact
        >
          <PatientServiceHistory />
        </PrivateRoute>
        <PrivateRoute path={`${routes.monitoring.url}`} exact>
          <MonitoringListPatients />
        </PrivateRoute>

        <PrivateRoute
          path={`${routes.monitoring.url}${routes.monitoringPatient.url}`}
        >
          <MonitoringPatient />
        </PrivateRoute>

        <PrivateRoute path={`${routes.clinicHistory.url}`} exact>
          <ClinicHistoryPatients />
        </PrivateRoute>

        <PrivateRoute
          path={`${routes.clinicHistory.url}${routes.clinicHistoryPatient.url}`}
        >
          <PatientClinicHistory />
        </PrivateRoute>

        <PrivateRoute path={routes.callCenter.url} exact>
          <ListCallCenter />
        </PrivateRoute>

        <PrivateRoute exact path={`${routes.pharmacyPatient.url}`}>
          <ListPharmacyPatient />
        </PrivateRoute>

        <PrivateRoute exact path={`${routes.pharmacyProduct.url}`}>
          <ListPharmacyProduct />
        </PrivateRoute>

        <PrivateRoute exact path={routes.notifications.url}>
          <NotificationsDashboard />
        </PrivateRoute>

        <PrivateRoute exact path={routes.readNotifications.url}>
          <ReadNotificationsDashboard />
        </PrivateRoute>

        <PrivateRoute exact path={'/ui'}>
          <UI />
        </PrivateRoute>

        <PrivateRoute>
          <NotFound />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

export default Routes

