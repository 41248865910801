import { FC } from 'react'

import IconClose from 'assets/icons/icon_close.svg'
import Button from 'components/Button'

interface ModalProps {
  disable?: boolean;
  onCloseModal: () => void
  onSubmit: () => void
  title: string
  showModal: boolean
}

const Modal: FC<ModalProps> = ({
  disable,
  onCloseModal,
  onSubmit,
  children,
  showModal,
  title,
}) => {


  return (
    <>
      {showModal &&
        <div className="">
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative py-8 px-6 w-full max-w-[608px] 2xl:max-w-[70%]">
              <div data-testid="modal_add_device" className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-[16px]">
                <div className=" pb-[24px] flex justify-between items-center">
                  <span className="text-left text-Fs8 font-medium">{title}</span>
                  <button
                    onClick={onCloseModal}
                    className="h-[30px] w-[30px] flex items-center justify-center"
                  >
                    <img src={IconClose} alt="close" className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6" />
                  </button>
                </div>
                {children}
                <div className="flex justify-center gap-4 mt-6 p-[16px]">
                  <Button type="button" variant='secondary' size="large" onClick={onCloseModal}
                  >Cancelar</Button>
                  <Button disabled={disable} data-testid="btn_medication" id="btn_add_medications" className='bg-primary-main border-primary-main ' type="submit" variant='primary' size="large" onClick={onSubmit}>Guardar</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      }
    </>
  )
}

export default Modal

