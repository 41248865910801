import fetcher from 'features/shared/utils/fetcher'

async function getPatientFullData(id:string) {

    return await fetcher.get('/patient/'+id)


}

export default getPatientFullData
