import fetcher from 'features/shared/utils/fetcher'
import { useMutation } from 'react-query'

export interface DataEpicrisisModel  {
  "personalInformation": {
    "name": string,
    "lastname": string,
    "age": number,
    "country": string,
    "address": string
    "gender": string
    "documentType": string
    "document": string
    "nhc": number
    "admissionOrigin": string
  },
  "service": {
    "admissionDate": string
    "dischargeDate": string
    "dischargeCondition": string,
    "dischargeDisability": string
  },
  "currentIllnes": {
    "summaryAnamnesis": string
    "currentPhysicalExam": string,
    "laboratoryExam": string
  },
  "evolution": {
    "treatmentReceived": {
      "createdAt": string
      "medicine": string
      "via": string
      "quantity": number
      "medicationStartTime": string
      "scheduleText": string
      "schedule": string
      "current_therapeutics": number,
      "daysTreatment": any
    }[],
    "evolutionSummary": { "createdAt": string, "description": string }[],
    "dischargeDiagnosis": string
    "secondaryDiagnosis1": string
    "secondaryDiagnosis2": string
  },
  "dischargeTreatment": {
    "dischargePrescription": {
      "createdAt": string
      "medicine": string
      "via": string
      "quantity": number
      "medicationStartTime": string,
      "scheduleText": string,
      "schedule": string,
      "current_therapeutics": number
      "daysTreatment": any
    }[],
    "indications": any
  },
  "doctorInformation": {
    "name": string
    "lastname": string
    "cmp": string
    "rne": string
    "signature": string
  }
}

export function useGetDataEpicrisis()  {
  return useMutation((serviceId: any) => getDataEpicrisis(serviceId));
}

async function getDataEpicrisis(serviceId: any) {
  return await fetcher.get( `/services/${serviceId}/epicrisis`);
}

export function useRegisterEpicrisis()  {
  return useMutation((body: any) => postEpicrisis(body));
}

async function postEpicrisis(body: any) {
  return await fetcher.post( `/services/epicrisis`, body);
}
