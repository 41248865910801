import IconClose from 'assets/icons/icon_close.svg';
import { onAlert } from 'components/Alert';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import InputRadio from 'components/InputRadio';
import LabelForm from 'components/LabelForm';
import Loader from 'components/Loader';
import TextArea from 'components/TextArea';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ScrollToBottom from 'react-scroll-to-bottom';
import { useGetChecklist, usePostChecklist } from './checklist.hook';

interface HomeConditionFormProps {
  serviceId: any;
  onCloseModal: () => any;
  onReloadPatient: () => any;
}

const optionRadioButton = [
  { name: 'Departamento', value: "departamento", 'data-testid': 'donde_vives_departamento' },
  { name: 'Casa', value: "casa", 'data-testid': 'donde_vives_casa' },
  { name: 'Casa de reposo', value: "casa_reposo", 'data-testid': 'donde_vives_casa_reposo' },
  { name: 'Otro', value: "otro", 'data-testid': 'donde_vives_otro' },
];

const HomeConditionForm: React.FC<HomeConditionFormProps> = ({
  serviceId,
  onCloseModal,
  onReloadPatient,
}) => {
  const { register, watch, control, setValue } = useForm();
  const [hogar_servicios, setHogarServicios] = useState<string[]>([]);
  const [equipos_medicos, setOwnDevices] = useState<string[]>([]);
  const { mutateAsync: postChecklist, isLoading: loadingPostChecklist } = usePostChecklist();

  const [userChecklist, setChecklist] = useState<any>();
  const { mutateAsync: getChecklist, isLoading: loadiingGetChecklist } = useGetChecklist();


  useEffect(() => {
    if (serviceId) {
      getChecklist(serviceId).then(res => {
        if (res.data) {
          const data = res.data;

          setChecklist(data);

          data.checklistResponses.forEach((el: any) => {
            switch (el.questionKey) {
              case "donde_vives":
                setValue('donde_vives', el.response.value);
                setValue("other_house_description", el.response.description);
                break;
              case "hogar_indicacion":
                setValue('hogar_indicacion', el.response);
                break;
              case "incomodidad_visitas":
                setValue('incomodidad_visitas', el.response);
                break;
              case "tiene_movil_internet":
                setValue('tiene_movil_internet', el.response);
                break;
              case "hogar_servicios":
                setHogarServicios(el.response);
                break;
              case "mascotas_libres":
                setValue('mascotas_libres', el.response);
                break;
              case "mascotas_mover":
                setValue('mascotas_mover', el.response);
                break;
              case "equipos_medicos":
                setOwnDevices(el.response);
                break;
              case "cuidador_24h":
                setValue('cuidador_24h', el.response);
                break;
            }
          })
        }
      })
    }
  }, [serviceId])

  /*useEffect(() => {
    if (isShowModal) {
      setIsShowForm(isShowModal)
    }
  }, [])*/

  const onChangeServices = (type: string) => {
    setHogarServicios([...hogar_servicios]?.includes(type) ? [...hogar_servicios].filter(item => item !== type) : [...hogar_servicios, type])
  }

  const onChangeOwnDevices = (type: string) => {
    setOwnDevices([...equipos_medicos]?.includes(type) ? [...equipos_medicos].filter(item => item !== type) : [...equipos_medicos, type])
  }

  const onSubmitChecklist = () => {
    if (isValidForm()) {
      const data = {
        serviceId,
        body: {
          "donde_vives": {
            "value": watch("donde_vives"),
            "description": watch("other_house_description")
          },
          "incomodidad_visitas": watch("incomodidad_visitas"),
          "tiene_movil_internet": watch("tiene_movil_internet"),
          "hogar_servicios": hogar_servicios,
          "mascotas_libres": watch("mascotas_libres"),
          "mascotas_mover": watch("mascotas_mover"),
          "equipos_medicos": equipos_medicos,
          "cuidador_24h": watch("cuidador_24h")
        }
      };

      postChecklist(data).then(res => {
        if (res.data) {
          if (userChecklist) {
            onAlert.success("Actualización Exitosa");
            onReloadPatient()
            onCloseModal()
          }
        } else {
          onAlert.error(res);
        }

      });
    }
  }

  const isValidForm = () => {
    return (
      !!watch("donde_vives") && (watch("donde_vives") === "otro" ? !!watch("other_house_description") : true) &&
      !!watch("incomodidad_visitas") && !!watch("tiene_movil_internet") && !!watch("mascotas_libres") &&
      (watch("mascotas_libres") === "si" ? !!watch("mascotas_mover") : true)
      &&
      !!watch("cuidador_24h")
    )
  }

  return (
    <>
      {
        (loadingPostChecklist || loadiingGetChecklist) && <Loader />
      }

      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative py-8 sm:px-6 w-full justify-center items-center flex ">
          <div
            className="border-0 md:max-w-[75%] sm:max-w-[90%] max-h-[90vh] rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none px-[16px] py-[24px]"
          >
            <div className='flex justify-between items-center mb-4'>
              <span className='text-left text-Fs8 font-medium'>Describe donde vives.</span>
              <button
                onClick={() => onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
              </button>
            </div>
            <div className="	">
              <ScrollToBottom
                mode={'top'}
                followButtonClassName='ScrollToBottom__btn__top'
                scrollViewClassName=" overflow-y-scroll scrollbar"
                className=" bg-white rounded-lg  w-full h-[360px] 2xl:h-[750px] "
              >
                {
                  <div className='px-[16px] xl:px-[64px] '>
                    <div className="">

                      <Controller
                        control={control}
                        name='donde_vives'
                        render={({ field: { onChange, value, name, ref } }) => (
                          <InputRadio
                            height={'48px'}
                            classNameInput="col w-full  "
                            className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-4 gap-4  '
                            data={optionRadioButton}
                            onSelected={(val: any) => { onChange(val) }}
                            selected={value}
                          />
                        )}
                      />
                    </div>
                    {
                      watch('donde_vives') === "otro" &&
                      <div className='mt-4'>
                        <TextArea
                          label="Describe"
                          maxLength={200}
                          value={watch('other_house_description')}
                          {...register('other_house_description')}
                        />
                      </div>
                    }
                    <LabelForm label="¿Hay alguna indicación especial para llegar a tu hogar?" className='mt-8 mb-2 font-medium' />
                    <TextArea
                      data-testid='hogar_indicacion'
                      label="Describe"
                      maxLength={200}
                      value={watch('hogar_indicacion')}
                      {...register('hogar_indicacion')}
                    />
                    <LabelForm label="¿No te incomoda recibir múltiples visitas diarias realizadas por profesionales de la Salud?" className='mt-8 mb-2 font-medium' />
                    <Controller
                      control={control}
                      name='incomodidad_visitas'
                      render={({ field: { onChange, value = "", name, ref } }) => (
                        <InputRadio
                          height={'48px'}
                          data={[{ name: 'Si', value: "si", 'data-testid': 'incomodidad_visitas_si' }, { name: 'No', value: "no", 'data-testid': 'incomodidad_visitas_no' }]}
                          onSelected={(val: any) => { onChange(val) }}
                          selected={value}
                        />
                      )}
                    />
                    <LabelForm label="¿Cuentan en tu hogar con un teléfono celular/móvil y con acceso a internet las 24h del día?" className='mt-8 mb-2 font-medium' />
                    <Controller
                      control={control}
                      name='tiene_movil_internet'
                      render={({ field: { onChange, value = "", name, ref } }) => (
                        <InputRadio
                          height={'48px'}
                          data={[{ name: 'Si', value: "si", 'data-testid': 'tiene_movil_internet_si' }, { name: 'No', value: "no", 'data-testid': 'tiene_movil_internet_no' }]}
                          onSelected={(val: any) => { onChange(val) }}
                          selected={value}
                        />
                      )}
                    />

                    <LabelForm label="Cuentan en tu domicilio con: (Puede marcar más de una opción) " className='mt-8 mb-2 font-medium' />
                    <div className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-4 gap-4  '>
                      <CheckBox
                        classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                        className='col h-[48px] rounded-[16px] bg-white text-grey-600
                          border-solid border-[1px] border-grey-200 hover:border-grey-400 '
                        name="Agua"
                        selected={hogar_servicios.includes('agua')}
                        onSelected={() => onChangeServices('agua')}
                      />
                      <CheckBox
                        classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                        className='col h-[48px] rounded-[16px] bg-white text-grey-600
                          border-solid border-[1px] border-grey-200 hover:border-grey-400 '
                        name="Luz"
                        selected={hogar_servicios?.includes('luz')}
                        onSelected={() => onChangeServices('luz')}
                      />
                      <CheckBox
                        classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                        className='col h-[48px] rounded-[16px] bg-white text-grey-600
                          border-solid border-[1px] border-grey-200 hover:border-grey-400 '
                        name="Acceso a internet"
                        selected={hogar_servicios?.includes('internet')}
                        onSelected={() => onChangeServices('internet')}
                      />
                      <CheckBox
                        classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                        className='col h-[48px] rounded-[16px] bg-white text-grey-600
                           border-solid border-[1px] border-grey-200 hover:border-grey-400 '
                        name="Acceso a sistema de refrigeración"
                        selected={hogar_servicios?.includes('refrigeracion')}
                        onSelected={() => onChangeServices('refrigeracion')}
                      />
                    </div>

                    <LabelForm label="¿Tiene mascotas (perro, gatos u otros) qué se mueven libremente en el hogar?" className='mt-8 mb-2 font-medium' />

                    <Controller
                      control={control}
                      name='mascotas_libres'
                      render={({ field: { onChange, value = "", name, ref } }) => (
                        <InputRadio
                          height={'48px'}
                          data={[{ name: 'Si', value: "si", 'data-testid':'mascotas_libres_si' }, { name: 'No', value: "no", 'data-testid':'mascotas_libres_no' }]}
                          onSelected={(val: any) => { onChange(val) }}
                          selected={value}
                        />
                      )}
                    />
                    {watch("mascotas_libres") === 'si' &&
                      <React.Fragment>
                        <LabelForm label="Si la respuesta anterior es sí, ¿Pueden colocarse las mascotas en una habitación separada durante la visita de los profesionales de la Salud?" className='mt-8 mb-2 font-medium' />
                        <Controller
                          control={control}
                          name='mascotas_mover'
                          render={({ field: { onChange, value = "", name, ref } }) => (
                            <InputRadio
                              height={'48px'}
                              data={[{ name: 'Si', value: "si" }, { name: 'No', value: "no" }]}
                              onSelected={(val: any) => { onChange(val) }}
                              selected={value}
                            />
                          )}
                        />
                      </React.Fragment>
                    }


                    <LabelForm label="Cuenta con alguno de los siguientes equipos médicos: (puede marcar más de uno)" className='mt-8 mb-2 font-medium' />

                    <div className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-3 gap-4  '>
                      <CheckBox
                        classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                        className='col h-[48px] rounded-[16px] bg-white text-grey-600
                          border-solid border-[1px] border-grey-200 hover:border-grey-400 '
                        name="Concentrador de oxigeno"
                        selected={equipos_medicos.includes('concentrador_oxigeno')}
                        onSelected={() => onChangeOwnDevices('concentrador_oxigeno')}
                      />
                      <CheckBox
                        classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                        className='col h-[48px] rounded-[16px] bg-white text-grey-600
                          border-solid border-[1px] border-grey-200 hover:border-grey-400 '
                        name="Equipo de aspiración"
                        selected={equipos_medicos.includes('equipo_aspiracion')}
                        onSelected={() => onChangeOwnDevices('equipo_aspiracion')}
                      />
                      <CheckBox
                        classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                        className='col h-[48px] rounded-[16px] bg-white text-grey-600
                          border-solid border-[1px] border-grey-200 hover:border-grey-400 '
                        name="Bomba de infusión"
                        selected={equipos_medicos.includes('bomba_infusion')}
                        onSelected={() => onChangeOwnDevices('bomba_infusion')}
                      />
                    </div>

                    <LabelForm label="¿ Cuenta con un cuidador en el domicilio las 24h del día?" className='mt-8 mb-2 font-medium' />

                    <Controller
                      control={control}
                      name='cuidador_24h'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputRadio
                          height={'48px'}
                          data={[{ name: 'Si', value: "si", 'data-testid': 'cuidador_24h_si' }, { name: 'No', value: "no", 'data-testid': 'cuidador_24h_no' }]}
                          onSelected={(val: any) => { onChange(val) }}
                          selected={value}
                        />
                      )}
                    />
                    {

                      <div className="flex flex-col sm:flex-row	 gap-4 justify-center mt-6">
                        <Button
                          type="button"
                          variant='secondary'
                          size="large"
                          onClick={() => onCloseModal()}
                          disabled={false}
                        >
                          Cancelar
                        </Button>

                        <Button
                          type="button"
                          variant='primary'
                          size="large"
                          onClick={() => onSubmitChecklist()}
                          disabled={!isValidForm()}
                          data-testid='habilitar_hogar_guardar_btn'
                        >
                          Guardar
                        </Button>
                      </div>
                    }

                  </div>
                }
              </ScrollToBottom>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

    </>
  )
}

export default HomeConditionForm;
