interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: string | "primary" | "secondary" | "tertiary" | "negative" | "ghost";
  size?: string | "large" | "extra-large" | "small" | "tiny" | "ultra-tiny";
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  variant,
  size,
  ...props
}) => {

  const defineVariant = () => {
    switch (variant) {
      case 'primary':
        return `
          px-[24px] bg-primary-dark text-white border-solid border-[1px] border-primary-dark 
          disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-50
        `;
      // hover:bg-primary-main hover:border-primary-main 
      // focus:border-primary-main focus:bg-primary-dark 
      case 'secondary':
        return `
          px-[24px] bg-white text-text-primary border-solid border-[1px] border-bluedark-600 
          hover:bg-grey-200 
          disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-200 font-semibold		
        `; 
        case 'secondary-b':
        return `
          px-[24px] bg-white text-primary-dark border-solid border-[1px] border-primary-dark
          disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-200 font-semibold		
        `;
      // focus:border-solid focus:border-[4px] focus:border-bluedark-200 focus:bg-grey-200 
      case 'tertiary':
        return `
          px-[24px] bg-white text-text-primary 
          hover:bg-grey-50 
          focus:px-[calc(24px-4px)] focus:border-solid focus:border-[4px] focus:border-grey-50 focus:bg-white 
          disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-200 
        `;
      case 'negative':
        return `
          px-[24px] bg-white text-text-primary 
          hover:bg-[#EBECF0]
          focus:px-[calc(24px-4px)] focus:border-solid focus:border-[4px] focus:border-[#EBECF0] focus:bg-white 
          disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-200 
        `;
      case 'emergency':
        return `
        px-[24px] bg-[#E21010] text-white border-solid border-[1px] border-[#E21010] 
        disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-50
      `;
      default:
        return ``;
    }
  }

  const defineSize = () => {
    switch (size) {
      case 'extra-large':
        return 'w-full max-w-[343px]';
      case 'large':
        return 'w-[243px]';
      case 'medium':
        return 'w-[168px]';
      case 'small':
        return '';
      case 'tiny':
        return 'h-[42px] px-[12px] rounded-[16px] text-body1';
      case 'ultra-tiny':
        return 'h-[38px] px-[8px] rounded-[8px] text-caption';
      default:
        return '';
    }
  }

  return (
    <button
      {...props}
      className={`
        min-h-[48px] rounded-[20px] text-button 
        ${defineVariant()} 
        ${defineSize()} 
        ${className}
      `}
    >
      {children}
    </button>
  )
}

export default Button
