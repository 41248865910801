export const ROLE = {
    SUPER_ADMIN: 'super-administrador',
    COUNTRY_ADMIN: 'administrador-de-paisred-de-clinicas',
    SERVICE_ADMIN: 'administradora-del-servicio',
    PATIENT: 'paciente',
    PROFESSIONAL_HEALTH: 'profesional-de-salud',
    DOCTOR: 'medico-tratante'
}

export const ROLE_ID: any = {
    1 : ROLE.SUPER_ADMIN,
    28: ROLE.COUNTRY_ADMIN,
    5: ROLE.COUNTRY_ADMIN,
    3: ROLE.SERVICE_ADMIN,
    2: ROLE.PATIENT,
    4: ROLE.PROFESSIONAL_HEALTH,
    22: ROLE.DOCTOR
}

export const PERMISSIONS = {
    PHARMACY_PER_USER: 'pharmacy-per-user',
    PHARMACY_PER_PRODUCT: 'pharmacy-per-product',
    MANAGEMENT_ADM_SERVICE: 'management-adm-service',
    MANAGEMENT_BACKOFFICE: 'management-backoffice',
    MANAGEMENT_SPECIALIST: 'management-specialist',
    DEVICE_REPORT: 'device-report',
    DEVICE_SUPPLIES: 'device-supplies',
    COVERAGE_AREAS: 'coverage-areas',
    NOTIFICATIONS: 'notifications',
    SCHEDULE_SPECIALIST: 'schedule-specialist',
    MEDICAL_CARE: 'medical-care',
    HISTORY_ATTENTIONS: 'history-attentions',
    MY_PLAN: 'my-plan',
    TERMS_CONDITIONS: 'terms-conditions',
    MONITORING: 'patient-monitoring',
    CLINICS: 'clinics',
    PATIENT_ONBOARDING: 'patient-onboarding',
    PATIENT_MONITORING: 'patient-monitoring',
    COMMAND_CENTER: 'command-center',
    ROLE: 'roles',
    CLINIC_NETWORKS: 'clinic-networks',
    CLINIC_HISTORY: 'clinic-history',
    PERMISSION_GEOFENCES: 'permission-geofences', 
}

export const PERMISSIONS_VALUES = {
    'pharmacy-per-user': 'Farmacia por usuario',
    'pharmacy-per-product': 'Farmacia por producto',
    'management-adm-service': 'Gestionar administradores de servicio',
    'management-backoffice': 'Gestionar usuarios de backoffice',
    'management-specialist': 'Gestionar especialistas de salud',
    'device-report': 'Dispositivos reportados',
    'device-supplies': 'Dispositivos y medicamentos',
    'coverage-areas': 'Zonas de cobertura',
    'notifications': 'Notificaciones',
    'schedule-specialist': 'Agenda médica',
    'medical-care': 'Atenciones médicas',
    'history-attentions': 'Historial de atención',
    'permission-geofences': 'permission-geofences'
}