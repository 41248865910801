import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import Pagination from 'components/Pagination';
import { BehaviorSubject, debounceTime } from 'rxjs';
import IconSearch from 'assets/icons/icon_search.svg';
import Input from 'components/input';
import Chip from 'components/Chip';
import Moment from 'moment'
import InputSelect from 'components/InputSelect';
import InputDatePicker from 'components/InputDatePicker';
import ReportPharmacy from './ReportPharmacy';
import ButtonToggle from 'components/ButtonToggle';
import IconDownload from 'assets/icons/icon_download.svg';
import { DevicesSuppliesPatientIdModel, DevicesSuppliesPatientModel, DevicesSuppliesPatientResponseModel, useGetDevicesSuppliesPatient, useGetDevicesSuppliesPatientId } from '../hooks/usePharmacy';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import { onAlert } from 'components/Alert';

const pageSize = 10;
const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

const ListPharmacy: React.FC = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showReportDevice, setShowReportDevice] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState<number>(0);

  const [listDevicesSuppliesPatientResponse, setListDevicesSuppliesPatientResponse] = useState<DevicesSuppliesPatientResponseModel>({ currentPage: 1, data: [], total: 1, totalPages: 1 });
  const {mutateAsync: getDevicesSuppliesPatient, isLoading: isLoadingGetDevicesSuppliesPatient} = useGetDevicesSuppliesPatient();

  const [patientSelected, setPatientSelected] = useState<DevicesSuppliesPatientModel>();
  const [listDevicesSuppliesPatiendId, setListDevicesSuppliesPatiendId] = useState<DevicesSuppliesPatientIdModel[]>([]);
  const {mutateAsync: getDevicesSuppliesPatientId, isLoading: isLoadingGetDevicesSuppliesPatientId} = useGetDevicesSuppliesPatientId();

  
  useEffect(() => {
    onGetListDevicesSupplies();

    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value) => setSearchValue(value));

    return () => {
      return searchSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    onGetListDevicesSupplies();
  }, [startDate, endDate, currentPage, status, searchValue]);

  const onGetListDevicesSupplies = async () => {
    if ((startDate && endDate) && Moment(endDate).diff(Moment(startDate), 'days') >= 0) {
      const from = Moment(startDate).format('YYYY-MM-DD');
      const to = Moment(endDate).format('YYYY-MM-DD');
      const searchText = searchValue !== '' ? `&searchText=${searchValue}` : '';
      const statusQuery = Number(status) > 0 ? `&status=${status}` : '';

      const query = `?from=${from}&to=${to}&page=${currentPage}${statusQuery}${searchText}`;
  
      getDevicesSuppliesPatient(query).then(res => {
        if (res.data) {
          setListDevicesSuppliesPatientResponse(res.data);
        } else {
          setListDevicesSuppliesPatientResponse({
            currentPage: 1,
            data: [],
            total: 1,
            totalPages: 1
          });
        }
      });
    } else {
      if (startDate && endDate) {
        onAlert.warning('La fecha de inicio no puede ser mayor a la fecha final');
      }
    }
  }

  const getDetailDevicesSuppliesById = (item: DevicesSuppliesPatientModel) => {
    const data = { patientId: item.patientId, query: '' };

    setPatientSelected(item);

    getDevicesSuppliesPatientId(data).then(res => {
      if (res.data) {
        setListDevicesSuppliesPatiendId(res.data);
        setShowReportDevice(true);
      }
    });
  }

  const downloadDetailDevicesSuppliesById = () => {
    const data = { patientId: patientSelected?.patientId, query: '?format=excel' };

    getDevicesSuppliesPatientId(data).then(res => {
      if (res.data) {
        const mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const a = document.createElement('a');
        a.href = mediaType + res.data;
        a.download = `Reporte Farmacia: ${patientSelected?.patient}.xlsx`;
        a.click();
      }
    });
  }

  const onDownloadExcel = async () => {
    const from = Moment(startDate).format('YYYY-MM-DD');
    const to = Moment(endDate).format('YYYY-MM-DD');
    const query = `?from=${from}&to=${to}&format=excel&page=${currentPage}`;

    getDevicesSuppliesPatient(query).then(res => {
      if (res.data) {
        const mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const a = document.createElement('a');
        a.href = mediaType + res.data;
        a.download = `Report Farmacia Por Usuario. Fecha: ${from} - ${to}.xlsx`;
        a.click();
      }
    });
  }

  return (
    <React.Fragment>
      {isLoadingGetDevicesSuppliesPatient || isLoadingGetDevicesSuppliesPatientId && <Loader />}

      <Breadcrumbs
        name="Por usuario"
        prevPathName="Farmacia"
        currentPathName="Por usuario"
        onClickPrev={() => {}}
        onClickHome={() => {history.push(`/`)}}
      />

      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-4"
      >
        <div className='flex flex-col sm:flex-row	 gap-4 items-center justify-between px-4  pb-[24px] '>
          <p className='text-body1 mr-4 text-text-secondary'> <span className='font-medium text-text-primary' >Pacientes</span> | {listDevicesSuppliesPatientResponse?.data?.length}</p>

          <div className='w-[70%]'>
          <div className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-4 gap-4 ' >
              <InputDatePicker
                label="Fecha inicio"
                onChange={(date: Date) => setStartDate(date)}
                selected={startDate}
                dateFormat="dd/MM/yyyy"
                locale="es"
              />

              <InputDatePicker
                label="Fecha fin"
                onChange={(date: Date) => setEndDate(date)}
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                locale="es"
              />

              <InputSelect
                label="Estado"
                name="tipoDocumento"
                required={false}
                onSelect={val => setStatus(val)}
                value={status}
              >
                <option value={1} >Enviado</option>
                <option value={2} >No Enviado</option>
              </InputSelect>

              <Input
                label="Buscar paciente"
                value={searchText}
                icon={IconSearch}
                onChange={(e: any) => {
                  inputChange.next(e.target.value);
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {
          showReportDevice && 
          <ReportPharmacy
            patientSelected={patientSelected}
            listData={listDevicesSuppliesPatiendId}
            onCloseModal={() => {setShowReportDevice(false)}}
            onDownload={downloadDetailDevicesSuppliesById}
          />
        }

        {!isLoadingGetDevicesSuppliesPatient && (
          <React.Fragment>
            <section data-testid="Table" className="w-full overflow-auto scrollbar">
              <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className='even:border-t-[1px] even:border-t-grey-200'>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      NHC
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Paciente
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Inicio de servicio
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Estado 
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {
                    listDevicesSuppliesPatientResponse?.data?.length > 0 ? (
                      listDevicesSuppliesPatientResponse?.data?.map((item: DevicesSuppliesPatientModel, index: any) => {
                        return (
                          <tr key={index} className="h-[54px] hover:bg-grey-50 cursor-pointer">
                            <td className="px-4 py-2 whitespace-nowrap text-body2" onClick={() => getDetailDevicesSuppliesById(item)}>
                              {item.nhc}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-body2" onClick={() => getDetailDevicesSuppliesById(item)}>
                              <div className="text-body2 flex items-center gap-2">
                                {false ? (
                                  <img src={''} alt={''} className="rounded-full w-10 h-10" />
                                ) : (
                                  <div className='rounded-full bg-[#DFF5FF] text-[#008EA3] min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 flex justify-center items-center text-caption'>{item.patient?.charAt(0)}</div>
                                )}
                                {item.patient}
                              </div>
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-body2" onClick={() => getDetailDevicesSuppliesById(item)}>
                              {item.serviceDate}
                            </td>
                            <td className="px-4 py-2 flex text-body2" onClick={() => getDetailDevicesSuppliesById(item)}>
                              <Chip
                                label={item.completeOrder}
                                onClose={() => { }}
                                isRemovable={false}
                                className={item.completeOrder === 'No enviado' ? "bg-[#FFEFED] text-[#E21010] border-none" : ""}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : <tr className='h-[54px]'><td colSpan={5} className="px-4 text-center" >No se encontraron resultados</td></tr>
                  }
                </tbody>
              </table>
            </section>

            <div
              className='flex justify-between items-center mt-[24px] px-4'
            >
              <div>
                {
                  listDevicesSuppliesPatientResponse?.data?.length > 0 && (
                    <ButtonToggle icon={IconDownload} isActive={true} onClick={() => onDownloadExcel()} >
                      Descargar Excel
                    </ButtonToggle>
                  )
                }
              </div>

              <Pagination
                currentPage={listDevicesSuppliesPatientResponse?.currentPage}
                totalCount={listDevicesSuppliesPatientResponse?.total}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListPharmacy;
