import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ShadowWrapper } from 'components/ShadowWrapper'
import Breadcrumbs from 'components/Breadcrumbs';
import FormUserCreate from 'features/UserManagement/components/FormUserCreate/form'

const UserManagementCreate: React.FC = () => {
  const history = useHistory();
  const location = useLocation()
  const state = location?.state as any
  const userStorage = state?.userStorage

  return(
    <>
      <Breadcrumbs
        name={`${userStorage ? 'Editar usuario' : 'Crear nuevos usuarios'}`}
        currentPathName={`${userStorage ? 'Editar usuario' : 'Crear nuevos usuarios'}`}
        onClickPrev={() => {}}
        onClickHome={() => {history.push(`/user-management`)}}
        className='mb-6'
      />
      <ShadowWrapper className='px-4 py-[24px]'>
        <FormUserCreate />
      </ShadowWrapper>
    </>
)}

export default UserManagementCreate