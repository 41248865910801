import { FC } from 'react'

import IconCall from 'assets/icons/icon_call.svg'
import IconWatch from 'assets/icons/icon_watch.svg'
import IconHealth from 'assets/icons/icon_health_white.svg'
import IconMessage from 'assets/icons/icon_message.svg'
import IconClipboard from 'assets/icons/icon_clipboard_B.svg'

import {
  NotificationsCounterProps,
  HeaderNotificationsCounterProps,
} from './types'

const variants: Record<NotificationsCounterProps['variant'], any> = {
  call: {
    iconName: IconCall,
    label: 'Llamadas',
    bg: 'bg-[#48B16E]',
    counter: {
      textColor: 'text-[#48B16E]',
    },
  },
  medication: {
    iconName: IconHealth,
    label: 'Medicamentos',
    bg: 'bg-[#00B1CC]',
    counter: {
      textColor: 'text-[#528EB7]',
    },
  },
  report: {
    iconName: IconWatch,
    label: 'Dispositivos',
    bg: 'bg-[#407FE7]',
    counter: {
      textColor: 'text-[#407FE7]',
    },
  },
  prescription: {
    iconName: IconHealth,
    label: 'Prescripciones',
    bg: 'bg-[#00B1CC]',
    counter: {
      textColor: 'text-[#528EB7]',
    },
  },
  recommendation: {
    iconName: IconMessage,
    label: 'Recomendaciones',
    bg: 'bg-[#407FE7]',
    counter: {
      textColor: 'text-[#407FE7]',
    },
  },
  consultation: {
    iconName: IconClipboard,
    label: 'Interconsulta',
    bg: 'bg-[#528EB7]',
    counter: {
      textColor: 'text-[#407FE7]',
    },
  }
}

export const HeaderNotificationsCounter: FC<
  HeaderNotificationsCounterProps
> = ({ count }) => {
  return (
    <div
      className={`absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-xs rounded-full text-white text-caption bg-[#D34000] select-none cursor-pointer`}
    >
      {count}
    </div>
  )
}

export const NotificationsCounter: FC<NotificationsCounterProps> = ({
  variant,
  count,
}) => {
  const selectedVariant = variants[variant]
  return (
    <div
      className={`flex flex-row flex-wrap grow-1 shrink-1 rounded-2xl py-4 px-4 justify-between items-center basis-[230px] ${selectedVariant.bg}`}
    >
      <div className={`flex flex-row gap-2 items-center`}>
        <img
          src={selectedVariant.iconName}
          alt={selectedVariant.iconName}
          className="sm:w-[24px] sm:h-[24px]"
        />
        <span className={`text-white text-caption font-normal`}>
          {selectedVariant.label}
        </span>
      </div>
      <div
        className={`flex items-center justify-center w-7 h-7 text-xs rounded-full bg-white ${selectedVariant.counter.textColor}`}
      >
        {count}
      </div>
    </div>
  )
}

