import { forwardRef, useEffect, useState } from 'react';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  errorText?: string;
  helperText?: string;
  value?: any;
  required?: boolean;
  maxLength?: number;
  height?: string;
  onClickIcon?: () => void;
  classNameWrapper?: string;
  handleInput?: (e: any) => void;
  myRef?: any;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  className,
  label,
  errorText,
  helperText,
  value,
  onClickIcon,
  required,
  maxLength,
  height,
  classNameWrapper,
  handleInput,
  myRef,
  ...props
}, ref) => {

  const [hasValue, setHasValue] = useState<boolean>(false);

  useEffect(() => {
    onHasValue(value);
  }, [value])

  const onHasValue = (val: any) => {
    val ? setHasValue(val.toString().length > 0) : setHasValue(false);
  }

  return (
    <div className={classNameWrapper} ref={myRef}>
      <div
        className={`
          min-w-[243px] max-w-full 
          text-body1 relative 
          flex items-center justify-start   px-[16px]
          rounded-[16px] bg-grey-50 text-grey-600 pt-[32px] pb-[16px]
          hover:border-grey-400 
          hover:px-[calc(16px-1px)] hover:pt-[calc(32px-1px)] hover:border-solid hover:border-[1px] 
            focus:px-[calc(16px-1px)] focus:pt-[calc(32px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73] 
        `}
      >
        <textarea
          className={`
          scrollbar
          h-[100px] 
            overflow-auto	
            bg-grey-50
            peer resize-none text-body1 
           w-full 
             ${height} 
             outline-none 
             pr-5
            ${className}
          `}
          {...props}
          value={value}
/*           maxLength={maxLength}
 */          ref={ref}
          onInput={handleInput}
        />
        <p
          className={`
            absolute text-neutral-600 pointer-events-none top-[10px]  pb-1 text-caption bg-grey-50  mt-[1px] 
          `}
        >{label}
          {required ? (<span className="font-semibold">*</span>) : ''}
        </p>
      </div>
{/*       {maxLength && <p className='text-caption text-neutral-400 text-right pr-4 mt-1'>{value?.length || 0}/{maxLength}</p>}
 */}    </div>
  )
});

export default TextArea
