import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import { BehaviorSubject, debounceTime } from 'rxjs';
import IconSearch from 'assets/icons/icon_search.svg';
import Input from 'components/input';
import Moment from 'moment'
import InputDatePicker from 'components/InputDatePicker';
import ButtonToggle from 'components/ButtonToggle';
import IconDownload from 'assets/icons/icon_download.svg';
import { DevicesSuppliesProductModel, DevicesSuppliesProductReponseModel, useGetDevicesSuppliesProduct } from '../hooks/usePharmacy';
import { useHistory } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import { onAlert } from 'components/Alert';

const pageSize = 10;
const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

const ListPharmacy: React.FC = () => {
  const history = useHistory();
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());

  const [listDevicesSuppliesProduct, setListDevicesSuppliesProduct] = useState<DevicesSuppliesProductModel[]>([]);
  const { mutateAsync: getDevicesSuppliesProduct, isLoading: isLoadingGetDevicesSuppliesProduct } = useGetDevicesSuppliesProduct();

  
  useEffect(() => {
    onGetListDevicesSupplies();

    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value) => setSearchValue(value));

    return () => {
      return searchSubscription.unsubscribe();
    }
  }, []);

  useEffect(() => {
    onGetListDevicesSupplies();
  }, [startDate, endDate, searchValue]);

  const onGetListDevicesSupplies = async () => {
    if ((startDate && endDate) && Moment(endDate).diff(Moment(startDate), 'days') > 0) {
      const from = Moment(startDate).format('YYYY-MM-DD');
      const to = Moment(endDate).format('YYYY-MM-DD');
      const searchText = searchValue !== '' ? `&searchText=${searchValue}` : '';
  
      const query = `?from=${from}&to=${to}${searchText}`;
  
      getDevicesSuppliesProduct(query).then(res => {
        if (res.data) {
          setListDevicesSuppliesProduct(res.data);
        }
      });
    } else {
      if (startDate && endDate) {
        onAlert.warning('La fecha de inicio no puede ser mayor a la fecha final');
      }
    }
  }

  const onDownloadExcel = async () => {
    const from = Moment(startDate).format('YYYY-MM-DD');
    const to = Moment(endDate).format('YYYY-MM-DD');
    const searchText = searchValue !== '' ? `&searchText=${searchValue}` : '';

    const query = `?from=${from}&to=${to}${searchText}&format=excel`;

    getDevicesSuppliesProduct(query).then(res => {
      if (res.data) {
        const mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const a = document.createElement('a');
        a.href = mediaType + res.data;
        a.download = `Report Farmacia Por Producto. Fecha: ${from} - ${to}.xlsx`;
        a.click();
      }
    });
  }

  return (
    <React.Fragment>
      {isLoadingGetDevicesSuppliesProduct && <Loader />}


      <Breadcrumbs
        name="Por producto"
        prevPathName="Farmacia"
        currentPathName="Por producto"
        onClickPrev={() => {}}
        onClickHome={() => {history.push(`/`)}}
      />

      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-4"
      >
        <div className='flex flex-col sm:flex-row	 gap-4 items-center justify-between px-4  pb-[24px] '>
          <p className='text-body1 mr-4 text-text-secondary'> <span className='font-medium text-text-primary' >Productos</span> | {listDevicesSuppliesProduct?.length}</p>

          <div className='w-[70%]'>
            <div className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-3 gap-4 ' >
              <InputDatePicker
                label="Fecha inicio"
                onChange={(date: Date) => setStartDate(date)}
                selected={startDate}
                dateFormat="dd/MM/yyyy"
                locale="es"
              />

              <InputDatePicker
                label="Fecha fin"
                onChange={(date: Date) => setEndDate(date)}
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                locale="es"
              />

              <Input data-testid="InputSearchProduct"
                label="Buscar producto"
                value={searchText}
                icon={IconSearch}
                onChange={(e: any) => {
                  inputChange.next(e.target.value);
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {!isLoadingGetDevicesSuppliesProduct && (
          <React.Fragment>
            <section data-testid="Table" className="w-full overflow-auto scrollbar">
              <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className='even:border-t-[1px] even:border-t-grey-200'>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Producto
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Cantidad
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Fecha
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {
                    listDevicesSuppliesProduct.length > 0 ? (
                      listDevicesSuppliesProduct.map((item: DevicesSuppliesProductModel, index: any) => {
                        return (
                          <tr key={index} className="h-[54px] hover:bg-grey-50 cursor-pointer">
                            <td className="px-4 py-2 whitespace-nowrap">
                              {item.descripcion}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap">
                              <div className="text-body2 flex items-center gap-2">
                                {item.quantity}
                              </div>
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap">
                              {true ? Moment(item.serviceDate).format('DD/MM/YYYY') : '-'}
                            </td>
                          </tr>
                        );
                      })
                    ) : <tr className='h-[54px]'><td colSpan={5} className="px-4 text-center" >No se encontraron resultados</td></tr>
                  }
                </tbody>
              </table>
            </section>

            <div
              className='flex justify-between items-center mt-[24px] px-4'
            >
              {
                listDevicesSuppliesProduct.length > 0 &&
                <ButtonToggle icon={IconDownload} isActive={true} onClick={() => {onDownloadExcel()}} >
                  Descargar Excel
                </ButtonToggle>
              }

              {/* <Pagination
                currentPage={listDevicesSuppliesProduct?.currentPage}
                totalCount={listDevicesSuppliesProduct?.total}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              /> */}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListPharmacy;
