import { useQuery, useMutation } from 'react-query';
import { getClinics, getClinicsSearchResults, createClinic, getClinicById, updateClinic } from '../services/clinic';

export const useGetClinic = (networkServiceId?: number) => useQuery(['clinics', networkServiceId], () => getClinics(networkServiceId));

export function useGetClinicSearchResults() {
  return useMutation((data: {clinicNetworkId: number, query: string}) => getClinicsSearchResults(data));
}

export function useCreateClinic() {
  return useMutation((data: any) => createClinic(data));
}

export function useGetClinicById() {
  return useMutation((id: any) => getClinicById(id))
}

export function useUpdateClinic() {
  return useMutation((data: any) => updateClinic(data));
}