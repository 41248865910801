import React, { useEffect, useState, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { t } from 'i18next'
import { BehaviorSubject, debounceTime } from 'rxjs'
import ScrollToBottom from 'react-scroll-to-bottom'

import { CountryContext } from 'layout'

import {
  useGetListDevicesAndMedications,
  usePostDevicesAndMedications,
  useGetClinicNetwork,
} from 'features/DevicesAndMedications/hooks/useDevicesAndMedications'
import useUser from 'features/shared/hooks/useUser'
import { DevicesAndMedicationsModel } from 'features/shared/types/userType'

import Input from 'components/input'
import Pagination from 'components/Pagination'
import InputSelect from 'components/InputSelect'
import ButtonToggle from 'components/ButtonToggle'
import CardProgress from 'components/CardProgress'
import Breadcrumbs from 'components/Breadcrumbs'
import Loader from '../../../components/Loader'
import Modal from '../components/Modal'

import IconFile from 'assets/icons/icon_file.svg'
import IconDownload from 'assets/icons/icon_download_white.svg'
import IconSearch from 'assets/icons/icon_search.svg'
import IconPlusCircle from 'assets/icons/icon_plus_circle.svg'
import IconUpload from 'assets/icons/icon_upload_multimedia.svg'
import { onAlert } from 'components/Alert'

const pageSize = 10
const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()
const useCountry = () => useContext(CountryContext)

const ListDevicesAndMedications: React.FC = () => {
  const { control, watch } = useForm()
  const { userCurrent } = useUser()
  const country = useCountry()

  const [searchValue, setSearchValue] = useState('')
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showModal, setShowModal] = useState(false)
  const [listDevicesAndMedications, setListDevicesAndMedications] =
    useState<DevicesAndMedicationsModel>({
      currentPage: 1,
      data: [],
      total: 1,
      totalPages: 1,
    })
  const { data: listClinicNetwork } = useGetClinicNetwork(
    userCurrent?.ipress?.countryId || country
  )
  const clinicNetworks = listClinicNetwork?.data
  const {
    isLoading: listDevicesAndMedicationsLoading,
    mutateAsync: mutateAsyncListDevicesAndMedications,
  } = useGetListDevicesAndMedications()
  const {
    isLoading: listPostDevicesAndMedicationsLoading,
    mutateAsync: mutateAsyncPostDevicesAndMedications,
  } = usePostDevicesAndMedications()
  const [devicesFile, setDevicesFile] = useState<any>(null)
  const [devicesFileArray, setDevicesFileArray] = useState<any>([])
  useEffect(() => {
    onGetListDevicesAndMedications(searchValue, currentPage)

    const searchSubscription = inputChange$
      .pipe(debounceTime(500))
      .subscribe((value) => {
        setSearchValue(value)
        // setCurrentPage(1);
      })

    return () => {
      return searchSubscription.unsubscribe()
    }
  }, [userCurrent?.ipress])

  useEffect(() => {
    if (currentPage) {
      onGetListDevicesAndMedications(searchValue, currentPage)
    }
  }, [currentPage])

  useEffect(() => {
    onGetListDevicesAndMedications(searchValue, 1)
  }, [searchValue])

  const onGetListDevicesAndMedications = async (
    searchValue?: any,
    currentPage?: any
  ) => {
    const searchText = searchValue !== '' ? `&searchText=${searchValue}` : ''

    const query = `?country=${
      userCurrent?.ipress?.countryId || country
    }&page=${currentPage}${searchText}`

    const response = await mutateAsyncListDevicesAndMedications(query)

    setListDevicesAndMedications(response.data)
  }

  const handleUploadFile = (e: any) => {
    e.preventDefault()

    if (e.target.files) {
      const reader = new FileReader()
      const file = e.target.files[0]

      if (reader !== undefined && file !== undefined) {
        reader.onloadend = async () => {
          let files = devicesFileArray
          files.push(file)
          setDevicesFileArray(files)
          await setDevicesFile(file)
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleClickDeleteDevicesFileArray = (name: any) => {
    let files = devicesFileArray

    var newArray = files.filter((item: { name: any; id: number }) => {
/*       console.log(item)
 */      return item.name !== name
    })
    setDevicesFileArray(newArray)
  }
  const getValeidate = () => {
    if (watch('clinicNetworkId') && devicesFileArray.length >= 1) {
      return true
    } else {
      return false
    }
  }
  const onSubmit = async () => {
    devicesFileArray.forEach(async (file: any) => {
      let obj = { data: file, clinicNetworkId: watch('clinicNetworkId') }

      const responsePostDevicesAndMedications =
        await mutateAsyncPostDevicesAndMedications(obj)
      if (responsePostDevicesAndMedications?.data) {
        onAlert.success('Archivo enviado')
        reset()
      } else {
        if (responsePostDevicesAndMedications?.data) {
          onAlert.error(responsePostDevicesAndMedications?.data)
        } else {
          onAlert.error(responsePostDevicesAndMedications)
        }
        reset()
      }
    })
  }
  const reset = () => {
    setShowModal(false)
    setDevicesFileArray([])
    onGetListDevicesAndMedications(searchValue, 1)
  }

  const handleClickOpenModal = () => {
    if (!country) {
      alert(
        'Para poder continuar deberás de seleccionar un país para continuar con el proceso.'
      )
    } else {
      setShowModal(true)
    }
  }

  return (
    <React.Fragment>
      <Breadcrumbs name={'Dispositivos y medicamentos'} currentPathName={'Dispositivos y medicamentos'} />
      <Modal
        showModal={showModal}
        onCloseModal={() => {
          setShowModal(false)
        }}
        onSubmit={() => {
          onSubmit()
        }}
        title={'Cargar archivos'}
        disable={!getValeidate()}
      >
        <Controller
          control={control}
          name="clinicNetworkId"
          render={({ field: { onChange, value, name, ref } }) => (
            <InputSelect
              data-testid="clinic_network_id"
              label={t('Seleccionar red de clinica')}
              ref={ref}
              name={'clinicNetworkId'}
              value={value}
              onSelect={(e) => onChange(e)}
              placeholder="Rol"
            >
              {clinicNetworks &&
                clinicNetworks.length > 0 &&
                clinicNetworks.map((clinicNetwork: any, i: number) => (
                  <option key={i} value={clinicNetwork?.id}>
                    {clinicNetwork?.name}
                  </option>
                ))}
            </InputSelect>
          )}
        />
        <div className="mt-[24px] h-[140px] bg-disabled relative overflow-hidden flex flex-col items-center justify-center border-dashed border-[3px] border-slate-200 p-6 mx-auto rounded-lg cursor-pointer">
          <input
            id="input_file_medications"
            className="w-[100%] h-[240px] top-[-35px] cursor-pointer absolute"
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={handleUploadFile}
          />

          <img
            src={IconUpload}
            id="previewSignature"
            className={`mb-6 min-w-[40px] min-h-[40px] w-[40px] h-[40px]`}
            alt="sube tu firma"
          />
          <div className="text-body2">
            {' '}
            <div>
              Arrastra y suelta el archivo , o{' '}
              <span className="text-primary-main">Búscalo</span>{' '}
            </div>
          </div>
          <div className="text-caption">soporta solo csv - máximo 10mb</div>
        </div>
        {devicesFileArray.length >= 1 && (
          <ScrollToBottom
            mode={'bottom'}
            followButtonClassName="ScrollToBottom__btn"
            scrollViewClassName=" overflow-y-scroll scrollbar"
            className={` bg-white rounded-lg  w-full ${
              devicesFileArray.length >= 2 ? 'h-[232px] ' : ' '
            }`}
          >
            <div className={`${devicesFileArray.length > 2 && 'pr-[16px]'}`}>
              {devicesFileArray?.map((item: any, i: any) => (
                <CardProgress
                  className="mt-[24px]"
                  key={i}
                  icon={IconFile}
                  name={item?.name}
                  size={item?.size + ' mb '}
                  onClose={() => {
                    handleClickDeleteDevicesFileArray(item?.name)
                  }}
                />
              ))}
            </div>
          </ScrollToBottom>
        )}
      </Modal>
      {
        (listDevicesAndMedicationsLoading ||
        listPostDevicesAndMedicationsLoading) && <Loader />}
      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        <div className="px-4 pb-[24px] flex flex-col sm:flex-row  gap-4  items-center justify-between">
          <div className="grow mt-[10px] flex flex-col sm:flex-row items-center">
            <p className="text-body1 mr-4 text-text-secondary">
              <span className="font-medium text-text-primary">
                Dispositivos y medicamentos
              </span>
            </p>
            <ButtonToggle
              data-testid="devices_medicatiion_add"
              icon={IconPlusCircle}
              isActive={true}
              disabled={false}
              onClick={handleClickOpenModal}
            >
              Agregar
            </ButtonToggle>
          </div>

          <div className="flex ">
            <Input
              id="input_buscar_dispositivos"
              label="Buscar dispositivo y medicamentos"
              value={searchText}
              icon={IconSearch}
              className={'xl:min-w-[416px]'}
              onChange={(e: any) => {
                inputChange.next(e.target.value)
                setSearchText(e.target.value)
              }}
            />
          </div>
        </div>

        {!listDevicesAndMedicationsLoading && (
          <React.Fragment>
            <section data-testid="Table" className="w-full overflow-auto scrollbar">
              <table id="tbl_medications_devices" className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className="even:border-t-[1px] even:border-t-grey-200">
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Código
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Descripción
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Tipo
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {listDevicesAndMedications?.data?.length > 0 ? (
                    listDevicesAndMedications?.data?.map(
                      (item: any, index: any) => (
                        <tr key={index} className="h-[54px] hover:bg-grey-50">
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2 text-text-secondary">
                              {item.code}
                            </div>
                          </td>
                          <td className="px-4 py-2 ">
                            <div className="text-body2 text-text-secondary flex items-center gap-2">
                              {item.name}
                            </div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2 text-text-secondary">
                              {item.type}
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr className="h-[54px]">
                      <td colSpan={5} className="px-4 text-center">
                        No se encontraron resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
            <div className="flex flex-col sm:flex-row	gap-4 justify-end mt-[24px] px-4 items-center">
              <a
                className="
                  h-[42px] rounded-[8px] text-button bg-primary-main
                  px-[16px] text-white flex items-center  mr-[auto]
                "
                href={`${window.location.origin}/plantilla-medicamentos-insumos.csv`}
                download="plantilla-medicamentos-insumos.csv"
              >
                <img
                  className="mr-2"
                  src={IconDownload}
                  width={16}
                  height={16}
                  alt={IconDownload}
                />
                Descargar plantilla
              </a>
              <Pagination
                currentPage={listDevicesAndMedications?.currentPage}
                totalCount={listDevicesAndMedications?.total}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListDevicesAndMedications

