import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query'

import {
  getNotifications,
  getNotificationsSummary,
  getNotificationsSummaryTotal,
  getAttendedNotifications,
  markNotificationAsRead as markNotificationAsReadService,
} from '../notifications.service'
import {
  GetNotificationsParams,
  GetAttendedNotificationsParams,
  GetNotificationsSummaryTotalResponse,
} from '../types'

export const GET_NOTIFICATIONS_SUMMARY_QUERY_KEY = 'getNotificationsSummary'
export const GET_NOTIFICATIONS_SUMMARY_TOTAL_QUERY_KEY =
  'getNotificationsSummaryTotal'
export const GET_NOTIFICATIONS_QUERY_KEY = 'getNotifications'
export const GET_NOTIFICATIONS_ATTENDED_QUERY_KEY = 'getAttendedNotifications'

export function useGetNotifications(
  params: Omit<GetNotificationsParams, 'page'>
) {
  const filteredParams = {
    role: params.role,
    type: params.type,
    from: params.from,
    to: params.to,
  }

  const {
    data: getNotificationsResponse,
    isLoading: getNotificationsIsLoading,
    isFetching: getNotificationsIsFetching,
    fetchNextPage: fetchNextNotificationsPage,
    hasNextPage: hasMoreNotifications,
  } = useInfiniteQuery({
    queryKey: [GET_NOTIFICATIONS_QUERY_KEY, filteredParams],
    queryFn: ({ pageParam = 1 }) =>
      getNotifications({ page: pageParam, ...filteredParams }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.totalPages > lastPage?.data?.currentPage) {
        return lastPage?.data?.currentPage + 1
      }
    },
  })

  return {
    getNotificationsResponse,
    getNotificationsIsLoading,
    getNotificationsIsFetching,
    fetchNextNotificationsPage,
    hasMoreNotifications,
  }
}

export function useGetAttendedNotifications(
  params: Omit<GetAttendedNotificationsParams, 'page'>
) {
  const filteredParams = {
    role: params.role,
    type: params.type,
    from: params.from,
    to: params.to,
  }
  const {
    data: getAttendedNotificationsResponse,
    isLoading: getAttendedNotificationsIsLoading,
    isFetching: getAttendedNotificationsIsFetching,
    fetchNextPage: fetchNextNotificationsPage,
    hasNextPage: hasMoreNotifications,
  } = useInfiniteQuery({
    queryKey: [GET_NOTIFICATIONS_QUERY_KEY, filteredParams],
    queryFn: ({ pageParam = 1 }) =>
      getAttendedNotifications({ page: pageParam, ...filteredParams }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.totalPages > lastPage?.data?.currentPage) {
        return lastPage?.data?.currentPage + 1
      }
    },
  })

  return {
    getAttendedNotificationsResponse,
    getAttendedNotificationsIsLoading,
    getAttendedNotificationsIsFetching,
    fetchNextNotificationsPage,
    hasMoreNotifications,
  }
}

interface UseGetNotificationsSummaryParams {
  role: string
}

export function useGetNotificationsSummary({
  role,
}: UseGetNotificationsSummaryParams) {
  const { data: notificationsSummary } = useQuery(
    GET_NOTIFICATIONS_SUMMARY_QUERY_KEY,
    () => getNotificationsSummary({ role })
  )

  return {
    notificationsSummary,
  }
}

interface UseGetNotificationsSummaryTotalParams
  extends UseGetNotificationsSummaryParams {}

export function useGetNotificationsSummaryTotal({
  role,
}: UseGetNotificationsSummaryTotalParams) {
  const {
    data: notificationsSummaryTotal,
    isLoading: notificationsSummaryTotalIsLoading,
  } = useQuery(GET_NOTIFICATIONS_SUMMARY_TOTAL_QUERY_KEY, () =>
    getNotificationsSummaryTotal({ role })
  )

  const getNotificationsSummaryTotalSum = (
    notificationsSummaryTotal: GetNotificationsSummaryTotalResponse
  ) => {
    return (
      notificationsSummaryTotal.data?.calls +
      notificationsSummaryTotal.data?.devices +
      notificationsSummaryTotal.data?.medications
    )
  }
  return {
    getNotificationsSummaryTotalSum,
    notificationsSummaryTotal,
    notificationsSummaryTotalIsLoading,
  }
}

interface MarkNotificationAsReadParams {
  onSuccessCb?: () => void
  onErrorCb?: () => void
  onSettledCb?: (data: any, error: any) => void
}

export function useMarkNotificationAsRead({
  onSuccessCb,
  onErrorCb,
  onSettledCb,
}: MarkNotificationAsReadParams = {}) {
  const queryClient = useQueryClient()

  const {
    mutate: markNotificationAsRead,
    isLoading: markNotificationAsReadIsLoading,
    isError: markNotificationAsReadIsError,
    isSuccess: markNotificationAsReadIsSuccess,
  } = useMutation(
    (notificationId: string) => markNotificationAsReadService(notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_NOTIFICATIONS_SUMMARY_QUERY_KEY)
        queryClient.invalidateQueries(GET_NOTIFICATIONS_SUMMARY_TOTAL_QUERY_KEY)
        queryClient.invalidateQueries(GET_NOTIFICATIONS_QUERY_KEY)
        queryClient.invalidateQueries(GET_NOTIFICATIONS_ATTENDED_QUERY_KEY)
        onSuccessCb?.()
      },
      onError: () => {
        onErrorCb?.()
      },
      onSettled: (data, error) => {
        onSettledCb?.(data, error)
      },
    }
  )

  return {
    markNotificationAsRead,
    markNotificationAsReadIsLoading,
    markNotificationAsReadIsError,
    markNotificationAsReadIsSuccess,
  }
}

