import React from "react";

interface WorkplanPatientCardProps {
  label?: string;
  description?: string;
  background?: string;
}

const WorkplanRecomendationsPatientCard: React.FC<WorkplanPatientCardProps> = ({
  label,
  description,
  background,
}) => {
  return (
    <div className={` mb-[8px] p-[16px] sm:mh-[76px] rounded  rounded-l-lg  bg-[${background}] }`}>
      <div className='text-left font-extralight text-Fs9	 mb-[4px]'>{label}</div>
      <div className='text-left text-Fs8 font-medium '>{description}</div>
    </div>
  )
}

export default WorkplanRecomendationsPatientCard;