import { useMutation } from 'react-query'
import { createMeetingChime, getMeetingUserJoined }from '../services/meetingChime'
import { MeetingChimePost } from '../types/meetingChime';

export function useCreateMeetingChime() {
  return useMutation((data: MeetingChimePost) => createMeetingChime(data));
}

export function useGetMeetingUserJoined()  {
  return useMutation((attendeeId: string) => getMeetingUserJoined(attendeeId));
}