import { useMutation } from 'react-query'
import { postRegisterPatient, putRegisterPatient } from '../services/postRegisterPatient'

export function usePostRegisterPatient() {

  return useMutation((value:object) => postRegisterPatient(value) );
}

export function useUpdatePatient() {

  return useMutation((value:any) => putRegisterPatient(value) );
}
