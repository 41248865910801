import { PatientsModel } from 'features/shared/types/userType'
import fetcher from 'features/shared/utils/fetcher';
import { useMutation } from 'react-query'
import { getListPatientsCO } from './patient.service'


export function useGetListPatients() {
  return useMutation((query: string) => getListPatients(query));
}
export function useGetAttentsListPatients() {
  return useMutation((query: string) => getAttentsListPatients(query));
}
export function useGetListPatientsCO() {
  return useMutation((page: number) => getListPatientsCO(page));
}

export async function getListPatients(query: string) {
  return await fetcher.get<{data: PatientsModel}>('/patients'+query);
}
export async function getAttentsListPatients(query: string) {
  return await fetcher.get<{data: PatientsModel}>('/attention/patients'+query);
}
