import InputSelectMultiple from 'components/InputSelectMultiple';
import MedicalDeviceCard from './MedicalDeviceCard';
import { PatientModel } from 'features/Patient/models/Patient.model';
import { useEffect, useState } from 'react';
import { MedicalDeviceOptionModel, MedicalDeviceServiceModel, RegisterMedicalDeviceModel, ReportMedicalDeviceModel, useGetDevicesService, useGetListDevices, useReportDeviceService, useUpdateDeviceServiceProp, useCreateDeviceService, useDeleteDeviceService } from '../hooks/useDevices';
import Loader from 'components/Loader';
import AllMedicalDevices from './AllMedicalDevices';
import useUser from 'features/shared/hooks/useUser';
import { sendNotificationReportDevice } from 'features/Notifications/services';
import { onAlert } from 'components/Alert';

interface MedicalDevicesProps {
  patientData?: PatientModel
}
const MedicalDevices: React.FC<MedicalDevicesProps> = ({
  patientData
}) => {
  const { userCurrent } = useUser();
  const [ optionDevices, setOptionDevices] = useState<MedicalDeviceOptionModel[]>([]);
  const [ listDevicesService, setListDevicesService] = useState<MedicalDeviceServiceModel[]>([]);
  const { mutateAsync: getLitDevices, isLoading: isLoadingDevices } = useGetListDevices();
  const { mutateAsync: getDevicesService, isLoading: isLoadingDevicesService } = useGetDevicesService();
  const { mutateAsync: reportDeviceService, isLoading: isLoadingReportDevicesService } = useReportDeviceService();
  const [showModalDevices, setShowModalDevices] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<{current?: any, send?: any}>({
    current: {}, send: { serviceId: undefined, data: {}}
  });
  const { mutateAsync: registerDeviceService } = useCreateDeviceService(selectedDevice.send);
  const { mutateAsync: deleteDeviceService, isLoading: isLoadingDeleteDeviceService } = useDeleteDeviceService();
  const { mutateAsync: updateDeviceServiceProp, isLoading: isLoadingUpdateDeviceServiceProp } = useUpdateDeviceServiceProp();

  const [actionSave, setActionSave] = useState(false)

  useEffect(() => {
    onGetListDevices();
    onGetDevicesService();
  }, [patientData?.service])

  useEffect(() => {
    if (selectedDevice?.send && actionSave)  {
      setActionSave(false)
      registerDeviceService(selectedDevice.send).then(res => {
        if (res.data) {
          const aux = [...listDevicesService];
          aux.pop();
          const list  = [...listDevicesService];
          const lastItem = list[list.length - 1];
          lastItem.id = res.data
          setListDevicesService([...aux, lastItem]);

        } else {
          const list  = [...listDevicesService];
          list.pop()
          setListDevicesService(list);
        }
      });
    }
  }, [actionSave])

  const onGetDevicesService = async () => {
    if (patientData?.service) {
      const response = await getDevicesService(patientData?.service?.id);
      if (response.data.length > 0) {
        setListDevicesService(response.data);
      } else {
        setListDevicesService([]);
      }
    }
  }

  const onGetListDevices = async () => {
    if (patientData?.service) {
      const response = await getLitDevices(patientData?.service?.id);

      if (response.data.length > 0) {
        setOptionDevices(response.data);
      }
    }
  }

  const onActivateDevice = (data: RegisterMedicalDeviceModel, planDeviceId?: number) => {
    updateDeviceServiceProp({ planDeviceId, data }).then(res => {
      if (res.data) {
/*         console.log('res.data', res.data)
 */       /*  const listUpdate = listDevicesService.map(e => {
          if (e.deviceId === data.deviceId) {
            return {
              ...e,
              status: 1,
            }
          }
          return {...e}
        })
        setListDevicesService(listUpdate); */
        onGetDevicesService();
        onAlert.success('Actualización exitosa');
      } else {
        onAlert.error(res);
      }
    })
  }
  const onReplaceDevice = (data: RegisterMedicalDeviceModel) => {   
    registerDeviceService({ serviceId:patientData?.service.id, data }).then(res => {
      if (res.data) {
/*         console.log('res.data', res.data)
 *//*          const listUpdate = listDevicesService.map(e => {
          if (e.deviceId === data.deviceId) {
            return {
              ...e,
              status: 1,
            }
          }
          return {...e}
        })
       setListDevicesService(listUpdate);
 */        onGetDevicesService();
        onAlert.success('Actualización exitosa');
      } else {
        onAlert.error(res);
      }
    })
  }
  const onReportDevice = (data: ReportMedicalDeviceModel, device: MedicalDeviceServiceModel) => {
    reportDeviceService({
      planDeviceId: device.id,
      data: data
    }).then(res => {
      if (res.data) {
        onAlert.success('Actualización exitosa');
        const listUpdate = listDevicesService.map(e => {
          if (e.id === device.id) e.status = 2;
          return e;
        });

        setListDevicesService(listUpdate);
        sendNotificationReportDevice(userCurrent?.id, device.id);
      } else {
        onAlert.error(res);
      }
    });
  }

  const onSelectDevice = (item: any) => {
    
    const newDevice: MedicalDeviceServiceModel = { "id": 0, "planId": 0, "serviceId":0,"deviceId": item.value, "deviceName": item.label, "serial": "", "status": 0, "createdAt": "", "updatedAt": "" };
    setActionSave(true)
    setSelectedDevice({
      'current': newDevice,
      'send':{ serviceId: patientData?.service.id, data: {deviceId: item.value} }
    })
    setListDevicesService([...listDevicesService, newDevice]);
  }

  const onDeleteDevice = (item: any) => {
    const el = listDevicesService.find(e => e.deviceId === item.value)
    deleteDeviceService(el?.id).then(res => {
      if (res.data) {
        const updateList = listDevicesService.filter(e => e.deviceId !== item.value);
        setListDevicesService(updateList);
      } else {
        onAlert.error(res);
      }
    })
  }

  const formatOptions = (data: MedicalDeviceOptionModel[]) => data.map((e: MedicalDeviceOptionModel) => ({value: e.id, label: e.name, isRemovable: e.id === 0}));

  const formatOptionsFromServiceDevices = (data: MedicalDeviceServiceModel[]) => data.map((e: MedicalDeviceServiceModel) => ({value: e.deviceId, label: e.deviceName, isRemovable: e.status === 0}));
  return (
    <>
      { (isLoadingReportDevicesService || isLoadingDeleteDeviceService || isLoadingUpdateDeviceServiceProp) && <Loader /> }

      <div className='border-solid border-[1px] border-grey-200 rounded-[16px] p-4'>
        <div className='flex justify-between items-center'>
          <p className='text-body1 mb-4'>Dispositivos médicos</p>
          {listDevicesService.length > 3 && <button className='border-nonde text-button p-0  mr-2' onClick={() => setShowModalDevices(true)}>Ver todos</button> }
        </div>
        <InputSelectMultiple
          data-testid="selectMultiple"
          label='Dispositivos'
          options={formatOptions(optionDevices)}
          value={formatOptionsFromServiceDevices(listDevicesService)}
          onSelect={onSelectDevice}
          onRemove={onDeleteDevice}
        />
        <div data-testid="devicesCreated" className='grid sm:grid-cols-3 gap-4 my-4'>
          {
            listDevicesService.slice(listDevicesService.length > 3 ? listDevicesService.length - 3 : 0).map((el: MedicalDeviceServiceModel, index: number) =>
              <MedicalDeviceCard
                key={index}
                data={el}
                onActivate={onActivateDevice}
                onReplace={onReplaceDevice}
                onReportDevice={onReportDevice}
                serviceId={patientData?.service?.id}
              />
            )
          }

          {
            showModalDevices &&
            <AllMedicalDevices
              onCloseModal={() => setShowModalDevices(false)}
            >
              <div className='grid sm:grid-cols-3 gap-4 my-4'>
                {
                  listDevicesService.map((el: MedicalDeviceServiceModel, index: number) =>
                    <MedicalDeviceCard
                      key={index}
                      data={el}
                      onActivate={onActivateDevice}
                      onReplace={onActivateDevice}
                      onReportDevice={onReportDevice}
                    />
                  )
                }
              </div>
            </AllMedicalDevices>
          }

        </div>
      </div>
    </>
  );
};

export default MedicalDevices;
