interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: any;
  isActivable: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  className,
  icon,
  isActivable,
  ...props
}) => {

  const filterActive = 'invert(100%) sepia(0%) saturate(3451%) hue-rotate(56deg) brightness(115%) contrast(98%)';

  return (
    <button
      {...props}
      className={`
        w-[56px] h-[56px] rounded-[8px] flex items-center justify-center 
        ${props.disabled ? 'opacity-50' : ''} 
        ${className}
      `}
    >
      {icon !== undefined && <img src={icon} alt='icon' className='w-[24px] h-[24px]' style={isActivable ? {filter: filterActive } : {}}/>} 
    </button>
  )
}

export default IconButton
