import fetcher from 'features/shared/utils/fetcher';
import { useMutation } from 'react-query'

export interface DevicesSuppliesModel {
  codigo: string;
  descripcion: string;
  unidad_medida: string;
  tipo: string;
  via_administracion: string;
  quantity: number;
}

export interface DevicesSuppliesPatientResponseModel {
  currentPage: number,
  total: number,
  totalPages: number,
  data: DevicesSuppliesPatientModel[],
}

export interface DevicesSuppliesPatientModel {
  patientId: number,
  serviceId: number,
  patient: string,
  nhc: number,
  serviceDate: string,
  completeOrder: string,
  countryId: string
}

export interface DevicesSuppliesPatientIdModel {
  code: string
  product: string
  quantity: number
  patient: string
  createdAt: string
  type: string
  measurement: string
  deliveryStatus: number
}

export interface DevicesSuppliesProductReponseModel {
  currentPage: number,
  total: number,
  totalPages: number,
  data: DevicesSuppliesProductModel[],
}

export interface DevicesSuppliesProductModel {
  codigo: string;
  descripcion: string;
  tipo: string;
  quantity: number;
  serviceDate: string;
}

export function useGetDevicesSupplies() {
  return useMutation((query: string) => getDevicesSupplies(query));
}

export async function getDevicesSupplies(query: string) {
  return await fetcher.get(`/devices-supplies/report${query}`);
}

export function useGetDevicesSuppliesPatientId() {
  return useMutation((data: any) => getDevicesSuppliesPatientId(data));
}

export async function getDevicesSuppliesPatientId(data: any) {
  return await fetcher.get(`/pharmacy/patients/${data.patientId}${data.query}`);
}

export function useGetDevicesSuppliesPatient() {
  return useMutation((query: string) => getDevicesSuppliesPatient(query));
}

export async function getDevicesSuppliesPatient(query: string) {
  return await fetcher.get(`/devices-supplies/report/patients${query}`);
}

export function useGetDevicesSuppliesProduct() {
  return useMutation((query: string) => getDevicesSuppliesProduct(query));
}

export async function getDevicesSuppliesProduct(query: string) {
  return await fetcher.get(`/devices-supplies/report${query}`);
}