import React, { useState } from 'react';
import Point from './img/icon.png';

const KebabMenu = (props: any) => {
  const [see, setSee] = useState<boolean>(false);

  const handleClick = (e: boolean) => {
    setSee(!e)
  };
  return (
    <div className='relative cursor-pointer'>
      <div className="relative w-max">
        <img onClick={() => handleClick(see)} className="inline object-cover cursor-pointer  "
          src={Point} alt={Point}
        />
        {props.amount &&

          <div className="rounded-full w-[18px] h-[18px] bg-error-main absolute	top-[5px] right-[14px] text-white flex items-center justify-center	 text-center text-Fs9 ">{props.amount}</div>
        }
      </div>
      {see &&
        <div className="absolute flex-col		top-[40pxl left-[-160px]  flex w-max	min-w-[154px]	 border-solid border-[1px]  bg-white  rounded-[8px] shadow-md border-grey-200 ">
          {props.items.map((e: any, i: any) =>
            <div onClick={e.onClick} key={i} className="flex items-center justify-start p-[30px]">
              <div className="relative cursor-pointer	">
                <img className="inline object-cover w-[16px] h-[16px] "
                  src={e.icon} alt={e.icon}
                />
                {e.amount &&
                  <div className="rounded-full w-[10px] h-[10px] bg-error-main absolute	top-[2px] right-[-3px] "></div>
                }
              </div>
              <div className="ml-[11px] cursor-pointer text-left text-Fs9">
                {e.name}
              </div>
            </div>
          )}
        </div>
      }

    </div >
  )
};

export default KebabMenu
