import { FC, useState, useRef, useCallback } from 'react'

import { AccordionProps } from './types'

import IconChevronUp from 'assets/icons/icon_chevron_up.svg'
import IconChevronDown from 'assets/icons/icon_chevron_down.svg'

const Icon: FC<{ iconName: string }> = ({ iconName }) => {
  return (
    <img src={iconName} alt={iconName} className="sm:w-[24px] sm:h-[24px]" />
  )
}

export const Accordion: FC<AccordionProps> = ({
  title,
  children,
  defaultOpen = false,
}) => {
  const [isOpened, setOpened] = useState<boolean>(defaultOpen)
  const [height, setHeight] = useState<string>(defaultOpen ? 'auto' : '0px')
  const contentElement = useRef<HTMLDivElement>(null)

  const HandleOpening = useCallback(() => {
    setOpened(!isOpened)
    setHeight(!isOpened ? `${contentElement?.current?.scrollHeight}px` : '0px')
  }, [isOpened, contentElement])

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex justify-between text-black'} onClick={HandleOpening}>
        <span className={'text-subtitle2'}>{title}</span>
        {isOpened ? (
          <Icon iconName={IconChevronUp} />
        ) : (
          <Icon iconName={IconChevronDown} />
        )}
      </div>
      <div
        ref={contentElement}
        style={{ height: height }}
        className="overflow-hidden transition-all duration-200"
      >
        {children}
      </div>
    </div>
  )
}

