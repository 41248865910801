import { useMutation, useQuery } from 'react-query'
import {
  getUserRole,
  getUserRoleById,
  createUserRole,
  updateUserRole,
  getRolesActive,
  updateRoleStatus,
  getRolesSearchResults,
  getRolePermissions
} from '../services/userRole'

export function useGetUserRole(query?: string) {
  return useQuery(['roles', query],() => getUserRole(query))
}

export function useGetRolesSearchResults() {
  return useMutation((query: string) => getRolesSearchResults(query));
}

export function useGetRolesActive() {
  return useQuery(['rolesActive'],() => getRolesActive())
}

export function useRolePermissions() {
  return useQuery(['permissions'],() => getRolePermissions())
}

export function useGetUserRoleById() {
  return useMutation((id: number) => getUserRoleById(id))
}

export function useCreateUserRole() {
  return useMutation((data: any) => createUserRole(data));
}

export function useUpdateUserRole() {
  return useMutation((data: any) => updateUserRole(data));
}

export function useUpdateRoleStatus() {
  return useMutation((data: any) => updateRoleStatus(data));
}
