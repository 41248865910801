import { FC } from 'react'

import IconClose from 'assets/icons/icon_close.svg'

interface ModalProps {
    disable?: boolean;
    onCloseModal: () => void
    onSubmit?: () => void
    title: string
    classHeader?: string
    showModal: boolean
}

const Modal: FC<ModalProps> = ({
    onCloseModal,
    children,
    showModal,
    title,
    classHeader,
}) => {


    return (
        <>
            {showModal &&
                <div className="">
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative py-8 px-6 w-full max-w-[608px] 2xl:max-w-[70%]">
                            <div data-testid="modal_add_device" className="border-0 rounded-lg shadow-lg 
                            relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-[16px] ">
                                <div className={` pb-[24px] flex justify-between items-center ${classHeader}`}>
                                    <span className="text-left text-Fs8 font-medium">{title}</span>
                                    <button
                                        onClick={onCloseModal}
                                        className="h-[30px] w-[30px] flex items-center justify-center"
                                    >
                                        <img src={IconClose} alt="close" className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6" />
                                    </button>
                                </div>
                                {children}
                                
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
            }
        </>
    )
}

export default Modal

