import React, { useState, useEffect } from 'react';
import IconClose from 'assets/icons/icon_close.svg';
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Input from 'components/input'
import LabelForm from 'components/LabelForm'
import InputFile from 'components/InputFile';
import Button from 'components/Button';
import SelectTypeCountry from '../SelectCountry';
import { useUploadFile } from 'features/UserManagement/hooks/useUploadFile';
import LoaderMini from 'components/LoaderMini';

interface Props {
  onCloseModal: () => void;
  storage: any;
  isLoading?: boolean;
  handleSendData: (data: any) => void;
}

const FormClinicNetwork: React.FC<Props> = ({
  onCloseModal,
  storage,
  isLoading,
  handleSendData,
}) => {
  const defaultFile = {
    name: '',
    src: ''
  }
  const { t } = useTranslation()
  const { register, watch, control, handleSubmit, setValue } = useForm()

  const [termsFile, setTermsFile] = useState<any>(defaultFile)
  const [policyFile, setPolicyFile] = useState<any>(defaultFile)
  const [consentsFile, setConsentsFile] = useState<any>(defaultFile)
  const [isEdit, setEdit] = useState<boolean>(false)

  const { mutateAsync: uploadFile } = useUploadFile()

  const onSubmit = (data: any) => {
    const sendData = {
      ...data,
      documentTermsCondition: termsFile.src,
      documentPrivacyPolicy: policyFile.src,
      documentInformedConsent: consentsFile.src
    }
    if (isEdit) {
      sendData.id = storage.id
    }
    handleSendData && handleSendData(sendData)
  }

  const handleUpload = (e: any, inputName: string) => {
    e.preventDefault()

    if (e.target.files) {
      const reader = new FileReader();
      const file = e.target.files[0];

      if (reader !== undefined && file !== undefined) {
        reader.onloadend = async () => {
          switch (inputName) {
            case 'termsFile':
              const json = await uploadFile({
                source: 'clinic-documents',
                file
              })
              if (json.data) {
                setTermsFile({ ...termsFile, src: json.data })
              }
              break
            case 'policyFile':
              const axios = await uploadFile({
                source: 'clinic-documents',
                file
              })
              if (axios.data) {
                setPolicyFile({ ...policyFile, src: axios.data })
              }
              break
            case 'consentsFile':
              const response = await uploadFile({
                source: 'clinic-documents',
                file
              })
              if (response.data) {
                setConsentsFile({ ...consentsFile, src: response.data })
              }
              break
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleClickClear = (e: any, inputName: string) => {
    e.stopPropagation();

    switch (inputName) {
      case 'termsFile':
        setTermsFile(defaultFile)
        break
      case 'policyFile':
        setPolicyFile(defaultFile)
        break
      case 'consentsFile':
        setConsentsFile(defaultFile)
        break
    }
  }

  const isFileFill = (file: any) => file.name || file.src ? true : false

  const validateForm = () => {
    if (
      watch('name') &&
      watch('countryId') &&
      isFileFill(termsFile) &&
      isFileFill(policyFile) &&
      isFileFill(consentsFile)
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (storage) {
      setEdit(true)
      setValue('name', storage?.name)
      setValue('countryId', storage?.countryId)
      setConsentsFile({
        ...consentsFile,
        src: storage?.documentInformedConsent
      })
      setPolicyFile({
        ...policyFile,
        src: storage?.documentPrivacyPolicy
      })
      setTermsFile({
        ...termsFile,
        src: storage?.documentTermsCondition,
      })
    }

  }, [])

  return (
    <>
      <div className="justify-end items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative h-full w-full max-w-[90%]  xl:max-w-[60%]">
          <div
            className="border-0 shadow-lg relative w-full h-full bg-white outline-none focus:outline-none overflow-auto	 p-4 flex flex-col"
          >
            <div className="relative">
              {isLoading && <LoaderMini />}
              <div className='pb-[40px] flex justify-between items-center mb-4'>
                <p className='text-subtitle1 font-medium'>
                  Agregar nueva Red de clínicas
                </p>
                <button
                  onClick={onCloseModal}
                  className="h-[30px] w-[30px] flex items-center justify-center"
                >
                  <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
                </button>
              </div>

              <div className='grow'>
                <form data-testid="form_add_newClinic" onSubmit={handleSubmit(onSubmit)}>
                  <Input data-testid="clinic-name"
                    type="text"
                    typeValidation='text'
                    required
                    label={t('name_clinic_all')}
                    value={watch('name')}
                    classNameWrapper='mb-2'
                    {...register('name')}
                  />

                  <LabelForm label="Cargar archivos" className='mt-6 mb-4 font-medium' />
                  <SelectTypeCountry control={control} />
                  <InputFile
                    label="Términos y condiciones"
                    required
                    value={termsFile?.src}
                    handleChange={(e: any) => handleUpload(e, 'termsFile')}
                    isClosable={true}
                    onClickIconDelete={(e: any) => handleClickClear(e, 'termsFile')}
                    accept=".pdf"
                    className="mb-2 mt-3"
                    classNameInput="pr-10"
                  />
                  <InputFile
                    label="Política y privacidad"
                    required
                    value={policyFile?.src}
                    handleChange={(e: any) => handleUpload(e, 'policyFile')}
                    isClosable={true}
                    onClickIconDelete={(e: any) => handleClickClear(e, 'policyFile')}

                    accept=".pdf"
                    className="mb-2"
                    classNameInput="pr-10"
                  />
                  <InputFile
                    label="Consentimiento informado"
                    required
                    value={consentsFile?.src}
                    handleChange={(e: any) => handleUpload(e, 'consentsFile')}
                    isClosable={true}
                    onClickIconDelete={(e: any) => handleClickClear(e, 'consentsFile')}

                    accept=".pdf"
                    classNameInput="pr-10"
                  />
                  <div className="flex justify-center gap-4 mt-[24px]">
                    <Button
                      className="w-[296px]"
                      type="button"
                      variant='secondary'
                      size="large"
                      onClick={onCloseModal} >
                      Cancelar
                    </Button>
                    <Button
                      data-testid="button_send_clinic"
                      className="w-[296px]"
                      type="submit"
                      variant='primary'
                      disabled={!validateForm()}
                      size="large">Guardar</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default FormClinicNetwork;