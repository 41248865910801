import React, { useEffect, useState } from 'react'


import TableBody from './body';
import { getGeofences } from 'features/UserManagement/services/user'


interface PropsTable {
  data: any;
  onSubmit: (value: any) => any
  className?: string
  isClinic?: boolean
  onGetListUsers?:() => void
  isSpecialty?: boolean
}

const Table: React.FC<PropsTable> = (props) => {

  return (
    <>
      <section data-testid="Table" className={`w-full ${props.className}`}>
        <table className="min-w-full divide-y divide-gray-200 border-collapse">
          <thead className="bg-bg7">
            <tr className='even:border-t-[1px] even:border-t-grey-200'>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Usuarios
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Email
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Rol
              </th>
              {
                props.isSpecialty &&
                <th
                  scope="col"
                  className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                >
                  Especialidad
                </th>
              }
              {
                props.isClinic &&
                <th
                  scope="col"
                  className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                >
                  Clínica
                </th>
              }
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Estado
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Acciones
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              props?.data?.length > 0 ? (
                props?.data?.map((item: any, index: any) => (
                  <TableBody
                  key={'TableBody-'+index}
                  isSpecialty={props.isSpecialty}
                  index={index}
                  item={item}
                  onGetListUsers={props.onGetListUsers}

                  />
                 
                ))
              ) : <tr className='h-[54px]'><td colSpan={5} className="px-4 text-center" >No se encontraron resultados</td></tr>
            }
          </tbody>
        </table>
      </section>
      
    </>
  )
}

export default Table
