

const Loader = (props) => {

  return (
      <div className={`absolute z-10		justify-center items-center flex  w-full h-full bg-whiteRgba ${props?.className} `}>
        <div className="w-[40px] h-[40px] border-l-4  border-bg4 rounded-full animate-spin"></div>
        
    </div>
  )
}

export default Loader
