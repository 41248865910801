import React from 'react';
import { usePagination, DOTS } from './usePagination';
import IconArrowNext from 'assets/icons/icon_arrow_next-b.svg';
import IconArrowPrev from 'assets/icons/icon_arrow_previous-b.svg';

const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange: any[] | undefined = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange!.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange![paginationRange!.length - 1];

  return (
    <ul
      className={`flex flex-wrap list-style-type-none gap-x-2`}
    >
      <li
        className={`
          h-[32px] w-[32px] border-solid border-[1px] rounded-[8px] flex items-center justify-center cursor-pointer 
          ${currentPage === 1 ? 'pointer-events-none hover:cursor-default bg-text-light border-none' : ''}
        `}
        onClick={onPrevious}
      >
        <img src={IconArrowPrev} alt="Prev" className='text-grey-400' />
      </li>
      
      {paginationRange!.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <li key={DOTS}
            className="h-[32px] w-[32px] border-solid border-[1px] rounded-[8px] flex items-center justify-center cursor-pointer "
          >&#8230;</li>;
        }

        return (
          <li
            key={index}
            className={`
              h-[32px] w-[32px] border-primary-gray border-solid border-[1px] rounded-[8px] flex items-center justify-center cursor-pointer 
              ${pageNumber === currentPage ? 'border-primary-dark text-primary-dark' : ''}
            `}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}

      <li
        className={`
          h-[32px] w-[32px] border-solid border-[1px] rounded-[8px] flex items-center justify-center cursor-pointer 
          ${currentPage === lastPage ? 'pointer-events-none hover:cursor-default bg-grey-200 border-none' : ''}
        `}
        onClick={onNext}
      >
        <img src={IconArrowNext} alt="Next" />
      </li>
    </ul>
  );
};

export default Pagination;
