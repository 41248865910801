import React, { useEffect, useState } from "react";
import IconClose from 'assets/icons/icon_close.svg';
import { useForm } from 'react-hook-form';
import TextArea from 'components/TextArea';
import { BehaviorSubject, debounceTime } from 'rxjs';
import InputRadio from "components/InputRadio";
import Button from "components/Button";

const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

interface Props {
  showModal: boolean;
  onCloseModal: () => any;
  onSubmitForm: (value: any) => any;
}

const Report: React.FC<Props> = (props) => {

  const { register, watch , setValue} = useForm();
  const [reportType, setTypeReplace] = useState(null);

  useEffect(() => {
    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value) => {
      if (value) onSubmitSearch(value);
    });

    return () => {
      return searchSubscription.unsubscribe();
    }
  }, [])

  const onSubmitSearch = async (value: any) => {
    if (value.length > 0) {
    } else {
    }
  }

  if (props.showModal) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative py-8 px-6 w-full max-w-[618px]">
            <div
              className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4"
            >
              <div className='border-b-[1px] border-[#14141426] pb-[24px] flex justify-between items-center mb-4'>
                <span className='text-left text-Fs8 font-medium'>Reportar</span>
                <button
                  onClick={() => props.onCloseModal()}
                  className="h-[30px] w-[30px] flex items-center justify-center"
                >
                  <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
                </button>
              </div>

              <p className="mb-4 text-body1">
                Seleccione una opción por el cual desea reportar y deje un comentario.
              </p>
              <InputRadio
                onSelected={(val: any) => {
                  setTypeReplace(val)
                }}
                selected={reportType}
                data={[{ name: 'Reemplazo', value: 1 }, { name: 'Falla', value: 2 }, { name: 'Retiro', value: 3 }]}
                className="flex-wrap min-h-max"
                classNameInput="w-full"
              />
              <div className='mt-6' >
                <TextArea
                  label="Comentario"
                  maxLength={800}
                  rows={1}
                  value={watch('comment')}
                  {...register('comment')}
                />
              </div>
              <div className="flex items-center justify-center w-full">
                <Button
                  onClick={() => {
                    props.onSubmitForm({
                      reportType,
                      comment: watch('comment')
                    });
                    props.onCloseModal();
                    setValue('comment','')
                  }}
                  variant='primary'
                  size="large"
                  disabled={(!reportType || watch('comment').length === 0)}
              >Enviar </Button>
              </div>

            </div>

          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  } else {
    return <></>
  }
};

export default Report;
