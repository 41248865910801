import fetcher from 'features/shared/utils/fetcher'

async function putRegisterResponsible(value: any ) {
  return await fetcher.put(
    `/patient/${value?.patientId}/responsible/${value?.responsibleId}/edit`,
    value?.data
  )
}

export default putRegisterResponsible
