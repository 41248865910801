import { useEffect, useState } from 'react'
import CalendarIcon from 'assets/icons/icon_calendar.svg'
import DatePicker from 'react-datepicker'
import IconClock from 'assets/icons/icon_clock.svg'

export const NotificationsDatePicker = (props: any) => {
  const [hasValue, setHasValue] = useState<boolean>(false)
  const [isFocus, setIsFocus] = useState<boolean>(false)

  useEffect(() => {
    if (props.selected) onHasValue(props.selected)
    if (props.startDate) onHasValue(props.startDate)
    if (props.endDate) onHasValue(props.endDate)
  }, [props.selected, props.startDate, props.endDate])

  const onHasValue = (val: any) => {
    setHasValue(val.toString().length > 0)
  }

  const onChangeSelect = (val: any) => {
    onHasValue(val)
    props.onChange(val)
  }

  return (
    <div>
      <div
        className="
          w-full max-w-full h-[56px] text-body1 relative cursor-pointer
          flex items-center justify-start
        "
      >
        <DatePicker
          className={`
            peer appearance-none
            px-[16px] !w-full h-[56px] rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none text-body1
            hover:px-[calc(16px-1px)] hover:border-solid hover:border-[1px] hover:border-grey-400
            focus:px-[calc(16px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73]
            ${
              !props.hasError
                ? 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'
                : ''
            }
            ${props.className}
          `}
          isClearable
          onChange={onChangeSelect}
          onFocus={() => setIsFocus(!isFocus)}
          {...props}
        />

        <label
          className={`
            px-[16px] absolute text-gray-600 pointer-events-none
            transition duration-150 ease-out
            ${hasValue ? 'mb-6 ease-in text-caption text-neutral-600' : ''}
            ${isFocus ? 'text-caption mb-6' : ''}
            ${!props.hasError ? 'text-error-main' : ''}
          `}
        >
          {props.label}
          {props.required ? <span className="font-semibold">*</span> : ''}
        </label>

        {props.selected ? null : props.showTimeSelectOnly ? (
          <img
            src={IconClock}
            className="w-[24px] h-[24px] absolute right-[16px] pointer-events-none"
            alt={IconClock}
          />
        ) : (
          <img
            src={CalendarIcon}
            className="w-[24px] h-[24px] absolute right-[16px] pointer-events-none"
            alt={CalendarIcon}
          />
        )}
      </div>
      {!props.hasError ? (
        <p
          className={`
            px-[16px] text-caption p-0 pt-1 text-error-main
            ${props.errorText ? '' : 'hidden'}
          `}
        >
          {props.errorText ? props.errorText : 'Este campo es obligatorio'}
        </p>
      ) : (
        <p
          className={`
            px-[16px] text-caption p-0 pt-1 text-gray-600
            ${props.helperText ? '' : 'hidden'}
          `}
        >
          {props.helperText}
        </p>
      )}
    </div>
  )
}
