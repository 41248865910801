import React from "react";
import IconClose from 'assets/icons/icon_close.svg';
import Button from "components/Button";
import Chip from 'components/Chip';
import Avatar from 'components/Avatar';
import TextArea from 'components/TextArea';
import { DevicesReportedModel } from '../hooks/useDevice';


interface ReportDeviceProps {
  onCloseModal: () => any;
  onSubmitForm: () => any;
  deviceSelected?: DevicesReportedModel;
}

const ReportDevice: React.FC<ReportDeviceProps> = (props) => {

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative py-8 px-6 w-full max-w-[448px] ">
          <div
            className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4"
          >
            <div className='border-b-[1px] border-[#14141426] pb-[24px] flex justify-between items-start mb-4'>
              <div className='flex items-center gap-4'>
                <p>
                  <span className='text-subtitle1'>{props.deviceSelected?.deviceName}</span> <br />
                  <span className='text-caption'>{props.deviceSelected?.serial}</span>
                </p>
                {
                  props.deviceSelected?.reviewed === 0 ? (
                    <Chip
                      label="Reportado"
                      onClose={() => { }}
                      isRemovable={false}
                      className="bg-[#FFEFED] text-[#E21010] border-none"
                    />
                  ) : (
                    <Chip
                      label="Revisado"
                      onClose={() => { }}
                      isRemovable={false}
                    />
                  )
                }
              </div>
              <button
                id='closeModal'
                onClick={() => props.onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
              </button>
            </div>

            <div className='mb-6'>
              <p className='text-body2 pb-2'>Paciente:</p>
              <Avatar
                name={props.deviceSelected?.patientName}
                className={''}
              />
            </div>
            <div>
              <p className='text-body2 pb-2'>Reportado por:</p>
              <Avatar
                name={props.deviceSelected?.specialistName}
                role="Medico General"
                className={''}
              />
            </div>

            <div className='mt-6'>
              <TextArea
                label='Comentario'
                value={props.deviceSelected?.comment}
                readOnly
              />
            </div>

            <div className="flex items-center justify-center w-full mt-6" >
              <Button
                onClick={() => {
                  props.onSubmitForm();
                  props.onCloseModal();
                }} variant='primary' size="large" 
                type='button'
                disabled={props.deviceSelected?.reviewed !== 0}
              >Revisado</Button>
            </div>

          </div>


        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
};

export default ReportDevice;
