import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Moment from 'moment'

import { PatientsModel } from 'features/shared/types/userType'

import Pagination from 'components/Pagination'
import Breadcrumbs from 'components/Breadcrumbs'

import Loader from '../../../components/Loader'
import { routes } from '../../../routes/routing'
import { useGetListPatients } from '../hooks/usePatient'

const pageSize = 10

const ListCallCenter: React.FC = () => {
  const history = useHistory()

  const [listPatients, setListPatients] = useState<PatientsModel>({
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1,
  })

  const {
    isLoading: listPatientLoading,
    isSuccess: listPatientSucces,
    mutateAsync: mutateAsyncListPatients,
  } = useGetListPatients()

  useEffect(() => {
    onGetListPatients(1)
  }, [])

  const onGetListPatients = async (page: number) => {
    const response = await mutateAsyncListPatients(page)

    setListPatients(response?.data)
  }

  return (
    <React.Fragment>
      {listPatientLoading && <Loader />}
      <Breadcrumbs name={'Central de atención'} currentPathName={'Central de atención'} />

      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        <div className="px-4 pb-[24px] flex items-center justify-between">
          <p className="text-body1 mr-4 text-text-secondary grow">
            {' '}
            <span className="font-medium text-text-primary">
              Pacientes
            </span> | {listPatientSucces && listPatients?.total}
          </p>
        </div>

        {!listPatientLoading && (
          <React.Fragment>
            <section data-testid="Table" className="w-full ">
              <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className="even:border-t-[1px] even:border-t-grey-200">
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Nro. Documento
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Nombre y Apellidos
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Dirección
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Fecha de registro
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      N° de Historia Clínica
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {listPatients?.data?.length > 0 ? (
                    listPatients?.data?.map((item: any, index: any) => (
                      <tr
                        key={index}
                        className="h-[54px] hover:bg-grey-50 cursor-pointer"
                      >
                        <td
                          className="px-4 py-2 whitespace-nowrap"
                          onClick={() => {
                            history.push(
                              `${routes.clinicHistory.url}/patient/${item.id}`
                            )
                          }}
                        >
                          <div className="text-body2">{item.document}</div>
                        </td>
                        <td
                          className="px-4 py-2 "
                          onClick={() => {
                            history.push(
                              `${routes.clinicHistory.url}/patient/${item.id}`
                            )
                          }}
                        >
                          <div className="text-body2 flex items-center gap-2">
                            {false ? (
                              <img
                                src={''}
                                alt={''}
                                className="rounded-full w-10 h-10"
                              />
                            ) : (
                              <div className="rounded-full bg-[#DFF5FF] text-[#008EA3] min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 flex justify-center items-center text-caption">
                                {' '}
                                {item.name?.charAt(0)}
                                {item.lastname?.charAt(0)}{' '}
                              </div>
                            )}
                            {item.name} {item.lastname}
                          </div>
                        </td>
                        <td
                          className="px-4 py-2 whitespace-nowrap"
                          onClick={() => {
                            history.push(
                              `${routes.clinicHistory.url}/patient/${item.id}`
                            )
                          }}
                        >
                          <div className="text-body2">Av. Javier prado</div>
                        </td>
                        <td
                          className="px-4 py-2 whitespace-nowrap"
                          onClick={() => {
                            history.push(
                              `${routes.clinicHistory.url}/patient/${item.id}`
                            )
                          }}
                        >
                          <div className="text-body2">
                            {item.admissionDate
                              ? Moment(item.admissionDate).format('YYYY/MM/DD')
                              : '-'}
                          </div>
                        </td>
                        <td
                          className="px-4 py-2 whitespace-nowrap"
                          onClick={() => {
                            history.push(
                              `${routes.clinicHistory.url}/patient/${item.id}`
                            )
                          }}
                        >
                          <div className="text-body2">
                            {item.clinicHistoryId || '-'}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="h-[54px]">
                      <td colSpan={5} className="px-4 text-center">
                        No se encontraron resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>

            <div className="flex justify-end mt-[24px] px-4">
              <Pagination
                currentPage={listPatients?.currentPage}
                totalCount={listPatients?.total}
                pageSize={pageSize}
                onPageChange={(page: number) => onGetListPatients(page)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListCallCenter

