import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
    scales: {
      x: {
        display: false,
        scaleLabel: false
      },
    },
  responsive: true,
  plugins: {
    legend: {
      //position: "top" as const,
      display: false
    }
  },
  elements: {
    point: {
      radius: 0
    }
  }
};


let dataY = []
let dataX = []
var limit = 5000;
var y = 100;    

for (let i = 0; i < limit; i += 1) {
  y += Math.round(Math.random() * 10 - 5);
  dataY.push(y)
  dataX.push(i)
}

const labels = [...dataX];

export const data = {
  labels,
  datasets: [
    {
      //label: "Dataset 1",
      data: [...dataY],
      //borderColor: "rgb(255, 99, 132)",
      //backgroundColor: "rgba(255, 99, 132, 0.5)",
      lineTension: 0,
      borderWidth:1,
      borderColor: 'blue',
            pointBorderColor: 'blue',
            pointBackgroundColor: 'blue',
            pointBorderWidth: 0,
    }
  ]
};

const DiagramECG: React.FC = () => {
  return (
    <div className='text-center w-full rounded-lg overflow-hidden'>
      <h3 className='bg-[#D2F9FF] text-lg p-3'>Electrocardigrama</h3>
      <Line options={options} data={data} />;
    </div>
  )
}

export { DiagramECG }