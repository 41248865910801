import axios from 'axios';
import fetcher from 'features/shared/utils/fetcher'
import { useMutation } from 'react-query'

export interface MedicalDeviceOptionModel {
  id: number;
  code: string;
  ipressId: number;
  name: string;
  minimumStock: number;
  stock: number;
  createdAt: string;
  updatedAt: string;
  planDeviceId?: number;
}

export interface MedicalDeviceServiceModel {
  serviceId: any;
  id: number;
  planId: number;
  deviceId: number;
  deviceName: string;
  serial: string;
  status: number; // 1 = activo , 2 = reportado
  createdAt: string;
  updatedAt: string;
}

export interface RegisterMedicalDeviceModel {
  deviceId: number;
  serial: string;
}

export interface ReportMedicalDeviceModel {
  reportType: number;
  comment: string;
}

export function useGetListDevices()  {
  return useMutation((serviceId: number) => getListDevices(serviceId));
}

async function getListDevices(serviceId?: number) {
  return await fetcher.get('/devices');
}

export function useGetDevicesService()  {
  return useMutation((serviceId: number) => getDevicesService(serviceId));
}

async function getDevicesService(serviceId: number) {
  return await fetcher.get( `/service/${serviceId}/plan/devices`);
}

export function useRegisterDeviceService()  {
  return useMutation((body: any) => postDevicesService(body));
}

export function useCreateDeviceService(body?: any) {
  return useMutation({
    mutationKey: [body.serviceId, body.data.deviceId],
    mutationFn: async (body: any) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return await fetcher.post( `/service/${body.serviceId}/plan/device`, body.data)
    }
  })
}

async function postDevicesService(body: any) {
  return await fetcher.post( `/service/${body.serviceId}/plan/device`, body.data);
}

export function useUpdateDeviceService()  {
  return useMutation((serviceId: number) => putDevicesService(serviceId));
}

async function putDevicesService(body: any) {
  return await fetcher.put( `/service/${body.serviceId}/plan/device`);
}

export function useReportDeviceService()  {
  return useMutation((body: any) => postReportDevicesService(body));
}

async function postReportDevicesService(body: any) {
  return await fetcher.post( `/service/plan/device/${body.planDeviceId}/report`, body.data);
}

export function useUpdateDeviceServiceProp()  {
  return useMutation((body: any) => updateDeviceServiceProp(body));
}

async function updateDeviceServiceProp(body: any) {
  return await fetcher.put( `/service/plan/device/${body.planDeviceId}/edit`, body.data);
}

export function useDeleteDeviceService()  {
  return useMutation((planDeviceId: any) => deleteDeviceService(planDeviceId));
}

async function deleteDeviceService(planDeviceId: any) {
  return await fetcher.delete( `/service/plan/device/${planDeviceId}/delete`);
}

export function useReplaceDeviceService()  {
  return useMutation((deviceId: number) => putReplaceDeviceService(deviceId));
}

async function putReplaceDeviceService(body: any) {
  return await fetcher.put( `/service/${body.serviceId}/plan/device`, body);
}