
interface ImageErrorProps {
  image: any;
  className?: any;
  classNameImage?: any;
}

const ImageError: React.FC<ImageErrorProps> = ({
  image,
  className,
  classNameImage,
  children,
}) => {
  return (
    <div className={`flex-col w-full flex justify-center items-center	  bg-[url( ${image})] ${className}`}>
      <img className={`w-[227px] mb-[16px] ${classNameImage}`} src={image} alt={image} />
      {children}
    </div>
  )
}

export default ImageError
