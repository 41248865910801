import fetcher from 'features/shared/utils/fetcher'
import { ClinicSearchModel } from '../types/clinic'

export async function getClinics(clinicNetworkId?: number) {
  return await fetcher.get(`/clinic-networks/${clinicNetworkId}/clinics`)
}

export async function getClinicsSearchResults(data: {clinicNetworkId: number, query: string}) {
  return await fetcher.get<{data: ClinicSearchModel}>(`/backoffice/clinic-networks/${data.clinicNetworkId}/clinics${data.query}`)
}

export async function createClinic(dataSend: any) {
  return await fetcher.post('/backoffice/clinics', dataSend)
}

export async function getClinicById(id: string) {
  return await fetcher.get(`/clinic-networks/${id}/clinics`)
}

export async function updateClinic(data: any) {
  const duplicated = {...data}
  /* console.log(duplicated, 'duplicated') */
  delete duplicated.id
  return await fetcher.put(`backoffice/clinics/${data.id}`, duplicated)
}