import {
  MeetingProvider,
  lightTheme,
} from 'amazon-chime-sdk-component-library-react'
import { ThemeProvider } from 'styled-components'

import { NotificationsProvider } from 'features/Notifications/NotificationsProvider'
import { UserProvider } from './user/user-context'

const FeaturesProvider: React.FC = ({ children }) => {
  return (
    <UserProvider>
      <ThemeProvider theme={lightTheme}>
        <MeetingProvider>
          <NotificationsProvider>{children}</NotificationsProvider>
        </MeetingProvider>
      </ThemeProvider>
    </UserProvider>
  )
}

export default FeaturesProvider

