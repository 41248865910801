import { useEffect, useState } from 'react';
import IconEmpty from 'assets/icons/icon_radio_empty.svg';
import IconSelected from 'assets/icons/icon_radio_selected.svg';

const InputRadio = (props: any) => {

  return (
    <div
      className={`
        max-w-full text-body2
        flex items-center justify-start gap-4
        ${props.className}
      `}
    >
      {props.data.map((e:any) =>
        <Radio
          name={e.name}
          key={e.value}
          selected={props.selected === e.value}
          onSelected={() => props.onSelected(e.value)}
          classNameInput={props.classNameInput}
          height={props.height}
          data-testid={e['data-testid']}
        />
      )}
    </div>
  )
}

const Radio = (props: any) => {
  return (
    <div
      className={`
      ${props.classNameInput}
        h-[${props.height || '56px'}]
        cursor-pointer flex items-center justify-start
        px-[calc(16px-1px)] rounded-[16px] bg-white text-grey-600
        border-solid border-[1px] border-grey-200 hover:border-grey-400
        ${props.selected ? '!border-[#0082CD] shadow-md' : ''}
        ${true ? '' : 'px-[calc(16px-2px)] border-solid border-[1px] !border-error-main'}
      `}
      onClick={() => props.onSelected()}
      data-testid={props['data-testid']}
    >
      <div className={`h-[24px] w-[24px] flex items-center justify-center`}>
        {
          props.selected ? (
            <img
              className='w-[24px] h-[24px]'
              src={IconSelected}
            />
          ) : (
            <img
              className='w-[20px] h-[20px]'
              src={IconEmpty}
            />
          )
        }
      </div>
      <span className='ml-[8px] text-body2'>{props.name}</span>
    </div>
  )
}

export default InputRadio;
