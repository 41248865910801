import fetcher from 'features/shared/utils/fetcher'
import { MeetingChimePost } from '../types/meetingChime'

export async function createMeetingChime(json: MeetingChimePost) {
  const id = json.planId
  delete json.planId
  const { data } = await fetcher.post(`/plans/${id}/meeting/join `, json)
  return data
}

export async function getMeetingUserJoined(attendeeId: string) {
  return await fetcher.get( `/meeting/attendee?attendeeId=${attendeeId}`);
}