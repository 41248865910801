import React, { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { TEMP_COUNTRY_DEFAULT } from 'global/constants'
import es from 'date-fns/locale/es'

import Input from '../input'
import useUser from 'features/shared/hooks/useUser'

import InputSelect from 'components/InputSelect'
import Button from 'components/Button';
import InputDatePicker from 'components/InputDatePicker';
import InputRadio from 'components/InputRadio';

import IconUser from 'assets/icons/icon_user.svg';
import IconNavigation from 'assets/icons/icon_navigation.svg';
import IconPhone from 'assets/icons/icon_phone.svg';
import IconHealth from 'assets/icons/icon_health.svg';
import IconMap from 'assets/icons/icon_map.svg';
import LabelForm from 'components/LabelForm';
import { useGetLocations, LocationModel } from 'features/shared/hooks/useGetLocations'
import { AddresSelectedModel } from 'components/MapLocation/MapModel'
import ModalMap from 'components/MapLocation'
import Moment from "moment";

registerLocale('es', es);

interface Props {
  onSubmit: (value: any) => any;
  setSectionStepFormPatient: (value: any) => any;
  dataPatient?: any;
  isEdit: boolean;
  selectOptions: any;
  formStep: number;
}

const FormPatient: React.FC<Props> = (props) => {
  const { userCurrent } = useUser();
  const { register, watch, setValue, handleSubmit, control } = useForm();

  let TEMP_COUNTRY = userCurrent?.ipress?.countryId ? userCurrent?.ipress?.countryId : TEMP_COUNTRY_DEFAULT;
  const [showModal, setShowModal] = useState(false);

  const [addressSelected, setAddressSelected] = useState<AddresSelectedModel>();
  const [listDepartments, setListDepartments] = useState<LocationModel[]>([]);
  const [listProvinces, setListProvinces] = useState<LocationModel[]>([]);
  const [listDistricts, setListDistricts] = useState<LocationModel[]>([]);
  const getListLocations = useGetLocations();

  let documentTypes = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "document_types");
  let coverageHealth = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "coverage_health");
  let membershipType = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "membership_type");
  let educationLevel = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "education_level");

  const history = useHistory();
  const { t } = useTranslation();
  const [startDate, setstartDate] = useState<any>(new Date());
  const [gender, setGender] = useState(null);
  const countryId = watch('countryId');
  const patientData = props.dataPatient;

  useEffect(() => {
    if (props.isEdit && patientData) {
      setValue('lastname', patientData.lastname);
      setValue('name', patientData.name);
      setValue('documentType', patientData.documentType);
      setValue('document', patientData.document);
      if (patientData?.birthday) {
        const birthDate = new Date(patientData.birthday);
        setstartDate(birthDate);
      }

      setGender(patientData.gender);

      if (patientData?.address?.ubigeo) {
        const _countryId = patientData.address.ubigeo[0]?.countryId;
        setValue('countryId', _countryId);

        if (_countryId === 'PE') {
          setValue('department', patientData?.address?.ubigeo[0]?.id);
          setValue('province', patientData?.address?.ubigeo[1]?.id);
          setValue('district', patientData?.address?.ubigeo[2]?.id);

          onGetListLocations(_countryId, patientData?.address?.ubigeo[0]?.parentId, 'department');
          onGetListLocations(_countryId, patientData?.address?.ubigeo[1]?.parentId, 'province');
          onGetListLocations(_countryId, patientData?.address?.ubigeo[2]?.parentId, 'district');
        } else {
          setValue('department', patientData?.address?.ubigeo[0]?.id);
          setValue('province', patientData?.address?.ubigeo[1]?.id);

          onGetListLocations(_countryId, 0, 'department');
          onGetListLocations(_countryId, patientData?.address?.ubigeo[0]?.id, 'province');
        }
      }

      setAddressSelected({
        name: patientData.address?.name,
        reference: patientData.address?.reference,
        lat: patientData.address?.lat,
        lng: patientData.address?.lng,
        number: patientData.address?.number,
        ubigeoId: patientData.address?.ubigeoId
      })

      setValue('mobilePhone', patientData.mobilePhone);
      setValue('email', patientData.email);
      setValue('maritalStatus', patientData.maritalStatus);
      setValue('educationLevel', patientData.educationLevel);
      setValue('occupation', patientData.occupation);
      setValue('healthCoverage', patientData.healthCoverage);
      setValue('membershipType', patientData.membershipType);
      setValue('landline', patientData.landline != 'null' ? patientData.landline : '');
    } else {

    }
  }, [props.dataPatient])


  const onGetListLocations = async (countryId: any, parentId: any, type: string) => {
    const response = await getListLocations.mutateAsync({ countryId, parentId });

    if (response.data.length > 0) {
      switch (type) {
        case 'department':
          setListDepartments(response.data);
          break;
        case 'province':
          setListProvinces(response.data);
          break;
        case 'district':
          setListDistricts(response.data);
          break;
      }
    }
  }

  const validateFormPersonnel = () => {
    if (
      (TEMP_COUNTRY === 'PE' ? watch('lastname') : true) &&
      watch('name') &&
      watch('documentType') &&
      watch('document') &&
      startDate &&
      gender &&
      (watch('countryId') === 'PE' ? (watch('department') && watch('province') && watch('district')) : (watch('department') && watch('province'))) &&
      addressSelected
    ) {
      return true
    } else {
      return false
    }
  }
  const validateFormContact = () => {
    if (
      (TEMP_COUNTRY === 'PE' ? watch('lastname') : true) &&
      watch('name') &&
      watch('documentType') &&
      watch('document') &&
      startDate &&
      gender &&
      (watch('countryId') === 'PE' ? (watch('department') && watch('province') && watch('district')) : (watch('department') && watch('province'))) &&
      addressSelected &&
      watch('mobilePhone') &&
      watch('email') &&
      watch('occupation') &&
      watch('healthCoverage') &&
      watch('membershipType')
    ) {
      return true
    } else {
      return false
    }
  }
  const handleChangeDate = (date: any) => {
    setstartDate(date)
  }

  const onSubmit = () => {
   /*  console.log('click'); */

    let obj = {
      "name": watch('name'),
      "lastname": watch('lastname'),
      "email": watch('email'),
      "address": {
        "name": addressSelected?.name,
        "reference": addressSelected?.reference,
        "lat": addressSelected?.lat,
        "lng": addressSelected?.lng,
        "number": addressSelected?.number,
        "ubigeoId": watch('countryId') === 'PE' ? parseInt(watch('district')) : parseInt(watch('province'))
      },
      "document": watch('document'),
      "documentType": watch('documentType'),
      "birthday": Moment(startDate).format('YYYY/MM/DD'),
      "gender": gender,
      "landline": (watch('landline') ? watch('landline') : 'null'),
      "mobilePhone": watch('mobilePhone'),
      "maritalStatus": watch('maritalStatus'),
      "educationLevel": watch('educationLevel'),
      "occupation": watch('occupation'),
      "healthCoverage": watch('healthCoverage'),
      "membershipType": watch('membershipType'),
      "countryId": watch("countryId")
    }
    props.onSubmit(obj)
  }

  return (
    <section data-testid="FomTypeA" className="w-full ">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full " data-testid={'create_patient_form'}>
        {props.formStep === 0 && (
          <div>

            <LabelForm icon={IconUser} label="Datos personales" className='mt-6 mb-4' />

            <div className={`flex flex-col xl:flex-row sm:grid sm:grid-cols-2 xl:grid-cols-3  gap-2`} >
              <div className="col">
                <Controller
                  control={control}
                  name='documentType'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label={t('document_type')}
                      name="documentType"
                      required
                      onSelect={val => {
                        onChange(val);
                        setValue("document", '');
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        documentTypes?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>

              <div className="col">
                {TEMP_COUNTRY === 'PE' ?
                  <Input
                    type="text"
                    // typeValidation={watch('documentType') === 'dni' ? 'number' : ''}
                    typeValidation={'document'}
                    required
                    label={t('document_number')}
                    minLength={watch('documentType') === 'dni' ? 8 : 12}
                    maxLength={watch('documentType') === 'dni' ? 8 : 12}
                    value={watch('document')}
                    {...register("document", { required: true, maxLength: watch('documentType') === 'dni' ? 8 : 12 })}
                  />
                  :
                  <Input
                    type="text"
                    typeValidation={watch('documentType') === 'pasaporte' ? 'number' : ''}
                    required
                    label={t('document_number')}
                    minLength={watch('documentType') === 'passport' ? 8 : 10}
                    maxLength={watch('documentType') === 'passport' ? 8 : 10}
                    value={watch('document')}
                    {...register('document', { required: true, maxLength: watch('documentType') === 'passport' ? 8 : 10 })}
                  />
                }
              </div>

              <div className="col">
                <Input
                  type="text"
                  typeValidation='text'
                  required
                  label={t('names')}
                  value={watch('name')}
                  {...register('name')}
                />
              </div>
              <div className="col pt-[25px]">
                <Input
                  type="text"
                  typeValidation='text'
                  label={t('lasname')}
                  required={TEMP_COUNTRY === 'PE' ? true : false}
                  value={watch('lastname')}
                  {...register('lastname')}
                />
              </div>
              <div className="col pt-[25px]">

                <InputDatePicker
                  label={t('date_of_Birth')}
                  onChange={handleChangeDate}
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  locale="es"
                  name={'dateOfBirth'}
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <div className="col">
                <label className="text-subtitle2 mb-2">
                  {t('sex')}*
                </label>
                <InputRadio
                  data-testid="select-gender"
                  data={[{ name: t('feminine'), value: 'F' }, { name: t('male'), value: 'M' }]}
                  onSelected={(val: any) => {
                    setGender(val)
                  }}
                  selected={gender}
                />
              </div>
            </div>

            <LabelForm icon={IconNavigation} label="Datos de ubicación" className='mt-6 mb-4' />

            <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-2">

              <div className="col">
                <Controller
                  control={control}
                  name='countryId'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label='País de residencia'
                      name="countryId"
                      required
                      onSelect={val => {
                        onChange(val);
                        setValue("department", '');
                        setValue("province", '');
                        setValue("district", '');
                        onGetListLocations(val, 0, 'department');
                        setListProvinces([]);
                        setListDistricts([]);
                      }}
                      value={value}
                      ref={ref}
                    >
                      <option value="PE"> {t('peru')} </option>
                      <option value="CO">{t('colombia')}</option>
                    </InputSelect>
                  )}
                />
              </div>

              {countryId && (
                <div className="col">
                  <Controller
                    control={control}
                    name='department'
                    render={({ field: { onChange, value, name, ref } }) => (
                      <InputSelect
                        label={t('department')}
                        name="department"
                        required
                        onSelect={val => {
                          onChange(val);
                          setValue("province", '');
                          setValue("district", '');
                          onGetListLocations(watch('countryId'), val, 'province');
                        }}
                        value={value}
                        ref={ref}
                      >
                        {listDepartments?.length > 0 &&
                          listDepartments.map((item) => (
                            <option value={item.id} key={item.parentId + item.id}>
                              {item.name}
                            </option>
                          ))
                        }
                      </InputSelect>
                    )}
                  />
                </div>
              )}

              {countryId === 'PE' && (
                <React.Fragment>
                  <div className="col">
                    <Controller
                      control={control}
                      name='province'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputSelect
                          label={t('province')}
                          name="province"
                          required
                          onSelect={val => {
                            onChange(val);
                            setValue("district", '');
                            onGetListLocations(watch('countryId'), val, 'district');
                          }}
                          value={value}
                          ref={ref}
                        >
                          {listProvinces?.length > 0 && listProvinces.map((item) => (
                            <option value={item.id} key={item.parentId + item.id}>
                              {item.name}
                            </option>
                          ))}
                        </InputSelect>
                      )}
                    />
                  </div>
                  <div className="col">
                    <Controller
                      control={control}
                      name='district'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputSelect
                          label={t('district')}
                          name="district"
                          required
                          onSelect={val => {
                            onChange(val);
                          }}
                          value={value}
                          ref={ref}
                        >
                          {listDistricts?.length > 0 && listDistricts.map((item) => (
                            <option value={item.id} key={item.parentId + item.id}>
                              {item.name}
                            </option>
                          ))}
                        </InputSelect>
                      )}
                    />
                  </div>
                </React.Fragment>
              )}

              {countryId === 'CO' && (

                <div className="col">
                  <Controller
                    control={control}
                    name='province'
                    render={({ field: { onChange, value, name, ref } }) => (
                      <InputSelect
                        label="Municipio"
                        name="province"
                        required
                        onSelect={val => {
                          onChange(val);
                        }}
                        value={value}
                        ref={ref}
                      >
                        {listProvinces?.length > 0 && listProvinces.map((item) => (
                          <option value={item.id} key={item.parentId + item.id}>
                            {item.name}
                          </option>
                        ))}
                      </InputSelect>
                    )}
                  />
                </div>
              )}

              <div className="col">
                <Input
                  name={'address'}
                  label={t('address')}
                  required
                  icon={IconMap}
                  value={addressSelected?.name}
                  onClick={() => { setShowModal(true) }}
                />
              </div>
            </div>
          </div>
        )}

        {props.formStep === 1 && (
          <div >
            <LabelForm icon={IconPhone} label="Datos de ubicación" className='mt-6 mb-4' />

            <div className={`grid sm:grid-cols-2 xl:grid-cols-3 gap-2 `}>
              <div className="col">
                <Input
                  type="tel"
                  typeValidation='phoneNumber'
                  maxLength={9}
                  label={t('landline_phone')}
                  value={watch('landline')}
                  {...register('landline')}
                />
              </div>
              <div className="col">
                <Input
                  type="tel"
                  typeValidation='phoneNumber'
                  required
                  maxLength={TEMP_COUNTRY === 'PE' ? 9 : 10}
                  label="Teléfono móvil"
                  value={watch('mobilePhone')}
                  {...register('mobilePhone')}
                />
              </div>
              <div className="col">
                <Input
                  type="email"
                  typeValidation='email'
                  required
                  label={t('enter_mail')}
                  value={watch('email')}
                  {...register('email')}
                />
              </div>
              <div className="col">
                <Controller
                  control={control}
                  name='maritalStatus'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Estado civil"
                      name="maritalStatus"
                      required={false}
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      <option value="casado">Casado/a</option>
                      <option value="soltero">Soltero/a.</option>
                      <option value="unionDehecho">Unión libre o unión de hecho.</option>
                      <option value="separado">Separado/a</option>
                      <option value="divorciado">Divorciado/a</option>
                      <option value="viudo">Viudo/a</option>
                    </InputSelect>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  control={control}
                  name='educationLevel'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Nivel educativo"
                      name="educationLevel"
                      required={false}
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        educationLevel?.map((colOpt: { id: number; value: string; label: string }) => (<option key={colOpt.id} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>

              <div className="col">
                <Input
                  type="text"
                  typeValidation='text'
                  required
                  label="Ocupación"
                  value={watch('occupation')}
                  {...register('occupation')}
                />
              </div>
            </div>

            <LabelForm icon={IconHealth} label="Datos de salud" className='mt-6 mb-4' />

            <div className={`grid sm:grid-cols-2 xl:grid-cols-3 gap-2`}>
              <div className="col">
                <Controller
                  control={control}
                  name='healthCoverage'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Cobertura en salud"
                      name="healthCoverage"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        coverageHealth?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  control={control}
                  name='membershipType'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Tipo de afiliación"
                      name="membershipType"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        membershipType?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>
            </div>
          </div>
        )}

        <ModalMap
          showModal={showModal}
          isEdit={props.isEdit}
          data={{
            name: patientData?.address.name,
            reference: patientData?.address.reference,
            lat: patientData?.address.lat,
            lng: patientData?.address.lng,
            number: patientData?.address.number,
            ubigeoId: patientData?.address.ubigeoId,
          }}
          region={watch('countryId') === 'PE' ? 'pe' : 'co'}
          onCloseModal={() => { setShowModal(false) }}
          onSubmitAddress={(data: AddresSelectedModel) => {
            setAddressSelected(data);
            setShowModal(false);
          }}
        />

        <div className="flex justify-center gap-4 mt-6">
          <Button type="button" variant='secondary' size="extra-large" onClick={() => { props.formStep === 0 ? history.goBack() : props.setSectionStepFormPatient(0) }} >{props.formStep === 0 ? 'Cancelar' : 'Regresar'}</Button>
          <Button className={`${props.formStep === 0 ? ' ' : 'hidden'}`} type="button" variant='primary' size="extra-large" disabled={!validateFormPersonnel()} onClick={() => { props.setSectionStepFormPatient(1) }} >Guardar y continuar</Button>
          <Button className={`${props.formStep === 0 ? 'hidden' : ''}`} type="submit" variant='primary' size="extra-large" disabled={!validateFormContact()} >Guardar y finalizar</Button>
        </div>
      </form>
    </section >
  )

}

export default FormPatient

