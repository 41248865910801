import fetcher from 'features/shared/utils/fetcher'
import { DevicesAndMedicationsModel } from 'features/shared/types/userType'


export async function getListDevicesAndMedications(query: string) {
  return await fetcher.get<{data: DevicesAndMedicationsModel}>('/devices-supplies'+query);
}


export async function postDevicesAndMedications(newDevicesAndMedications: any) {
/*   console.log(newDevicesAndMedications);
 */  let form_data = new FormData();
  for (let key in newDevicesAndMedications) {
    form_data.append(key, newDevicesAndMedications[key]);
  }
  return await fetcher.post('/devices-supplies/import', form_data)
}
export async function getClinicNetworks(countryId?: string) {
  return await fetcher.get(`/clinic-networks?countryId=${countryId}`)
}