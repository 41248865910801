import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button'

import IconThumbsUp from 'assets/icons/icon_thumbs_up.svg'
import IconThumbsDown from 'assets/icons/icon_thumbs_down.svg'

interface MedicineQuestionCardProps {
  medicineName: string
  onClickYes: () => void
  onClickNo: () => void
}

const Icon: FC<{ iconName: string }> = ({ iconName }) => {
  return (
    <img src={iconName} alt={iconName} className="sm:w-[16px] sm:h-[16px]" />
  )
}

const MedicineQuestionCard: FC<MedicineQuestionCardProps> = ({
  medicineName,
  onClickYes,
  onClickNo,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center justify-center border-[1px] rounded-[16px] p-2">
      <div className="flex flex-col items-center justify-center text-center">
        <h1 className="text-caption">
         {medicineName}
        </h1>
      </div>
      <div className="flex flex-row items-center justify-center mt-2 gap-4">
        <Button
          onClick={onClickYes}
          variant={'primary'}
          size={'ultra-tiny'}
          className="rounded bg-primary-main h-[40px] text-subtitle2"
        >
          <span className="flex flex-row gap-2">
            <Icon iconName={IconThumbsUp} />
            {t('yes')}
          </span>
        </Button>
        <Button
          onClick={onClickNo}
          variant={'emergency'}
          size={'ultra-tiny'}
          className="rounded h-[40px] text-subtitle2"
        >
          <span className="flex flex-row gap-2">
            <Icon iconName={IconThumbsDown} />
            {t('not')}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default MedicineQuestionCard
