import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BehaviorSubject, debounceTime } from 'rxjs'
import Moment from 'moment'

import useUser from 'features/shared/hooks/useUser'
import { useGetListPatients } from 'features/Patients/hooks/usePatient'
import { PatientsModel } from 'features/shared/types/userType'

import Button from 'components/Button'
import Pagination from 'components/Pagination'
import IconButton from 'components/IconButton'
import Breadcrumbs from 'components/Breadcrumbs'
import Input from 'components/input'

import Loader from '../../../components/Loader'
import { routes } from '../../../routes/routing'

import IconSearch from 'assets/icons/icon_search.svg'
import IconFile from 'assets/icons/icon_edit.svg'
import IconList from 'assets/icons/icon_list.svg'
import CheckBox from 'components/CheckBox'

const pageSize = 10
const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

const ListPatients: React.FC = () => {
  const history = useHistory()
  const { userCurrent } = useUser()
  const [searchValue, setSearchValue] = useState('')
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isCheckedSearchCountry, setIsCheckedSearchCountry] =
    React.useState(false)

  const [listPatients, setListPatients] = useState<PatientsModel>({
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1,
  })

  const {
    isLoading: listPatientLoading,
    isSuccess: listPatientSucces,
    mutateAsync: mutateAsyncListPatients,
  } = useGetListPatients()

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    onGetListPatients(searchValue, currentPage)

    const searchSubscription = inputChange$
      .pipe(debounceTime(500))
      .subscribe((value) => {
        setSearchValue(value)
        // setCurrentPage(1);
      })

    return () => {
      return searchSubscription.unsubscribe()
    }
  }, [userCurrent?.ipress])

  useEffect(() => {
    if (currentPage) {
      onGetListPatients(searchValue, currentPage)
    }
  }, [currentPage])

  useEffect(() => {
    if (searchValue === searchText || isCheckedSearchCountry) {
      onGetListPatients(searchValue, 1)
    } else {
      setSearchValue('')
    }
  }, [searchValue, searchText, isCheckedSearchCountry])

  const onGetListPatients = async (
    searchValue?: string,
    currentPage?: number
  ) => {
    if (userCurrent?.ipress?.countryId) {
      const searchText = searchValue !== '' ? `&searchText=${searchValue}` : ''

      const query = `?country=${userCurrent?.ipress?.countryId}&page=${currentPage}${searchText}&searchAllCountry=${isCheckedSearchCountry}`

      const response = await mutateAsyncListPatients(query)

      setListPatients(response.data)
    }
  }

  return (
    <React.Fragment>
      {listPatientLoading && <Loader />}
      <Breadcrumbs name={'Pacientes'} currentPathName={'Lista de pacientes'} />
      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        <div className="px-4 pb-[24px] flex flex-col xl:flex-row	flex justify-end  items-center 	w-full gap-8 xl:gap-0">
          <div className="grow mt-[10px] flex items-center">
            <p className="text-body1 mr-4 text-text-secondary">
              <span className="font-medium text-text-primary">Pacientes</span> |{' '}
              {listPatientSucces && listPatients?.total}
            </p>

            <Button
              data-testid="patients__add_patient"
              variant="primary"
              onClick={() => {
                history.push(`${routes.patients.url}${routes.newPatient.url}`)
              }}
            >
              Añadir paciente
            </Button>
          </div>

          <div className="flex flex-col ">
            <Input
              label="Buscar paciente"
              value={searchText}
              icon={IconSearch}
              classNameWrapper={'h-[56px]'}
              onChange={(e: any) => {
                inputChange.next(e.target.value)
                setSearchText(e.target.value)
              }}
            />
            <CheckBox
              classNameCheck="h-[18px] w-[18px] min-w-[18px]"
              className=" mt-[8px] pl-[0px] text-caption text-text-primary"
              name="Buscar en todo el país"
              selected={isCheckedSearchCountry}
              onSelected={() => {
                setIsCheckedSearchCountry(!isCheckedSearchCountry)
              }}
            />
          </div>
        </div>

        {!listPatientLoading && (
          <React.Fragment>
            <div className="w-full overflow-auto	 scrollbar">
              <section data-testid="Table" className="w-full ">
                <table className="min-w-full divide-y divide-gray-200 border-collapse">
                  <thead className="bg-bg7">
                    <tr className="even:border-t-[1px] even:border-t-grey-200">
                      <th
                        scope="col"
                        className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                      >
                        Nro. Documento
                      </th>
                      <th
                        scope="col"
                        className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                      >
                        Nombre y Apellidos
                      </th>
                      <th
                        scope="col"
                        className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                      >
                        Fecha de registro
                      </th>
                      <th
                        scope="col"
                        className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                      >
                        N° de Historia Clínica
                      </th>
                      <th
                        scope="col"
                        className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {listPatients?.data?.length > 0 ? (
                      listPatients?.data?.map((item: any, index: any) => (
                        <tr key={index} className="h-[54px] hover:bg-grey-50">
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">{item.document}</div>
                          </td>
                          <td className="px-4 py-2 ">
                            <div className="text-body2 flex items-center gap-2">
                              {false ? (
                                <img
                                  src={''}
                                  alt={''}
                                  className="rounded-full w-10 h-10"
                                />
                              ) : (
                                <div className="rounded-full bg-[#DFF5FF] text-[#008EA3] min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 flex justify-center items-center text-caption">
                                  {' '}
                                  {item.name?.charAt(0)}
                                  {item.lastname?.charAt(0)}{' '}
                                </div>
                              )}
                              {item.name} {item.lastname}
                            </div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">
                              {item.admissionDate
                                ? Moment(item.admissionDate).format('YYYY/MM/DD')
                                : '-'}
                            </div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-body2">{item.nhc || '-'}</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="flex gap-2">
                              <IconButton
                                isActivable={false}
                                icon={IconFile}
                                onClick={() =>
                                  history.push(
                                    `${routes.patients.url}${routes.patient.url}/${item.id}`
                                  )
                                }
                              />
                              <IconButton
                                isActivable={false}
                                icon={IconList}
                                onClick={() =>
                                  history.push(
                                    `${routes.patients.url}${routes.patientHistory.url}/${item.id}`
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="h-[54px]">
                        <td colSpan={5} className="px-4 text-center">
                          No se encontraron resultados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </section>
            </div>
            <div className="flex justify-end mt-[24px] px-4">
              <Pagination
                currentPage={listPatients?.currentPage}
                totalCount={listPatients?.total}
                pageSize={pageSize}
                onPageChange={handleChangePage}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListPatients
