import React, { ChangeEvent, useEffect, useState } from 'react';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import Moment from "moment";

import { useTranslation } from 'react-i18next';
import Input from '../input';
import '../../features/shared/css/index.scss';
import { Controller, useForm } from 'react-hook-form';
import useUser from 'features/shared/hooks/useUser';
import { TEMP_COUNTRY_DEFAULT } from 'global/constants';
import InputSelect from 'components/InputSelect';
import InputDatePicker from 'components/InputDatePicker';
import Button from 'components/Button';
import InputFile from 'components/InputFile';
import { PatientModel } from 'features/Patient/models/Patient.model';

registerLocale('es', es);

interface Props {
  onSubmit: (value: any) => any;
  setStep: (value: any) => any;
  allDatapatient?: PatientModel;
  selectOptions: any;
}

const FormCheap: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { register, watch, setValue, handleSubmit, control, formState: { errors } } = useForm();
  const [cartaGarantiaFile, setcartaGarantiaFile] = useState<any>('')
  const [fileIsChanged, setFileIsChanged] = useState<boolean>(false);
  const [authorizationDate, setAuthorizationDate] = useState(new Date())
  const patientData = props.allDatapatient;
  const { userCurrent } = useUser();
  
  let TEMP_COUNTRY = userCurrent?.ipress?.countryId ? userCurrent?.ipress?.countryId : TEMP_COUNTRY_DEFAULT;   
  let responsibleType = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "responsible_type");
  let insuranceCarrier = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "insurance_carrier");
  let plan = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "plan");
  let economicBenefits = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "economic_benefits");
  let economicProduct = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "economic_product");

  const photoUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e.target.files) {
      const reader = new FileReader();
      const file = e.target.files[0];

      if (reader !== undefined && file !== undefined) {
        reader.onloadend = () => {
          setcartaGarantiaFile(file);
          setFileIsChanged(true);
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleChangeDate = (date: any) => {
    setAuthorizationDate(date)
  }

  const onSubmit = () => {
    let obj = {
      responsibleType: watch('responsibleType'),
      insuranceProvider: watch('insuranceProvider'),
      plan: watch('plan'),
      benefits: (TEMP_COUNTRY === 'PE' ? watch('benefits') : null),
      policyNumber: (TEMP_COUNTRY === 'PE' ? watch('policyNumber') : null) ,
      product: (TEMP_COUNTRY === 'PE' ? watch('product') : null),
      authorizationNumber: watch('authorizationNumber'),
      authorizationDate: Moment(authorizationDate).format('YYYY-MM-DD'),
      authorizedPerson: watch('authorizedPerson'),
      secondAuthorizationCode: watch('secondAuthorizationCode'),
      coverageMaximum: watch('coverageMaximum'),
      copayment: watch('copayment'),
      voucherNumber: ((watch('copayment') > 0) ? watch('voucherNumber') : null),
      authorizedServiceCode: watch('authorizedServiceCode'),
    }

    if (fileIsChanged) {
      props.onSubmit({ ...obj, guaranteeLetter: cartaGarantiaFile });
    } else {
      props.onSubmit(obj);
    }
  }

  const getValeidate = () => {
    if (
      watch('responsibleType') &&
      watch('insuranceProvider') &&
      watch('plan') &&
      (TEMP_COUNTRY === 'PE' ? watch('policyNumber') : true) &&
      (TEMP_COUNTRY === 'PE' ? watch('product') : true) &&
      watch('coverageMaximum') &&
      watch('copayment') &&
      watch('authorizedPerson')&&
      ((watch('copayment') > 0) ? watch('voucherNumber') : true) &&
      watch('authorizedServiceCode') &&
      cartaGarantiaFile?.name
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (patientData) {
      setValue('responsibleType', patientData?.service?.economic?.responsibleType || "");
      setValue('insuranceProvider', patientData?.service?.economic?.insuranceProvider || "");
      setValue('plan', patientData?.service?.economic?.plan || "");
      setValue('benefits', patientData?.service?.economic?.benefits || "");
      setValue('policyNumber', patientData?.service?.economic?.policyNumber || "");
      setValue('product', patientData?.service?.economic?.product || "");
      setValue('authorizationNumber', patientData?.service?.economic?.authorizationNumber || "");
      setValue('authorizedPerson', patientData?.service?.economic?.authorizedPerson || "");
      setValue('secondAuthorizationCode', patientData?.service?.economic?.secondAuthorizationCode || "");
      setValue('coverageMaximum', patientData?.service?.economic?.coverageMaximum || "");
      setValue('voucherNumber', patientData?.service?.economic?.voucherNumber !== 'null' ? patientData?.service?.economic?.voucherNumber : "");
      setValue('authorizedServiceCode', patientData?.service?.economic?.authorizedServiceCode || "");
      setcartaGarantiaFile({ name: patientData?.service?.economic?.guaranteeLetterFilename } || "");

      if (TEMP_COUNTRY === 'PE') {
        setValue('copayment', patientData?.service?.economic?.copayment || "");
      } else {
        setValue('copayment', Number(patientData?.service?.economic?.copayment) > 0 ? 1 : 0);
      }

      if (patientData?.service?.economic?.authorizationDate) {
        setAuthorizationDate(new Date(patientData?.service?.economic?.authorizationDate))
      }
    }
  }, [patientData])

  return (
    <section data-testid="FormTypeD">
      <div className="container px-1 py-5 ">
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full ">

            <div className={`grid sm:grid-cols-3 gap-2`} >

              <div className="col">
                <Controller
                  control={control}
                  name='responsibleType'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Tipo de responsable"
                      name="responsibleType"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        responsibleType?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  control={control}
                  name='insuranceProvider'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Aseguradora"
                      name="insuranceProvider"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        insuranceCarrier?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>

              <div className="col">
                <Controller
                  control={control}
                  name='plan'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label=" Plan"
                      name="plan"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        plan?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>

              {TEMP_COUNTRY === 'PE' &&
                <div className="col ">
                  <Controller
                    control={control}
                    name='benefits'
                    render={({ field: { onChange, value, name, ref } }) => (
                      <InputSelect
                        label=" Beneficios"
                        name="benefits"
                        required={false}
                        onSelect={val => {
                          onChange(val);
                        }}
                        value={value}
                        ref={ref}
                      >
                        {
                          economicBenefits?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                        }
                      </InputSelect>
                    )}
                  />
                </div>
              }
              {TEMP_COUNTRY === 'PE' &&
                <div className="col ">

                  <Input
                    type="number"
                    typeValidation='number'
                    required
                    label="Número de póliza"
                    value={watch('policyNumber')}
                    {...register('policyNumber')}
                  />
                </div>
              }
              {TEMP_COUNTRY === 'PE' &&
                <div className="col ">

                  <Controller
                    control={control}
                    name='product'
                    render={({ field: { onChange, value, name, ref } }) => (
                      <InputSelect
                        label=" Producto"
                        name="product"
                        required
                        onSelect={val => {
                          onChange(val);
                        }}
                        value={value}
                        ref={ref}
                      >
                        {
                          economicProduct?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                        }                      </InputSelect>
                    )}
                  />
                </div>


              }

              <div className="col ">

                <Input
                  type="text"
                  typeValidation='number'
                  required
                  label="Número de autorización"
                  value={watch('authorizationNumber')}
                  {...register('authorizationNumber')}
                />
              </div>
              <div className="col ">
                <InputDatePicker
                  label="Fecha de autorización"
                  selected={authorizationDate}
                  onChange={handleChangeDate}
                  locale="es"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="col">
                <Input
                  type="text"
                  typeValidation='text'
                  label={'Nombre de la persona autorizada'}
                  required
                  value={watch('authorizedPerson')}
                  {...register('authorizedPerson')}
                />
              </div>
              <div className="col">
                <Input
                  type="text"
                  typeValidation='number'
                  label={'Segundo código de autorización'}
                  maxLength={20}
                  required
                  value={watch('secondAuthorizationCode')}
                  {...register('secondAuthorizationCode')}
                />
              </div>

              <div className="col ">
                <InputFile
                  label="Carta de garantía"
                  required
                  value={cartaGarantiaFile?.name}
                  handleChange={photoUpload}
                  accept=".pdf"
                />
              </div>

              <div className="col">
                <Input
                  type="text"
                  typeValidation="number"
                  min={0}
                  max={100}
                  maxLength={3}
                  label={'Tope de cobertura (%) '}
                  required
                  value={watch('coverageMaximum')}
                  {...register('coverageMaximum')}
                />
              </div>
                {TEMP_COUNTRY === 'PE' ? (
                  <div className="col">
                    <Input
                      type="number"
                      typeValidation='number'
                      min={0}
                      max={999999}
                      label={`Copago ${TEMP_COUNTRY === 'PE' ? '(S/)' : '(%)'}`}
                      required
                      value={watch('copayment')}
                      {...register('copayment')}
                    />
                  </div>
                ) : (
                  <div className="col  ">
                    <Controller
                      control={control}
                      name='copayment'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputSelect
                          label='Copago'
                          name={"copayment"}
                          required
                          onSelect={val => {
                            onChange(val);
                          }}
                          value={value}
                          ref={ref}
                        >
                          <option value={1} >Si </option>
                          <option value={0} >No </option>
                        </InputSelect>
                      )}
                    />
                  </div>
                )
              }
              {(watch('copayment') > 0 || TEMP_COUNTRY === 'PE') &&
                <div className="col  ">
                  <Input
                    type="text"
                    typeValidation=''
                    label={'Número de comprobante'}
                    required
                    maxLength={12}
                    value={watch('voucherNumber')}
                    {...register('voucherNumber')}
                  />
                </div>
              }

              <div className="col  ">
                <Controller
                  control={control}
                  name='authorizedServiceCode'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label='Código de servicio autorizado'
                      name="authorizedServiceCode"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      <option value="noAplica">No aplica </option>

                    </InputSelect>
                  )}
                />
              </div>

            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button type="button" variant='secondary' size="extra-large"  onClick={() => props.setStep(0)} >Cancelar</Button>
              <Button type="submit" variant='primary' size="extra-large"  disabled={!getValeidate()} >Guardar</Button>
            </div>

          </form>
        </div>
      </div>
    </section>
  )
}

export default FormCheap

