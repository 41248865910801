import React from "react";

interface AvatarProps {
  name?: string;
  role?: string;
  image?: string;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  name,
  role,
  image,
  className
}) => {
  return (
    <div className={`flex items-center justify-start text-left ${className}`}>
      {
        image
          ? (<img src={image} alt={name} className="rounded-full w-10 h-10" />)
          : (<div className='rounded-full bg-[#DFF5FF] text-[#008EA3] w-10 h-10 flex justify-center items-center font-semibold'> {name?.charAt(0)}  </div>)
      }

      {name && (
        <p className='mx-2 leading-none'>
          <span className='text-body2 pb-0 tex-grey-900'>{name}</span>
          {role &&
            <React.Fragment>
              <br className='h-0' />
              <span className='text-caption font-light text-grey-600'>{role}</span>
            </React.Fragment>
          }
        </p>
      )}
    </div>
  )
}

export default Avatar;