import React, { useEffect, useState } from 'react'
import { BehaviorSubject, debounceTime } from 'rxjs'

import useUser from 'features/shared/hooks/useUser'

import Input from 'components/input'
import Chip from 'components/Chip'
import Pagination from 'components/Pagination'
import Breadcrumbs from 'components/Breadcrumbs'

import {
  useUpdateDeviceStatus,
  DevicesReportedModel,
  useGetDevicesReported,
} from '../hooks/useDevice'
import Loader from '../../../components/Loader'
import ReportDevice from './ReportDevice'

import IconSearch from 'assets/icons/icon_search.svg'
import { onAlert } from 'components/Alert'

const pageSize = 10
const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

const ListDevices: React.FC = () => {
  const [showReportDevice, setShowReportDevice] = useState<boolean>(false)
  const [deviceSelected, setDeviceSelected] = useState<DevicesReportedModel>()
  const { userCurrent } = useUser()

  const [searchValue, setSearchValue] = useState('')
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [deviceData, setDeviceData] = useState({
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1,
  })
  const listDevicesReported: DevicesReportedModel[] = deviceData?.data
  const {
    isLoading: LoadingUpdateDeviceStatus,
    mutateAsync: updateDeviceStatus,
  } = useUpdateDeviceStatus()
  const {
    isLoading: LoadingGetDevicesReported,
    mutateAsync: getDevicesReported,
  } = useGetDevicesReported()


  useEffect(() => {
    onGetDevicesReported('', currentPage)

    const searchSubscription = inputChange$
      .pipe(debounceTime(500))
      .subscribe((value) => setSearchValue(value))

    return () => {
      return searchSubscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (currentPage) {
      onGetDevicesReported(searchValue, currentPage)
    }
  }, [currentPage])

  useEffect(() => {
    onGetDevicesReported(searchValue, 1)
  }, [searchValue])

  const onGetDevicesReported = (searchValue?: string, currentPage?: number) => {
    if (userCurrent?.ipress?.countryId) {
      const searchText = searchValue !== '' ? `&searchText=${searchValue}` : ''
      const query = `?country=${userCurrent?.ipress?.countryId}&page=${currentPage}${searchText}`

      getDevicesReported(query).then((res) => {
        if (res.data) {
          setDeviceData(res.data)
        } else {
          onAlert.error(res)
        }
      })
    }
  }

  const selectItem = (item: DevicesReportedModel) => {
    setDeviceSelected(item)
    setShowReportDevice(true)
  }

  const submitDeviceStatus = () => {
    updateDeviceStatus(deviceSelected?.planDeviceId).then((res) => {
      if (res.data) {
        onAlert.success('Registro Exitoso')
        onGetDevicesReported(searchValue, currentPage)
      } else {
        onAlert.error(res)
      }
    })
  }

  return (
    <React.Fragment>
      {LoadingUpdateDeviceStatus || (LoadingGetDevicesReported && <Loader />)}
      <Breadcrumbs
        name={'Dispositivos reportados'}
        currentPathName={'Dispositivos reportados'}
      />  

      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        <div className="px-4 pb-[24px] flex items-center justify-between">
          <p className="text-body1 mr-4 text-text-secondary grow">
            {' '}
            <span className="font-medium text-text-primary">
              Dispositivos reportados
            </span>{' '}
            | {listDevicesReported?.length}
          </p>

          <div className="grow flex justify-end">
            <Input
              id="input_search_patient"
              label="Buscar paciente"
              value={searchText}
              icon={IconSearch}
              onChange={(e: any) => {
                inputChange.next(e.target.value)
                setSearchText(e.target.value)
              }}
            />
          </div>
        </div>

        {showReportDevice && (
          <ReportDevice
            deviceSelected={deviceSelected}
            onCloseModal={() => {
              setShowReportDevice(false)
            }}
            onSubmitForm={() => {
              submitDeviceStatus()
            }}
          />
        )}

        {!LoadingGetDevicesReported && (
          <React.Fragment>
            <section data-testid="Table" className="w-full overflow-auto scrollbar" id='table_device_report'>
              <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className="even:border-t-[1px] even:border-t-grey-200">
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Dispositivo
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Serie
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Médico
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Paciente
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Estado
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {listDevicesReported?.length > 0 ? (
                    listDevicesReported?.map(
                      (item: DevicesReportedModel, index: any) => {
                        return (
                          <tr
                            data-reviewed={item.reviewed}
                            key={index}
                            className="h-[54px] hover:bg-grey-50 cursor-pointer"
                          >
                            <td
                              className="px-4 py-2 whitespace-nowrap"
                              onClick={() => selectItem(item)}
                            >
                              {item.deviceName}
                            </td>
                            <td
                              className="px-4 py-2 "
                              onClick={() => selectItem(item)}
                            >
                              {item.serial}
                            </td>
                            <td
                              className="px-4 py-2 whitespace-nowrap"
                              onClick={() => selectItem(item)}
                            >
                              <div className="text-body2 flex items-center gap-2">
                                {false ? (
                                  <img
                                    src={''}
                                    alt={''}
                                    className="rounded-full w-10 h-10"
                                  />
                                ) : (
                                  <div className="rounded-full bg-[#DFF5FF] text-[#008EA3] min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 flex justify-center items-center text-caption">
                                    {/* {item.specialistName?.charAt(0)} */}
                                    {'J'.charAt(0)}
                                  </div>
                                )}
                                {/* {item.specialistName} */}
                                Juan Gonzales
                              </div>
                            </td>
                            <td
                              className="px-4 py-2 whitespace-nowrap"
                              onClick={() => selectItem(item)}
                            >
                              <div className="text-body2 flex items-center gap-2">
                                {false ? (
                                  <img
                                    src={''}
                                    alt={''}
                                    className="rounded-full w-10 h-10"
                                  />
                                ) : (
                                  <div className="rounded-full bg-[#DFF5FF] text-[#008EA3] min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 flex justify-center items-center text-caption">
                                    {item.patientName?.charAt(0)}
                                  </div>
                                )}
                                {item.patientName}
                              </div>
                            </td>
                            <td
                              className="px-4 py-2 flex"
                              onClick={() => selectItem(item)}
                            >
                              {item.reviewed === 0 ? (
                                <Chip
                                  label="Reportado"
                                  onClose={() => {}}
                                  isRemovable={false}
                                  className="bg-[#FFEFED] text-[#E21010] border-none"
                                />
                              ) : (
                                <Chip
                                  label="Revisado"
                                  onClose={() => {}}
                                  isRemovable={false}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      }
                    )
                  ) : (
                    <tr className="h-[54px]">
                      <td colSpan={5} className="px-4 text-center">
                        No se encontraron resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>

            <div className="flex justify-end mt-[24px] px-4">
              <Pagination
                currentPage={deviceData?.currentPage}
                totalCount={deviceData?.total}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListDevices

