import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { CountryContext } from 'layout'
import { routes } from 'routes/routing'
import ListUser from 'features/UserManagement/components/ListUser'
import { useGetUser, useGetUserMutate } from 'features/UserManagement/hooks/useUser'
import useUser from 'features/shared/hooks/useUser'

import Breadcrumbs from 'components/Breadcrumbs'
import { ROLE } from 'global/constants/roles'

export const RoleContext = React.createContext([])
export const SpecialityContext = React.createContext([])
const useCountry = () => useContext(CountryContext)

const UserManagement: React.FC = () => {
  const history = useHistory()
  const { userCurrent } = useUser()
  const role = userCurrent?.roleSlug
  const country = useCountry()

  const [params, setParams] = useState<any>({
    page: 1,
    searchText: '',
    countryId: '',
  })

  const [specialities, setSpecialities] = useState([])
  const [users, setusers] = useState([])

  const format = (obj: any) => {
    let st = ''
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        st = st + '&' + key + '=' + obj[key]
      }
    })
    return st.substring(1)
  }

  const { mutateAsync: getUserList, isLoading: isLoadingUsers } = useGetUserMutate();

  const onGetListUsers = async () => {
    const formatParants = format(params);
    const response = await getUserList({
      query:formatParants,
      role
    });
    if (response.data) {
      setusers(response?.data);
    }

  }
  const handleChangePage = (page: number) => {
    setParams({ ...params, page })
  }

  const handleChangeFilters = (filters: any) => {
    if (filters && Object.keys(filters).length > 0) {
      setParams({ ...params, ...filters, page: 1 })
    }
  }

  const handleGetSpecialities = (listSpecialities: any) => {
    setSpecialities(listSpecialities)
  }

  const handleClickAddUser = () => {
    history.push(routes.userManagementNewUser.url)
  }

  const setParamsByCountry = () => {
    let configParams = { ...params }
    if (country === 'Todos') {
      delete configParams.countryId
    } else {
      configParams.countryId = country
    }
    setParams({ ...configParams, page: 1 })
  }

  useEffect(() => {
    setParamsByCountry()
  }, [country])
  useEffect(() => {
    onGetListUsers()
  }, [])
  useEffect(() => {
    if (params) {
      onGetListUsers()
    }
  }, [params])
  return (
    <SpecialityContext.Provider value={specialities}>
      <Breadcrumbs
        name="Usuarios"
        currentPathName={'Lista de usuarios'}
      />
      <>
        {
          <ListUser
            users={
              users || {
                currentPage: 1,
                data: [],
                total: 1,
                totalPages: 1,
              }
            }
            onGetListUsers={onGetListUsers}
            handleChangePage={handleChangePage}
            handleChangeFilters={handleChangeFilters}
            handleGetSpecialities={handleGetSpecialities}
            handleClickAdd={handleClickAddUser}
            isLoading={isLoadingUsers}
            isRole={role == ROLE.SERVICE_ADMIN}
            isSpecialty={role == ROLE.SERVICE_ADMIN}
          />
        }
      </>
    </SpecialityContext.Provider>
  )
}

export default UserManagement

