import fetcher from 'features/shared/utils/fetcher'

export async function getAvailability(specialistsId?: number) {
  return await fetcher.get(`/backoffice/specialists/${specialistsId}/availability`)
}


export async function createAvailability(data: any) {
  return await fetcher.post(`/backoffice/specialists/${data.specialistsId}/availability`, data.body)
}


export async function updateteAvailability(data: any) {
  return await fetcher.put(`/backoffice/specialists/${data.specialistsId}/availability`, data.body)
  
}


export async function deleteAvailability(data: any) {
/*   console.log(data);
 */  
  return await fetcher.post(`/backoffice/specialists/${data.specialistsId}/availability/delete`, data.body)
}

