import React, { useState } from 'react';
import { ClinicNetworkModel } from 'features/UserManagement/types/clinicNetwork';
import IconEdit from 'assets/icons/icon_edit.svg';
import Modal from 'components/modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useUpdateClinicNetwork } from 'features/UserManagement/hooks/useClinicNetwork';

interface TableProps {
  data?: ClinicNetworkModel[];
  className?: string;
  //handleClickListClinic?: (e: any) => void;
  handleActionEdit?: (e: any) => void;
  handleState?: (item: any) => void;
}

const TableClinicNetworks: React.FC<TableProps> = ({
  data,
  className,
  //handleClickListClinic,
  handleActionEdit,
  handleState
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isClinicNetworkActivate, setIsClinicNetworkActivate] = useState(false);

  const { mutateAsync: asyncUpdateClinicNetwork, isLoading: isLoadingUpdateNetwork } = useUpdateClinicNetwork()

  const handleClickEdit = (e: any, item: ClinicNetworkModel) => {
    e.stopPropagation();
    /* console.log('edit', item); */
    handleActionEdit && handleActionEdit(item)
  }

  const handleClickState = async (e: any, item: any) => {
    e.stopPropagation()
    handleState && handleState(item);
  }

  const handleConfirm = () => {
    setShowConfirm(false);
  }

  /*const handleClickItem = (e: any, item: ClinicNetworkModel) => {
    e.stopPropagation();
    history.push(
      routes.clinics.url.replace(':clinicNetworkId', String(item.id)),
      {
        clinicDetail: item
      }
    );
  }*/

  return (
    <>
      <section data-testid="Table" className={`w-full ${className}`}>
      <div className="w-full overflow-auto	 scrollbar">
        <table className="min-w-full divide-y divide-gray-200 border-collapse">
          <thead className="bg-bg7">
            <tr className='even:border-t-[1px] even:border-t-grey-200'>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Red de clínica
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                País
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Estado
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Acciones
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              data && data?.length > 0 ? (
                data?.map((item: ClinicNetworkModel, index: number) => (
                  <tr
                    //onClick={(e) => handleClickItem(e, item)}
                    key={index}
                    className="h-[54px] hover:bg-grey-50">
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-body2">
                        {item.name}
                      </div>
                    </td>
                    <td className="px-4 py-2 ">
                      <div
                        className="text-body2"
                      >
                        {item.countryName}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div
                        className="text-body2"
                      >
                      <label htmlFor={`checked-toggle${index}`} className="inline-flex relative items-center">
                        <div
                          onClick={(e) => handleClickState(e, item)}
                          className={`cursor-pointer relative w-11 h-6 bg-gray-200 rounded-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 ${item.status == 1 && 'bg-[#48B16E] after:translate-x-full'}`}></div>
                      </label>
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-body2" >
                        <a className='cursor-pointer'>
                          <img
                            onClick={(e) => handleClickEdit(e, item)}
                            src={IconEdit}
                            alt="Editar"
                            className='h-[20px] w-[20px]' />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))
              ) : <tr className='h-[54px]'><td colSpan={5} className="px-4 text-center" >No se encontraron resultados</td></tr>
            }
          </tbody>
        </table>
       </div>
      </section>
      <Modal
        showModal={showConfirm}
        setShowModal={handleConfirm}
        text={isClinicNetworkActivate ? 'Rol Activado' : 'Rol Desactivado'}
        btn={t('end')} />
    </>
  )
}

export default TableClinicNetworks;
