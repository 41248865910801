import fetcher from 'features/shared/utils/fetcher'

async function putRegisterEconomic(value: any ) {
  let form_data = new FormData();
  for (let key in value?.data) {
    form_data.append(key, value?.data[key]);
  }
  return await fetcher.put(
    `/patient/${value?.patientId}/economic-data/${value?.serviceId}/edit`,
    form_data
  )
}

export default putRegisterEconomic
