import React, { forwardRef, useEffect, useState } from "react"
import ArrowDownIcon from 'assets/icons/icon_chevron_down.svg';

interface InputSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  required?: boolean;
  label?: string;
  errorText?: string;
  helperText?: string;
  value?: any;
  onSelect?: ((val:any) => any);
  classNameWrapper?: string
  inputRef?: any
  hidePlaceholder?: boolean
}

const InputSelect = forwardRef<HTMLSelectElement, InputSelectProps>(({
  className,
  classNameWrapper,
  required,
  label,
  errorText,
  helperText,
  value,
  onSelect,
  inputRef,
  ...props
}, ref) => {
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (value) {
      onHasValue(value);
    }
  }, [value])

  const onHasValue = (val: any) => {
    setHasValue(val.toString().length > 0);
  }

  const onChangeSelect = (val: any) => {
/*     console.log('on slect input select ____', val.target.value);
 */    if (val == '') {
      onHasValue("");
      onSelect && onSelect(null);
    } else {
      onHasValue(val.target.value);
      onSelect && onSelect(val.target.value);
    }
  }

  return (
    <div className={classNameWrapper}>
      <div
        className='
          w-full max-w-full h-[56px] text-body1 relative cursor-pointer 
          flex items-center justify-start
        '
      >
        <select
          className={`
            peer appearance-none 
            ${hasValue ? 'text-init' : 'text-transparent'} 
            px-[16px] w-full h-full rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none 
            hover:pl-[calc(16px-1px)] hover:pr-[calc(32px-1px)] hover:border-solid hover:border-[1px] hover:border-grey-400 
            focus:w-full focus:pl-[calc(16px-1px)] focus:pr-[calc(32px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73]  focus:text-grey-600 pr-[32px] 
            ${isValid ? '' : 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'} 
            ${className}
          `}
          onChange={onChangeSelect}
          value={value}
          {...props}
          ref={inputRef}
        >
          {!props.hidePlaceholder && <option value="" >Seleccionar...</option>}

          
          {props.children}
        </select>

        <label
          className={`
            px-[16px] absolute text-gray-600 pointer-events-none 
            transition duration-150 ease-out 
            ${hasValue ? 'mb-6 text-caption text-neutral-600 ease-in' : ''}
            peer-focus:text-caption peer-focus:mb-6 
            ${isValid ? '' : 'text-error-main'}
          `}
        >
          {label} 
          {required ? (<span className="font-semibold">*</span>) : ''}
        </label>

        <img src={ArrowDownIcon} className="w-[24px] h-[24px] absolute right-[8px] pointer-events-none"/>
      </div>
      {
        isValid ? (
          <p className={`
            px-[16px] text-caption p-0 pt-1 text-gray-600 
            ${helperText ? '' : 'hidden'}
          `}>
            {helperText}
          </p>
        ) : (
          <p className={`
            px-[16px] text-caption p-0 pt-1 text-error-main 
            ${hasValue && errorText ? '' : 'hidden'}
          `}>
            {hasValue ? 'Este campo es obligatorio' : errorText }
          </p>
        )
      }
    </div>
  )
})

export default InputSelect;
