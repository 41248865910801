import { useMutation } from 'react-query'
import { getAvailability, createAvailability ,updateteAvailability , deleteAvailability} from '../services/userCalendar'

export function useGetAvailability() {
  return useMutation((data: any) => getAvailability(data))
}

export function useCreateAvailability() {
  return useMutation((data: any) => createAvailability(data));
}

export function useUpdateteAvailability() {
  return useMutation((data: any) => updateteAvailability(data))
}

export function useDeleteAvailability() {
  return useMutation((data: any) => deleteAvailability(data));
}

