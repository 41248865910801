import constants from 'configs/constants'
import { QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: constants.IS_PRODUCTION,
      staleTime:  1000 * 60 * 1, // 1 minute
    },
  },
})

export default queryClient
