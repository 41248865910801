import fetcher from 'features/shared/utils/fetcher';
import { useMutation, useQuery } from 'react-query';

export interface PatientServiceHistoryModel {
  epicrisisId: number;
  status: string;
  startDate: string;
  endDate: string;
  eventNUmber: string;
}

export function useGetPatientServiceHistory() {
  return useMutation((patientId: any) => getPatientServiceHistory(patientId));
}

async function getPatientServiceHistory(patientId: any) {
  return await fetcher.get(`/patients/${patientId}/attentions`);
}

export function useDownloadFile() {
  return useMutation((data: any) => downloadFile(data));
}

async function downloadFile(data: any) {
  return await fetcher.get(`/epicrisis/${data.epicrisisId}/download-file?fileType=${data.fileType}`);
}
