import fetcher from 'features/shared/utils/fetcher'
import { useMutation } from 'react-query'

export interface SuppliesOptionsModel {
  id: number;
  code: string;
  type: number;
  ipressId: number;
  name: string;
  via: string;
  measurement: string;
  minimumStock: number;
  stock: number;
}

export interface SuppliesServiceModel {
  type?: number;
  planId?: number;
  supplyId?: number;
  quantity?: number;
  schedule?: string;
  via?: string;
  medicationStartTime?: string;
  current_therapeutics?: number;
  supplyType?: number;
  supplyName?: string;
  createdAt?: string;
  daysOfTreatment: any;
  days_of_treatment?: any;
  tempId?: string;
}

export interface RegisterSupplyModel {
  supplyId?: number;
  quantity?: number;
  schedule?: string;
  medicationStartTime?: string;
  current_therapeutics?: boolean;
}

export function useGetSuppliesOptions()  {
  return useMutation((serviceId: number) => getSuppliesOptions(serviceId));
}

async function getSuppliesOptions(serviceId?: number) {
  return await fetcher.get(`/supplies`);
}

export function useGetSuppliesOptionsSearch()  {
  return useMutation((data: any) => getSuppliesOptionsSearch(data.serviceId, data.searchText));
}

async function getSuppliesOptionsSearch(serviceId?: number, searchText?: string) {
  return await fetcher.get(`/supplies?searchText=${searchText}`);
}

export function useGetSuppliesService()  {
  return useMutation((serviceId: number) => getSuppliesService(serviceId));
}

async function getSuppliesService(serviceId: number) {
  return await fetcher.get(`/service/${serviceId}/plan/supplies`);
}

export function useRegisterSuppliesService()  {
  return useMutation((body: any) => postSuppliesService(body));
}

async function postSuppliesService(body: any) {
  
  return await fetcher.post(`/service/${body.serviceId}/plan/supply`, body.data);
}

export function useUpdateSuppliesService()  {
  return useMutation((body: any) => putSuppliesService(body));
}

async function putSuppliesService(body: any) {
  return await fetcher.put(`/service/${body.serviceId}/plan/supply/${body.supplyId}/edit`, body.data);
}

export function useDeleteSuppliesService()  {
  return useMutation((body: any) => postDeleteSuppliesService(body));
}

async function postDeleteSuppliesService(body: any) {
  return await fetcher.delete(`/service/${body.serviceId}/plan/supply/${body.supplyId}/delete`);
}
export function useNotificationSuppliesService()  {
  return useMutation((body: any) => notificationSuppliesService(body));
}

async function notificationSuppliesService(body: any) {
  
  return await fetcher.post(`/notification/specialist/created-prescription`,body);
}

