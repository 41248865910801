import { useEffect, useState } from 'react';
import ArrowDownIcon from 'assets/icons/icon_chevron_down.svg';
import Chip from 'components/Chip';

interface OptionModel {
  value: any;
  label: string;
  isRemovable: boolean;
}

interface InputSelectMultipleModel {
  label?: string;
  helperText?: string;
  errorText?: string;
  required?: boolean;
  options?: OptionModel[];
  value?: OptionModel[];
  className?: string;
  onSelect: (value: any) => void;
  onRemove: (value: any) => void;
}

const InputSelectMultiple: React.FC<InputSelectMultipleModel> = ({
  label,
  helperText,
  errorText,
  required,
  options = [],
  value = [],
  className,
  onSelect,
  onRemove,
  ...props
}) => {
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [selectedValues, setSelectedValues] = useState<OptionModel[]>([]);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isHoverOptions, setIsHoverOptionns] = useState<boolean>(false);
  const [isHoverChip, setIsHoverChip] = useState<boolean>(false);
  const [optionValues, setOptionValues] = useState<OptionModel[]>([]);

  useEffect(() => {
    if (value && options) {
      onHasValue(value);
      onReceiveValues(value);
    }
  }, [options, value]);

  const onReceiveValues = (values: OptionModel[]) => {
    const findOptions = values.length > 0 ? options.filter((op: OptionModel) => !values.find((val: OptionModel) => op.value == val.value)) : options;
    if (values.length > 0) {
      const selects = [...options].map((op) => {
        const findOption = values.find((val) => op.value == val.value);

        return {
          value: op.value,
          label: op.label,
          isRemovable: findOption ? findOption.isRemovable : true
        }
      }).filter((op: OptionModel) => !!values.find((val: OptionModel) => op.value == val.value));

      setSelectedValues(selects);
    } else {
      setSelectedValues([]);
    }

    setOptionValues(findOptions);
  }

  const onHasValue = (val: OptionModel[]) => {
    setHasValue(val.length > 0);
  }

  const onSelecValue = (item: OptionModel) => {
    const newOptions = optionValues.filter((e: OptionModel) => e.value !== item.value);
    setOptionValues(newOptions);

    const selected: OptionModel[] = [...selectedValues, item];
    setSelectedValues(selected);
    onSelect(item);
    onHasValue(selected);
  }

  const onRemoveItem = (item: OptionModel) => {
    const newOptions: OptionModel[] = [item, ...optionValues].sort((a: OptionModel, b: OptionModel) => a.value - b.value);
    const selected: OptionModel[] = selectedValues.filter((e: OptionModel) => e.value !== item.value);

    setOptionValues(newOptions);
    setSelectedValues(selected);
    onRemove(item);
    onHasValue(selected);
    setIsHoverChip(false);
  }

  return (
    <div
      {...props}
      className='relative'
      tabIndex={50}
      onBlur={() => { if (!isHoverOptions) setIsFocus(false); }}
    >
      <div
        className={`
          min-w-[243px] min-h-[56px] max-w-full text-body1 relative 
          peer flex justify-between items-center 
          px-4 py-1 w-full rounded-[16px] bg-grey-50 text-grey-600 outline-none border-solid border-[1px] border-grey-50 
          hover:border-grey-400 
          ${isFocus ? '!border-[#003C73]' : ''} 
          ${isValid ? '' : '!border-error-main'} 
        `}

        onClick={() => { if (!isHoverChip) setIsFocus(!isFocus); }}
      >
        <div className='grow'>
          <label
            className={`
            text-gray-600 pointer-events-none 
              ${hasValue ? 'text-caption text-neutral-600' : ''} 
              peer-focus:text-caption peer-focus:mb-2 
              ${isValid ? '' : 'text-error-main'}
            `}
          >{label}
            {required ? (<span className="font-semibold">*</span>) : ''}
          </label>

          <div data-testid="optionMultipleSelecteds" className='flex gap-2 flex-wrap'>
            {selectedValues?.map((item: OptionModel, index: number) =>
              <div
                onMouseEnter={() => setIsHoverChip(true)}
                onMouseLeave={() => setIsHoverChip(false)}
                key={index}
              >
                <Chip
                  label={item.label}
                  onClose={() => { onRemoveItem(item) }}
                  isRemovable={item.isRemovable}
                />
              </div>
            )}
          </div>
        </div>

        <img src={ArrowDownIcon} className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 ml-2 pointer-events-none" />
      </div>

      <div
        className={`
          absolute z-40 top-[calc(100%+8px)] left-[0px] w-full 
          ${isFocus ? "visible" : "invisible"}
        `}
        onMouseEnter={() => setIsHoverOptionns(true)}
        onMouseLeave={() => setIsHoverOptionns(false)}
      >
        <ul
          data-testid="optionSelectMultiple"
          className='shadow-lg min-h-120px max-h-[200px] w-full overflow-y-scroll bg-white border-[1px] border-solid border-grey-400 rounded-[4px]'
        >
          {
            optionValues.length > 0 && optionValues?.map((item: OptionModel, index) => (
              <li
                key={item.value + index}
                className={`
                  cursor-pointer text-body2 overflow-x-hidde px-2 py-4 hover:bg-grey-200 w-full
                `}
                onClick={() => onSelecValue(item)}
              >
                {item.label}
              </li>
            ))
          }
        </ul>
      </div>
      {
        isValid ? (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-gray-600 
          '>
            {helperText}
          </p>
        ) : (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-error-main 
          '>
            {hasValue && required ? 'Este campo es obligatorio' : errorText}
          </p>
        )
      }
    </div>
  )
}



export default InputSelectMultiple;