import { FC, ReactNode } from 'react'

import IconClose from 'assets/icons/icon_close.svg'

interface SimpleClosingModalProps {
  onClose: () => void
  title: ReactNode
  withDivider?: boolean
  maxWidth?: string
}

export const SimpleClosingModal: FC<SimpleClosingModalProps> = ({
  onClose,
  children,
  title,
  withDivider = true,
  maxWidth = '618px',
}) => {

  const maxWidthStyle = `max-w-[${maxWidth}]`

  const handleCloseIconClick = () => {
    onClose()
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className={`relative py-8 px-6 w-full ${maxWidthStyle}`}>
          <div className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4">
            <div
              className={`flex justify-between items-center ${
                withDivider
                  ? 'border-b-[1px] border-[#14141426] pb-[24px]  mb-4'
                  : ''
              }`}
            >
              {title}
              <button
                onClick={handleCloseIconClick}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6" />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

