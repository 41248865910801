import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ShadowWrapper } from 'components/ShadowWrapper'
import Breadcrumbs from 'components/Breadcrumbs';
import FormUserCalendar from 'features/UserManagement/components/FormUserCalendar/form'

const UserManagementCalendar
: React.FC = () => {
  const history = useHistory();
  const location = useLocation()
  const state = location?.state as any
  const userStorage = state?.userStorage
  return(
    <>
      <Breadcrumbs
        name={`Disponibilidad`}
        prevPathName={`${userStorage?.name}  ${userStorage?.lastname}`}
        currentPathName={`Disponibilidad`}
        onClickPrev={() => {}}
        onClickHome={() => {history.push(`/user-management`)}}
        className='mb-6'
      />
      <ShadowWrapper className='px-4 py-[24px]'>
        <FormUserCalendar />
      </ShadowWrapper>
    </>
)}

export default UserManagementCalendar
