// @ts-nocheck
import { Route, RouteProps } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '../PrivateRoute/index.scss'
import Layout from '../../layout'

const Wrapper: React.FC = ({ children }) => <>{children}</>
const AuthWrapper =  withAuthenticator(Wrapper)

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
   
    <Route {...rest}>
      <AuthWrapper>
        <Layout loader={false}> {children} </Layout>
      </AuthWrapper>
    </Route>
   

  )
}

export default PrivateRoute
