import { useEffect, useState } from 'react'
import { format as formatDate, isValid } from 'date-fns'

import { useQueryParams } from 'features/shared/hooks/use-query-params'

import { NotificationType } from '../services'

export const useNotificationsFilters = () => {
  const { query, setQuery } = useQueryParams()
  const [from, setFrom] = useState(query.from || '')
  const [to, setTo] = useState(query.to || '')
  const [datesChanged, setDatesChanged] = useState<string[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({})

  const handleSelectType = (value: NotificationType) => {
    setQuery({ type: value })
  }

  const handleChangeDate = (queryParamName: string) => (value: Date | null) => {
    if (value && isValid(value)) {
      const formattedDate = formatDate(value, 'yyyy-MM-dd')
      if (queryParamName === 'from') {
        setFrom(formattedDate)
        setDatesChanged((prevState) => {
          if (!prevState.includes('from')) {
            return [...prevState, 'from']
          }
          return prevState
        })
        if (!to && !errors.includes('to')) {
          setErrors((prevState) => {
            const fromHasError = prevState.includes('from')
            if (fromHasError) {
              prevState.splice(prevState.indexOf('from'), 1)
            }
            const toHasError = prevState.includes('to')
            if (!toHasError) {
              prevState.push('to')
            }
            return [...prevState]
          })
          setErrorMessages((prevState) => ({
            ...prevState,
            to: 'Hasta es requerido',
          }))
        } else {
          setErrors((prevState) => {
            const fromHasError = prevState.includes('from')
            if (fromHasError) {
              prevState.splice(prevState.indexOf('from'), 1)
            }
            return [...prevState]
          })
        }
      }
      if (queryParamName === 'to') {
        if (!from && !errors.includes('from')) {
          setErrors((prevState) => {
            const toHasError = prevState.includes('to')
            if (toHasError) {
              prevState.splice(prevState.indexOf('to'), 1)
            }
            const fromHasError = prevState.includes('from')
            if (!fromHasError) {
              prevState.push('from')
            }
            return [...prevState]
          })
          setErrorMessages((prevState) => ({
            ...prevState,
            from: 'Desde es requerido',
          }))
        }
        if (from && value < new Date(from)) {
          setTo('')
          setErrors((prevState) => {
            const toHasError = prevState.includes('to')
            if (!toHasError) {
              prevState.push('to')
            }
            return prevState
          })
          setErrorMessages((prevState) => ({
            ...prevState,
            to: 'Hasta debe ser mayor o igual a Desde',
          }))
        } else {
          setTo(formattedDate)
          setErrors((prevState) => {
            const toHasError = prevState.includes('to')
            if (toHasError) {
              prevState.splice(prevState.indexOf('to'), 1)
            }
            return prevState
          })
          setDatesChanged((prevState) => {
            if (!prevState.includes('to')) {
              return [...prevState, 'to']
            }
            return prevState
          })
        }
      }
    }
    if (value === null) {
      if (queryParamName === 'from') {
        setFrom('')
        setDatesChanged((prevState) => {
          if (!prevState.includes('from')) {
            return [...prevState, 'from']
          }
          return prevState
        })
        if (to && !errors.includes('from')) {
          setErrors((prevState) => {
            prevState.push('from')
            return [...prevState]
          })
          setErrorMessages((prevState) => ({
            ...prevState,
            from: 'Desde es requerido',
          }))
        }
        if (!to && errors.includes('to')) {
          setErrors((prevState) => {
            prevState.splice(prevState.indexOf('to'), 1)
            return [...prevState]
          })
          setErrorMessages((prevState) => {
            delete prevState.to
            return { ...prevState }
          })
        }
      }
      if (queryParamName === 'to') {
        setTo('')
        setDatesChanged((prevState) => {
          if (!prevState.includes('to')) {
            return [...prevState, 'to']
          }
          return prevState
        })
        if (from && !errors.includes('to')) {
          setErrors((prevState) => {
            prevState.push('to')
            return [...prevState]
          })
          setErrorMessages((prevState) => ({
            ...prevState,
            to: 'hasta es requerido',
          }))
        }
        if (!from && errors.includes('from')) {
          setErrors((prevState) => {
            prevState.splice(prevState.indexOf('from'), 1)
            return [...prevState]
          })
          setErrorMessages((prevState) => {
            delete prevState.from
            return { ...prevState }
          })
        }
      }
    }
  }

  const getDateFromQueryParam = (queryParamName: string) => {
    const date = query[queryParamName]
    return date ? new Date(date + 'T00:00:00') : undefined
  }

  const getDateFromString = (date: string) => {
    return date ? new Date(date + 'T00:00:00') : undefined
  }

  useEffect(() => {
    if (
      !errors.length &&
      (datesChanged.includes('from') || datesChanged.includes('to'))
    ) {
      setQuery({ from, to })
    }
  }, [from, to, datesChanged, setQuery, errors.length])

  return {
    query,
    setQuery,
    from,
    to,
    errors,
    errorMessages,
    handleSelectType,
    handleChangeDate,
    getDateFromString,
    getDateFromQueryParam,
  }
}

