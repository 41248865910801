import React from 'react'
import { Controller } from 'react-hook-form'
import SelectTypeSpeciality from 'features/UserManagement/components/SelectTypeSpeciality/input';
import Input from 'components/input'
import LabelForm from 'components/LabelForm'
import IconBriefcase from 'assets/icons/icon_briefcase.svg';
import IconTrash from 'assets/icons/icon_trash.svg';
import IconUpload from 'assets/icons/icon_upload_multimedia.svg';

interface Props {
  register: any
  watch: any
  control: any
  imgSignature: any
  handleUploadMultimediaSignature: (e: any) => void
  handleClickDeleteSignature: (e: any) => void
}

const FormSpecialitySection: React.FC<Props> = ({
  register,
  watch,
  control,
  imgSignature,
  handleUploadMultimediaSignature,
  handleClickDeleteSignature
}) => {
  return (
    <>
      <LabelForm icon={IconBriefcase} label="Datos profesionales" className='mt-6 mb-4' />
      <div className={`grid sm:grid-cols-3 gap-2`} >
        <div className="col">
          <Controller
            control={control}
            name='specialty'
            render={({ field: { onChange, value, name, ref } }) => (
              <SelectTypeSpeciality
                onChange={val => onChange(val)}
                inputRef={ref}
                value={value}
                isRequired
              />
            )}
          />
        </div>
        <div className="col">
          <Input
            type="text"
            typeValidation='number'
            label='RNE'
            value={watch('rne')}
            {...register('rne')}
          />
        </div>
        <div className="col">
          <Input
            type="text"
            typeValidation='number'
            label='CMP'
            value={watch('cmp')}
            {...register('cmp')}
          />
        </div>
      </div>
      <div className='relative overflow-hidden flex flex-col items-center justify-center border-dashed border-[3px] border-slate-200 max-w-[80%] p-6 mx-auto rounded-lg cursor-pointer mt-1'>
        <input
          className='w-[100%] h-[400px] top-[-35px] cursor-pointer absolute'
          type='file'
          accept="image/jpeg, image/png, image/jpg"
          onChange={handleUploadMultimediaSignature} />
        {
          imgSignature &&
          <img
            onClick={handleClickDeleteSignature}
            className='absolute w-[25px] h-[25px] top-[20px] right-[20px] cursor-pointer'
            src={IconTrash}
            alt='eliminar firma' />
        }
        {
          <img
            src={imgSignature?.img?.src || IconUpload}
            id='previewSignature'
            className={`mb-6 ${imgSignature ? 'w-[246px] h-[113px]' : 'min-w-[40px] min-h-[40px] w-[40px] h-[40px]'}`}
            alt='sube tu firma' />
        }
        <p className='text-base'>{imgSignature ? imgSignature?.img?.name : 'Cargar firma'}</p>
      </div>
    </>
)}

export default FormSpecialitySection