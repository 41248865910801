import { useHistory } from 'react-router-dom'

import IconHome from 'assets/icons/icon_home.svg'
import IconChevronRight from 'assets/icons/icon_chevron_right.svg'

interface BreadcrumbsProps {
  name: string
  currentPathName: string
  prevPathName?: string
  onClickHome?: () => void
  onClickPrev?: () => void
  className?: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  name,
  currentPathName,
  prevPathName,
  onClickHome,
  onClickPrev,
  className = '',
}) => {
  const history = useHistory()

  const handleClickPrevPath = () => {
    if (onClickPrev) {
      onClickPrev()
    }
  }

  const handleClickHome = () => {
    if (onClickHome) {
      onClickHome()
    } else {
      history.push(`/`)
    }
  }

  const filterActive = 'invert(33%) sepia(86%) saturate(1046%) hue-rotate(154deg) brightness(99%) contrast(101%)';

  return (
    <div className={`flex flex-wrap gap-2 items-center ${className}`}>
      <span className="text-headline4 font-[600] text-grey-600">{name}</span>
      <div className="h-8 w-[1px] bg-grey-400 mr-2"></div>
      <img
        src={IconHome}
        alt="home"
        onClick={handleClickHome}
        className="cursor-pointer"
        style={{ filter: filterActive }}
      />
      {prevPathName && (
        <>
          <img src={IconChevronRight} alt={prevPathName} />
          <span
            onClick={handleClickPrevPath}
            className="cursor-pointer text-primary-main text-text-body1"
          >
            {prevPathName}
          </span>
        </>
      )}
      <img src={IconChevronRight} alt={currentPathName} />
      <span className="text-text-body1 text-grey-600">{currentPathName}</span>
    </div>
  )
}

export default Breadcrumbs

