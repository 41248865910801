import { useQuery } from 'react-query'
import getTermsConditions from '../services/getTermsConditions'

function useGetTermsConditions(patientId:number) {
  const queryKey = ['useGetTermsConditions',patientId]

  return useQuery(queryKey, () => getTermsConditions(patientId))
}

export default useGetTermsConditions
