import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { BehaviorSubject, debounceTime } from 'rxjs';
import { useTranslation } from 'react-i18next'

import {
  useGetUserRole,
  useGetUserRoleById,
  useUpdateRoleStatus,
  useGetRolesSearchResults
} from 'features/UserManagement/hooks/useUserRole'

import { ShadowWrapper } from 'components/ShadowWrapper'
import Breadcrumbs from 'components/Breadcrumbs'
import LabelForm from 'components/LabelForm'
import Loader from 'components/Loader'
import Input from 'components/input'
import Button from 'components/Button'
import ModalRole from '../../components/ModalRole'

import IconEdit from 'assets/icons/icon_edit.svg'
import IconInfo from 'assets/icons/icon_list.svg'
import IconAdd from 'assets/icons/icon_add.svg'
import IconSearch from 'assets/icons/icon_search.svg'
import { onAlert } from 'components/Alert';

const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

const UserManagementCreateRol: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [roleData, setRoleData] = useState(null as any)
  const [isShowModal, setIsShowModal] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [listRoles, setListRoles] = useState([])
  const [isActive, setIsActive] = useState(false)


  const { mutateAsync: asyncGetUserRoleById, isLoading: isLoadingRoleData } =
    useGetUserRoleById()
  const { mutateAsync: updateRoleStatus } = useUpdateRoleStatus()
  const { mutateAsync: mutateAsyncListRoles, isLoading: isLoadingGetList } = useGetRolesSearchResults();


  const handleCloseModal = (isOkay?: boolean) => {
    setIsShowModal(false)
    setRoleData(null)
    if (isOkay) {
      onGetListRoles(searchValue)
    }
  }

  const handleClickAdd = () => {
    setIsShowModal(true)
  }

  const onGetUserRoleById = async (roleId: number) => {
    const role = await asyncGetUserRoleById(roleId)
    if (role?.data) {
      setRoleData(role?.data)
      setTimeout(() => {
        setIsShowModal(true)
      }, 500)
    }
  }

  const handleClickOpenModal = (roleId: number) => {
    onGetUserRoleById(roleId)
  }

  const handleClickActivate = async (status: number, id: number) => {
    const data: any = { id }
    data.status = !Boolean(status)
    setIsActive(data.status)
    const response = await updateRoleStatus(data)
    if (response?.data) {
      if (data.status) {
        onAlert.success('Rol activado');
      } else {
        onAlert.info('Rol desactivado');
      }
      onGetListRoles(searchValue)
    } else {
      onAlert.error(response?.data);
    }
  }

  const handleChangeSearch = (e: any) => {
    inputChange.next(e.target.value);
    setSearchText(e.target.value);
  }

  const onGetListRoles = useCallback(async (searchValue?: string) => {
    const searchWord = (searchValue === searchText) ? `?searchText=${searchValue}` : ''
    const response = await mutateAsyncListRoles(searchWord)
    setListRoles(response.data)
  }, [searchText, searchValue])


  useEffect(() => {
    onGetListRoles(searchValue)
    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value) => {
      setSearchValue(value);
    });
    return () => {
      searchSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (searchValue === searchText) {
      onGetListRoles(searchValue);
    }
  }, [searchValue, searchText, onGetListRoles]);

  return (
    <>
      <Breadcrumbs
        name="Crear nuevo rol"
        currentPathName="Crear nuevo rol"
        onClickPrev={() => { }}
        onClickHome={() => {
          history.push(`/user-management`)
        }}
        className="mb-6"
      />
      {isLoadingGetList && <Loader />}
      <ShadowWrapper>
        <div className="flex mb-4 px-4">
          <LabelForm icon={IconInfo} label="Rol" />
          <Button
            type="submit"
            variant="primary"
            onClick={handleClickAdd}
            className="ml-auto flex items-center"
          >
            <img src={IconAdd} alt="agregar" />
            <span className="ml-2">Agregar</span>
          </Button>
        </div>
        <Input
          label="Buscar roles"
          value={searchText}
          icon={IconSearch}
          onChange={handleChangeSearch}
          classNameWrapper='flex-[0_0_100%] max-w-[416px] mb-4 ml-auto mr-4'
        />
        <div className="grow flex justify-end"></div>
        <section data-testid="Table" className={`w-full`}>
          <div className="w-full overflow-auto	 scrollbar">
            <table className="min-w-full divide-y divide-gray-200 border-collapse">
              <thead className="bg-bg7">
                <tr className="even:border-t-[1px] even:border-t-grey-200">
                  <th
                    scope="col"
                    className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                  >
                    Rol
                  </th>
                  <th
                    scope="col"
                    className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                  >
                    Cant. asignados
                  </th>
                  <th
                    scope="col"
                    className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                  >
                    Estado
                  </th>
                  <th
                    scope="col"
                    className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {(listRoles &&
                  listRoles.length > 0) ?
                  listRoles.map((role: any, i: number) => (
                    <tr key={i} className="h-[54px]">
                      <td className="px-4 py-2 whitespace-nowrap">
                        <div className="text-body2">{role?.name}</div>
                      </td>
                      <td className="px-4 py-2 ">
                        <div className="text-body2">{role?.assignedUsers}</div>
                      </td>
                      <td className="px-4 py-2">
                        {
                          !role?.fixed &&
                          <label htmlFor={`checked-toggle${i}`} className="inline-flex relative items-center">
                            <div
                              onClick={() => handleClickActivate(role?.status, role?.id)}
                              className={`cursor-pointer relative w-11 h-6 bg-gray-200 rounded-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 ${Boolean(role?.status) && 'bg-[#48B16E] after:translate-x-full'}`}></div>
                          </label>
                        }
                      </td>
                      <td className="px-4 py-2 ">
                        <div className="text-body2">
                          <div className="text-body2">
                            {!role?.fixed && (
                              <a
                                className="cursor-pointer"
                                onClick={() => handleClickOpenModal(role?.id)}
                              >
                                <img
                                  src={IconEdit}
                                  alt="Editar"
                                  className="h-[20px] w-[20px]"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td className="w-full">
                      <div className="flex justify-center items-center p-4">No se encontraron resultados</div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </section>
        {isShowModal && <ModalRole
          showModal={isShowModal}
          onCloseModal={handleCloseModal}
          onSubmitForm={() => console.log('e')}
          dataStorage={roleData}
          isLoading={isLoadingRoleData}
        />}
      </ShadowWrapper>
    </>
  )
}

export default UserManagementCreateRol
