import React, { useEffect, useState } from "react";
import IconClose from 'assets/icons/icon_close.svg';
import InputDatePicker from 'components/InputDatePicker';
import { GetRecommendationModel, RecommendationModel, useDeleteRecommendation, useGetRecommendations } from '../hooks/useMedicalRecomendation';
import Moment from "moment";
import Loader from 'components/Loader';
import IconDelete from 'assets/icons/icon_delete.svg';
import ScrollToBottom from 'react-scroll-to-bottom';
import { onAlert } from "components/Alert";

interface Props {
  planId?: number;
  onCloseModal: () => any;
}

const HistoricRecomendation: React.FC<Props> = (props) => {
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [ listRecommendations, setListRecommendations] = useState<RecommendationModel[]>([]);
  const { mutateAsync: getRecommendations, isLoading: loadingGetRecommendations } = useGetRecommendations();
  const { mutateAsync: deleteRecommendation, isLoading: loadingDeleteRecommendation } = useDeleteRecommendation();


  useEffect(() => {
    onGetRecommendations();
  }, [props.planId])

  const onGetRecommendations = () => {
    if (Moment(endDate).diff(Moment(startDate), 'days') > 0) {
      const query: GetRecommendationModel = {
        planId: props.planId,
        startDate: Moment(startDate).format('YYYY-MM-DD'),
        endDate: Moment(endDate).format('YYYY-MM-DD'),
      };
  
      getRecommendations(query).then(res => {
        if (res.data) {
          setListRecommendations(res.data);
        } else {
          onAlert.error(res);
        }
      });
    } else {
      onAlert.error('La fecha de inicio no puede ser mayor');
    }
  }

  const onDeleteRecommendation = (id: any) => {
    deleteRecommendation(id).then(res => {
      if (res.data) {
        onAlert.success('Se ha eliminado el comentario.');
        const newList = listRecommendations.filter(e => e.id !== id);
        setListRecommendations(newList);
      } else {
        onAlert.error(res);
      }
    });
  }

  return (
    <>
      { (loadingGetRecommendations || loadingDeleteRecommendation) && <Loader /> }
      
      {/* TODO ANGELICA: agregar height max y habilitar scroll auto */}
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative py-8 px-6  min-w-[80%] w-full max-w-[80%]">
          <div
            className="2xl:min-h-[600px] border-0 rounded-lg shadow-lg relative w-full h-full bg-white outline-none focus:outline-none p-4"
          >
            <div className='flex justify-between items-center mb-4'>
              <span className='text-subtitle1 text-grey-900'>Historial de recomendaciones</span>
              <button
                onClick={() => props.onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
              </button>
            </div>

            <div className='relative grid sm:grid-cols-2 gap-4 border-b-solid border-b-[1px] border-b-grey-200 mb-4 pb-4'>
              <p className='row-span-1 col-span-full text-grey-600 text-subtitle1'>Filtro</p>
              <InputDatePicker
                label="Fecha inicio"
                selected={startDate}
                onChange={(date: any) => {
                  setStartDate(date);
                  onGetRecommendations();
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale="es"
                dateFormat="dd/MM/yyyy"
              />
              <InputDatePicker
                label="Hasta"
                selected={endDate}
                onChange={(date: any) => {
                  setEndDate(date);
                  onGetRecommendations();
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale="es"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <ScrollToBottom mode={'top'} followButtonClassName='ScrollToBottom__btn__top'	 scrollViewClassName=' overflow-y-scroll scrollbar' className=" bg-white rounded-lg  w-full h-[170px] ">

            <div className=' pr-[16px]'>
              {
                listRecommendations.map((e: RecommendationModel) => (
                  <div key={e.id}>
                    <div className='flex justify-between items-center mb-2'>
                      <p className='text-subtitle2'>{e.name} {e.lastname} - {e.speciality}: {Moment(e.createdAt).format('DD/MM/YYYY')}</p>
                      <button data-testid='clinic-history__delete_recommendation' type='button' onClick={() => {onDeleteRecommendation(e.id)}} ><img src={IconDelete} style={{filter: 'invert(8%) sepia(98%) saturate(7424%) hue-rotate(1deg) brightness(105%) contrast(89%)'}} /></button>
                    </div>

                    <div className='p-4 rounded-[16px] bg-grey-50 text-grey-600'>
                      {e.message}
                    </div>
                  </div>
                ))
              }
            </div>
            </ScrollToBottom>

          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
};

export default HistoricRecomendation;
