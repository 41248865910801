import React from "react"
import IconMeetWhite from 'assets/icons/icon_video_line.svg';
import ButtonToggle from 'components/ButtonToggle';

interface Props {
  imgSrc: any
  name: string
  age: number
}

const  ProfileLight: React.FC<Props> = (props) => (
  <div className="flex items-center pt-6">
    <div className=" rounded-full w-20 h-20 overflow-hidden">
      {props.imgSrc && <img src={props.imgSrc} alt="imagen perfil" />}
    </div>
    <div className="ml-4">
      <p className="font-bold mb-4">{props.name}, {props.age} años</p>
      <ButtonToggle
        icon={IconMeetWhite}
        onClick={e => console.log(e)}
        isActive={true}
        className="h-[40px] rounded-[8px] bg-primary-main w-[221px] justify-center">
          <span className='text-white'>Llamar</span>
      </ButtonToggle>
    </div>
  </div>
)

export default ProfileLight