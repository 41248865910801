import IconClose from 'assets/icons/icon_close.svg'

interface CardProgressProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: any;
  className?: string;
  name?: string;
  progress?: string;
  size?: string;
  onClose?: () => void

}

const CardProgress: React.FC<CardProgressProps> = ({
  className,
  name,
  icon,
  onClose,
  progress,
  size
}) => {
  return (
    <div
      className={` min-h-[92px] w-full border-solid border-[1px] border-grey-200 p-4 rounded-[16px] p-[16px] pl-[40px]
        ${className}
      `}
    >
      <div className="text-body2 relative">
        <img className='top-0 left-[-20px] absolute w-[16px]' src={icon} alt={icon} />
        {name}
        {onClose && 
        
        <button
          onClick={onClose}
          className='top-0 right-[0] absolute w-[16px]'
        >
          <img  src={IconClose} alt="close" />
        </button>
        }
      </div>
      <div className=" text-caption"> {size}</div>
      {progress &&
        <div className="flex w-full items-center justify-star">
          <div className="flex w-full bg-disabled rounded-full h-[6px] dark:bg-gray-700">
            <div className="bg-primary-main h-[6px] rounded-full" style={{ width: progress }}></div>
          </div>

          <div className="ml-[8px] text-caption"> {progress}</div>

        </div>}
    </div>
  )
};

export default CardProgress
