import { t } from "i18next"
import IconHome from 'assets/icons/icon_home.svg';
import IconHealth from 'assets/icons/icon_health.svg';
import IconUsers from 'assets/icons/icon_users.svg';
import IconClinicTable from 'assets/icons/icon_clinic_table.svg';
import IconBot from 'assets/icons/icon_bot.svg';
import IconUser from 'assets/icons/icon_user.svg';
import IconFile from 'assets/icons/icon_file.svg';
import IconErrorTriangle from 'assets/icons/icon_error_triangle.svg';
import IconPressure from 'assets/icons/icon_pressure_black.svg';

import { PERMISSIONS } from "global/constants/roles";

enum TypeMenu {
  section = 'section',
  group = 'group',
  menu = 'menu',
};

export const routes: any = {
  // home hospital patient (client) views
  patientDashboard: {
    name: 'Mi plan de trabajo',
    url: '/patient-dashboard',
    permissionSlug: [PERMISSIONS.MY_PLAN],
    icon: IconUser,
    type: TypeMenu.menu,
  },
  // home hospital users views
  monitoring: {
    name: 'Monitoreo',
    url: '/monitoring',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    icon: IconPressure,
    type: TypeMenu.menu,
  },
  monitoringPatient:{
    name: 'Monitoreo de paciente',
    url: '/patient/:patientId',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    icon: IconHome,
    type: TypeMenu.menu,
  },
  clinicHistory: {
    name: 'Atenciones',
    url: '/attention',
    permissionSlug: [PERMISSIONS.CLINIC_HISTORY],
    icon: IconFile,
    type: TypeMenu.menu,
  },
  clinicHistoryPatient: {
    name: 'Atención Paciente',
    url: '/patient/:patientId',
    permissionSlug: [PERMISSIONS.CLINIC_HISTORY],
    icon: IconHome,
    type: TypeMenu.menu,
  },

  home: {
    name: t('home'),
    url: '/',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: IconHome,
    type: TypeMenu.menu,
  },
  patients:  {
    name: t('patients'),
    url: '/patients',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: IconUser,
    type: TypeMenu.menu,
  },
  patient: {
    name: 'Paciente',
    url: '/edit-patient',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: IconHome,
    type: TypeMenu.menu,
  },
  patientHistory:  {
    name: 'Paciente',
    url: '/patient/history',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: IconHome,
    type: TypeMenu.menu,
  },
  newPatient:
  {
    name: 'Nuevo Paciente',
    url: '/new',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: IconHome,
    type: TypeMenu.menu,
  },

  callCenter: {
    name: 'Central de atención',
    url: '/call-center',
    permissionSlug: [PERMISSIONS.COMMAND_CENTER],
    icon: IconHome,
    type: TypeMenu.menu,
  },

  pharmacyPatient: {
    name: 'Farmacia por usuario',
    url: '/pharmacy/patient',
    permissionSlug: [PERMISSIONS.PHARMACY_PER_USER],
    type: TypeMenu.menu,
  },

  pharmacyProduct: {
    name: 'Farmacia por producto',
    url: '/pharmacy/product',
    permissionSlug: [PERMISSIONS.PHARMACY_PER_PRODUCT],
    type: TypeMenu.menu,
  },

  devices: {
    name: 'Dispositivos reportados',
    url: '/devices',
    permissionSlug: [PERMISSIONS.DEVICE_REPORT],
    icon: IconErrorTriangle,
    type: TypeMenu.menu,
  },

  userManagementNewRol: {
    name: 'Roles',
    url: '/roles',
    permissionSlug: [PERMISSIONS.ROLE],
    icon: IconBot,
    type: TypeMenu.menu,
  },

  userManagement: {
    name: 'Usuarios',
    url: '/user-management',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    icon: IconUsers,
    type: TypeMenu.menu,
  },
  userManagementCalendar: {
    name: 'Disponibilidad',
    url: '/user-management/availability/:userId',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    icon: IconUsers,
    type: TypeMenu.menu,
  },
  userManagementNewUser: {
    name:'Gestión de usuarios nuevo usuario',
    url: '/user-management/new-user',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    icon: IconHome,
    type: TypeMenu.menu,
  },
  userManagementEditUser: {
    name: 'Gestión de usuarios deitar usuario',
    url: '/user-management/user/:userId',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    icon: IconHome,
    type: TypeMenu.menu,
  },
  userManagementDevicesAndMedications: {
    name: 'Dispositivos y medicamentos',
    url: '/devices-and-medications',
    permissionSlug: [
      PERMISSIONS.DEVICE_SUPPLIES
    ],
    icon: IconPressure,
    type: TypeMenu.menu,
  },
  notifications: { name: 'Notificaciones', url: '/notifications', permissionSlug: [], icon: IconHome, type: TypeMenu.menu, },
  readNotifications: { name: 'Notificaciones atendidas', url: '/notifications/read', permissionSlug: [], },
  icon: IconHome,
  type: TypeMenu.menu,

  clinicNetwork: {
    name: 'Red de clínicas',
    url: '/clinic-networks',
    permissionSlug: [PERMISSIONS.CLINIC_NETWORKS],
    type: TypeMenu.menu,
  },
  clinics: {
    name: 'Clínicas',
    url: '/clinics/:clinicNetworkId/clinics',
    permissionSlug: [PERMISSIONS.CLINICS],
    type: TypeMenu.menu,
  },
};

const superAdmin = [
  {
    name: 'Gestión',
    type: TypeMenu.section,
    list: [
      {
        name: 'Centros',
        type: TypeMenu.group,
        icon: IconClinicTable,
        list: [
          routes.clinicNetwork,
        ]
      }
    ]
  },
  {
    ...routes.userManagementNewRol,
    principal: true
  },
  {
    ...routes.userManagement,
    principal: true

  }
];

const countryAdmin = [
  {
    name: 'Gestión',
    type: TypeMenu.section,
    list: [
      {
        name: 'Centros',
        type: TypeMenu.group,
        icon: IconClinicTable,
        list: [
          routes.clinics,
        ]
      }
    ]
  },
  {
    ...routes.userManagement,
    principal: true
  }
];

const serviceAdmin = [
  {
    name: 'Pacientes',
    type: TypeMenu.section,
    list: [
      {
        ...routes.patients,
        principal: true
      },
      {
        ...routes.clinicHistory,
        principal: true
      },
      {
        ...routes.monitoring,
        principal: true

      },
      {
        ...routes.devices,
        principal: true
      },
      {
        name: 'Farmacia',
        type: 'group',
        icon: IconHealth,
        list: [
          routes.pharmacyProduct,
          routes.pharmacyPatient,
        ]
      }
    ]
  },
  {
    name: 'Gestión',
    type: TypeMenu.section,
    list: [
      {
        ...routes.userManagementDevicesAndMedications,
        principal: true
      },
      {
        ...routes.userManagement,
        principal: true
      }
    ]
  },
];

const treatingMedical = [
  {
    ...routes.monitoring,
    principal: true
  }
];

const professionalHealth = [
  {
    ...routes.clinicHistory,
    principal: true

  },
  {
    ...routes.monitoring,
    principal: true
  }
];

const patient = [
  {
    ...routes.patientDashboard,
    principal: true
  }
];


export const rolMenuList = {
  superAdmin,
  serviceAdmin,
  treatingMedical,
  countryAdmin,
  patient,
  professionalHealth
};

