import IconUser from 'assets/icons/icon_user.svg';
import IconMap from 'assets/icons/icon_map.svg';
import LabelForm from 'components/LabelForm';
import Input from 'components/input';
import InputDatePicker from 'components/InputDatePicker';
import { useState } from 'react';
import ModalMap from 'components/MapLocation';
import { AddresSelectedModel } from 'components/MapLocation/MapModel';
import { PatientModel } from 'features/Patient/models/Patient.model';
import moment from 'moment';

interface PersonalInformationProps {
  patient?: PatientModel
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({patient}) => {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <div className='grid sm:grid-cols-3 gap-2'>
      <LabelForm icon={IconUser} label="Datos personales" className='mt-4 mb-2 row-span-1 col-span-full'/>

      <Input
        type="text"
        label="Número de documento"
        value={patient?.document}
        readOnly
      />

      <Input
        type="text"
        readOnly
        label="Nombres"
        value={patient?.name}
      />

      <Input
        type="text"
        readOnly
        label="Apellidos"
        value={patient?.lastname}
      />

      <Input
        type="text"
        readOnly
        label="Dirección"
        value={patient?.address.name}
        icon={IconMap}
        onClick={() => {setIsShowModal(true)}}
      />

      <ModalMap
        showModal={isShowModal}
        isEdit={true}
        data={{
          name: patient?.address?.name || "",
          reference: patient?.address?.reference || "",
          lat: patient?.address?.lat || 0,
          lng: patient?.address?.lng || 0,
          number: patient?.address?.number || 0,
          ubigeoId: patient?.address?.id || 0,
        }}
        region={patient?.countryId === 'PE' ? 'pe' : 'co'}
        onCloseModal={() => {setIsShowModal(false)}}
        onSubmitAddress={(data: AddresSelectedModel) => {
          // setAddressSelected(data);
          setIsShowModal(false);
        }}
      />

      <InputDatePicker
        label="Fecha de nacimiento"
        onChange={() => {}}
        readOnly
        dateFormat="dd/MM/yyyy"
        locale="es"
        selected={patient?.birthday && new Date(moment(patient?.birthday).utc().format('YYYY-MM-DD'))}
      />

      <Input
        type="text"
        readOnly
        label="Servicio de procedencia"
        value="Cardiología"
      />

      <LabelForm icon={IconUser} label="Datos del cuidador" className='mt-4 mb-2 col-span-full'/>

      <Input
        type="text"
        readOnly
        label="Número de documento"
        value={patient?.responsibles[0]?.document || ""}
      />

      <Input
        type="text"
        readOnly
        label="Nombres"
        value={patient?.responsibles[0]?.name || ""}
      />

      <Input
        type="text"
        readOnly
        label="Apellidos"
        value={patient?.responsibles[0]?.lastname || ""}
      />

      <Input
        type="text"
        readOnly
        label="Número de celular"
        value={patient?.responsibles[0]?.mobilePhone || ""}
      />

    </div>
  )
}

export default PersonalInformation;