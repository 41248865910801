import React, { useEffect } from 'react'

import ModalTermsConditions from 'components/modalTermsConditions'
import useGetCheckTermsConditions from 'features/shared/hooks/useGetCheckTermsConditions'
import useGetTermsConditions from 'features/shared/hooks/useGetTermsConditions'

interface termsConditionsProps {
    userId: number;
    changeShowMain: (value:boolean) => any;

  };
  
const TermsConditions: React.FC <termsConditionsProps> = ({userId,changeShowMain}) => {
    const [showModal, setShowModal] = React.useState(true)
   const getCheckTermsConditions = useGetCheckTermsConditions(userId);
    const getTermsConditionspdf = useGetTermsConditions(userId); 
    const click = () => {
      setShowModal(false)
    }
    useEffect(() => {
      if (getCheckTermsConditions?.data) {        
        if (getCheckTermsConditions?.data?.data?.showTermsAndConditions) {
          setShowModal(true)
          changeShowMain(false)
        } else {
          setShowModal(false)
        }
      }else{
        setShowModal(false)
      }
    }, [getCheckTermsConditions?.data])
  
    return (
      <React.Fragment>
        {showModal &&
          <ModalTermsConditions changeShowMain={changeShowMain} showModal={showModal} setShowModal={click} termsConditionspdf={getTermsConditionspdf?.data?.data} userId={userId} />
 }
           
      </React.Fragment>
    )
}

export default TermsConditions
