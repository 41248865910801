import React from "react";
import IconClose from 'assets/icons/icon_close.svg';

interface AllMedicalDevicesProps {
  onCloseModal: () => any;
  children: any
}

const AllMedicalDevices: React.FC<AllMedicalDevicesProps> = (props) => {

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-[80%] h-[80%]">
          <div
            className="border-0 rounded-lg shadow-lg relative w-full h-full bg-white outline-none focus:outline-none overflow-hidden p-4 grid sm:grid-cols-1"
          >
            <div className='flex justify-between items-center mb-4'>
              <span className='text-subtitle1 text-grey-900'>Dispositivos médicos</span>
              <button
                onClick={() => props.onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
              </button>
            </div>
            <div className='grow overflow-y-auto pb-2'>
              {props.children}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
};

export default AllMedicalDevices;
