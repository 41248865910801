import LabelForm from 'components/LabelForm';
import IconFile from 'assets/icons/icon_file.svg';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import { useState } from 'react';
import IconList from 'assets/icons/icon_list.svg';
import HistoricRecomendation from './HistoricRecomendation';
import { PatientModel } from 'features/Patient/models/Patient.model';
import { useForm } from 'react-hook-form';
import { GetRecommendationModel, RecommendationModel, RegisterRecommendationModel, useGetLastRecomendation, useGetRecommendations, useRegisterNewRecommendation } from '../hooks/useMedicalRecomendation';
import useUser from 'features/shared/hooks/useUser'
import Loader from 'components/Loader';
import Moment from "moment";
import { onAlert } from 'components/Alert';
import { ROLE } from 'global/constants/roles';

interface WorkPlanProps {
  patientData?: PatientModel
}

const WorkPlan: React.FC<WorkPlanProps> = ({
  patientData
}) => {
  const { userCurrent } = useUser();
  const [showRecomendations, setShowRecomendations] = useState<boolean>(false);
  const { register, watch, setValue } = useForm();
  const [ lastRecommendation, setLastRecommendation] = useState<RecommendationModel>();
  const { mutateAsync: registerNewRecommendation, isLoading: loadingRegisterNewRecommendation } = useRegisterNewRecommendation();
  const { mutateAsync: getLastRecomendation, isLoading: loadingGetLastRecomendation } = useGetLastRecomendation();



  const onRegisterRecommendation = () => {
    const data: RegisterRecommendationModel = {
      planId: patientData?.service?.id,
      userId: userCurrent?.id,
      message: watch('comment'),
    };

    registerNewRecommendation(data).then(res => {
      if (res.data) {
        onAlert.success('Recomendación registrada');
        setValue('comment', '');
      } else {
        onAlert.error(res);
      }
    });
  }

  return (
    <>
      { (loadingRegisterNewRecommendation) && <Loader /> }
      <div className='border-solid border-[1px] border-grey-200 rounded-[16px] p-4'>
        <div className="flex justify-between items-center mb-4">
          <LabelForm
            icon={IconFile}
            label="Recomendaciones médicas"
          />

          <div className='flex items-center'>
            <button data-testid='clinic-history__see_history' className='border-nonde text-body1 p-0 flex items-center mr-4' onClick={() => {setShowRecomendations(true)}}>
              <img src={IconList} className="w-4 h-4 mr-2" /> Ver histórico
            </button>
          </div>
        </div>

        {
          showRecomendations &&
          <HistoricRecomendation
            planId={patientData?.service?.id}
            onCloseModal={() => {setShowRecomendations(false)}}
          />
        }

        <TextArea
          data-testid="clinic-history__medical_recommendation"
          label="Comentario"
          maxLength={800}
          rows={1}
          disabled={!(userCurrent?.roleSlug !== ROLE.SERVICE_ADMIN)}
/*           disabled={userCurrent?.roleSlug !== ROLE.SERVICE_ADMIN}
 */          value={watch('comment')}
          {...register('comment')}
        />
        {userCurrent?.roleSlug !== ROLE.SERVICE_ADMIN  &&
                <Button className='mt-5' data-testid="clinic-history__send_recommendation" type="submit" variant='primary' size="small" disabled={!watch('comment')} onClick={onRegisterRecommendation} >Enviar</Button>

        }
      </div>
    </>
  );
};


export default WorkPlan;
