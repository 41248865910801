import fetcher from 'features/shared/utils/fetcher'

export async function postRegisterPatient(value:object) {
  
  return await fetcher.post('/patient/store',value);
}

export async function putRegisterPatient(value: any) {
  
  return await fetcher.put('/patient/'+value?.id+"/edit", value.data);
}
