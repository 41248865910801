import React from 'react'
import ReactDOM from 'react-dom'
import AppProvider from 'state'
import './configs'
import './index.css'

import { ToastContainer } from 'react-toastify';
import Routes from './routes/index'

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="top-right"
      toastClassName={`
        w-full !rounded-[12px] sm:!p-[12px] 
        md:!rounded-[24px] md:!p-6 
      `}
      bodyClassName={"!p-[0px] !m-[0px] !h-[100%] !w-[100%] !items-start"}
      autoClose={false}
      limit={1}
      closeOnClick={false}
      hideProgressBar
      newestOnTop={false}
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />
    <AppProvider>
      <Routes />
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);