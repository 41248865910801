import IconFile from 'assets/icons/icon_file.svg';
import IconHealth from 'assets/icons/icon_health.svg';
import LabelForm from 'components/LabelForm';
import Input from 'components/input';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import InputSelectMultiple from 'components/InputSelectMultiple';
import React, { useEffect, useState } from 'react';
import { PatientClinicHistory, useGetPatientClinicHistory, usePostPatientClinicHistory, useUpdatePatientClinicHistory } from 'features/ClinicHistory/hooks/usePatientClinicHistory';
import getDiagnosticos from 'features/shared/services/getDiagnosticos'
import InputSelectSearch from 'components/InputSelectSearch';
import Loader from 'components/Loader';
import { useHistory } from 'react-router'
import { onAlert } from 'components/Alert';

interface OptionModel {
  value: any;
  label: string;
  isRemovable: boolean;
}

interface HistoryProps {
  serviceId?: number,
  onReloadPatient: () => any;
}

const History: React.FC<HistoryProps> = ({ serviceId,onReloadPatient }) => {
  const history = useHistory();

  const { register, watch, control, handleSubmit, setValue } = useForm();
  const [patientClinicHistory, setPatientClinicHistory] = useState<PatientClinicHistory>();
  const [drugAllergies, setDrugAllergies] = useState<OptionModel[] | []>([]);
  const [drugAllergiesOptions, setDrugAllergiesOptions] = useState<OptionModel[] | []>([
    { value: 'ninguno', label: 'Ninguno', isRemovable: true },
    { value: 'aines', label: 'AINES', isRemovable: true },
    { value: 'penicilinas', label: 'Penicilinas', isRemovable: true },
    { value: 'sulfamidas', label: 'Sulfamidas', isRemovable: true },
    { value: 'corticoides', label: 'Corticoides', isRemovable: true },
    { value: 'contrastesYodados', label: 'Contrastes Yodados', isRemovable: true },
    { value: '0', label: 'otros', isRemovable: true }]);
  const [medicalHistory, setMedicalHistory] = useState<OptionModel[] | []>([]);
  const [medicalHistoryOptions, setMedicalHistoryOptions] = useState<OptionModel[] | []>([
    { value: 'ninguno', label: 'Ninguno', isRemovable: true },
    { value: 'hipertensionArterial', label: 'Hipertension Arterial', isRemovable: true },
    { value: 'diabetesMellitus', label: 'Diabetes Mellitus', isRemovable: true },
    { value: 'asma', label: 'Asma', isRemovable: true },
    { value: 'epoc', label: 'EPOC', isRemovable: true },
    { value: '0', label: 'otros', isRemovable: true }]);
  const [surgicalHistory, setSurgicalHistory] = useState<OptionModel[] | []>([]);
  const [surgicalHistoryOptions, setSurgicalHistoryOptions] = useState<OptionModel[] | []>([
    { value: 'ninguno', label: 'Ninguno', isRemovable: true },
    { value: 'apendicectomia', label: 'Apendicectomia', isRemovable: true },
    { value: 'colecistectomia', label: 'Colecistectomia', isRemovable: true },
    { value: 'cesarea', label: 'Cesarea', isRemovable: true },
    { value: 'histerectomia', label: 'Histerectomia', isRemovable: true },
    { value: 'ooforectomia', label: 'Ooforectomia', isRemovable: true },
    { value: 'prostatectomia', label: 'Prostatectomia', isRemovable: true },
    { value: '0', label: 'otros', isRemovable: true }]);
  const { mutateAsync: postPatientClinicHistory, isLoading: loadingPostPatientClinicHistory } = usePostPatientClinicHistory();
  const { mutateAsync: getPatientClinicHistory, isLoading: loadingGetPatientClinicHistory } = useGetPatientClinicHistory();
  const { mutateAsync: updatePatientClinicHistory, isLoading: loadingUpdatePatientClinicHistory } = useUpdatePatientClinicHistory();
  const isOtherSelected = (val: OptionModel[]): boolean => !!(val.find((e: OptionModel) => e.value === '0'));
  const [isSelectLoading, setIsSelectLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string, label: string }[]>([]);
  const [principalDiagnostic, setPrincipalDiagnostic] = useState({ value: '', label: '' });
  const [secondaryDiagnostic, setSecondaryDiagnostic] = useState({ value: '', label: '' });

  useEffect(() => {
    onGetClinicHistory();
  }, [serviceId])
  const handleSelectChangeInfo = (e: any) => setPrincipalDiagnostic(e);

  const handleInputSelectChange = (value: string) => {
    if (value.length > 0) {
      setIsSelectLoading(true);
      getDiagnosticos(value).then((resp: any) => {
        const transformedResponse = resp?.data?.map((data: any) => ({
          value: data?.cie10,
          label: `[${data?.cie10}] ${data?.descripcion}`
        }));

        setOptions([...transformedResponse]);
      }).finally(() => setIsSelectLoading(false));
    }
  };
  const onGetClinicHistory = () => {
    getPatientClinicHistory(serviceId).then((res) => {
      if (res.data) {
        const data: PatientClinicHistory = res.data;
        setPatientClinicHistory(data);
        setDrugAllergies(standarizeOptionsSelected(data.drug_allergies));
        setValue('other_drug_allergies', data.other_drug_allergies);
        setMedicalHistory(standarizeOptionsSelected(data.medical_history));
        setValue('other_medical_history', data.other_medical_history);
        setSurgicalHistory(standarizeOptionsSelected(data.surgical_history));
        setValue('other_surgical_history', data.other_surgical_history);
        setValue('family_background', data.family_background);
        setValue('usual_treatment', data.usual_treatment);
        setValue('sick_time', data.sick_time);
        setValue('medical_condition', data.medical_condition);
        if (!!data.principal_diagnostic) setPrincipalDiagnostic({ value: data.principal_diagnostic, label: `[${data?.principal_diagnostic}] ${data.principal_diagnostic_description}` });
        if (data?.secondary_diagnostic_description) {
          if (!!data.secondary_diagnostic) setSecondaryDiagnostic({ value: data.secondary_diagnostic, label: `[${data?.secondary_diagnostic}] ${data.secondary_diagnostic_description}` });
        }
      }
    })
  }

  const standarizeOptionsSelected = (option: string) => {
    return option.split(',').map(e => ({ value: e, label: '', isRemovable: true }))
  }

  const onSubmitForm = async () => {
    const data = {
      drug_allergies: drugAllergies.map(e => e.value).join(','),
      other_drug_allergies: watch('other_drug_allergies') === "" ? "_" : watch('other_drug_allergies'),
      medical_history: medicalHistory.map(e => e.value).join(','),
      other_medical_history: watch('other_medical_history') === "" ? "_" : watch('other_medical_history'),
      surgical_history: surgicalHistory.map(e => e.value).join(','),
      other_surgical_history: watch('other_surgical_history') === "" ? "_" : watch('other_surgical_history'),
      family_background: watch('family_background'),
      usual_treatment: watch('usual_treatment'),
      sick_time: watch('sick_time'),
      principal_diagnostic: principalDiagnostic?.value,
      secondary_diagnostic: secondaryDiagnostic?.value === "" ? "_" : secondaryDiagnostic?.value,
      medical_condition: watch('medical_condition'),
      serviceId: serviceId,
    };

    if (patientClinicHistory?.serviceId) {
      const body = { data, id: patientClinicHistory?.id };
      updatePatientClinicHistory(body).then(res => {
        if (res.data) {
          onAlert.success('Actualización exitosa');
          onReloadPatient();
        } else {
          onAlert.error(res);
        }
      })
    } else {

      postPatientClinicHistory(data).then(res => {
        if (res.data) {
          onAlert.success('Registro exitoso');
          onReloadPatient();
        } else {
          onAlert.error(res);
        }
      })
    }

  }
  const getValeidate = () => {
/*     console.log(drugAllergies.map(e => e.value).join(','));
 */
    if (
      drugAllergies.map(e => e.value).join(',') &&
      (drugAllergies.map(e => e.value).join(',').includes("0") ? watch('other_drug_allergies') : true) &&
      medicalHistory.map(e => e.value).join(',') &&
      (medicalHistory.map(e => e.value).join(',').includes("0") ? watch('other_medical_history') : true) &&
      surgicalHistory.map(e => e.value).join(',') &&
      (surgicalHistory.map(e => e.value).join(',').includes("0") ? watch('other_surgical_history') : true) &&
      watch('family_background') &&
      watch('usual_treatment') &&
      watch('sick_time') &&
      principalDiagnostic?.value &&
      watch('medical_condition')
    ) {
      return true
    } else {
      return false
    }
  }
  return (
    <React.Fragment>
      {(loadingPostPatientClinicHistory || loadingGetPatientClinicHistory || loadingUpdatePatientClinicHistory) && <Loader />}
      <form action="submit" onSubmit={handleSubmit(onSubmitForm)} className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-3 gap-4 '>
        <LabelForm icon={IconHealth} label="Datos médicos" className='mt-4 mb-2 row-span-1 col-span-full' />
        <InputSelectMultiple
          label='Alergias medicamentosas'
          options={drugAllergiesOptions}
          value={drugAllergies}
          required
          onSelect={(e) => {
            const newList = [...drugAllergies, e];
            setDrugAllergies(newList)
          }}
          onRemove={(item) => {
            const newList = drugAllergies.filter(e => e.value !== item.value);
            setDrugAllergies(newList);
          }}
        />

        {isOtherSelected(drugAllergies) &&
          <Input
            type="text"
            label="Otros alergias medicamentosas"
            value={watch('other_drug_allergies')}
            {...register('other_drug_allergies')}
            required
          />
        }

        <InputSelectMultiple
          label='Antecedentes médicos'
          options={medicalHistoryOptions}
          value={medicalHistory}
          required
          onSelect={(e) => {
            const newList = [...medicalHistory, e];
            setMedicalHistory(newList)
          }}
          onRemove={(item) => {
            const newList = medicalHistory.filter(e => e.value !== item.value);
            setMedicalHistory(newList);
          }}
        />

        {isOtherSelected(medicalHistory) &&
          <Input
            type="text"
            label="Otros antecedentes médicos"
            value={watch('other_medical_history')}
            {...register('other_medical_history')}
            required
          />
        }

        <InputSelectMultiple
          label='Antecedentes quirúrgicos'
          options={surgicalHistoryOptions}
          value={surgicalHistory}
          required
          onSelect={(e) => {
            const newList = [...surgicalHistory, e];
            setSurgicalHistory(newList)
          }}
          onRemove={(item) => {
            const newList = surgicalHistory.filter(e => e.value !== item.value);
            setSurgicalHistory(newList);
          }}
        />

        {isOtherSelected(surgicalHistory) &&
          <Input
            type="text"
            label="Otros antecedentes quirúrgicos"
            value={watch('other_surgical_history')}
            {...register('other_surgical_history')}
            required
          />
        }

        <Input
          type="text"
          label="Antecedentes familiares"
          value={watch('family_background')}
          {...register('family_background')}
          required
        />

        <Input
          type="text"
          label="Tratamiento habitual"
          value={watch('usual_treatment')}
          {...register('usual_treatment')}
          required
        />

        <Input
          type="text"
          label="Tiempo de enfermedad"
          value={watch('sick_time')}
          {...register('sick_time')}
          required
        />


        <InputSelectSearch
          label="Diagnóstico principal"
          value={principalDiagnostic}
          options={options}
          onSearch={handleInputSelectChange}
          onSelect={handleSelectChangeInfo}
          isLoading={isSelectLoading}
          required
        />

        <InputSelectSearch
          label="Diagnóstico secundario"
          value={secondaryDiagnostic}
          options={options}
          onSearch={handleInputSelectChange}
          onSelect={(e: any) => setSecondaryDiagnostic(e)}
          isLoading={isSelectLoading}
        />


        <LabelForm icon={IconFile} label="Descripción de condición médica" className='mt-4 mb-2 col-span-full' />

        <div className='col-span-full'>
          <TextArea
            label="Relato de enfermedad"
            maxLength={800}
            value={watch('medical_condition')}
            {...register('medical_condition')}
            required
          />
        </div>

        <div className="flex justify-center gap-4 mt-6 col-span-full">
          <Button type="button" variant='secondary' size="large" onClick={() => { history.goBack() }}  >Cancelar</Button>
          <Button type="submit" variant='primary' size="large" disabled={!getValeidate()} >Guardar</Button>

        </div>
      </form>
    </React.Fragment>

  )
}

export default History;