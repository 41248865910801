
interface ToggleProps {
  isActive: boolean;
  className?: any;
  data?: any;
  setActive: (a: any) => any;
}

const Toggle: React.FC<ToggleProps> = ({
  isActive,
  setActive,
  className,
  data,
  children,
}) => {
  return (
    <label className={`h-fit inline-flex relative items-center  cursor-pointer ${className}`}>
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isActive}
        readOnly
      />
      <div
        data-testid={`toggle-btn-${data?.id}`}

        onClick={setActive}
        className={`${isActive ? 'isActiveToggle' : 'isInActiveToggle'} w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-[#48B16E]  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#48B16E]`}
      ></div>
      {children &&
        <span className="ml-5 ml-2 text-sm font-medium text-gray-900">
          {children}
        </span>
      }

    </label>
  )
}

export default Toggle
