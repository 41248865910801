import { FC } from 'react'

const ModalRelative: FC = ({ children = null }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none absolute">
        <div className="box-border relative w-[302px] max-w-[302px]">
          <div className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4">
            {children}
          </div>
        </div>
      </div>
      <div className="absolute inset-0 z-40 backdrop-blur-sm bg-white/0"></div>
    </>
  )
}

export default ModalRelative
