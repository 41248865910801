import ButtonToggle from 'components/ButtonToggle';
import { Route, Switch, useHistory, useRouteMatch, useLocation, useParams } from 'react-router-dom';
import History from './History';
import PersonalInformation from './PersonalInformation';

import IconFile from 'assets/icons/icon_file.svg';
import IconInfo from 'assets/icons/icon_info.svg';
import IconHealth from 'assets/icons/icon_health.svg';
import IconFilePlus from 'assets/icons/icon_file_plus.svg';
import { useState, useEffect } from 'react';
import { routes } from 'routes/routing';
import Breadcrumbs from 'components/Breadcrumbs';
import { PatientModel } from 'features/Patient/models/Patient.model';
import { useGetPatientData } from 'features/shared/hooks/useGetPatientFullData';
import DailyEvolution from './DailyEvolution';
import WorkPlan from 'components/WorkPlan';
import Loader from 'components/Loader';
import useUser from 'features/shared/hooks/useUser'
import Epicrisis from './Epicrisis';
interface RouteParams {
  patientId: string
}

const PatientClinicHistory = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<RouteParams>();
  const { userCurrent } = useUser();

  let { path, url } = useRouteMatch();
  const [sectionstep, setSectionstep] = useState(0);
  const [allDataPatient, setAllDataPatient] = useState<PatientModel>();
  const { mutateAsync: asynGetPatientData, isLoading: loadingPatientData } = useGetPatientData();

  const onGetPatient = async () => {
    const dataPatient = await asynGetPatientData(params.patientId);

    if (dataPatient?.data) setAllDataPatient(dataPatient.data);
  }

  useEffect(() => {
    onGetPatient();
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case `${url}/history`:
        setSectionstep(1)
        break;
      case `${url}/daily-evolution`:
        setSectionstep(2)
        break;
      case `${url}/workplan`:
        setSectionstep(3)
        break;
      case `${url}/epicrisis`:
        setSectionstep(4)
        break;
      default:
        setSectionstep(0)
        break;
    }
  }, [location])

  if (loadingPatientData && !allDataPatient?.userId) {
    return <Loader />
  }

  return (
    <div>
      <Breadcrumbs
        name={`${allDataPatient?.name} ${allDataPatient?.lastname}`}
        prevPathName="Lista de pacientes"
        currentPathName={`${allDataPatient?.name} ${allDataPatient?.lastname}`}
        onClickPrev={() => { history.push(`${routes.clinicHistory.url}`) }}
        onClickHome={() => { history.push(`/`) }}
      />

      <div className="w-full bg-white py-6 px-4 rounded-[16px] shadow-md mt-6">
        <div className="flex flex-wrap gap-2 mb-6 w-full" data-testid='patient-history-menu'>
          <ButtonToggle icon={IconInfo} isActive={sectionstep === 0} onClick={() => { history.push(`${url}`) }} >
            Información Personal
          </ButtonToggle>
          <ButtonToggle disabled={!allDataPatient?.service} icon={IconHealth} isActive={sectionstep === 1} onClick={() => { history.push(`${url}/history`) }} >
            Atención
          </ButtonToggle>
          <ButtonToggle disabled={!allDataPatient?.service} icon={IconFilePlus} isActive={sectionstep === 2} onClick={() => { history.push(`${url}/daily-evolution`) }} >
            Evolución diaria
          </ButtonToggle>
          <ButtonToggle disabled={!allDataPatient?.service} icon={IconFile} isActive={sectionstep === 3} onClick={() => { history.push(`${url}/workplan`) }} data-testid='btn_plan' >
            Plan de trabajo
          </ButtonToggle>
          <ButtonToggle disabled={!allDataPatient?.service || !allDataPatient?.haveMedicalHistory} icon={IconFile} isActive={sectionstep === 4} onClick={() => { history.push(`${url}/epicrisis`) }} >
            Alta del paciente
          </ButtonToggle>
        </div>

        <Switch>
          <Route exact path={path}>
            <PersonalInformation
              patient={allDataPatient}
            />
          </Route>
          <Route exact path={`${path}/history`}>
            <History
              serviceId={allDataPatient?.service?.id}
              onReloadPatient={() => {
                onGetPatient()
              }}
            />
          </Route>
          {/*  {console.log(allDataPatient,'alldata patient')} */}
          {
            allDataPatient?.userId &&
            <Route exact path={`${path}/daily-evolution`}>
              {/*    {console.log(allDataPatient, 'allldAtapatient')} */}
              <DailyEvolution
                serviceId={allDataPatient?.service?.id}
                patientId={allDataPatient?.userId}
                userId={userCurrent?.id}
                userData={userCurrent}
                userProfile={allDataPatient?.profile} />
            </Route>
          }

          <Route exact path={`${path}/workplan`}>
            <WorkPlan
              patientData={allDataPatient}
            />
          </Route>

          <Route exact path={`${path}/epicrisis`}>
            {allDataPatient?.haveMedicalHistory ?
              <Epicrisis
                patientData={allDataPatient}
                serviceId={allDataPatient?.service?.id}
              />
              :
              'No cuentas con atención'
            }
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default PatientClinicHistory;