import IconCopy from 'assets/icons/icon_copy.svg';
import Input from 'components/input';
import VerticalMenu from 'components/VerticalMenu';
import IconErrorTriangle from 'assets/icons/icon_error_triangle.svg';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import Replace from 'components/WorkPlan/MedicalDevices/Replace';
import { useEffect, useState } from 'react';
import Report from './Report';
import { MedicalDeviceServiceModel, RegisterMedicalDeviceModel, ReportMedicalDeviceModel } from '../hooks/useDevices';
import { useMutation, useIsMutating } from "react-query";
import useUser from 'features/shared/hooks/useUser';
import { ROLE } from 'global/constants/roles';

interface MedicalDeviceCardProps {
  data: MedicalDeviceServiceModel;
  onActivate: (body: RegisterMedicalDeviceModel, planDeviceId: number) => any;
  onReplace:(body: RegisterMedicalDeviceModel) => any;
  onReportDevice: (body: ReportMedicalDeviceModel, device: MedicalDeviceServiceModel) => any;
  serviceId?: number;
}

const MedicalDeviceCard: React.FC<MedicalDeviceCardProps> = ({
  data,
  onActivate,
  onReplace,
  onReportDevice,
  serviceId
}) => {
  const { userCurrent} = useUser()
  const {register, watch, setValue, handleSubmit, control} = useForm();
  const [showReport, setShowReport] = useState<boolean>(false);
  const [showReplace, setShowReplace] = useState<boolean>(false);
  const isMutating = useIsMutating({ mutationKey: [serviceId, data.deviceId] });

  useEffect(() => {
    setValue('serial', data.serial);
  }, [data.serial]);


  const onCopyClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(function () {
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }
  return (
    <div
      data-testid={data.deviceName}
      className={`
        border-solid border-[1px] border-grey-200 rounded-[16px] p-4
        ${data.status === 2 ? 'border-orange-400' : ''}
      `}
    >
      {
        (isMutating > 0) ?
          <div className='animate-pulse'>
            <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-4 bg-slate-200 rounded col-span-2"></div>
              <div className="h-4 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div className="h-10 bg-slate-200 rounded"></div>
            </div>
          </div>
        :
          <>
            <div className='flex items-start'>
              <p className='text-headline5 font-semibold grow'>{data.deviceName}</p>
              {data.status === 2 ? (
                  <Button
                    className='mr-1 border-none focus:none bg-orange-400 !h-[32px] !px-[8px] !rounded-[8px] !text-body1'
                    variant='primary'
                    size="ultra-tiny"
                    onClick={() => setShowReplace(true)}
                  >
                    Reemplazar
                  </Button>
                ) : (
                  <Button
                    className='mr-1 !h-[32px] !px-[8px] !rounded-[8px] !text-body1'
                    variant='primary'
                    size="ultra-tiny"
                    onClick={() => onActivate({
                      deviceId: data.deviceId,
                      serial: watch('serial'),
                    }, data.id)}
                    disabled={data.status > 0 ? true : !watch('serial')}
                  >Activar</Button>
                )
              }

              {
                (data.status === 1 && userCurrent?.roleSlug !== ROLE.SERVICE_ADMIN) &&
                <VerticalMenu>
                  <>
                    <li className={`cursor-pointer py-4 hover:bg-grey-200 text-caption w-[133px] `} >
                      <div onClick={() => setShowReport(true)} className='flex mx-4'>
                        <img className='w-[18px] h-[18px] mr-4' src={IconErrorTriangle} alt="report" /> <span>Reportar</span>
                      </div>
                    </li>
                  </>
                </VerticalMenu>
              }
            </div>
            <div className='mt-4'>
              <Input
                label="Código de producto"
                required
                icon={IconCopy}
                onClickIcon={() => { onCopyClipboard(watch('input1')) }}
                value={watch('serial')}
                {...register('serial')}
                readOnly={!!data.serial}
              />
              <Report
                showModal={showReport}
                onCloseModal={() => { setShowReport(false) }}
                onSubmitForm={(report) => {
                  onReportDevice(report, data);
                }}
              />
              <Replace
                showModal={showReplace}
                onCloseModal={() => { setShowReplace(false) }}
                onSubmitForm={(serial) => {
                  onReplace({
                    deviceId: data.deviceId,
                    serial,
                  });
                  setValue('serial', serial);

                }}
              />
            </div>
          </>
      }
    </div>
  )
}

export default MedicalDeviceCard;
