import { useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export function useQueryParams() {
  const { search, pathname } = useLocation()
  const history = useHistory()

  const query = useMemo(
    () => Object.fromEntries(new URLSearchParams(search).entries()),
    [search]
  )

  const setQuery = (newQuery: Record<string, string>) => {
    const newSearch = new URLSearchParams({
      ...query,
      ...newQuery,
    }).toString()

    history.push(`${pathname}?${newSearch}`)
  }

  return {
    query,
    setQuery,
  }
}

