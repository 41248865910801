interface MenuItemProps {
  isActive: boolean;
  icon?: string;
  label?: string;
  onClick: () => any;
}

const MenuItem: React.FC<MenuItemProps> = ({
  isActive,
  icon,
  label,
  onClick
}) => {
  const filterActive = 'invert(33%) sepia(86%) saturate(1046%) hue-rotate(154deg) brightness(99%) contrast(101%)';

  return (
    <div
      className={`
        w-full flex items-center gap-2 hover:bg-grey-50 rounded-[8px] p-2 text-grey-600 text-body1 cursor-pointer mt-2 
        ${isActive ? 'bg-bluedark-50 text-primary-dark' : ''}
      `}

      onClick={onClick}
    >
      { icon ? (
          <img 
            src={icon} 
            alt='icon'
            className='w-4 h-4'
            style={isActive ? { filter: filterActive } : {}}
          />
        )
        : (
          <div className='w-4 h-4' ></div>
        )
      }

      <p className='grow'>
        {label}
      </p>
    </div>
  )
}

interface MenuItemLightProps {
  isActive: boolean;
  icon?: string;
}

export const MenuItemLight: React.FC<MenuItemLightProps> = ({
  isActive,
  icon,
}) => {
  const filterActive = 'invert(33%) sepia(86%) saturate(1046%) hue-rotate(154deg) brightness(99%) contrast(101%)';

  return (
    <div
      className={`
        w-8 h-8 hover:bg-grey-50 rounded-[8px] p-2 text-grey-600 text-body1 mt-2 mx-auto
        ${isActive ? 'bg-bluedark-50 text-primary-dark' : ''}
      `}
    >
      { icon ? (
          <img 
            src={icon} 
            alt='icon'
            className='w-4 h-4'
            style={isActive ? { filter: filterActive } : {}}
          />
        )
        : (
          <div className='w-4 h-4' ></div>
        )
      }
    </div>
  )
}

export default MenuItem
