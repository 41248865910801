import React from 'react'
import LabelForm from 'components/LabelForm'
import IconUser from 'assets/icons/icon_user.svg';
import Input from 'components/input'
import { useTranslation } from 'react-i18next'
import InputRadio from 'components/InputRadio';

interface Props {
  register: any
  watch: any
  handleSelect: (e: any) => void
  gender: any
}
const FormPersonalSection: React.FC<Props> = ({
  register,
  watch,
  handleSelect,
  gender
}) => {
  const { t } = useTranslation()

  return (
  <>
    <LabelForm icon={IconUser} label="Datos personales" className='mt-6 mb-4' />
    <div className={`flex flex-col sm:flex-row	sm:grid sm:grid-cols-3 gap-y-7 gap-x-2`} >
      <div className="col">
        <Input
          type="text"
          typeValidation='text'
          required
          label={t('name')}
          value={watch('name')}
          {...register('name')}
        />
      </div>
      <div className="col">
        <Input
          type="text"
          typeValidation='text'
          label={t('last_name')}
          value={watch('lastname')}
          required
          {...register('lastname')}
        />
      </div>
      <div className="col">
        <Input
          type="email"
          typeValidation='email'
          required
          label={t('enter_mail')}
          value={watch('email')}
          {...register('email')}
        />
      </div>
      <div className="col">
        <Input
          type="number"
          typeValidation='phone'
          label={t('cellphone_number')}
          value={watch('phone')}
          {...register('phone')}
        />
      </div>

        <div className="col relative pt-[10px]">
          <label className="text-subtitle2 mb-2 absolute top-[-16px] left-[3px]">
            {t('sex')}
          </label>
          <InputRadio
            data={[{ name: t('male'), value: 'M' }, { name: t('feminine'), value: 'F' }]}
            onSelected={handleSelect}
            height="48px"
            selected={gender}
            className={'flex flex-col sm:flex-row	'}
          />
        </div>
    </div>
  </>
)}

export default FormPersonalSection