import { useQuery } from 'react-query'
import getDiagnosticos from '../services/getDiagnosticos'
import { diagnostico } from '../types/diagnosticosType'

function useGetDiagnosticos(searchedValue = 'conva') {
  const queryKey = 'usegetDiagnosticos'
  return useQuery<{ data: diagnostico[] }>(queryKey, () => getDiagnosticos(searchedValue))
}

export default useGetDiagnosticos
