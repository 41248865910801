import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { routes } from 'routes/routing'
import { TEMP_COUNTRY_DEFAULT } from 'global/constants'

import useGetSelectOptions from 'features/shared/hooks/useGetSelectOptions'
import useUser from 'features/shared/hooks/useUser'
import { useUpdatePatient } from 'features/shared/hooks/usePostRegisterPatient'

import ButtonToggle from 'components/ButtonToggle'
import WorkPlan from 'components/WorkPlan'
import Button from 'components/Button'
import Breadcrumbs from 'components/Breadcrumbs'

import Loader from '../../../components/Loader'
import FormPatient from '../../../components/FormPatient'
import FormResponsibleOrCompanion from '../../../components/FormResponsibleOrCompanion'
import FormService from '../../../components/FormService'
import FormCheap from '../../../components/FormCheap'

import { useGetPatientData } from '../../shared/hooks/useGetPatientFullData'
import usePostRegisterResponsible from '../../shared/hooks/usePostRegisterResponsible'
import usePutRegisterResponsible from '../../shared/hooks/usePutRegisterResponsible'
import usePostRegisterService from '../../shared/hooks/usePostRegisterService'
import usePutRegisterService from '../../shared/hooks/usePutRegisterService'
import usePostRegisterEconomic from '../../shared/hooks/usePostRegisterEconomic'
import usePutRegisterEconomic from '../../shared/hooks/usePutRegisterEconomic'
import { onAlert } from 'components/Alert';
import {
  PatientModel,
  PatientResponsibleModel,
  PatientServiceModel,
} from '../models/Patient.model'

import IconPlusCircle from 'assets/icons/icon_plus_circle.svg'
import IconChevronDown from 'assets/icons/icon_chevron_down.svg'
import IconChevronUp from 'assets/icons/icon_chevron_up.svg'
import IconFile from 'assets/icons/icon_file.svg'
import IconInfo from 'assets/icons/icon_info.svg'

interface RouteParams {
  doumentPatient: string
}

const Patient: React.FC<any> = (props) => {
  const history = useHistory()
  const params = useParams<RouteParams>()
  const [allDataPatient, setAllDataPatient] = useState<PatientModel>()
  const [patientService, setPatientService] = useState<PatientServiceModel>()
  const [sectionStepFormPatient, setSectionStepFormPatient] = React.useState(0)

  const { mutateAsync, isLoading } = useUpdatePatient()
  const { mutateAsync: asynGetPatientData, isLoading: loadingPatientData } =
    useGetPatientData()
  const postRegisterResponsible = usePostRegisterResponsible()
  const postRegisterService = usePostRegisterService()
  const postRegisterEconomic = usePostRegisterEconomic()
  const putRegisterEconomic = usePutRegisterEconomic()
  const putRegisterResponsible = usePutRegisterResponsible()
  const putRegisterService = usePutRegisterService()
  const { userCurrent } = useUser()
  let TEMP_COUNTRY = userCurrent?.ipress?.countryId
    ? userCurrent?.ipress?.countryId
    : TEMP_COUNTRY_DEFAULT
  const getSelectOptions = useGetSelectOptions(TEMP_COUNTRY)
  const selectOptions = getSelectOptions?.data?.data
  const { t } = useTranslation()
  const [serviceid, setServiceid] = useState() 
  const [newCompanionPatient, setNewCompanionPatient] = useState<boolean>(false)
  const [companionPatientActive, setCompanionPatientActive] =
    useState<number>(0)

  const [step, setStep] = useState(1)

  useEffect(() => {
    onGetPatient()
  }, [])

  const onGetPatient = async () => {
    const dataPatient = await asynGetPatientData(params.doumentPatient)

    if (dataPatient?.data) {
      setAllDataPatient(dataPatient.data)
      setPatientService(dataPatient.data.service)
    }

    if (dataPatient?.data?.id && dataPatient?.responsibles?.length === 0)
      setNewCompanionPatient(true)
  }

  const onSubmitPatient = async (data: any) => {
    const responseUpdatePatient = await mutateAsync({
      data,
      id: params.doumentPatient,
    })
    if (Number.isInteger(responseUpdatePatient?.data)) {
      onAlert.success('Paciente actualizado');
      const userData = { ...allDataPatient, ...data }
      setAllDataPatient(userData)
    } else {
      if (responseUpdatePatient?.data) {
        onAlert.error(responseUpdatePatient?.data);
      } else {
        onAlert.error(responseUpdatePatient);
      }
    }
  }

  const onSubmitResponsible = async (
    data: any,
    isEdit: boolean,
    responsibleId: number
  ) => {
    let responsibletDataSend;
    let textSuccess;
    let responsibles: PatientResponsibleModel[] = allDataPatient?.responsibles
      ? allDataPatient?.responsibles
      : []

    if (isEdit) {
      textSuccess='Responsable actualizado';
      let objPut = { patientId: allDataPatient?.userId, ...data }

      responsibletDataSend = await putRegisterResponsible.mutateAsync({
        patientId: allDataPatient?.userId,
        responsibleId,
        data: objPut,
      })

      responsibles = responsibles.map((e: PatientResponsibleModel) =>
        e.id === responsibleId ? { ...e, ...data } : e
      )
    } else {
      textSuccess='Registro exitoso';
      let objPost = { patientId: allDataPatient?.userId, ...data }

      responsibletDataSend = await postRegisterResponsible.mutateAsync(objPost)
      responsibles?.push({ ...data, id: responsibletDataSend?.data })
    }

    if (responsibles && allDataPatient)
      setAllDataPatient({ ...allDataPatient, responsibles })

    if (Number.isInteger(responsibletDataSend?.data)) {
      onAlert.success(textSuccess);
      onGetPatient()
    } else {
      if (responsibletDataSend?.data) {
        onAlert.error(responsibletDataSend?.data);
      } else {
        onAlert.error(responsibletDataSend);
      }
    }
    setNewCompanionPatient(false)
  }

  const onSubmitServicio = async (data: any) => {
    let textSuccess;
    let registerService;

    if (allDataPatient?.service) {
      textSuccess='Servicio actualizado';

      registerService = await putRegisterService.mutateAsync({
        patientId: allDataPatient?.userId,
        serviceId: allDataPatient?.service?.id,
        data: { ...data },
      })
      /*if (!allDataPatient.service?.eventNumber) {
        onGetPatient()
      }*/

    } else {
      textSuccess='Servicio registrado';

      registerService = await postRegisterService.mutateAsync({
        patientId: allDataPatient?.userId,
        ...data,
      })
      //onGetPatient()
    }

    if (registerService?.data) {
      setServiceid(registerService?.data)
      onGetPatient()
      onAlert.success(textSuccess);
    } else {
      if (registerService?.data) {
        onAlert.error(registerService?.data);
      } else {
        onAlert.error(registerService);
      }
    }
  }

  const onSubmitEconomicos = async (data: any) => {
    if (allDataPatient?.service) {
      let objPost = {
        serviceId: serviceid ? serviceid : allDataPatient?.service?.id,
        ...data,
      }

      let objPut = { ...data };
      let textSuccess;
      let patientDataSend;

      if (allDataPatient?.service?.economic) {
        textSuccess='Datos económicos registrados';
        patientDataSend = await putRegisterEconomic.mutateAsync({
          patientId: allDataPatient?.userId,
          serviceId: allDataPatient?.service?.id,
          data: objPut,
        })
      } else {
        textSuccess='Datos económicos actualizados';
        patientDataSend = await postRegisterEconomic.mutateAsync(objPost)
      }

      onGetPatient()
      if (Number.isInteger(patientDataSend?.data)) {
        onAlert.success(textSuccess);
      } else {
        if (patientDataSend?.data) {
          onAlert.error(patientDataSend?.data);
        } else {
          onAlert.error(patientDataSend);
        }
      }
    
    }
  }

  return (
    <React.Fragment>
      {(loadingPatientData ||
        isLoading ||
        postRegisterResponsible.isLoading ||
        postRegisterService.isLoading ||
        postRegisterEconomic.isLoading ||
        putRegisterEconomic.isLoading ||
        putRegisterResponsible.isLoading ||
        putRegisterService.isLoading ||
        getSelectOptions.isLoading) && <Loader />}

      <Breadcrumbs
        name={`${allDataPatient?.name?  allDataPatient?.name :'' }
        
        ${allDataPatient?.lastname?  allDataPatient?.lastname :''}`}
        prevPathName="Lista de pacientes"
        currentPathName={`${allDataPatient?.name?  allDataPatient?.name :'' }
        
        ${allDataPatient?.lastname?  allDataPatient?.lastname :''}`}
        onClickPrev={() => {
          history.push(`${routes.patients.url}`)
        }}
        onClickHome={() => {
          history.push(`/`)
        }}
      />

      <div className="container px-1 py-5 mt-4">
        <div
          data-testid="Add"
          className="w-full flex flex-col  min-h-screen  justify-start"
        >
          <div className="flex justify-start gap-2 mb-8 overflow-x-auto overflow-y-none">
            {[
              { name: 'Datos del paciente', disabled: false,
              dataTestId:'btn-patient-data' },
              { name: 'Datos del responsable', disabled: false,
              dataTestId:'btn-person-charge' },
              { name: 'Datos del servicio', disabled: false,
              dataTestId:'btn-service-data' },
              {
                name: 'Datos económicos',
                disabled: allDataPatient && (!allDataPatient.service?.eventNumber || allDataPatient.service?.homeEnabled === 0),
                dataTestId:'btn-economic-data'
              },
              {
                name: 'Plan de Trabajo',
                disabled: allDataPatient && (!allDataPatient.service?.eventNumber || allDataPatient.service?.homeEnabled === 0),
                dataTestId:'btn-workplan'
              },
            ].map((item: any, index) => (
              <Button
                key={index}
                type="button"
                size="small"
                variant={step === index + 1 ? 'primary' : 'secondary'}
                onClick={() => {
                  setStep(index + 1)
                }}
                data-testid={item.dataTestId}
                className="whitespace-nowrap"
                disabled={item.disabled}
              >
                {item.name}
              </Button>
            ))}
          </div>

          <div className="w-full bg-white py-6 px-4 rounded-[16px] shadow-md">
            <div className={`${step === 1 ? '' : 'hidden'} `}>
              <div className="flex flex-wrap gap-2">
                <ButtonToggle
                  icon={IconInfo}
                  isActive={sectionStepFormPatient === 0}
                  onClick={() => setSectionStepFormPatient(0)}
                >
                  Información Personal
                </ButtonToggle>

                <ButtonToggle
                  icon={IconFile}
                  isActive={sectionStepFormPatient === 1}
                  onClick={() => setSectionStepFormPatient(1)}
                >
                  Información de Contacto
                </ButtonToggle>
              </div>
              {allDataPatient && (
                <FormPatient
                  setSectionStepFormPatient={setSectionStepFormPatient}
                  formStep={sectionStepFormPatient}
                  isEdit={true}
                  selectOptions={selectOptions}
                  dataPatient={allDataPatient}
                  onSubmit={onSubmitPatient}
                />
              )}
            </div>
            <div className={`${step === 2 ? '' : 'hidden'} `}>
              <div className="flex flex-wrap gap-2 justify-between items-center">
                <span className="text-subtitle1">Acompañantes</span>
                <ButtonToggle
                  icon={IconPlusCircle}
                  isActive={!newCompanionPatient}
                  disabled={newCompanionPatient}
                  onClick={() => setNewCompanionPatient(true)}
                >
                  Añadir acompañante
                </ButtonToggle>
              </div>

              {newCompanionPatient ? (
                <FormResponsibleOrCompanion
                  dataPatient={allDataPatient}
                  isEdit={false}
                  data={null}
                  selectOptions={selectOptions}
                  onSubmit={(data: any) => onSubmitResponsible(data, false, 0)}
                  onCancel={() => setNewCompanionPatient(false)}
                />
              ) : (
                <>
                  {allDataPatient &&
                    allDataPatient?.responsibles?.map(
                      (item: PatientResponsibleModel, index: number) => (
                        <div
                          className="border-solid border-[1px] border-grey-200 p-4 rounded-lg mt-6"
                          key={item.id}
                        >
                          <div
                            className={`
                            border-b-solid border-b-[1px] border-b-grey-200 flex items-center justify-between cursor-pointer pb-4
                            ${
                              companionPatientActive === index
                                ? 'pointer-events-none'
                                : ''
                            }
                            ${
                              companionPatientActive === index
                                ? ''
                                : 'border-none pb-[0px]'
                            }
                          `}
                            onClick={() => {
                              setCompanionPatientActive(index)
                            }}
                          >
                            <span className="text-body1">
                              {item.name} {item.lastname}
                            </span>

                            {companionPatientActive === index ? (
                              <img src={IconChevronUp} alt="more"  width={24} height={24} />
                            ) : (
                              <img src={IconChevronDown} alt="more" width={24} height={24} />
                            )}
                          </div>

                          {companionPatientActive === index && (
                            <FormResponsibleOrCompanion
                              dataPatient={allDataPatient}
                              isEdit={true}
                              data={item}
                              selectOptions={selectOptions}
                              onSubmit={(data: any) =>
                                onSubmitResponsible(data, true, item?.id)
                              }
                              onCancel={() => {}}
                            />
                          )}
                        </div>
                      )
                    )}
                </>
              )}
            </div>
            <div className={`${step === 3 ? '' : 'hidden'} `}>
              {allDataPatient && (
                <FormService
                  allDatapatient={allDataPatient}
                  medicoCampo={[]}
                  setStep={() => {}}
                  onSubmit={onSubmitServicio}
                  onReloadPatient={() => {
                    onGetPatient()
                  }}
                  pacienteId={allDataPatient?.userId}
                  selectOptions={selectOptions}
                />
              )}
            </div>
            <div className={`${step === 4 ? '' : 'hidden'} `}>
              <FormCheap
                selectOptions={selectOptions}
                allDatapatient={allDataPatient}
                setStep={() => {}}
                onSubmit={onSubmitEconomicos}
              />
            </div>
            <div className={`${step === 5 ? '' : 'hidden'} `}>
              <WorkPlan patientData={allDataPatient} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Patient

