import { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useNotificationContext } from 'features/Notifications/hooks'
import { useMarkNotificationAsRead } from 'features/Notifications/services'
import useUser from 'features/shared/hooks/useUser'

import { routes } from 'routes/routing'
import { getName, getLastname, getHeaderDate } from '../utils'

import { NotificationsModalProps, NotificationModalCardProps } from './types'
import { useCreateNotificationCallPatient } from 'features/ClinicHistory/hooks/useNotification'
import { onAlert } from 'components/Alert'

export const NotificationModalCard: FC<NotificationModalCardProps> = ({
  notification,
}) => {
  const history = useHistory()
  const { userCurrent } = useUser()
  const {
    setOpenVideoCall,
    setSelectedNotification,
    setOpenDeviceReport,
    setShowModalError,
    setTextModalError,
  } = useNotificationContext()

  const onErrorMarkAsRead = () => {
    setSelectedNotification(null)
  }

  const onSettledMarkAsRead = () => {
    switch (notification?.notificationType) {
      case 'call':
        const patientId = notification.data.body.patientId
        const userId = userCurrent?.id
        if (patientId && userId) {
          createNotificationPatient({ patientId, userId })
            .then(() => setOpenVideoCall(true))
            .catch(() => {
              onAlert.error(
                'Hubo un error al realizar la llamada. El paciente aún no se ha registrado en la plataforma.'
              )
            })
        }
        break
      case 'medication':
        history.push(
          `${routes.clinicHistory.url}/patient/${notification.data.body.patientId}/daily-evolution?focusMessage=true`
        )
        break
    }
  }

  const { markNotificationAsRead } = useMarkNotificationAsRead({
    onErrorCb: onErrorMarkAsRead,
    onSettledCb: onSettledMarkAsRead,
  })

  const { mutateAsync: createNotificationPatient } =
    useCreateNotificationCallPatient()
  const handleCallNotificationClick = () => {
    setSelectedNotification(notification)
    markNotificationAsRead(`${notification.notificationId}`)
  }

  const handleDeviceReportNotificationClick = () => {
    setSelectedNotification(notification)
    setOpenDeviceReport(true)
  }

  const handleMedicationNotificationClick = () => {
    setSelectedNotification(null)
    markNotificationAsRead(`${notification.notificationId}`)
  }

  const getAction = () => {
    switch (notification.notificationType) {
      case 'call':
        return handleCallNotificationClick
      case 'medication':
        return handleMedicationNotificationClick
      case 'report':
        return handleDeviceReportNotificationClick
    }
  }
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const date = getHeaderDate(notification.createdAt)
  return (
    <div
      className={
        'flex flex-row justify-between items-center w-full py-2 px-4 border-b-[1px] border-[#F6F6F6] hover:bg-gray-200 cursor-pointer'
      }
      onClick={getAction()}
    >
      <div className={'flex flex-row items-center gap-2'}>
        <div
          className={
            'rounded-full bg-[#DFF5FF] text-[#008EA3] w-8 h-8 flex justify-center items-center font-semibold'
          }
        >
          {name.charAt(0)}
          {lastname.charAt(0)}
        </div>
        <div className={'flex flex-col'}>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
          <span className={'max-w-[175px] text-caption text-[#545454]'}>
            {notification.data.title}
          </span>
        </div>
      </div>
      <span className={'text-caption text-[#ADADAD]'}>{date}</span>
    </div>
  )
}

export const NotificationsModal: FC<NotificationsModalProps> = ({
  totalNotifications = 0,
  notifications = [],
}) => {
  return (
    <div
      className={`left-[-41vw] w-[330px] sm:left-[inherit] sm:right-[0px] sm:w-[380px] top-[32px] flex flex-col overflow-x-hidden overflow-y-auto z-50 outline-none focus:outline-none absolute  shadow-2xl rounded-2xl bg-white box-border`}
    >
      <div className="flex flex-col w-full">
        <div className="w-full outline-none focus:outline-none overflow-hidden">
          <div className="border-b-[1px] border-[#F6F6F6] flex justify-between items-center p-4 mb-6">
            <span className="text-left text-Fs8 font-medium">
              Notificaciones
            </span>
            {notifications.length > 0 ? (
              <div
                className={
                  'text-caption text-[#008EA3] bg-[#E2FBFF] rounded-2xl py-1 px-3'
                }
              >{`${totalNotifications} nuevos`}</div>
            ) : null}
          </div>
          <div className={'mb-6'}>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <NotificationModalCard
                  key={notification.notificationId}
                  notification={notification}
                />
              ))
            ) : (
              <div className={'flex flex-row justify-center'}>
                <span className={'text-body1 text-[#545454]'}>
                  Aún no tienes notificaciones
                </span>
              </div>
            )}
          </div>
          <div className={'w-full p-4'}>
            {notifications.length > 0 ? (
              <Link to={routes.notifications.url}>
                <button className="w-full text-Fs8 text-white text-body1 py-4 bg-[#008EA3] rounded-3xl h-[48px] flex justify-center items-center cursor-pointer">
                  Ver todas las notificaciones
                </button>
              </Link>
            ) : (
              <button className="w-full text-Fs8 text-[#ADADAD] text-body1 py-4 bg-[#F6F6F6] rounded-3xl h-[48px] flex justify-center items-center cursor-not-allowed">
                Ver todas las notificaciones
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

