import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Input from '../input'
import useUser from 'features/shared/hooks/useUser'
import { TEMP_COUNTRY_DEFAULT } from 'global/constants'
import IconUser from 'assets/icons/icon_user.svg';
import IconPhone from 'assets/icons/icon_phone.svg';

import LabelForm from 'components/LabelForm'
import InputSelect from 'components/InputSelect'
import InputRadio from 'components/InputRadio'
import Button from 'components/Button'

interface Props {
  isEdit: boolean;
  onSubmit: (value: any) => any;
  onCancel?: () => void;
  dataPatient: any;
  data: any;
  selectOptions: any
}

const FormResponsibleOrCompanion: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [assignAsCaregiver, setAssignAsCaregiver] = useState(false);

  const { register, watch, setValue, handleSubmit, control, formState: { errors } } = useForm();
  const responsible = props.data;

  const { userCurrent } = useUser();

  let TEMP_COUNTRY = userCurrent?.ipress?.countryId ? userCurrent?.ipress?.countryId : TEMP_COUNTRY_DEFAULT;
  let documentTypes = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "document_types");
  let relationship = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "relationship");

  const getValeidate = () => {
    if (
      watch('documenttype') &&
      watch('documentnumber') &&
      watch('name') &&
      watch('lastname') &&
      watch('relationship') &&
      watch('mobilePhone') &&
      watch('email')) {
      return true
    } else {
      return false
    }
  }
  const onSubmit = () => {
    let obj = {
      "document": watch('documentnumber'),
      "documentType": watch('documenttype'),
      "name": watch('name'),
      "lastname": watch('lastname'),
      "relationship": watch('relationship'),
      "mobilePhone": watch('mobilePhone'),
      "landline": watch('landline'),
      "email": watch('email'),
      "status": watch('enableDisable'),
      "isCaregiver": assignAsCaregiver
    }
    props.onSubmit(obj)
  }

  useEffect(() => {
    if (responsible && props.isEdit) {
      setValue('documenttype', responsible?.documentType);
      setValue('documentnumber', responsible?.document);
      setValue('name', responsible?.name);
      setValue('lastname', responsible?.lastname);
      setValue('relationship', responsible?.relationship);
      setValue('mobilePhone', responsible?.mobilePhone);
      setValue('landline', responsible?.landline);
      setValue('email', responsible?.email);
      setValue('enableDisable', responsible?.responsibleStatus !== 0);

      setAssignAsCaregiver(responsible?.isCaregiver !== 0)
    }

  }, [responsible]);

  return (
    <section data-testid="FormTypeB">
      <div className="container px-1 pt-5">
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-3 gap-4 items-center">

            <div className='flex justify-between items-center mb-4 row-span-1 col-span-full'>
              <LabelForm icon={IconUser} label="Datos personales" className='grow' />

              <label className="text-subtitle2 mr-4">
                Habilitar y deshabilitar
              </label>
              <label htmlFor="checked-toggle" className="inline-flex relative items-center cursor-pointer">
                <input
                  {...register('enableDisable')}
                  type="checkbox"
                  id="checked-toggle"
                  className="sr-only peer" />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-none dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-400 peer-checked:bg-primary-dark"></div>
              </label>
            </div>

            <Controller
              control={control}
              name='documenttype'
              render={({ field: { onChange, value, name, ref } }) => (
                <InputSelect
                  label={t('document_type')}
                  name="documenttype"
                  required
                  onSelect={val => {
                    onChange(val);
                  }}
                  value={value}
                  ref={ref}
                >
                  {documentTypes?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))}
                </InputSelect>
              )}
            />

            {TEMP_COUNTRY === 'PE' ?
              <Input
                type="text"
                typeValidation="number"
                required
                label={t('document_number')}
                minLength={8}
                maxLength={
                  watch('documenttype') === 'dni' ? 8 : watch('documenttype') === 'passport' ? 9 : 12
                }
                value={watch('documentnumber')}
                {...register("documentnumber", { required: true, maxLength: 8 })}
              />
              :
              <Input
                type="text"
                typeValidation='number'
                required
                label={t('document_number')}
                minLength={5}
                maxLength={watch('documenttype') === 'passport' ? 8 : 10}
                value={watch('documentnumber')}
                {...register('documentnumber')}
              />
            }

            <Input
              type="text"
              typeValidation='text'
              required
              label={t('names')}
              value={watch('name')}
              {...register('name')}
            />
            <div className="col pt-[25px]">

            <Input
              type="text"
              typeValidation='text'
              label={t('lasname')}
              required
              value={watch('lastname')}
              {...register('lastname')}
            />
             </div>
            <div className="col pt-[25px]">
              <Controller
                control={control}
                name='relationship'
                render={({ field: { onChange, value, name, ref } }) => (
                  <InputSelect
                    label={t('relationship')}
                    name="relationship"
                    required
                    onSelect={val => {
                      onChange(val);
                    }}
                    value={value}
                    ref={ref}
                  >
                    {relationship?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))}
                  </InputSelect>
                )}
              />
            </div>
            <div>
              <label className="text-subtitle2 mb-2">
                {t('Assign_as_caregiver')}
              </label>
              <InputRadio
                data={[{ name: t('yes'), value: true }, { name: t('not'), value: false }]}
                onSelected={(val: any) => {
                  setAssignAsCaregiver(val)
                }}
                selected={assignAsCaregiver}
              />
            </div>

            <LabelForm icon={IconPhone} label="Datos de contacto" className='mt-6 mb-4 row-span-1 col-span-full' />

            <Input
              type="text"
              typeValidation='number'
              maxLength={9}
              label={t('landline_phone')}
              value={watch('landline')}
              {...register('landline')}
            />

            <Input
              type="text"
              typeValidation='number'
              required
              maxLength={TEMP_COUNTRY === 'PE' ? 9 : 10}
              label="Teléfono móvil"
              value={watch('mobilePhone')}
              {...register('mobilePhone')}
            />

            <Input
              type="email"
              typeValidation='email'
              required
              label={t('enter_mail')}
              value={watch('email')}
              {...register('email')}
            />

            <div className="flex justify-center gap-4 mt-4 lg:row-span-1 lg:col-span-full">
              <Button type="button" variant='secondary' size="extra-large"  onClick={props.onCancel} >Cancelar</Button>
              <Button type="submit" variant='primary' size="extra-large"  disabled={!getValeidate()} >Guardar</Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default FormResponsibleOrCompanion
