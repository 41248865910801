import React from 'react'
import LabelForm from 'components/LabelForm'
import { ShadowWrapper } from 'components/ShadowWrapper'
import Button from 'components/Button'
import Table from '../Table'
import Pagination from 'components/Pagination'
import Filter from '../Filter'
import IconAdd from 'assets/icons/icon_add.svg'
import Loader from 'components/Loader'
import LoaderMini from 'components/LoaderMini'

interface Props {
  handleChangePage?: Function
  handleChangeSearchInput?: React.ChangeEventHandler<HTMLInputElement>
  users: any
  handleChangeFilters?: Function
  handleGetRoles?: Function
  handleGetSpecialities?: Function
  handleClickAdd?: () => void
  isLoading?: boolean
  isRole?: boolean
  isSpecialty?: boolean
  onGetListUsers?:() => void
}

const ListUser: React.FC<Props> = ({
  handleChangePage,
  handleChangeSearchInput,
  users,
  handleChangeFilters,
  handleGetRoles,
  handleGetSpecialities,
  handleClickAdd,
  isLoading,
  isRole,
  isSpecialty,
  onGetListUsers
}) => {
  return (
    <ShadowWrapper className='mt-6 px-0'>
      <div className='flex p-4'>
        <div className='flex flex-col xl:flex-row	flex justify-end  items-center 	w-full gap-8 xl:gap-0	' >
          <LabelForm className='whitespace-nowrap	' label='Usuarios'><span>| {users?.total}</span></LabelForm>
          <Button
            type="submit"
            variant='primary'
            onClick={handleClickAdd}
            className='flex w-full xl:w-[260px]	 items-center ml-4 '>
            <img src={IconAdd} alt='agregar' />
            <span className='ml-2 whitespace-nowrap	'>Nuevo usuario</span>
          </Button>
          <Filter
            handleChangeSearchInput={handleChangeSearchInput}
            getFilters={handleChangeFilters}
            getRoles={handleGetRoles}
            getSpecialities={handleGetSpecialities}
            isRole={isRole}
            isSpecialty={isSpecialty}
          />
        </div>
      </div>
    
          <div className='relative'>
            
          {isLoading && <LoaderMini />}
            <div className=" w-full overflow-auto	 scrollbar">
            
              <Table
                isSpecialty={isSpecialty}
                data={users?.data}
                onSubmit={e => console.log(e)}
                onGetListUsers={onGetListUsers}

              />
            </div>

            <div
              className='flex justify-end mt-[24px] px-4'
            >{users?.currentPage &&
              <Pagination
                currentPage={users?.currentPage}
                totalCount={users?.totalPages}
                pageSize={1}
                onPageChange={handleChangePage}
              /> }
            </div>
          </div>
    </ShadowWrapper>
  )
}

export default ListUser