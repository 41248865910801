import React, { useState, useEffect } from 'react'
import InputSelect from 'components/InputSelect'
import { useTranslation } from 'react-i18next'
import { useGetListSpeciality } from 'components/WorkPlan/hooks/useSpecialist';

interface Props {
  isRequired?: boolean
  classNameWrapper?: string
  inputRef?: any
  onChange?: (val: any) => any
  value?: any
  getSpecialities?: Function
}

const SelectTypeSpeciality: React.FC<Props> = ({
  isRequired,
  classNameWrapper,
  inputRef,
  onChange,
  value,
  getSpecialities
}) => {
  const { t } = useTranslation()
  const [specialities, setSpecialities] = useState([])

  const { mutateAsync: asyncGetListSpeciality } = useGetListSpeciality();

  const onGetUserSpecialities = async () => {
    const response = await asyncGetListSpeciality()
    if (response?.data)
      setSpecialities(response?.data)
  }

  useEffect(() => {
    onGetUserSpecialities()
  }, [])

  useEffect(() => {
    if (specialities && specialities.length > 0) {
      getSpecialities && getSpecialities(specialities)
    }
  }, [specialities])

  return (
    <InputSelect
      label={t('type_speciality')}
      inputRef={inputRef}
      name={'specialityId'}
      value={value}
      onSelect={onChange}
      placeholder='Rol'
      required={isRequired}
      classNameWrapper={classNameWrapper}
    >
      {
        specialities && specialities.length > 0 && specialities.map((speciality: any, i: any) => (
          <option key={i} value={speciality?.value}>{speciality?.label}</option>
        ))
      }
    </InputSelect>
  )
}

export default SelectTypeSpeciality