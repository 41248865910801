import fetcher from 'features/shared/utils/fetcher'
import { ClinicNetworkSearchModel } from '../types/clinicNetwork'

export async function getClinicNetworks(countryId?: string) {
  return await fetcher.get(`/clinic-networks?countryId=${countryId}`)
}

export async function getClinicNetworksSearchResults(query: string) {
  return await fetcher.get<{data: ClinicNetworkSearchModel}>('/backoffice/clinic-networks' + query)
}

export async function createClinicNetwork(dataSend: any) {
  return await fetcher.post('/backoffice/clinic-networks', dataSend)
}

export async function getClinicsNetworkById(id: string) {
  return await fetcher.get(`/backoffice/clinic-networks/${id}`)
}

export async function updateClinicNetwork(data: any) {
  const duplicated = {...data}
  delete duplicated.id
  if (!duplicated?.documentTermsCondition) {
    delete duplicated.documentTermsCondition
  }
  if (!duplicated?.documentPrivacyPolicy) {
    delete duplicated.documentPrivacyPolicy
  }
  if (!duplicated?.documentInformedConsent) {
    delete duplicated.documentInformedConsent
  } 
  return await fetcher.put(`backoffice/clinic-networks/${data.id}`, duplicated)
}