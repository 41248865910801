import { useMutation, useQuery } from 'react-query'
import getPatientFullData from '../services/getPatientFullData'

export function useGetPatientFullData(id:string) {
  const queryKey = 'useGetPatientFullData'
  
  return useQuery(queryKey, () => getPatientFullData(id))
}

export function useGetPatientData() {
  return useMutation((id: string) => getPatientFullData(id));
}
