import Button from 'components/Button';
import InputField from 'components/input';
import InputDatePicker from 'components/InputDatePicker';
import InputFile from 'components/InputFile';
import InputRadio from 'components/InputRadio';
import InputSelect from 'components/InputSelect';
import CardUser from 'components/cardUser';
import KebabMenu from 'components/kebabMenu';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import IconCheck from '../shared/img/Icon.png';
import IconEdit from '../../assets/icons/icon_edit.svg';
import IconBox from '../../assets/icons/icon_box.svg';
import Modal from 'components/modal';
import MapPolygon from 'components/MapPolygon';

const UI = () => {
  const [selectValue, setSelectValue] = useState<any>('');
  const [dateSelect, setDateSelect] = useState(new Date());
  const [inputValue, setInputValue] = useState();

  return (
    <div className='w-full h-full bg-white p-4'>
      <h1 className='text-headline3 text-text-primary'>Vista de componentes</h1> <br />

      <div className=''>
        <h2 className='text-subtitle1'>buttons</h2>
        <hr />

        <br /> <br />

        {/*<MapPolygon
          showModal={true}
          onCloseModal={() => {}}
        />*/}

        <Button className='mr-6' variant='primary' size="large" > Primary Large </Button>
        <Button className='' variant='primary' > Primary Small </Button>

        <br /> <br />

        <Button className='mr-6' size="large" variant='primary' disabled > Primary disabled </Button>
        <Button className='' variant='primary' disabled > Primary disabled </Button>

        <br /> <br /><br /> <br />

        <Button className='mr-6' variant='secondary' size="large" > Secondary Large </Button>
        <Button className='' variant='secondary' > Secondary Small </Button>

        <br /> <br />

        <Button className='mr-6' size="large" variant='secondary' disabled > Secondary disabled </Button>
        <Button className='' variant='secondary' disabled > Secondary disabled </Button>

        <br /> <br />

        <Button className='mr-6' size="large" variant='tertiary' > Tertiary Large </Button>
        <Button className='' variant='tertiary' > Tertiary small </Button>

        <br /> <br />

        <Button className='mr-6' size="large" variant='tertiary' disabled > Tertiary disabled </Button>
        <Button className='' variant='tertiary' disabled > Tertiary disabled </Button>

        <br /> <br />

        <Button className='mr-6' size="large" variant='negative' > Negative Large </Button>
        <Button className='' variant='negative' > Negative small </Button>

        <br /> <br />

        <Button className='mr-6' size="large" variant='negative' disabled > Negative disabled </Button>
        <Button className='' variant='negative' disabled > Negative disabled </Button>

        <br /> <br />
        <br /> <br />
        <h2 className='text-subtitle1'>Inputs</h2>
        <hr />
        <br /> <br />

        <InputField
          label="Insert text"
          type='text'
          errorText="Error"
          helperText="solo text"
          required
        />
        <br /> <br />
        <InputField
          label="Input Icon"
          helperText="solo text"
          required
          icon={IconCheck}
          onClickIcon={() => { console.log('click icon'); }}
        />
        <InputField
          label="Input Icon"
          helperText="solo text"
          required
          icon={IconCheck}
        />
        <br /> <br />
        <InputField
          label="Insert number"
          type='number'
          errorText="Error"
          helperText="solo number"
        />
        <br /> <br />
        <InputField
          label="Insert email"
          type='email'
          errorText="Error"
          helperText="solo email"
          required
        />

        <br /> <br />
        <br /> <br />
        <h2 className='text-subtitle1'>Select</h2>
        <hr />

        <br /> <br />
        <br /> <br />

        <InputSelect
          label="Tipo de documento"
          name="tipoDocumento"
          required={false}
          onSelect={val => {
            setSelectValue(val);
          }}
          value={selectValue}
        >
          <option value={1} >Option 1</option>
          <option value={2} >Option 2</option>
          <option value={3} >Option 3</option>
          <option value={4} >Option 4</option>
        </InputSelect>

        <br /> <br />
        <InputDatePicker
          label='Fecha de nacimiento'
          onSelect={(date: Date) => { setDateSelect(date) }}
          selected={dateSelect}
          dateFormat="dd/MM/yyyy"
          locale="es"
        />

        <br /> <br />
        <InputRadio
          data={[{ name: 'option1', value: 1 }, { name: 'option 2', value: 2 }, { name: 'option 3', value: 3 }]}
          onSelected={(val: any) => {
            setInputValue(val)
          }}
          selected={inputValue}
        />
        <br /> <br />

        <InputFile
          label="Input Field"
          type="file"
          helperText="solo text"
          required
          handleChange={(e: any) => { console.log('on Select>>>>', e.target.files[0]); }}
        />
        <br />
        <CardUser
         showBotton={true}
         showBtn={true}
          user={{
            name: 'Herber Silva',
            description: 'Medico general',
            avatar: 'https://images.pexels.com/photos/2589653/pexels-photo-2589653.jpeg?auto=compress&cs=tinysrgb&h=650&w=940'
          }} onClick={() => { console.log('click CardUser'); }}
        />
       
        <br />
        <div className="flex items-center justify-center h-50">
        <KebabMenu
          amount={'6'}
          items={[{icon:IconEdit,name:'Eliminar', onClick:() => { console.log('KebabMenu')  },amount:false }
        ,{icon:IconBox,name:'Ir a almacen', onClick:() => { console.log('KebabMenu 2') },amount:true }
        ]}
        />
        </div>
      
       
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      </div>
    </div>
  )
};


export default UI;