import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import InputSelect from 'components/InputSelect'
import { useGetCountries } from 'features/UserManagement/hooks/useCountry';
import { useTranslation } from 'react-i18next'

interface Props {
  isRequired?: boolean
  classNameWrapper?: string
  control: any
  getItem?: Function
}

const SelectTypeCountry: React.FC<Props> = ({
  isRequired,
  classNameWrapper,
  control,
  getItem
}) => {
  const { t } = useTranslation()
  const [countries, setCountries] = useState([])

  const { mutateAsync: asyncGetCuntries } = useGetCountries()

  const onGetCountries = async() => {
    const response = await asyncGetCuntries()
    if (response?.data) {
      setCountries(response?.data)
    }
  }

  useEffect(() => {
    onGetCountries()
  }, [])

  return (
    <Controller
      control={control}
      name='countryId'
      render={
        ({ field: { onChange, value, name, ref } }) => (
          <InputSelect
          data-testid="select-country"
            label={t('country')}
            ref={ref}
            name={'countryId'}
            value={value}
            onSelect={(e) => {
              const item = countries.filter((item: any) => item.prefix === e)
              getItem && getItem(item)
              onChange(e)
            }}
            placeholder='Rol'
            required={isRequired}
            classNameWrapper={classNameWrapper}
          >
            {
              countries && countries.length > 0 && countries.map((country: any, i: any) => (
                <option key={i} value={country?.prefix}>{country?.name}</option>
              ))
            }
          </InputSelect>
        )
      }
    />
)}

export default SelectTypeCountry