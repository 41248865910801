import fetcher from 'features/shared/utils/fetcher'

import { ROLE } from 'global/constants/roles'

import {
  GetNotificationsParams,
  GetNotificationsResponse,
  GetAttendedNotificationsParams,
  GetAttendedNotificationsResponse,
  GetNotificationsSummaryTotalResponse,
  GetNotificationsSummaryResponse,
  GetNotificationsSummaryParams,
  GetNotificationsSummaryTotalParams,
  SendNotificationReportDevicePayload,
} from './types'

export async function getNotifications({
  role,
  ...params
}: GetNotificationsParams): Promise<GetNotificationsResponse> {
  if (
    [
      ROLE.SERVICE_ADMIN,
      ROLE.PATIENT,
      ROLE.PROFESSIONAL_HEALTH,
      ROLE.DOCTOR,
    ].includes(role)
  ) {
    return await fetcher.get<GetNotificationsResponse>('/notifications', {
      params,
    })
  }
  return Promise.resolve({ data: { currentPage: 0, data: [], totalPages: 0 } })
}

export async function getAttendedNotifications({
  role,
  ...params
}: GetAttendedNotificationsParams): Promise<GetAttendedNotificationsResponse> {
  if (
    [
      ROLE.SERVICE_ADMIN,
      ROLE.PATIENT,
      ROLE.PROFESSIONAL_HEALTH,
      ROLE.DOCTOR,
    ].includes(role)
  ) {
    return await fetcher.get<GetAttendedNotificationsResponse>(
      '/notifications/attended',
      {
        params,
      }
    )
  }
  return Promise.resolve({ data: { currentPage: 0, data: [], totalPages: 0 } })
}

export async function getNotificationsSummaryTotal({
  role,
}: GetNotificationsSummaryTotalParams) {
  if (
    [
      ROLE.SERVICE_ADMIN,
      ROLE.PATIENT,
      ROLE.PROFESSIONAL_HEALTH,
      ROLE.DOCTOR,
    ].includes(role)
  ) {
    return await fetcher.get<GetNotificationsSummaryTotalResponse>(
      '/notifications/summary/total'
    )
  }
  return Promise.resolve({ data: { calls: 0, medications: 0, devices: 0, prescriptions: 0, recommendations: 0 ,consultations: 0 } })
}

export async function getNotificationsSummary({
  role,
}: GetNotificationsSummaryParams): Promise<GetNotificationsSummaryResponse> {
  if (
    [
      ROLE.SERVICE_ADMIN,
      ROLE.PATIENT,
      ROLE.PROFESSIONAL_HEALTH,
      ROLE.DOCTOR,
    ].includes(role)
  ) {
    return await fetcher.get<GetNotificationsSummaryResponse>(
      '/notifications/summary'
    )
  }
  return Promise.resolve({ data: { total: 0, notifications: [] } })
}

export async function markNotificationAsRead(notificationId: string) {
  return await fetcher.post(`/notifications/${notificationId}/read`)
}

export async function sendNotificationReportDevice(
  userId: number,
  deviceId: number
) {
  return await fetcher.post<SendNotificationReportDevicePayload>(
    `/notification/specialist/report-device`,
    {
      userId,
      planDeviceId: deviceId,
    }
  )
}

