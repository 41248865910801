import IconFile from 'assets/icons/icon_file.svg';
import LabelForm from 'components/LabelForm';

import WorkplanRecomendationsPatientCard from 'components/WorkplanRecomendationsPatientCard';
import { useEffect, useState } from 'react';
import { RecommendationModel, useGetAllRecommendations } from '../hooks/useRecomendation';
import { PlanTodayRecommendationsModel } from '../hooks/userPlanToday';

interface RecommendationsModel {
  data: PlanTodayRecommendationsModel[]
}

const Recommendations: React.FC<RecommendationsModel> = ({
  data
}) => {

  return (
    <>
      <div>
        <div className='bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full'>
          <div className="flex justify-between items-center mb-4">
            <LabelForm
              icon={IconFile}
              label="Recomendaciones"
              className='text-[14px] leading-[20px] font-medium'
            />
          </div>
          <div className=" rounded-lg  w-full sm:h-[476px] overflow-y-scroll scrollbar">
            {
              data?.length > 0 ? (
                data.map((el: RecommendationModel) => 
                  <WorkplanRecomendationsPatientCard
                    key={el.id}
                    label='Recomendaciones'
                    description={el.message}
                    background='#F6F6F6'
                  />
                )
              ) : (
                <></>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Recommendations;
