import { useContext } from 'react'
import { NotificationsContext } from '../NotificationsProvider'

const useNotificationContext = () => {
  const context = useContext(NotificationsContext)
  if (!context) {
    throw new Error(
      'useNotificationContext must be used within a NotificationsDashboardContext'
    )
  }
  return context
}
useNotificationContext.displayName = 'useNotificationContext'

export { useNotificationContext }
