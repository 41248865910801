import fetcher from 'features/shared/utils/fetcher';
import { useMutation } from 'react-query'

export interface DevicesSuppliesModel {
  codigo: string;
  descripcion: string;
  unidad_medida: string;
  tipo: string;
  via_administracion: string;
  quantity: number;
}

export interface DevicesSuppliesPatientResponseModel {
  currentPage: number,
  total: number,
  totalPages: number,
  data: DevicesSuppliesPatientModel[],
}

export interface DevicesSuppliesPatientModel {
  patientId: number,
  body:{
    "planId": number,
    "deviceId": any,
    "supplyId": number,
    "deliveryStatus": boolean,
    "serial": string
  }
}

export function usePostDevicesSupplie() {
  return useMutation((data: any) => postDevicesSupplie(data));
}

export async function postDevicesSupplie(data: any) {
  return await fetcher.post(`/devices-supplies/patients/${data.patientId}/delivery-order`, data.body);
}