import CheckButton from 'components/CheckButton';
import Chip from 'components/Chip';
import Toggle from 'components/Toggle';
import React from 'react';
import { useEffect, useState } from 'react';


interface CardUserProps {
  id?: any;
  user?: any;
  onlyText?: any;
  icon?: any;
  labelIcon?: string;
  showToggle?: boolean;
  showBotton?: boolean;
  showBtn?: boolean;
  showCheckbtn?: boolean;
  validate?: boolean;
  chips?: any;
  chipsOnlyText?: any;
  validateToggle?: boolean;
  labelToggle?: string;
  onClick?: (a: any) => any;
  onClickCheckbtn?: (a: any) => any;
  setToggle?: (a: any) => any;
}

const CardUser: React.FC<CardUserProps> = ({
  id,
  user,
  icon,
  labelIcon,
  showToggle,
  showBotton,
  showBtn,
  showCheckbtn,
  onlyText,
  validate,
  validateToggle,
  labelToggle,
  onClick,
  onClickCheckbtn,
  setToggle,
  chips,
  chipsOnlyText
}) => {
  const [actievToggle, setActiveToggle] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  useEffect(() => {
    setActiveToggle(false)
  }, [validateToggle]);
  return (
    <div className="h-fit	 col flex flex-col  w-full border-solid border-[1px] 1pxborder-grey-200 p-4 rounded-[16px] ">
      <div className="flex w-full justify-between  items-center	">
        {!onlyText ?
          <div className="flex ">
            <div className="mr-[8px]">
              {
                user?.picture
                  ? (<img src={user?.picture} alt={user?.picture} className="rounded-full w-10 h-10" />)
                  : (<div className='rounded-full bg-[#DFF5FF] text-[#008EA3] w-10 h-10 flex justify-center items-center font-semibold'> {user?.name?.charAt(0)}  </div>)
              }
            </div>
            <div className='flex flex-col	' >
              <div className=" text-left font-medium text-subtitle2">
                {user?.name} {user?.lastname}
              </div>
              <div className="text-left text-caption">
                {user?.description}
              </div>
            </div>
          </div> :
          <div className='font-medium ' >
            {user?.name}

          </div>
        }
        {showBtn && onClick &&
          <div className="flex items-center justify-center ">
            <div className="flex flex-col items-end	">
              {labelIcon &&
                <div className="text-left text-caption mb-1.5	">
                  {labelIcon}
                </div>
              }
              <img onClick={() => { validate && onClick(user) }} src={icon} alt={icon}
                className={`${validate ? 'active' : 'inactive'} min-min-w-[40px] min-h-[40px] w-[40px] h-[40px] cursor-pointer`} data-testid={`specialist-btn-${user?.id}`} />
            </div>


          </div>
        }
        {showCheckbtn && onClickCheckbtn &&
          <div className="">
            <CheckButton selected={checkbox} onSelected={() => { setCheckbox(!checkbox); onClickCheckbtn(user) }} >
            </CheckButton>
          </div>
        }
      </div>
      {chipsOnlyText &&
        <div className='flex flex-wrap mb-[5px]' >
          {
            chipsOnlyText?.map((item: any, index: any) => (
              <Chip
                key={index}
                icon={item?.icon} icon2={item?.icon2} variant='four' className='text-left text-caption w-full lg:w-auto lg:min-w-[120px] mr-[5px] mb-[5px] mt-[10px]'
                label={item?.label} label2={item?.label2} onClose={() => { }} />

            ))
          }
        </div>
        }
      {showBotton &&
        <div className="  justify-between	 border-solid border-t-[1px] mt-4 pt-4 border-grey-200">
          <div className="flex flex-wrap mb-[5px]	">
            {
              chips && chips?.map((item: any, index: any) => (
                <Chip
                  key={index}
                  icon={item?.icon} icon2={item?.icon2} variant='tertiary' className='text-left text-caption w-full lg:w-auto lg:min-w-[120px] mr-[5px] mb-[5px]'
                  label={item?.label} label2={item?.label2} onClose={() => { }} />

              ))
            }
          </div>
          {showToggle && setToggle && <div className="flex justify-between	">
            <React.Fragment>


              <div className="text-left text-subtitle2 mr-2">{labelToggle}</div>

              <Toggle data={user} isActive={validateToggle ? false : true} setActive={() => {
                if (validateToggle) {
                  setToggle({ ...user, toggle: true })
                }
              }} >
              </Toggle></React.Fragment></div>
          }
        </div>
      }
    </div>
  )
};

export default CardUser
