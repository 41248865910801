import React, { useState, useEffect } from 'react'

import { NotificationsModal } from 'features/Notifications/components/NotificationsModal'
import { HeaderNotificationsCounter } from 'features/Notifications/components/NotificationsCounter'
import InputSelect from 'components/InputSelect'
import { useGetNotificationsSummary } from 'features/Notifications/services'

import IconNotification from 'assets/icons/icon_notification.svg'
import IconPeru from 'assets/icons/icon_peru.svg'
import IconColombia from 'assets/icons/icon_colombia.svg'
import { ROLE } from 'global/constants/roles'

interface headerLayoutProps {
  user: string
  role: string
  roleName: string
  countryFrom?: string
  getCountry?: (country?: string) => void
}

const COUNTRY: any = {
  PE: {
    name: 'Perú',
    icon: IconPeru,
  },
  CO: {
    name: 'Colombia',
    icon: IconColombia,
  },
}

const HeaderLayout: React.FC<headerLayoutProps> = ({
  user,
  role,
  roleName,
  getCountry,
  countryFrom,
}) => {
  const { notificationsSummary } = useGetNotificationsSummary({ role })
  const [selected, setSelected] = useState('Todos')

  const [showNotificationModal, setShowNotificationModal] = useState(false)

  const handleIconNotificationClick = () => {
    setShowNotificationModal(!showNotificationModal)
  }

  const handleChange = (val: string) => {
    setSelected(val)
    getCountry && getCountry(val)
  }

  useEffect(() => {
    if (role !== ROLE.SUPER_ADMIN) {
      getCountry && getCountry(countryFrom)
    }
  }, [role])

  return (
    <div
      className="
      bg-white rounded-xl shadow-md p-4
      flex justify-end
    "
    >
      <div className=" flex flex-wrap	 items-center ">
        {role === ROLE.SUPER_ADMIN ? (
          <InputSelect
            classNameWrapper="mr-4"
            label="País"
            onSelect={handleChange}
            hidePlaceholder={true}
            value={selected}
          >
            <option value="Todos">Todos</option>
            <option value="PE">Perú</option>
            <option value="CO">Colombia</option>
          </InputSelect>
        ) : (
          role === ROLE.COUNTRY_ADMIN && (
            <p className="mr-4 flex">
              Estas en
              <img
                className="mx-2"
                alt="icono pais"
                src={countryFrom && COUNTRY[countryFrom].icon}
              />
              {countryFrom && COUNTRY[countryFrom].name}
            </p>
          )
        )}
        <div className={'relative'} onClick={handleIconNotificationClick}>
          <button className="w-8 h-8 flex items-center justify-center">
            <img
              className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6"
              src={IconNotification}
              alt="notification"
            />
          </button>
          {notificationsSummary && notificationsSummary?.data ? (
            <>
              <HeaderNotificationsCounter
                count={notificationsSummary?.data.total}
              />
              {showNotificationModal ? (
                <>
                  <NotificationsModal
                    totalNotifications={notificationsSummary?.data.total}
                    notifications={notificationsSummary?.data.notifications}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </div>
        <p className="mx-4 text-right leading-none">
          <span className="text-sm pb-0">{user}</span>
          <br className="h-0" />
          <span className="text-xs font-light">{roleName} </span>
        </p>
        <div className="rounded-full bg-[#DFF5FF] text-[#008EA3] w-11 h-11 flex justify-center items-center font-semibold">
          {user?.charAt(0)}
        </div>
      </div>
    </div>
  )
}
export default HeaderLayout

