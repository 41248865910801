import fetcher from 'features/shared/utils/fetcher'
import { UserDesactivatePost } from '../types/user'
import { ROLE } from 'global/constants/roles'

export async function getUser(query: string, role: string) {
  let url: string = 'specialists'
  if (role === ROLE.SUPER_ADMIN) {
    url = 'users'
  }
  return await fetcher.get(`/backoffice/${url}?${query}`)
}
export async function getUserMutate(data: any) {
  let url: string = 'specialists'
  if (data.role === ROLE.SUPER_ADMIN) {
    url = 'users'
  }
  return await fetcher.get(`/backoffice/${url}?${data.query}`)
}
export async function getUserById(data: any) {
  let url: string = 'specialists'
  if (data.typeUser === ROLE.SUPER_ADMIN) {
    url = 'users'
  }
  return await fetcher.get(`/backoffice/${url}/${data.id}`)
}

const propertiesNotEmpty = (duplicated: any) => {
  const send: any = {}
  for (let key in duplicated) {
    if (duplicated[key]) {
      send[key] = duplicated[key]
    }
  }
  return send
}

export async function createUser(data: any) {
  /*   console.log(data);
   */
  let url: string = 'specialists/create'
  const duplicated = { ...data }
  let formData = null
  delete duplicated.typeUser
  if (data.typeUser === ROLE.SERVICE_ADMIN) {
    delete duplicated.clinicNetworkId
  }
  if (data.typeUser === ROLE.COUNTRY_ADMIN) {
    delete duplicated.clinicNetworkId
    delete duplicated.clinicId
    duplicated['ipressId'] = data.clinicId
  }
  if (data.typeUser === ROLE.SUPER_ADMIN) {
    url = 'users'
  } else {
    formData = new FormData();
    for (let key in duplicated) {
      if (duplicated[key]) {
        formData.append(key, duplicated[key]);
      }
    }
  }

  return await fetcher.post(`/backoffice/${url}`, formData || propertiesNotEmpty(duplicated))
}

export async function updateUser(data: any) {
  /*   console.log(data, 'data')
   */
  let url: string = `backoffice/specialists/${data.id}/edit`
  let formData: any = null
  const duplicated = { ...data }
  delete duplicated.id
  delete duplicated.typeUser
  if (!duplicated?.signatureFile) {
    delete duplicated.signatureFile
  }
  if (!duplicated?.photoFile) {
    delete duplicated.photoFile
  }
  if (data.typeUser === ROLE.SUPER_ADMIN) {
    url = `backoffice/users/${data.id}`
    formData = data;
  } else {
    formData = new FormData();
    for (let key in duplicated) {
      if (duplicated[key]) {
        formData.append(key, duplicated[key]);
      }
    }
  }

  delete duplicated.typeUser
  return await fetcher.put(url, formData || propertiesNotEmpty(duplicated))
}

export async function desactivateUser(data: UserDesactivatePost) {
  return await fetcher.put(`/backoffice/specialists/${data.id}/deactivate`, data)
}

export async function activateUser(data: UserDesactivatePost) {
  return await fetcher.put(`/backoffice/specialists/${data.id}/activate`, data)
}

export async function getGeofences() {

  return await fetcher.get(`/geofences`)
}
export async function getGeofencesSpecialists(data: any) {
  return await fetcher.get(`/backoffice/specialists/${data}/geofences`)
}
export async function postUpdatGeofences(data: any) {
  return await fetcher.post(`/backoffice/specialists/geofences`, data)
}