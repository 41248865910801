import React, { useEffect, createRef } from "react";
import IconClose from 'assets/icons/icon_close.svg';
import { useForm } from 'react-hook-form';
import TextArea from 'components/TextArea';
import Avatar from 'components/Avatar';
import useUser from 'features/shared/hooks/useUser';
import Button from 'components/Button';

interface Props {
  showModal: boolean
  onCloseModal: () => any
  onSubmitForm: (value: any) => any
  isLoading: boolean
}

const NewMessageModal: React.FC<Props> = (props) => {
  const { register, watch, handleSubmit, setValue } = useForm();
  const { userCurrent } = useUser();
  const textareaRef: any = createRef();

  const validateForm = () => {
    if (
      watch('description')
    ) {
      return true
    } else {
      return false
    }
  }

  const onSubmit = (data: any) => {
    props.onSubmitForm(data);
  }

  useEffect(() => {
    setValue('description', null)
  }, [setValue])

  const handleInput = (e: any) => {
    const element: any = e.currentTarget;

    if (element.scrollHeight > 180 && element.scrollHeight < 300 ) {
      element.style.height = "auto";
      element.style.height = element.scrollHeight + "px";
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    }
  }

  if (props.showModal) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative py-8 px-6 w-[100%] max-w-[80%]">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="border-0 rounded-lg shadow-lg relative w-full h-full bg-white outline-none focus:outline-none overflow-hidden p-4"
            >
              <div className='flex justify-between items-center pb-4 border-b-[1px] border-b-solid border-b-grey-200 mb-6'>
                <span className='text-subtitle1 text-grey-900'>Nueva descripción</span>
                <button
                  onClick={() => props.onCloseModal()}
                  className="h-[30px] w-[30px] flex items-center justify-center"
                >
                  <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
                </button>
              </div>

              <Avatar
                name={userCurrent?.name}
                role={userCurrent?.specialtyName}
                image={userCurrent?.image}
                className='mb-2'
              />

              <TextArea
                label="Evolución diaria"
                rows={1}
                height='h-[100%]'
                value={watch('description')}
                {...register('description')}
                classNameWrapper='h-[180px]'
                handleInput={handleInput}
                myRef={textareaRef}
              />

              <Button className='mt-4' type="submit" variant='primary' size="small" disabled={!validateForm() || props.isLoading } >Publicar</Button>
            </form>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  } else {
    return <></>
  }
};

export default NewMessageModal;
