import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { routes } from 'routes/routing';
import IconFile from 'assets/icons/icon_file.svg';
import IconFolder from 'assets/icons/icon_folder.svg';
import Breadcrumbs from 'components/Breadcrumbs'
import { useGetPatientData } from 'features/shared/hooks/useGetPatientFullData';
import Loader from 'components/Loader';
import { PatientModel } from 'features/Patient/models/Patient.model';
import { PatientServiceHistoryModel, useDownloadFile, useGetPatientServiceHistory } from './hooks/userPatientServiceHistory';
import Chip from 'components/Chip';
import VerticalMenu from 'components/VerticalMenu';
import { onAlert } from 'components/Alert';

interface RouteParams {
  doumentPatient: string
}

const PatientServiceHistory: React.FC<any> = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();

  const [allDataPatient, setAllDataPatient] = useState<PatientModel>();
  const { mutateAsync: asynGetPatientData, isLoading: loadingPatientData } = useGetPatientData();

  const [listServiceHistory, setListServiceHistory] = useState<PatientServiceHistoryModel[]>([]);
  const { mutateAsync: getPatientServiceHistory, isLoading: loadingGetPatientServiceHistory } = useGetPatientServiceHistory();

  const { mutateAsync: downloadFile, isLoading: loadingDownloadFile } = useDownloadFile();

  useEffect(() => {
    onGetPatient();
  }, []);

  useEffect(() => {
    onGetPatientServiceHistory();
  }, [allDataPatient]);

  const onGetPatient = async () => {
    const dataPatient = await asynGetPatientData(params.doumentPatient);

    if (dataPatient?.data) {
      setAllDataPatient(dataPatient.data);
    }
  };

  const onGetPatientServiceHistory = async () => {
    if (allDataPatient?.userId) {
      getPatientServiceHistory(allDataPatient?.userId).then(res => {
        if (res.data) {
          setListServiceHistory(res.data);
        }
      })
    }
  };

  const onDownloadFile = (fileType: string, epicrisisId: number) => {
    downloadFile({ epicrisisId, fileType }).then(res => {
      if (res.data) {
        const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";

        const a = document.createElement('a');
        a.href = mediaType + res.data;
        a.download = `${allDataPatient?.name} ${allDataPatient?.lastname} ${fileType}.pdf`;

        a.click();
        a.remove();
      } else {
        onAlert.error(`No se cuenta ${fileType}`);
      }
    });
  }


  return (
    <React.Fragment>
      {(loadingPatientData || loadingGetPatientServiceHistory || loadingDownloadFile) && <Loader />}

      <Breadcrumbs
        name={`${allDataPatient?.name} ${allDataPatient?.lastname}`}
        prevPathName="Lista de pacientes"
        currentPathName={`${allDataPatient?.name} ${allDataPatient?.lastname}`}
        onClickPrev={() => { history.push(`${routes.patients.url}`) }}
        onClickHome={() => { history.push(`/`) }}
      />

      <div className="w-full overflow-auto scrollbar bg-white py-6 px-4 rounded-[16px] shadow-md mt-6">
        <table className="min-w-full divide-y divide-gray-200 border-collapse">
          <thead className="bg-bg7">
            <tr className='even:border-t-[1px] even:border-t-grey-200'>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Fecha de inicio
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Fecha de finalización
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                N° de Encuentro
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Estado
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Descargar PDF
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              listServiceHistory?.length > 0 ? (
                listServiceHistory?.map((item: PatientServiceHistoryModel, index: any) => {
/*                   console.log(item);
 */
                  return (
                    <tr key={index} className="h-[54px] hover:bg-grey-50 cursor-pointer">
                      <td className="px-4 py-2 whitespace-nowrap" >
                        {item.startDate}
                      </td>
                      <td className="px-4 py-2 " >
                        {item.endDate}
                      </td>
                      <td className="px-4 py-2 " >
                        {item.eventNUmber}
                      </td>
                      <td className="px-4 py-2 flex" >
                        {item.status === "En atención" &&
                          <Chip
                            label={item.status}
                            onClose={() => { }}
                            isRemovable={false}
                            className="bg-[#E9FCFF] text-[#008EA3] border font-medium"
                          />}

                          {item.status === "Dado de alta" &&
                          <Chip
                            label={item.status}
                            onClose={() => { }}
                            isRemovable={false}
                            className="bg-[#FFEFED] text-[#FB3836] border-none font-medium"
                          />}
                          {item.status === "Pendiente" &&
                          <Chip
                            label={item.status}
                            onClose={() => { }}
                            isRemovable={false}
                            className="bg-[#FFFAF0] text-[#E5B51F] border-none font-medium"
                          />}

                      </td>
                      <td className="px-4 py-2 " >

                        {!['En atención', 'Pendiente'].includes(item.status) ? (
                          <VerticalMenu>
                            <>
                              <li className={`cursor-pointer py-4 hover:bg-grey-200 text-caption w-[133px] `} >
                                <div onClick={() => onDownloadFile('epicrisis', item.epicrisisId)} className='flex mx-4'>
                                  <img className='w-[18px] h-[18px] mr-4' src={IconFolder} alt="epicrisis" /> <span>Epicrisis</span>
                                </div>
                              </li>
                              <li className={`cursor-pointer py-4 hover:bg-grey-200 text-caption w-[133px] `} >
                                <div onClick={() => onDownloadFile('alta-medica', item.epicrisisId)} className='flex mx-4'>
                                  <img className='w-[18px] h-[18px] mr-4' src={IconFile} alt="alta" /> <span>Alta de paciente</span>
                                </div>
                              </li>
                            </>
                          </VerticalMenu>
                        ) : null}
                      </td>
                    </tr>
                  );
                })
              ) : <tr className='h-[54px]'><td colSpan={5} className="px-4 text-center" >No se encontraron resultados</td></tr>
            }
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default PatientServiceHistory

