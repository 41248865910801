import React from "react";

interface AnnotationsCardProps {
  comentary?: string;
  description?: string;

  className?: string;
}

const AnnotationsCard: React.FC<AnnotationsCardProps> = ({
  comentary,
  description,
  className
}) => {  
  return (
    <div className={`rounded-[16px]  p-[16px] w-full ${className}`}>
        <div className='text-left text-Fs9 text-gray-500 '>
        {comentary}
      </div>
      <div className='text-left text-Fs8 font-medium text-text-primary overflow-auto	 '>
      {description?.split("\n").map((e: any, i: any) =>
            <React.Fragment
            key={i}>
              {e}
              <br />
            </React.Fragment>
          )}
      </div>
    </div>
  )
}

export default AnnotationsCard;