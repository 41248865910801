import React, { useEffect, useState } from 'react'
import IconEdit from 'assets/icons/icon_edit.svg'
import CalendarIcon from 'assets/icons/icon_calendar.svg';
import IconMapPing from 'assets/icons/icon_map_pin_b.svg';

import { useHistory } from 'react-router'
import { routes } from 'routes/routing'
import {
  useDesactivateUserRole, useActivateUserRole,
  useUpdateUser, useGeofencesSpecialists, usePostUpdatGeofences, useGeofences
} from 'features/UserManagement/hooks/useUser'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Modal from 'components/modal';
import ModalB from '../Modal/index';
import useUser from 'features/shared/hooks/useUser'
import { ROLE } from 'global/constants/roles'
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';
import ScrollToBottom from 'react-scroll-to-bottom';
import LoaderMini from 'components/LoaderMini';
import { onAlert } from 'components/Alert';
import Input from 'components/input';
import IconSearch from 'assets/icons/icon_search.svg'
import { BehaviorSubject, debounceTime } from 'rxjs';
import { log } from 'console';

const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();
interface PropsTableBody {
  item: any;
  index: any;
  className?: string
  isClinic?: boolean
  isSpecialty?: boolean
  onGetListUsers?: () => void

}

const TableBody: React.FC<PropsTableBody> = (props) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { userCurrent } = useUser();
  const role = userCurrent?.roleSlug
  const [searchText, setSearchText] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const [idSpecilist, setidSpecilist] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showGeofences, setShowGeofences] = useState(false)
  const [isRoleActivate, setIsRoleActivate] = useState(false)
  const [stateListgeofences, setStateListGeofences] = useState([]);
  const [state_geofences, setStateGeofences] = useState<string[]>([]);

  const { isLoading: isLoadinggetGeofences, mutateAsync: getGeofences } = useGeofences()
  const { isLoading: isLoadinggetGeofencesSpecialists, mutateAsync: getGeofencesSpecialists } = useGeofencesSpecialists()
  const { isLoading: isLoadingpostUpdatGeofences, mutateAsync: postUpdatGeofences } = usePostUpdatGeofences()
  const { mutateAsync: asyncDesactivateRole } = useDesactivateUserRole()
  const { mutateAsync: asyncActivateRole } = useActivateUserRole()
  const { mutateAsync: asyncUpdateUser } = useUpdateUser()

  const handleClickGeofences = (id: any) => {
    setidSpecilist(id)
    setShowGeofences(true)
  }
  const handleClickEdit = (item: any) => {
    history.push(`${routes.userManagementEditUser.url.replace(':userId', item.id)}`, {
      userStorage: item
    })
  }
  const handleClickCalendar = (item: any) => {
    history.push(`${routes.userManagementCalendar.url.replace(':userId', item.id)}`, {
      userStorage: item
    })
  }
  const handleClickState = async (active: any, id: number) => {
    /*     console.log(active, id);
     */
    let response;
    const sendData: any = {
      id: String(id)
    }
    if (
      role === ROLE.SUPER_ADMIN
    ) {
      sendData['typeUser'] = role
      sendData['status'] = !active
      if (!active == true) {
        setIsRoleActivate(true)
      } else {
        setIsRoleActivate(false)
      }
      /*       console.log(sendData)
       */
      response = await asyncUpdateUser(sendData)

    } else {
      if (active) {
        setIsRoleActivate(false)
        response = await asyncDesactivateRole(sendData)
      } else {
        setIsRoleActivate(true)
        response = await asyncActivateRole(sendData)
      }
    }
    if (response?.data) {
      setShowConfirm(true)
     /*  console.log('entro') */
      if (props?.onGetListUsers) {
        props?.onGetListUsers()

      }
    }
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    queryClient.invalidateQueries('users')
  }

  const getActive = (item: any) => role === ROLE.SUPER_ADMIN ? item.status : item.active;
  const onSubmit = async () => {

    let obj = {
      "specialistId": idSpecilist,
      "geofenceIds": state_geofences
    }
    let sendGeofences = await postUpdatGeofences(obj)
    if (sendGeofences?.data) {
      onAlert.success('Geo cerco asignado');
      setShowGeofences(false)
    } else {
      onAlert.error(sendGeofences);
    }

  }

  const onChangeGeofences = (type: string) => {
    setStateGeofences([...state_geofences]?.includes(type) ? [...state_geofences].filter(item => item !== type) : [...state_geofences, type])
  }
  const onGetGeofences = async (text?: any) => {
    const searchText = searchValue
    let listGeofences = await getGeofences()

    let listGeofencesSpecialists = await getGeofencesSpecialists(props.item.id)
    const initialGeofencesSpeciaselect = listGeofencesSpecialists?.data?.map((element: any) => {
      return (element.geofenceId);

    })
    let listGeofencesFilter = listGeofences?.data.filter((e: any) => {
      if (e.name.trim().toLowerCase().includes(searchText.toLowerCase())) {
        return (e)

      }
    })

    setStateListGeofences(listGeofencesFilter);
    setStateGeofences(initialGeofencesSpeciaselect)

  }
  useEffect(() => {
    if (showGeofences) {
      onGetGeofences()
      const searchSubscription = inputChange$
        .pipe(debounceTime(500))
        .subscribe((value) => {
          setSearchValue(value)
        })

      return () => {
        return searchSubscription.unsubscribe()
      }
    }


  }, [showGeofences]);
  useEffect(() => {
    if (searchValue === searchText && showGeofences) {
      onGetGeofences(searchValue)
    } else {
      setSearchValue('')
    }
  }, [searchValue, searchText])
  return (
    <React.Fragment>
      <tr key={props.index} className="h-[54px] hover:bg-grey-50 cursor-pointer">
        <td className="px-4 py-2 whitespace-nowrap">
          <div className="text-body2">
            {props.item.name} {props.item.lastname}
          </div>
        </td>
        <td className="px-4 py-2 ">
          <div
            className="text-body2"
          >
            {props.item.email}
          </div>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <div className="text-body2">
            {props.item?.roleName}
          </div>
        </td>
        {
          props.isSpecialty &&
          <td className="px-4 py-2 whitespace-nowrap">
            <div
              className="text-body2"
            >
              {props.item?.specialty}
            </div>
          </td>
        }
        {
          props.isClinic &&

          <td className="px-4 py-2 whitespace-nowrap">
            <div
              className="text-body2"
            >
              {props.item?.specialty}
            </div>
          </td>
        }
        <td className="px-4 py-2 whitespace-nowrap">
          <div
            className="text-body2"
          >
            <label htmlFor={`checked-toggle${props.index}`} className="inline-flex relative items-center">
              <div
                onClick={() => handleClickState(getActive(props.item), props.item.id)}
                className={`cursor-pointer relative w-11 h-6 bg-gray-200 rounded-full after:content-[''] after:absolute 
                after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                ${getActive(props.item) == 1 && 'bg-[#48B16E] after:translate-x-full'}`}></div>
            </label>

          </div>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <div className="text-body2 flex justify-end w-full" >
            <a className='cursor-pointer ml-[6px]'>

              <img
                onClick={() => handleClickEdit(props.item)}
                src={IconEdit}
                alt="Editar"
                className='h-[16px] w-[16px]' />
            </a>
            {props.item?.roleId === 4 && (
              <React.Fragment>
                <a className='cursor-pointer ml-[6px]'>
                  <img
                    onClick={() => handleClickCalendar(props.item)}
                    src={CalendarIcon}
                    alt="Calendar"
                    className='h-[16px] w-[16px]' />
                </a>

                <a className='cursor-pointer ml-[6px]'>
                  <img
                    onClick={() => handleClickGeofences(props.item.id)}
                    src={IconMapPing}
                    alt="Calendar"
                    className='h-[16px] w-[16px]' />
                </a>
              </React.Fragment>)
            }
          </div>
        </td>
      </tr>
      <Modal
        showModal={showConfirm}
        setShowModal={handleConfirm}
        text={isRoleActivate ? 'Usuario Activado' : 'Usuario Desactivado'}
        btn={t('end')} />
      <ModalB
        showModal={showGeofences}
        onCloseModal={() => {
          setShowGeofences(false)
        }}
        classHeader={'border-b-[1px] mb-[24px]'}
        title={'Geocerco'}
        disable={true}
      >

        <div className="mb-[24px]">Seleccione los geo cercos que deseas asignar al especialista</div>

        <Input
          label="Buscar geo cerco"
          value={searchText}
          icon={IconSearch}
          classNameWrapper={'h-[56px] mb-[24px]'}
          onChange={(e: any) => {
            inputChange.next(e.target.value)
            setSearchText(e.target.value)
          }}
        />
        <ScrollToBottom mode={'top'} followButtonClassName='ScrollToBottom__btn__top' scrollViewClassName=' overflow-y-scroll scrollbar' className="relative bg-white rounded-lg  w-full h-[170px] ">
          {(isLoadinggetGeofencesSpecialists || isLoadingpostUpdatGeofences || isLoadinggetGeofences) && < LoaderMini />}
          {
            stateListgeofences && stateListgeofences?.map((geofence: any) => (
              <CheckBox
                classNameCheck='h-[18px] w-[18px] min-w-[18px]  '

                className='col h-[48px] rounded-[16px] bg-white text-grey-600
                          border-solid border-[1px] border-grey-200 hover:border-grey-400 mb-[8px] capitalize'
                name={geofence.name}
                selected={state_geofences.includes(geofence.id)}
                onSelected={() => onChangeGeofences(geofence.id)}
              />

            ))
          }

        </ScrollToBottom>

        <div className="flex justify-center gap-4 mt-6 p-[16px]">

          <Button disabled={state_geofences.length === 0} className='bg-primary-main border-primary-main '
            type="submit" variant='primary' size="extra-large" onClick={() => onSubmit()}>Enviar</Button>
        </div>
      </ModalB>
    </React.Fragment>
  )
}

export default TableBody
