// @ts-nocheck
import React, { useEffect, useState } from 'react'
import InputSelect from 'components/InputSelect'
import { useTranslation } from 'react-i18next'
import SelectTypeRole from '../SelectTypeRole/Input'
import SelectTypeSpeciality from '../SelectTypeSpeciality/input'
import SelectClinic from '../SelectClinic/input'
import InputField from 'components/input';
import { BehaviorSubject, debounceTime } from 'rxjs';
import IconSearch from 'assets/icons/icon_search.svg';

interface Props {
  getFilters?: Function
  getRoles?: Function
  getSpecialities?: Function
  handleChangeSearchInput?: React.ChangeEventHandler<HTMLInputElement>
  isSpecialty?: boolean
  isClinic?: boolean
  isRole?: boolean
  isStatus?: boolean
  isAdminCountry?: boolean
  isSuperAdmin?: boolean
}

const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

const Filter: React.FC<Props> = ({
  getFilters,
  getRoles,
  getSpecialities,
  handleChangeSearchInput,
  isSpecialty,
  isClinic,
  isRole,
  isStatus,
  isAdminCountry,
  isSuperAdmin,
}) => {
  const { t } = useTranslation()
  const [ config, setConfig] = useState({})
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [clinic, setClinic] = useState("");
  const [status, setStatus] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    getFilters && getFilters(config)
  }, [config])

  useEffect(() => {
    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value) => {
      setSearchValue(value);
  });

    return () => {
      return searchSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (searchValue === searchText) {
      setConfig({searchText})
      setStatus('')
      setRole('')
      handleChangeSearchInput && handleChangeSearchInput(searchValue)
    }
  }, [searchValue, searchText])

  const handleChangeRole = (e: any) => {
    setRole(e)
    setConfig({...config, roleId: e})
  }

  const handleChangeSpeciality = (e: any) => {
    setClinic(e)
    setConfig({...config, specialty: e})
  }

  const handleChangeStatus = (e: any) => {
    setConfig({...config, status: e})
  }

  const onChange = (e) => {
    inputChange.next(e.target.value);
    setSearchText(e.target.value);
  }

  return (
    <div className='flex flex-col xl:flex-row	flex justify-end  items-center 	w-full gap-2'>
      <div className='mb-4 w-full 	 xl:mb-0  xl:w-[175px]'>
        {
          isRole &&
          <SelectTypeRole
            onChange={handleChangeRole}
            getRoles={getRoles}
            value={role}
          />
        }
      </div>
      {
        isStatus &&
        <div className='mb-4 xl:mb-0 w-full sm:ml-4 xl:max-w-[175px]'>
          <InputSelect
            label={t('type_status')}
            name="documentType"
            onSelect={handleChangeStatus}
            value={status}
          >
            <option value='1'>Activo</option>
            <option value='0'>Inactivo</option>
          </InputSelect>
        </div>
      }
      {
        isSpecialty &&
        <div className='mb-4 xl:mb-0 w-full  sm:ml-4 xl:max-w-[175px]'>
          <SelectTypeSpeciality
            onChange={handleChangeSpeciality}
            getSpecialities={getSpecialities}
          />
        </div>
      }
      {
        isClinic && <div className='mb-4 xl:mb-0 w-full  sm:ml-4 xl:max-w-[216px]'>
          <SelectClinic
            onChange={handleChangeSpeciality}
            value={clinic}
          />
        </div>
      }
      {
        <div className="mb-4 xl:mb-0 w-full  sm:ml-4 xl:w-[390px]">
          <InputField
            label="Buscar usuario"
            value={searchText}
            icon={IconSearch}
            onChange={onChange}
          />
        </div>
      }
   </div>
)}

export default Filter