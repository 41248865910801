import React, { useState } from 'react'
import IconEdit from 'assets/icons/icon_edit.svg';
import Input from 'components/input'
import { useTranslation } from 'react-i18next'
import IconSave from 'assets/icons/icon_save.svg';
import IconClose from 'assets/icons/icon_close.svg';
import { useParams } from 'react-router-dom';

interface Props {
  handleActivate: (index: number, sendData: any) => void
  index: number
  handleCancel?: (id: number, response?: any) => void
  handleSave: (sendData?: any, action?: string) => void
  status?: number
  id: number
  name: string
}

const ItemTableClinic: React.FC<Props> = ({
  index,
  handleActivate,
  id,
  handleCancel,
  handleSave,
  name,
  status
}) => {
  const { t } = useTranslation()
  const params = useParams<any>();
  const networkId = params?.clinicNetworkId

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>()

  const handleClickSave = async () => {
    const sendData: any = {
      name: inputValue,
      clinicNetworkId: networkId,
    }
    if (isEdit) {
      sendData.id = id
      handleSave && handleSave(sendData, 'edit')
    } else{
      delete sendData.id
      handleSave && handleSave(sendData)
    }
  }

  const handleClickCancel = (e: any, id: number) => {
    handleCancel && handleCancel(id)
    setIsEdit(false)
  }

  const onClickEdit = (e: any) => {
    setIsEdit(true)
    setInputValue(name)
  }

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleClickActivate = () => {
    handleActivate && handleActivate(index, {
      id,
      status,
      name
    })
  }

  return(
  <tr className="h-[54px] hover:bg-grey-50">
    <td className="px-4 py-2 whitespace-nowrap">
      <div className="text-body2">
        {
          (isEdit || !id) ?
            <Input
              type="text"
              typeValidation='text'
              required
              label={t('name_clinic_all')}
              value={inputValue}
              onChange={handleChangeInput}
              validateFieldOnChange={true}
              //value={watch('name')}
              //{...register('name')}
              data-testid='addClinicName'
            />
          : name
        }
      </div>
    </td>
    <td className="px-4 py-2 whitespace-nowrap">
      <div
        className="text-body2"
      >
      <label htmlFor={`checked-toggle${index}`} className="inline-flex relative items-center">
        <div
          onClick={handleClickActivate}
          className={`cursor-pointer relative w-11 h-6 bg-gray-200 rounded-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 ${Boolean(status) && 'bg-[#48B16E] after:translate-x-full'}`} data-testid="toggleActive"></div>
      </label>
      </div>
    </td>
    <td className="px-4 py-2 whitespace-nowrap">
      <div className="text-body2" >
        {
          (isEdit || !id) ?
          <div className='flex text-[8px]'>
            {
              (inputValue !== '' || !id) &&
              <p onClick={handleClickSave} className='mr-2 cursor-pointer'>
                <img src={IconSave} alt="icon" className='mx-[auto]'  data-testId='saveBtn' />
                <span>Guardar</span>
              </p>
            }
            <p onClick={(e) => handleClickCancel(e, index)} className='mr-2 cursor-pointer'>
              <img src={IconClose} alt="icon" className='mx-[auto]' />
              <span>Cancelar</span>
            </p>
          </div>
          :
          <a className='cursor-pointer'>
            <img 
              data-testid="edit_user"
              onClick={onClickEdit}
              src={IconEdit}
              alt="Editar"
              className='h-[20px] w-[20px]'
              data-testId='editBtn' />
          </a>

        }
      </div>
    </td>
  </tr>
)}

export default ItemTableClinic
