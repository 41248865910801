import React from "react";
interface Props {
  showModal: boolean;
  text: string;
  btn: string;
  setShowModal: () => any;

}

const Modal: React.FC<Props> = (props) => {

  const click =()=>{
    props.setShowModal()
  }
  
  return (
    <div data-testid="Modal-2" >
      {props.showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[61] outline-none focus:outline-none">
            <div className="relative modal my-6">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="max-w-[352px] w-full flex flex-col items-center justify-center p-10 pt-2">
                  <div className="w-full flex  items-start justify-end">
                    <button
                      className="close ml-auto bg-transparent border-0 text-black  float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => click()}
                    >
                      <span>x</span>
                    </button>
                  </div>
               
                  <>
                    <h1
                      className="mb-5 mt-3 text-sm font-semibold	max-w-sm"
                    >
                      {props.text}
                    </h1>
                
                    <div className=" w-full flex flex-col items-center justify-center">
                     
                      <button
                        className="cursor-pointer text-sm  bg-bg4 border-bg4 flex-shrink-0  hover:bg-bg4-700  border-bg4-500 hover:border-bg4-700 border-4 text-white py-1 px-4 rounded"
                        type="button"
                        onClick={() => click()}
                        >
                          {props.btn}
                        
                      </button>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default Modal;
