import IconCheck from 'assets/icons/icon_check.svg';

interface CheckBoxProps {
  selected: boolean;
  onSelected?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  ref?: any;
}

const CheckButton: React.FC<CheckBoxProps> = (props) => {
  const filterActive = 'invert(100%) sepia(0%) saturate(3451%) hue-rotate(56deg) brightness(115%) contrast(98%)';

  return (
    <div
      className={`
        h-[24px] w-[24px] flex items-center justify-center border-solid border-[2px] border-grey-400 rounded-[3px] 
        ${props.selected ? '!border-primary-dark bg-primary-dark' : ''} 
        ${props.className}
      `}
      onClick={props.onSelected}
      ref={props.ref}
    >
      {props.selected && <img className='w-[24px] h-[24px]' src={IconCheck} style={{filter: filterActive}} />}
    </div>
  )
}

export default CheckButton