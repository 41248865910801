import {
  FC,
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useMemo,
} from 'react'

import Loader from 'components/Loader'
import SimpleClosingModal from 'components/SimpleClosingModal'
import { MeetingVideoCall } from 'components/MeetingVideoCall'
import LabelForm from 'components/LabelForm'
import Modal from 'components/modal'
import ModalError from 'components/modalError'

import ReportDevice from './components/ReportDeviceNotifications'

import {
  useMarkNotificationAsRead,
  Notification,
} from './services'

import IconVideo from 'assets/icons/icon_video_line.svg'
import { useUpdateDeviceStatus } from 'features/Devices/hooks/useDevice'

interface INotificationsContext {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  showModalError: boolean
  setShowModalError: Dispatch<SetStateAction<boolean>>
  textModal: string
  setTextModal: Dispatch<SetStateAction<string>>
  textModalError: string
  setTextModalError: Dispatch<SetStateAction<string>>
  openVideoCall: boolean
  setOpenVideoCall: Dispatch<SetStateAction<boolean>>
  openDeviceReport: boolean
  setOpenDeviceReport: Dispatch<SetStateAction<boolean>>
  selectedNotification: Notification | null
  setSelectedNotification: Dispatch<SetStateAction<Notification | null>>
}

export const NotificationsContext = createContext<INotificationsContext>({
  showModal: false,
  setShowModal: () => {},
  showModalError: false,
  setShowModalError: () => {},
  textModal: 'Registro Exitoso',
  setTextModal: () => {},
  textModalError: 'Ocurrió un error',
  setTextModalError: () => {},
  openVideoCall: false,
  setOpenVideoCall: () => {},
  openDeviceReport: false,
  setOpenDeviceReport: () => {},
  selectedNotification: null,
  setSelectedNotification: () => {},
})

export const NotificationsProvider: FC = ({ children }) => {
  const [showModal, setShowModal] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [textModal, setTextModal] = useState('Registro Exitoso')
  const [textModalError, setTextModalError] = useState('Ocurrió un error')
  const [openVideoCall, setOpenVideoCall] = useState(false)
  const [openDeviceReport, setOpenDeviceReport] = useState(false)
  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null)

  const {
    isLoading: isLoadingUpdateDeviceStatus,
    mutateAsync: updateDeviceStatus,
  } = useUpdateDeviceStatus()

  const { markNotificationAsRead } = useMarkNotificationAsRead()

  const handleCloseVideoCall = () => {
    setOpenVideoCall(false)
    setSelectedNotification(null)
  }

  const handleCloseDeviceReport = () => {
    setOpenDeviceReport(false)
    setSelectedNotification(null)
  }

  const handleSubmitDeviceReport = () => {
    updateDeviceStatus(selectedNotification?.data.body.device?.planDeviceId)
      .then((res) => {
        if (res.data) {
          setShowModal(true)
        } else {
          setShowModalError(true)
          setTextModalError(res)
        }
      })
      .catch((err) => {
        setShowModalError(true)
        setTextModalError(err)
      })
      .finally(() => {
        setOpenDeviceReport(false)
        setSelectedNotification(null)
        markNotificationAsRead(`${selectedNotification?.notificationId}`)
      })
  }

  const noficationsDashboardContext = useMemo(
    () => ({
      showModal,
      setShowModal,
      showModalError,
      setShowModalError,
      textModal,
      setTextModal,
      textModalError,
      setTextModalError,
      openVideoCall,
      setOpenVideoCall,
      openDeviceReport,
      setOpenDeviceReport,
      selectedNotification,
      setSelectedNotification,
    }),
    [
      showModal,
      setShowModal,
      showModalError,
      setShowModalError,
      textModal,
      setTextModal,
      textModalError,
      setTextModalError,
      openVideoCall,
      setOpenVideoCall,
      openDeviceReport,
      setOpenDeviceReport,
      selectedNotification,
      setSelectedNotification,
    ]
  )
  return (
    <NotificationsContext.Provider value={noficationsDashboardContext}>
      {isLoadingUpdateDeviceStatus ? <Loader /> : null}
      {children}
      <>
        {openVideoCall && selectedNotification ? (
          <SimpleClosingModal
            title={
              <LabelForm
                icon={IconVideo}
                label="Videollamada"
                className="mt-4 mb-2 row-span-1 col-span-full"
              />
            }
            onClose={handleCloseVideoCall}
            withDivider={false}
            maxWidth={'80%'}
          >
            <MeetingVideoCall
              planId={selectedNotification.data.body.planId}
              userId={selectedNotification.data.body.patientId}
              showLabel={false}
              initCall={true}
              onLeaveMeeting={handleCloseVideoCall}
            />
          </SimpleClosingModal>
        ) : null}

        {openDeviceReport && selectedNotification ? (
          <ReportDevice
            onCloseModal={handleCloseDeviceReport}
            onSubmitForm={handleSubmitDeviceReport}
            deviceSelected={selectedNotification.data.body.device}
          />
        ) : null}

        <Modal
          showModal={showModal}
          setShowModal={() => setShowModal(false)}
          text={textModal}
          btn="De acuerdo"
        />
        <ModalError
          text={textModalError}
          showModal={showModalError}
          setShowModal={setShowModalError}
        />
      </>
    </NotificationsContext.Provider>
  )
}
