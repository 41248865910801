import React, { useEffect } from 'react'
import InputSelect from 'components/InputSelect'
import { useGetRolesActive } from 'features/UserManagement/hooks/useUserRole';
import { useTranslation } from 'react-i18next'
import useUser from 'features/shared/hooks/useUser';
import { ROLE } from 'global/constants/roles'

interface Props {
  isRequired?: boolean
  classNameWrapper?: string
  inputRef?: any
  onChange?: (val: any) => any
  value?: any
  getRoles?: Function
  getItem?: Function
}

const SelectTypeRole: React.FC<Props> = ({
  isRequired,
  classNameWrapper,
  inputRef,
  value,
  onChange,
  getRoles,
  getItem
}) => {
  const { t } = useTranslation()
  const { userCurrent } = useUser()
  const typeUser = userCurrent?.roleSlug
  const input = 'slug'

  const { data: rolesData } = useGetRolesActive()
  const roles = rolesData?.data

  const formatRoles = () => {
    if (typeUser === ROLE.SUPER_ADMIN) {
      return roles.filter((item: any) => item[input] === ROLE.SUPER_ADMIN || item[input] === ROLE.COUNTRY_ADMIN)
    }
    if (typeUser === ROLE.COUNTRY_ADMIN) {
      return roles.filter((item: any) => item[input] === ROLE.SERVICE_ADMIN)
    }
    return roles.filter((item: any) => item[input] !== ROLE.SUPER_ADMIN && item[input] !== ROLE.COUNTRY_ADMIN && item[input] !== ROLE.SERVICE_ADMIN && item[input] !== ROLE.PATIENT)
  }

  const handleSelect = (val: string) => {
    const item = formatRoles().filter((item: any) => item.id == val)[0]
    getItem && getItem(item)
    onChange && onChange(val)
  }

  useEffect(() => {
    if (roles && roles.length > 0) {
      getRoles && getRoles(formatRoles())
      const item = formatRoles().filter((item: any) => item.id == value)[0]
     /*  console.log(item, 'item') */
      getItem && getItem(item)
    }
  }, [roles, value])



  return (
    <InputSelect
      label={t('type_role')}
      ref={inputRef}
      name={'roleId'}
      value={value}
      onSelect={handleSelect}
      placeholder='Rol'
      required={isRequired}
      classNameWrapper={classNameWrapper}
    >
      {
        roles && roles.length > 0 && formatRoles().map((role: any, i: any) => (
          <option key={i} value={role?.id}>{role?.name}</option>
        ))
      }
    </InputSelect>
)}

export default SelectTypeRole