import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BehaviorSubject, debounceTime } from 'rxjs'
import Moment from 'moment'

import useUser from 'features/shared/hooks/useUser'
import Button from 'components/Button'
import Pagination from 'components/Pagination'
import { PatientsModel } from 'features/shared/types/userType'
import IconSearch from 'assets/icons/icon_search.svg'
import { AddresSelectedModel } from 'components/MapLocation/MapModel'
import Breadcrumbs from 'components/Breadcrumbs'
import IconEmpty from 'assets/icons/icon_empty.svg'
import CheckBox from 'components/CheckBox'

import Loader from '../../../components/Loader'
import { routes } from '../../../routes/routing'
import { useGetAttentsListPatients, useGetListPatients } from 'features/Patients/hooks/usePatient'
import { ROLE } from 'global/constants/roles'
import ImageError from 'components/ImageError'
import SimpleClosingModal from 'components/SimpleClosingModal'
import Input from 'components/input'

const pageSize = 10
const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

const ClinicHistoryPatients: React.FC = () => {
  const history = useHistory()
  const { userCurrent } = useUser()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchText, setSearchText] = useState('')
  const [initial, setIsInitial] = React.useState(false);
  const [isCheckedSearchShowAll, setIsCheckedSearchShowAll] = React.useState(false);
  const [isCheckedSearchCountry, setIsCheckedSearchCountry] = React.useState(false);
  const [isDate, setIsDate] = React.useState(Moment(new Date()).format('YYYY-MM-DD'));

  const [srcPatients, setSrcPatients] = useState<PatientsModel>({
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1,
  })

  const [listPatients, setListPatients] = useState<PatientsModel>({
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1,
  })


  const {
    isLoading: listAttentsPatientLoading,
    isSuccess: listPatientAttentsSucces,
    mutateAsync: mutateAsyncAttentsListPatients,
  } = useGetAttentsListPatients()
  const {
    isLoading: listPatientLoading,
    mutateAsync: mutateAsyncListPatients,
  } = useGetListPatients()
  const handleChangePage = (page: number) => {
    setCurrentPage(page)
  }


  useEffect(() => {
    if (isCheckedSearchShowAll || isDate || isCheckedSearchCountry) {
      if (currentPage) {
        onGetListPatients(searchText, currentPage)
      } else {
        onGetListPatients(searchText, 1)
      }
      setIsInitial(true)
    }
  }, [isCheckedSearchShowAll, isDate, isCheckedSearchCountry, currentPage])

  useEffect(() => {
    if (initial) {
      const searchSubscription = inputChange$
        .pipe(debounceTime(500))
        .subscribe((value) => {
          setSearchText(value)
          onGetListPatients(searchText, currentPage)
        })

      return () => {
        return searchSubscription.unsubscribe()
      }
    }
  }, [searchText])

  const onGetListPatients = async (
    searchValue?: string,
    currentPage?: number
  ) => {
    if (userCurrent?.ipress?.countryId) {
      const searchText = searchValue !== '' ? `&searchText=${searchValue}` : ''

      const query = `?country=${userCurrent?.ipress?.countryId}&page=${currentPage}${searchText}&showAll=${isCheckedSearchShowAll}&date=${isDate}&searchAllCountry=${isCheckedSearchCountry}`

      const response = userCurrent?.roleSlug === ROLE.PROFESSIONAL_HEALTH ? await mutateAsyncAttentsListPatients(query) : await mutateAsyncListPatients(query);

      setListPatients(response.data)
    }
  }

  return (
    <React.Fragment>
      {(listPatientLoading || listAttentsPatientLoading) && <Loader />}

      <Breadcrumbs
        name={'Atenciones'}
        currentPathName={'Lista de pacientes'}
      />

      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        <div className="px-4 pb-[24px] flex items-center justify-between">
          <p className="text-body1 mr-4 text-text-secondary grow mt-[10px]">
            {' '}
            <span className="font-medium text-text-primary">
              Pacientes
            </span> | {listPatientAttentsSucces && listPatients?.total}
          </p>
          {userCurrent?.roleSlug === ROLE.PROFESSIONAL_HEALTH ?
            <div className="flex flex-col ">
              <div className="flex gap-4">
                <Button disabled={isCheckedSearchShowAll} size="tiny" variant={`${isDate === Moment(new Date(new Date().setDate(new Date().getDate() - 1))).format('YYYY-MM-DD') ? 'primary' : 'secondary-b'}`} onClick={() => { setIsDate(Moment(new Date(new Date().setDate(new Date().getDate() - 1))).format('YYYY-MM-DD')) }}  > Ayer </Button>
                <Button disabled={isCheckedSearchShowAll} size="tiny" variant={`${isDate === Moment(new Date()).format('YYYY-MM-DD') ? 'primary' : 'secondary-b'}`} onClick={() => { setIsDate(Moment(new Date()).format('YYYY-MM-DD')) }} > Hoy </Button>
                <Button disabled={isCheckedSearchShowAll} size="tiny" variant={`${isDate === Moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD') ? 'primary' : 'secondary-b'}`} onClick={() => { setIsDate(Moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD')) }} > Mañana </Button>
              </div>

              <CheckBox
                classNameCheck="h-[18px] w-[18px] min-w-[18px]"
                className=" mt-[8px] pl-[0px] text-caption text-text-primary"
                name="Ver todos mis pacientes"
                selected={isCheckedSearchShowAll}
                onSelected={() => {
                  setIsCheckedSearchShowAll(!isCheckedSearchShowAll)
                }}
              />
            </div>
            :
            <div className="flex flex-col ">
              <Input
                label="Buscar paciente"
                value={searchText}
                icon={IconSearch}
                classNameWrapper={'h-[56px]'}
                onChange={(e: any) => {
                  inputChange.next(e.target.value)
                  setSearchText(e.target.value)
                }}
              />
              <CheckBox
                classNameCheck="h-[18px] w-[18px] min-w-[18px]"
                className=" mt-[8px] pl-[0px] text-caption text-text-primary"
                name="Buscar en todo el país"
                selected={isCheckedSearchCountry}
                onSelected={() => {
                  setIsCheckedSearchCountry(!isCheckedSearchCountry)
                }}
              />
            </div>}
        </div>



        {(!listPatientLoading || !listAttentsPatientLoading) && (
          <React.Fragment>
            <section data-testid="Table" className="w-full overflow-auto scrollbar">
              <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-bg7">
                  <tr className="even:border-t-[1px] even:border-t-grey-200">
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Nro. Documento
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Nombre y Apellidos
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      N° de contacto
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Dirección
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    ></th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      Fecha de registro
                    </th>
                    <th
                      scope="col"
                      className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
                    >
                      N° de Historia Clínica
                    </th>
                  </tr>
                </thead>

                {listPatients?.data?.length > 0 && (
                  <tbody className="bg-white divide-y divide-gray-200">
                    {
                      listPatients?.data?.map((item: any, index: any) => (
                        <tr
                          key={index}
                          className="h-[54px] hover:bg-grey-50 cursor-pointer"
                        >
                          <td
                            className="px-4 py-2 whitespace-nowrap"
                            onClick={() => {
                              history.push(
                                `${routes.clinicHistory.url}/patient/${item.id}`
                              )
                            }}
                          >
                            <div className="text-body2">{item?.documentNumber ? item?.documentNumber : item?.document}</div>
                          </td>
                          <td
                            className="px-4 py-2 "
                            onClick={() => {
                              history.push(
                                `${routes.clinicHistory.url}/patient/${item.id}`
                              )
                            }}
                          >
                            <div className="text-body2 flex items-center gap-2">
                              {false ? (
                                <img
                                  src={''}
                                  alt={''}
                                  className="rounded-full w-10 h-10"
                                />
                              ) : (
                                <div className="rounded-full bg-[#DFF5FF] text-[#008EA3] min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 flex justify-center items-center text-caption">
                                  {' '}
                                  {item.name?.charAt(0)}
                                  {item.lastname?.charAt(0)}{' '}
                                </div>
                              )}
                              {item.name} {item.lastname}
                            </div>
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap"
                            onClick={() => {
                              history.push(
                                `${routes.clinicHistory.url}/patient/${item.id}`
                              )
                            }}
                          >
                            <div className="text-body2">{item.phone}</div>
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap"
                            onClick={() => {
                              history.push(
                                `${routes.clinicHistory.url}/patient/${item.id}`
                              )
                            }}
                          >
                            <div className="text-body2">{item?.address?.name ? item?.address?.name : item?.address}</div>

                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <a href={`https://www.google.com/maps/search/?api=1&query=${item?.address?.lat ? item.address?.lat : item?.lat},${item?.address?.lng ? item.address?.lng : item?.lng}`} target="_blank" rel="noopener noreferrer">
                              <button
                                className="border-nonde text-body2 p-0 text-primary-main"
                              >
                                Ver ubicación
                              </button>
                            </a>
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap"
                            onClick={() => {
                              history.push(
                                `${routes.clinicHistory.url}/patient/${item.id}`
                              )
                            }}
                          >
                            <div className="text-body2">
                              {item.admissionDate
                                ? Moment(item.admissionDate).format('YYYY/MM/DD')
                                : '-'}
                            </div>
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap"
                            onClick={() => {
                              history.push(
                                `${routes.clinicHistory.url}/patient/${item.id}`
                              )
                            }}
                          >
                            <div className="text-body2">
                              {item.nhc || '-'}
                            </div>
                          </td>
                        </tr>

                      ))
                    }
                  </tbody>
                )}

              </table>
            </section>
            {listPatients?.data?.length <= 0 &&
              <div className={'flex flex-row justify-center mt-6 mb-6 p-5'}>

                <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px]	h-full  text-left text-caption '} image={IconEmpty}>
                  <br />
                  <br />
                  <br />
                  {userCurrent?.roleSlug === ROLE.PROFESSIONAL_HEALTH ?
                    'No tienes pacientes para esta fecha.' :
                    'No tienes pacientes.'
                  }
                </ImageError>
              </div>
            }

            <div className="flex justify-end mt-[24px] px-4">
              <Pagination
                currentPage={listPatients?.currentPage}
                totalCount={listPatients?.total}
                pageSize={pageSize}
                onPageChange={handleChangePage}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ClinicHistoryPatients

