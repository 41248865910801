import IconFile from 'assets/icons/icon_file.svg';
import LabelForm from 'components/LabelForm';
import Input from 'components/input';
import Avatar from 'components/Avatar';
import AnnotationsCard from 'components/AnnotationsCard';
import IconSend from 'assets/icons/icon_send.svg';
import React, { useState } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { useCreateAnnotation } from '../../hooks/useAnnotation'
import Moment from 'moment'
import { useQueryClient } from 'react-query'
import useUser from 'features/shared/hooks/useUser';
import { PlanTodayAnotationsModel } from 'features/PatientDashboard/hooks/userPlanToday';

interface Props {
  className?: string
  planId?: any
  patientId?: number
  patientView: boolean
  data: PlanTodayAnotationsModel[]
}

const Annotation: React.FC <Props> = ({ planId, className, patientId, patientView, data }) => {
  const queryClient = useQueryClient()
  const { mutate } = useCreateAnnotation()
  const { userCurrent } = useUser();

  const [message, setMessage] = useState('');

  const handleConversations = () => { 
    if (message) {
      mutate({
        planId,
        userId: userCurrent?.id,
        message
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries('planToday')
        }
      })
      setMessage('')
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleConversations()
    }
  }

  return (
    <div className={className}>
      <div className='bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full'>
        <div className="flex justify-between items-center mb-4">
          <LabelForm
            icon={IconFile}
            label="Anotaciones de pacientes"
            className='text-[14px] leading-[20px] font-medium'

          />
        </div>
        <ScrollToBottom  followButtonClassName='ScrollToBottom__btn' scrollViewClassName=' overflow-y-scroll scrollbar' className=" bg-white rounded-lg  w-full h-[360px] ">
          <div data-testid="annotationList" className='pr-[16px]'>
            {
              data?.map((conversation: PlanTodayAnotationsModel, i) => (
                <React.Fragment key={i}>
                  <Avatar
                    name={conversation?.name}
                    role={conversation?.speciality}
                    image={conversation?.image}
                    className={`${(patientView ? conversation?.userId !== patientId : conversation?.userId === patientId) ? 'mb-2 flex-row-reverse justify-end' : 'mb-2 justify-end'}`}
                  />
                  <AnnotationsCard
                    comentary={conversation && `Comentario (${Moment(conversation?.createdAt).format('DD/MM/YYYY, HH:mm:ss')})`}
                    description={conversation?.message}
                    className={`${conversation?.userId === patientId ? 'bg-[#F6F6F6] mb-2' : 'bg-[#DEF1FF] mb-2'}  `}
                  />
                </React.Fragment>
              ))
            }
          </div>
        </ScrollToBottom>
        <Input
          label="Mensaje"
          data-testid="inputAnnotation"
          icon={IconSend}
          value={message}
          send={true}
          sendValidate={message? true : false}
          onChange={(event) => setMessage(event.target.value)}
          onKeyDown={handleKeyDown}
          onClickIcon={() => {
            handleConversations();
          }}
        />
      </div>
    </div>
  )
}

export default Annotation