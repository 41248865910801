import FeaturesProvider from 'features/shared/state'
import ReactQueryProvider from './reactQuery/ReactQueryProvider'

const AppProvider: React.FC = ({ children }) => {
  return (
    <ReactQueryProvider>
      <FeaturesProvider>{children}</FeaturesProvider>
    </ReactQueryProvider>
  )
}

export default AppProvider
