import { routes } from 'routes/routing';

import IconHealth from 'assets/icons/icon_health.svg';
import LabelForm from 'components/LabelForm';
import Input from 'components/input';
import { Controller, useForm } from 'react-hook-form';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import React, { useEffect, useState } from 'react';
import Moment from "moment";
import { PatientModel } from 'features/Patient/models/Patient.model';
import Loader from 'components/Loader';
import { useHistory } from 'react-router'
import InputRadio from 'components/InputRadio';
import InputDatePicker from 'components/InputDatePicker';
import Supplies from 'components/WorkPlan/Supplies';
import { SuppliesServiceModel } from 'components/WorkPlan/hooks/useSupplies';
import ConfirmModal from './ConfirmModal';
import { DataEpicrisisModel, useGetDataEpicrisis, useRegisterEpicrisis } from 'features/ClinicHistory/hooks/useEpicrisis';
import InputSelectSearch from 'components/InputSelectSearch';
import getDiagnosticos from 'features/shared/services/getDiagnosticos'
import { onAlert } from 'components/Alert';

interface EpicrisisProps {
  serviceId?: number,
  patientData?: PatientModel
}

const optionRadioButton = [{ name: 'Alta a domicilio', value: 1 }, { name: 'Reingreso a clínica', value: 2 }, { name: 'Alta voluntaria', value: 3 }, { name: 'Fallecimiento', value: 4 }];

const Epicrisis: React.FC<EpicrisisProps> = ({ serviceId, patientData }) => {
  const history = useHistory();
  const [typeEpicrisis, setTypeEpicrisis] = useState(1);

  const [dataEpicrisis, setDataEpicrisis] = useState<DataEpicrisisModel>();
  const { mutateAsync: getDataEpicrisis, isLoading: isLoadingGetDataEpicrisis } = useGetDataEpicrisis();
  const [deathDate, setDeathDate] = useState(new Date());
  const [suppliesObject, setSuppliesObject] = useState<SuppliesServiceModel[]>();
  const [isSuppliesObjectValid, setIsSuppliesObjectValid] = useState<boolean>(true);

  const { register, watch, control, handleSubmit, setValue } = useForm();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { mutateAsync: registerEpicrisis, isLoading: loadingRegisterEpicrisis } = useRegisterEpicrisis();

  const [diagnosisDischarge, setDiagnosisDischarge] = React.useState({ value: '', label: '' });
  const [isSelectLoading, setIsSelectLoading] = React.useState<boolean>(false);
  const [optionsDiagnosisDischarge, setOptionsDiagnosisDischarge] = React.useState<{ value: string, label: string }[]>([]);

  const [diagnosisDischarge1, setDiagnosisDischarge1] = React.useState({ value: '', label: '' });
  const [diagnosisDischarge2, setDiagnosisDischarge2] = React.useState({ value: '', label: '' });
  const [directCause, setDirectCause] = React.useState({ value: '', label: '' });
  const [indirectCause, setIndirectCause] = React.useState({ value: '', label: '' });
  const [basicCause, setBasicCause] = React.useState({ value: '', label: '' });

  useEffect(() => {
    if (serviceId) {
      onGetDataEpicrisis();
    }
  }, [serviceId]);

  const onGetDataEpicrisis = async () => {
    getDataEpicrisis(serviceId).then(res => {
      if (res.data) {
        setDataEpicrisis(res.data);
      }
    })
  }

  useEffect(() => {
    setValue('summary_anamnesis', dataEpicrisis?.currentIllnes?.summaryAnamnesis);
  }, [dataEpicrisis])

  const onSubmitForm = async () => {
    const listSchedule:any = {
    'every_2h': 'Cada 2 horas',
    'every_4h': 'Cada 4 horas',
    'every_6h': 'Cada 6 horas',
    'every_8h': 'Cada 8 horas',
    'every_12h': 'Cada 12 horas',
    'every_24h': 'Cada 24 horas'
    };

    const body = {
      userId: patientData?.userId,
      serviceId: serviceId,
      service: {
        dischargeCondition: optionRadioButton[typeEpicrisis - 1].name,
        dischargeDisability: watch('disability_condition'),
      },
      currentIllnes: {
        summaryAnamnesis: watch('summary_anamnesis'),
        currentPhysicalExam: watch('current_phisical_exam'),
        laboratoryExam: watch('laboratory_exam'),
      },
      evolution: {
        dischargeDiagnosis: `${diagnosisDischarge.label}`,
        secondaryDiagnosis1: `${diagnosisDischarge1.label}`,
        secondaryDiagnosis2: `${diagnosisDischarge2.label}`,
      },
      dischargeTreatment: {
        dischargePrescription: suppliesObject ? suppliesObject.map((el: SuppliesServiceModel) => ({
          "medicine": el.supplyName,
          "via": el.via,
          "quantity": el.quantity,
          "medicationStartTime": el.medicationStartTime,
          "scheduleText": el.schedule ? listSchedule?.[el.schedule] : '',
          "schedule": el.schedule,
          "current_therapeutics": el.current_therapeutics,
          "daysTreatment": el.daysOfTreatment || el.days_of_treatment,
          "createdAt": Moment(new Date()).format('DD/MM/YYYY')
        })) : [],
        indications: watch('indications'),
      },
      deathInformation: typeEpicrisis === 4 ? {
        place: watch('place_occurrence'),
        date: deathDate,
        directCause: `${directCause.label}`,
        intermediateCause: `${indirectCause.label}`,
        basicCause: `${basicCause.label}`,
        cmd: watch('number_death_certificate'),
      } : null
    };

    registerEpicrisis(body).then(res => {
      if (res.data) {
        history.push(routes.clinicHistory.url);
        onAlert.success('Registro exitoso');
      } else {
        onAlert.error(res);
      }
    })
  }

  const isValidForm = (): boolean => {
    return (
      !!watch('disability_condition')
      && !!watch('current_phisical_exam') &&
      !!diagnosisDischarge.value &&
      isSuppliesObjectValid && !!watch('indications') &&
      (typeEpicrisis === 4 ? (!!watch('place_occurrence') && !!deathDate && !!directCause.value && !!indirectCause.value && !!basicCause.value && !!watch('number_death_certificate')) : true)
    )
  }

  const handleInputSelectChange = (value: string) => {
    if (value.length > 0) {
      setIsSelectLoading(true);
      getDiagnosticos(value).then((resp: any) => {
        const transformedResponse = resp?.data?.map((data: any) => ({
          value: `${data?.cie10}`,
          label: `[${data?.cie10}] ${data?.descripcion}`
        }));

        setOptionsDiagnosisDischarge([...transformedResponse]);
      }).finally(() => setIsSelectLoading(false));
    }
  };


  return (
    <React.Fragment>
      {(loadingRegisterEpicrisis || isLoadingGetDataEpicrisis) && <Loader />}

      <div className='flex items-center'>
        {false ? (<img src={''} alt={''} className="rounded-full w-20 h-20" />
        ) : (
          <div className='rounded-full bg-[#DFF5FF] text-[#008EA3] w-20 h-20 flex justify-center items-center text-headline3'>
            {(dataEpicrisis?.personalInformation.name)?.charAt(0).toUpperCase()} {(dataEpicrisis?.personalInformation.lastname)?.charAt(0).toUpperCase()}
          </div>
        )
        }
        <p className='ml-4 text-caption text-gray-600'>
          <span className='text-body1' >{dataEpicrisis?.personalInformation.name} {dataEpicrisis?.personalInformation.lastname} ({dataEpicrisis?.personalInformation.age}) ({dataEpicrisis?.personalInformation.gender})</span> <br />
          {dataEpicrisis?.personalInformation.documentType}: {dataEpicrisis?.personalInformation.document} <br />
          NHC: {dataEpicrisis?.personalInformation.nhc} <br />
          S.Procedencia: {dataEpicrisis?.personalInformation.admissionOrigin}
        </p>
      </div>

      <div className='mt-6'>
        <InputRadio
          classNameInput="col w-full  "
          className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-4 gap-4  '
          data={optionRadioButton}
          onSelected={(val: any) => { setTypeEpicrisis(val) }}
          selected={typeEpicrisis}
        />
      </div>

      <LabelForm icon={IconHealth} label="Datos de servicio" className='mt-8 mb-2' />

      <div className='grid grid-cols-2 gap-2 mb-4'>
        <Input
          type="text"
          label="Fecha de ingreso"
          value={dataEpicrisis?.service.admissionDate}
          readOnly
          data-testid='admition-date'
        />
        <Input
          type="text"
          label="Fecha de egreso"
          value={Moment(new Date()).format('DD/MM/YYYY')}
          readOnly
          data-testid='discharge-date'
        />
      </div>

      <TextArea
        label="Incapacidad al alta"
        maxLength={200}
        value={watch('disability_condition')}
        {...register('disability_condition')}
        required
        data-testid='discharge-disability'
      />

      <LabelForm icon={IconHealth} label="Enfermedad actual" className='mt-8 mb-2' />
      <div className="mb-2">
        <TextArea
          label="Resumen de anamnesis"
          maxLength={200}
          value={watch('summary_anamnesis')}
          {...register('summary_anamnesis')}
          data-testid='patient-history-summary'
        />
      </div>
      <div className="mb-2">
        <TextArea
          label="Examen fisico actual"
          maxLength={200}
          value={watch('current_phisical_exam')}
          {...register('current_phisical_exam')}
          required
          data-testid='current-phisical-exam'
        />
      </div>
      <div className="mb-2">
        <TextArea
          label="Examen de laboratorio"
          value={watch('laboratory_exam')}
          {...register('laboratory_exam')}
          data-testid='laboratory-exam'
        />
      </div>
      <LabelForm icon={IconHealth} label="Evolución" className='mt-8 mb-2' />

      <div className='flex flex-col sm:flex-row	 sm:grid sm:grid-cols-2 xl:grid-cols-3 gap-4 ' data-testid='patient-evolution'>
        <InputSelectSearch
          label='Diagnostico de egreso o alta'
          value={diagnosisDischarge}
          options={optionsDiagnosisDischarge}
          onSearch={handleInputSelectChange}
          onSelect={(e: any) => setDiagnosisDischarge(e)}
          isLoading={isSelectLoading}
          required
          data-testid='discharge-diagnosis'
        />

        <InputSelectSearch
          label='Diagnostico secundario 1'
          value={diagnosisDischarge1}
          options={optionsDiagnosisDischarge}
          onSearch={handleInputSelectChange}
          onSelect={(e: any) => setDiagnosisDischarge1(e)}
          isLoading={isSelectLoading}
          data-testid='diagnosis-discharge1'
        />

        <InputSelectSearch
          label='Diagnostico secundario 2'
          value={diagnosisDischarge2}
          options={optionsDiagnosisDischarge}
          onSearch={handleInputSelectChange}
          onSelect={(e: any) => setDiagnosisDischarge2(e)}
          isLoading={isSelectLoading}
          data-testid='diagnosis-discharge2'
        />

      </div>


      <LabelForm icon={IconHealth} label="Tratamiento recibido" className='mt-8 mb-2' data-testid='discharge-treatment' />

      <div className='grid grid-cols-1 gap-2' data-testid='received_treatment' >
        {
          dataEpicrisis?.evolution.treatmentReceived.map((item, index) => (
            <div className='grid grid-cols-[3fr,3fr,3fr,2fr,3fr] gap-2' key={index}>
              <Input
                type="text"
                label="Fecha"
                value={item.createdAt}
                readOnly
                data-testid={'fecha-' + index}
              />
              <Input
                type="text"
                label="Medicamento"
                value={item.medicine}
                readOnly
              />
              <Input
                type="text"
                label="Vía de administración"
                value={item.via}
                readOnly
              />
              <Input
                type="text"
                label="Cantidades"
                value={item.quantity}
                readOnly
              />
              <Input
                type="text"
                label="Horario"
                // value={item.schedule.replace('every_', 'C/')}
                value={item.scheduleText}
                readOnly
              />
            </div>
          ))
        }
      </div>

      <LabelForm icon={IconHealth} label="Resumen de evolución/Complicaciones" className='mt-8 mb-2' />

      <div className="w-full " data-testid='summary-evolution-complications'>
        <table className="min-w-full divide-y divide-gray-200 border-collapse">
          <thead className="bg-bg7">
            <tr className='even:border-t-[1px] even:border-t-grey-200'>
              <th className="text-left text-body2 text-text-primary font-medium h-[38px] px-4" >
                Fecha
              </th>
              <th className="text-left text-body2 text-text-primary font-medium h-[38px] px-4" >
                Resumen
              </th>
            </tr>
          </thead>
          <tbody data-testid="epicrisis__table_evolutionSummary" className="bg-white divide-y divide-gray-200">
            {
              dataEpicrisis && dataEpicrisis?.evolution.evolutionSummary.length > 0 ? (
                dataEpicrisis?.evolution.evolutionSummary.map((evol, index) => (
                  <tr key={index} className="h-[54px] hover:bg-grey-50 cursor-pointer">
                    <td className="text-body2">{evol.createdAt}</td>
                    <td className="text-body2">{evol.description}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2} className="text-caption">Ninguno</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>


      <div data-testid="epicrisis__patient_treatment" className='relative my-8'>
        <LabelForm icon={IconHealth} label="Tratamiento de alta" className='absolute left-0 mt-2' />
        <Supplies
          patientData={patientData}
          isPlainForm={true}
          deleteValue={true}
          onChangeSupplies={(list: SuppliesServiceModel[], isValid: boolean) => {
            setSuppliesObject(list);
            setIsSuppliesObjectValid(isValid);
          }}
        />
      </div>

      <div className='mt-4'>
        <TextArea
          data-testid="indications-treatment-patient"
          label="Indicaciones"
          maxLength={200}
          value={watch('indications')}
          {...register('indications')}
          required
        />
      </div>

      {typeEpicrisis === 4 && (
        <>
          <LabelForm icon={IconHealth} label="En caso de fallecimiento" className='mt-8 mb-2' />

          <div className='grid grid-cols-3 gap-2'>
            <Controller
              control={control}
              name='place_occurrence'
              render={({ field: { onChange, value, name, ref } }) => (
                <InputSelect
                  label="Lugar de ocurrencia"
                  name="place_occurrence"
                  required
                  onSelect={val => {
                    onChange(val);
                  }}
                  value={value}
                  ref={ref}
                >
                  <option value={'Domicilio'} >Domicilio</option>
                  <option value={'Durante el traslado'} >Durante el traslado</option>
                  <option value={'Institución de salud'} >Institución de salud</option>
                </InputSelect>
              )}
            />

            <InputDatePicker
              label="Fecha del fallecimiento"
              onChange={(date: any) => setDeathDate(date)}
              selected={deathDate}
              dateFormat="dd/MM/yyyy"
              locale="es"
              required
            />

            <InputSelectSearch
              label='Causa directa'
              value={directCause}
              options={optionsDiagnosisDischarge}
              onSearch={handleInputSelectChange}
              onSelect={(e: any) => setDirectCause(e)}
              isLoading={isSelectLoading}
              required
            />

            <InputSelectSearch
              label='Causa indirecta'
              value={indirectCause}
              options={optionsDiagnosisDischarge}
              onSearch={handleInputSelectChange}
              onSelect={(e: any) => setIndirectCause(e)}
              isLoading={isSelectLoading}
              required
            />

            <InputSelectSearch
              label='Causa básica'
              value={basicCause}
              options={optionsDiagnosisDischarge}
              onSearch={handleInputSelectChange}
              onSelect={(e: any) => setBasicCause(e)}
              isLoading={isSelectLoading}
              required
            />

            <Input
              type="text"
              label="Número de certificado de defunción"
              value={watch('number_death_certificate')}
              {...register('number_death_certificate')}
              required
            />
          </div>
        </>
      )}

      <div className="flex justify-center mt-6 col-span-full">
        <Button
          type="submit"
          variant='primary'
          size="large"
          onClick={() => setShowConfirmModal(true)}
          disabled={!isValidForm()}
        >
          Guardar
        </Button>
      </div>

      {showConfirmModal && (
        <ConfirmModal
          onAcceptProcess={() => onSubmitForm()}
          onCloseModal={() => setShowConfirmModal(false)}
        />
      )}
    </React.Fragment>
  )
}

export default Epicrisis;