import { FC } from 'react'

import IconNotification from 'assets/icons/icon_notification.svg'
import IconArrowForward from 'assets/icons/icon_arrow_forward_black.svg'

import { GoToReadNotificationsProps } from './types'

export const GoToReadNotifications: FC<GoToReadNotificationsProps> = ({
  onClick,
}) => {
  return (
    <div
      className={
        'flex flex-row flex-wrap rounded-2xl py-4 px-4 justify-between items-center basis-[294px] grow-1 shrink-1 bg-[#91BBCC]'
      }
    >
      <div className={'flex flex-row flex-wrap gap-2 items-center'}>
        <img
          src={IconNotification}
          alt={IconNotification}
          className="sm:w-[24px] sm:h-[24px]"
        />
        <span className={'text-[#001C2F] text-caption font-normal max-w-[86px]'}>
          {'Notificaciones atendidas'}
        </span>
      </div>
      <div
        className={
          'flex items-center justify-center w-[91px] h-[24px] px-2 py-1 text-caption rounded-lg bg-white/20 text-[#001C2F] cursor-pointer'
        }
        onClick={onClick}
      >
        Visualizar
        <img
          src={IconArrowForward}
          alt={IconArrowForward}
          className="sm:w-[16px] sm:h-[16px]"
        />
      </div>
    </div>
  )
}

