interface LabelFormProps {
  icon?: any,
  label: string,
  className?: string,
}

const LabelForm: React.FC<LabelFormProps> = ({
  icon,
  label,
  className,
  children
}) => (
  <div className={`flex items-center gap-2 text-subtitle1 ${className}`}>
    {icon && <img src={icon} alt="icon" className='w-4 h-4' />} {label} {children}
  </div>
)

export default LabelForm;