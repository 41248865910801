import React, { useState } from 'react';
import { ClinicModel } from 'features/UserManagement/types/clinic';
import Modal from 'components/modal';
import { useTranslation } from 'react-i18next';
import ItemTableClinic from 'features/UserManagement/components/ItemTableClinic';
interface TableProps {
  data?: ClinicModel[];
  className?: string;
  handleClickListClinic?: (e: any) => void;
  handleActionEdit?: (e: any) => void;
  handleCancel?: (id: number, response?: any) => void;
  handleSave: (sendData: any, action?: string) => void
  handleActivate: (index: number, sendData?: any) => void
}

const TableClinic: React.FC<TableProps> = ({
  data,
  className,
  handleCancel,
  handleSave,
  handleActivate
}) => {
  const { t } = useTranslation()
  const [showConfirm, setShowConfirm] = useState(false);
  const [isClinicActivate, setIsClinicActivate] = useState(false);

  const handleConfirm = () => {
    setShowConfirm(false);
  }

  return (
    <>
      <section data-testid="Table" className={`w-full overflow-auto scrollbar ${className}`}>
        <table className="min-w-full divide-y divide-gray-200 border-collapse">
          <thead className="bg-bg7">
            <tr className='even:border-t-[1px] even:border-t-grey-200'>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Nombre de clínica
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Estado
              </th>
              <th
                scope="col"
                className="text-left text-body2 text-text-primary font-medium h-[38px] px-4"
              >
                Acciones
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              data && data.length > 0 ? (
                data.map((item: any, index: any) => (
                  <>

                    <ItemTableClinic
                      index={index}
                      status={item.status}
                      id={item.id}
                      name={item.name}
                      handleActivate={handleActivate}
                      handleCancel={handleCancel}
                      handleSave={handleSave}
                    />
                  </>
                ))
              ) : <tr className='h-[54px]'><td colSpan={5} className="px-4 text-center" >No se encontraron resultados</td></tr>
            }
          </tbody>
        </table>
      </section>
      <Modal
        showModal={showConfirm}
        setShowModal={handleConfirm}
        text={isClinicActivate ? 'Clinica Activada' : 'Clínica Desactivada'}
        btn={t('end')} />
    </>
  )
}

export default TableClinic;
