import React, { } from "react";
import IconClose from 'assets/icons/icon_close.svg';
import Button from "components/Button";
import Error from "./img/error.png";


interface Props {
  showModal: boolean;
  onCloseModal: () => any;
  onSubmitForm: () => any;
}

const Confirmation: React.FC<Props> = (props) => {



  if (props.showModal) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative py-8 px-6 w-full max-w-[574px] w-full  ">
            <div
              className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4"
            >
              <div className=' border-[#14141426] mb-[24px]  flex justify-between items-center mb-4'>
                <div className="flex items-center ">
                <img src={Error} alt={Error} />
                <span className='text-subtitle1 text-text-primary ml-[24px]'>
                  ¿Estás seguro de querer rechazar?</span>
                </div>
                
                <button
                  onClick={() => props.onCloseModal()}
                  className="h-[30px] w-[30px] flex items-center justify-center"
                >
                  <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
                </button>
              </div>

              <div className="text-Fs10 max-w-[75%] mx-auto text-text-secondary">
              Si rechazas los términos y condiciones, no podrás continuar en la web.
              </div>

              <div className="flex flex-wrap sm:flex-nowrap		w-full justify-center gap-4 mt-6">
                <Button type="button" variant='secondary' size="small"
                  onClick={() => props.onSubmitForm()}

                >Rechazar</Button>
                <Button type="button" variant='primary' size="small"
                  onClick={() => props.onCloseModal()}

                >Continuar</Button>

              </div>

            </div>


          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  } else {
    return <></>
  }
};

export default Confirmation;
