import React, {
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { useTranslation } from 'react-i18next'

import { CountryContext } from 'layout'
import IconSearch from 'assets/icons/icon_search.svg';
import IconAdd from 'assets/icons/icon_add.svg';

import Button from 'components/Button';
import { ShadowWrapper } from 'components/ShadowWrapper'
import Input from 'components/input';
import Pagination from 'components/Pagination';

import TableClinicNetwoks from 'features/UserManagement/components/TableClinicNetworks';
import FormClinicNetwork from 'features/UserManagement/components/FormClinicNetwork'
import {
  useGetClinicNetworkSearchResults,
  useGetClinicNetworkById,
  useCreateClinicNetwork,
  useUpdateClinicNetwork
} from 'features/UserManagement/hooks/useClinicNetwork';
import { ClinicNetworkSearchModel, ClinicNetworkModel } from 'features/UserManagement/types/clinicNetwork';
import { onAlert } from 'components/Alert';
import LoaderMini from 'components/LoaderMini';

const useCountry = () => useContext(CountryContext)
const pageSize = 10;
const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

const ClinicNetworks: React.FC = () => {
  const { t } = useTranslation()
  const country = useCountry()
  const [isShowForm, setIsShowForm] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [clinicNetwork, setClinicNetwork] = useState<any>(null);
  const [listNetworks, setListNetworks] = useState<ClinicNetworkSearchModel>(
    {
      currentPage: 1,
      //data: [],
      data: clinicNetwork,
      total: 1,
      totalPages: 1
    }
  );

  const { mutateAsync: mutateAsyncListNetwork, isLoading: isLoadingGetList } = useGetClinicNetworkSearchResults();
  const { mutateAsync: asyncGetNetworkById, isLoading: isLoadingGetNetworkById } = useGetClinicNetworkById()
  const { mutateAsync: asyncCreateClinicNetwork, isLoading: isLoadingCreateClinicNetwork } = useCreateClinicNetwork()
  const { mutateAsync: asyncUpdateClinicNetwork, isLoading: isLoadingUpdateNetwork } = useUpdateClinicNetwork()

  const handleClickAdd = () => {
    setClinicNetwork(null)
    setIsShowForm(true);
  }

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  }

  const handleChangeSearch = (e: any) => {
    inputChange.next(e.target.value);
    setSearchText(e.target.value);
  }

  const handleCloseModal = () => {
    setIsShowForm(false);
  }

  const handleClickEdit = async (item: ClinicNetworkModel) => {
    const response = await (asyncGetNetworkById(item.id));
    if (response?.data) {
   /*    console.log(response?.data, 'responsedata') */
      setClinicNetwork(response.data);
      setIsShowForm(true);
    }
  }

  const handleSendData = async (data: any) => {
    let response = null;
    let edit = false;
    if (data.id) {
      edit = true;
      response = await asyncUpdateClinicNetwork(data)
    } else {
      response = await asyncCreateClinicNetwork(data)
      edit = false;
    }
    if (Number.isInteger(response?.data)) {
      onAlert.success(edit ? 'Red de Clínica actualizada' : 'Red de Clínica creada');
      onGetListClinicNetwork(searchValue, currentPage)
      handleCloseModal()
    } else {

      if (response?.data) {
        onAlert.error(response?.data);
      } else {
        onAlert.error(response);
      }
    }
  }

  const handleState = async (data: any) => {
    const send = {
      ...data,
      status: (data.status === 1) ? 0 : 1
    }
    const response = await asyncUpdateClinicNetwork(send)
    if (Number.isInteger(response?.data)) {
      onAlert.success('Red de Clínica actualizada');
      onGetListClinicNetwork(searchValue, currentPage)
    } else {

      if (response?.data) {
        onAlert.error(response?.data);
      } else {
        onAlert.error(response);
      }
    }
  }

  const onGetListClinicNetwork = useCallback(async (searchValue?: string, currentPage?: number) => {
    const searchWord = (searchValue === searchText) ? `&searchText=${searchValue}` : '';
    let query = `?page=${currentPage}${searchWord}`
    if (country) {
      if (country === 'Todos') {
        query = `?page=${currentPage}${searchWord}`;
      } else {
        query = `?countryId=${country}&page=${currentPage}${searchWord}`;
      }
    }
    const response = await mutateAsyncListNetwork(query);
    setListNetworks(response.data);
  }, [country, searchText, searchValue])

  useEffect(() => {
    onGetListClinicNetwork(searchValue, currentPage);
    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value) => {
      setSearchValue(value);
    });

    return () => {
      return searchSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (searchValue === searchText) {
      onGetListClinicNetwork(searchValue, 1);
    }
  }, [searchValue, searchText, onGetListClinicNetwork]);

  useEffect(() => {
    if (currentPage) {
      onGetListClinicNetwork(searchValue, currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1)
  }, [country])


  return (
    <ShadowWrapper>
      <div className='flex flex-col justify-between p-4  items-center 	w-full gap-8  xl:flex-row	 xl:gap-0	' >
        <Button
          data-testid="button_add_new_clinic"
          type="submit"
          variant='primary'
          onClick={handleClickAdd}
          className='flex w-full xl:w-auto	 items-center ml-4 '>
          <img src={IconAdd} alt='agregar' />
          <span className='ml-2'>Agregar</span>
        </Button>
        <Input
          data-testid="input_search_clinicNetwork"
          label="Buscar por red de clínicas"
          value={searchText}
          icon={IconSearch}
          onChange={handleChangeSearch}
          classNameWrapper='flex-[0_0_100%] w-full  xl:max-w-[416px]'
        />
      </div>

      <>
        <div className="relative">
        {isLoadingGetList && <LoaderMini />}
          <TableClinicNetwoks
            data={listNetworks?.data}
            handleActionEdit={handleClickEdit}
            handleState={handleState}
          />
          </div>
        {
          isShowForm &&
          <FormClinicNetwork
            handleSendData={handleSendData}
            isLoading={isLoadingCreateClinicNetwork ||isLoadingUpdateNetwork ||isLoadingGetNetworkById }
            storage={clinicNetwork}
            onCloseModal={handleCloseModal} />
        }

        <div
          className='flex justify-end mt-[24px] px-4'
        >
          <Pagination
            currentPage={listNetworks?.currentPage}
            totalCount={listNetworks?.total}
            pageSize={pageSize}
            onPageChange={handleChangePage}
          />
        </div>

      </>

    </ShadowWrapper>
  )
}

export default ClinicNetworks