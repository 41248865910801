import Button from "components/Button";
import Loader from "components/Loader";
import usePutTermsConditions from "features/shared/hooks/usePutTermsConditions";

import React, { useState } from "react";
import useUser from 'features/shared/hooks/useUser'
import "react-toastify/dist/ReactToastify.css";

import Confirmation from "./confirmation";

interface Props {
  showModal: boolean;
  termsConditionspdf: any;
  userId: number;
  setShowModal: () => any;
  changeShowMain: (value: boolean) => any;

}

const ModalTermsConditions: React.FC<Props> = (props) => {
  const { signOut } = useUser()
  const [showSeeModal, setShowSeeModal] = useState<boolean>(false);

  const [sectionstep, setIsectionstep] = useState(0);
  const [isCheckedTerms, setIsCheckedTerms] = React.useState(false)
  const [isCheckedPolitics, setIsCheckedPolitics] = React.useState(false)
  const [isCheckedConsent, setIsCheckedConsent] = React.useState(false)
  const putTermsCondition = usePutTermsConditions()
  const defaulUrlPdf = 'https://www.macroplastics.com/images/docs/Terminos-y-Condiciones-de-Venta.pdf';
  const onSubmit = async () => {
    const sendPutTermsConditions = await putTermsCondition.mutateAsync(props.userId)
/*     console.log(sendPutTermsConditions);
 */
    if (Number.isInteger(sendPutTermsConditions?.data )) {
      props.setShowModal();
      props.changeShowMain(true)

    }
  }

  const next = () => {
    if (sectionstep <= 1) {
      setIsectionstep(sectionstep + 1)
    } else {
      setIsectionstep(sectionstep)
    }
  }
  const click = () => {
    props.setShowModal()
    signOut()
  }
  const handleChangeTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedTerms(e.target.checked)
  }
  const handleChangePolitics = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedPolitics(e.target.checked)
  }
  const handleChangeConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedConsent(e.target.checked)
  }
  return (

    <div data-testid="Modal-2" >

      {props.showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            {putTermsCondition.isLoading && <Loader />}
            <div className="relative p-4 w-full max-w-[80vw] h-[80vh] w-full flex ">

              <div className="border-0 rounded-lg shadow-lg relative h-full flex flex-col w-full bg-white outline-none focus:outline-none">

                <Confirmation
                  showModal={showSeeModal}
                  onCloseModal={() => { setShowSeeModal(false) }}
                  onSubmitForm={() => click()}
                />
                <div className=" h-full  w-full flex flex-col items-center justify-between md:p-10 p-2 pt-2 pb-4">
                  <div className="h-[5%] flex justify-center items-center mt-2 mb-2 w-full relative  ">
                    {sectionstep === 0 &&
                      <h1 className="text-center text-Fs8 font-medium	">Términos y condiciones</h1>
                    }
                    {sectionstep === 1 &&
                      <h1 className="text-center text-Fs8 font-medium	">Política de Privacidad Datos Personales</h1>
                    }
                    {sectionstep === 2 &&
                      <h1 className="text-center text-Fs8 font-medium	">Consentimiento informado para Hospitalización Domiciliaria “Home Hospital” y
                        Telemonitoreo</h1>
                    }
                    <div className="absolute top-0 right-0 text-bg5 text-center text-Fs8 font-medium ">{sectionstep + 1}/3</div>

                  </div>
                  <div className="h-[60%] md:h-[80%]  w-full">
                    {sectionstep === 0 &&
                      <iframe src={`${props.termsConditionspdf?.documentInformedConsent ? props.termsConditionspdf?.documentInformedConsent : defaulUrlPdf}`} title="testPdf" height="100%" width="100%" />
                    }
                    {sectionstep === 1 &&
                      <iframe src={`${props.termsConditionspdf?.documentPrivacyPolicy ? props.termsConditionspdf?.documentPrivacyPolicy : defaulUrlPdf}`} title="testPdf" height="100%" width="100%" />
                    }
                    {sectionstep === 2 &&
                      <iframe src={`${props.termsConditionspdf?.documentTermsCondition ? props.termsConditionspdf?.documentTermsCondition : defaulUrlPdf}`} title="testPdf" height="100%" width="100%" />
                    }

                  </div>
                  {putTermsCondition.isError && 'Oops!, Vuelve a interlo despues'}

                  <div className="h-[30%] md:h-[10%]  flex flex-wrap sm:flex-nowrap		w-full justify-center gap-4 mt-6">
                    <Button style={{ width: '100%' }} type="button" variant='secondary' size="large" onClick={() => setShowSeeModal(true)} >Rechazar</Button>
                    {sectionstep === 0 &&
                      <Button style={{ width: '100%' }} type="button" variant='primary' size="large" disabled={!isCheckedTerms} onClick={() => next()}  >Aceptar</Button>
                    }
                    {sectionstep === 1 &&
                      <Button style={{ width: '100%' }} type="button" variant='primary' size="large" disabled={!isCheckedPolitics} onClick={() => next()}  >Aceptar</Button>
                    }
                    {sectionstep === 2 &&
                      <Button style={{ width: '100%' }} type="button" variant='primary' size="large" disabled={!isCheckedConsent} onClick={() => onSubmit()}  >Aceptar y finalizar</Button>
                    }
                  </div>

                  <div className="h-[5%]   flex  w-full mt-2 text-sm  flex-wrap">
                    <div className="h-fit flex  w-full justify-center">
                      {sectionstep === 0 &&
                        <div className="form-check w-full flex">
                          <label className="checkbox ">
                            <input
                              id="flexCheckDefault"
                              type="checkbox"
                              checked={isCheckedTerms}
                              onChange={handleChangeTerms}
                            />

                          </label>

                          <label
                            className=" ml-3 form-check-label items-center flex font-medium text-Fs9"
                            htmlFor="flexCheckDefault"
                          >
                            He leído y acepto los términos y condiciones.
                          </label>
                        </div>
                      }
                      {sectionstep === 1 &&
                        <div className="form-check w-full flex">
                          <label className="checkbox ">
                            <input
                              id="flexCheckDefault"
                              type="checkbox"
                              checked={isCheckedPolitics}
                              onChange={handleChangePolitics}
                            />

                          </label>

                          <label
                            className=" ml-3 form-check-label items-center flex font-medium text-Fs9"
                            htmlFor="flexCheckDefault"
                          >
                            He leído y acepto Política de Privacidad de Datos Personales.
                          </label>
                        </div>
                      }
                      {sectionstep === 2 &&
                        <div className="form-check w-full flex">
                          <label className="checkbox ">
                            <input
                              id="flexCheckDefault"
                              type="checkbox"
                              checked={isCheckedConsent}
                              onChange={handleChangeConsent}
                            />

                          </label>
                          <label
                            className=" ml-3 form-check-label items-center flex font-medium text-Fs9"
                            htmlFor="flexCheckDefault"
                          >
                            He leído y acepto Consentimiento informado para Hospitalización Domiciliaria “Home Hospital” y Telemonitoreo.
                          </label>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

        </>
      ) : null}
    </div>
  );
};

export default ModalTermsConditions;
