import { useMutation, useQuery } from 'react-query'
import { getUser, getUserMutate, createUser, updateUser, getUserById, activateUser, desactivateUser, getGeofences, postUpdatGeofences, getGeofencesSpecialists } from '../services/user'
import { UserDesactivatePost } from '../types/user';

export function useGetUser(data: any, typeUser: string) {
  return useQuery(['users', data, typeUser], () => getUser(data, typeUser))
}
export function useGetUserMutate() {
  return useMutation((data: any) => getUserMutate(data))
}
export function useGetUserById() {
  return useMutation((data: any) => getUserById(data))
}

export function useCreateUser() {
  return useMutation((data: any) => createUser(data));
}

export function useUpdateUser() {
  return useMutation((data: any) => updateUser(data));
}

export function useActivateUserRole() {
  return useMutation((data: UserDesactivatePost) => activateUser(data));
}

export function useDesactivateUserRole() {
  return useMutation((data: UserDesactivatePost) => desactivateUser(data));
}

export function useGeofences() {
  return useMutation(() => getGeofences())
}
export function useGeofencesSpecialists() {
  return useMutation((data) => getGeofencesSpecialists(data))
}
export function usePostUpdatGeofences() {
  return useMutation((data: any) => postUpdatGeofences(data));
}
