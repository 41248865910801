import { useQuery } from 'react-query'
import getSelectOptions from '../services/getSelectOptions'

function useGetSelectOptions(country:any) {
  const queryKey = ['getSelectOptions',country]

  return useQuery(queryKey, () => getSelectOptions(country))
}

export default useGetSelectOptions
