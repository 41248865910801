import React from 'react'

interface Props {
  className?: string
  handleClick?: React.MouseEventHandler<HTMLDivElement> 
}

export const ShadowWrapper: React.FC<Props> = ({ children, className, handleClick}) => (
  <div
    onClick={handleClick}
    className={`
    bg-white rounded-xl shadow-md py-[16px]
    ${className}
    `}
  >
    {children}
  </div>
)