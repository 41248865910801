import { useQuery } from 'react-query'
import getCheckTermsConditions from '../services/getCheckTermsConditions'

function useGetCheckTermsConditions(patientId:number) {
  const queryKey = ['useGetCheckTermsConditions',patientId]

  return useQuery(queryKey, () => getCheckTermsConditions(patientId))
}

export default useGetCheckTermsConditions
