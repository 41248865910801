import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import InputSelect from 'components/InputSelect'
import { useTranslation } from 'react-i18next'
import { useGetClinic } from 'features/UserManagement/hooks/useClinic';
import { useQueryClient } from 'react-query'

interface Props {
  isRequired?: boolean
  classNameWrapper?: string
  control: any,
  clinicNetworkId?: number
}

const SelectClinic: React.FC<Props> = ({
  isRequired,
  classNameWrapper,
  control,
  clinicNetworkId
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data } = useGetClinic(clinicNetworkId)
  const clinics = data?.data

  useEffect(() => {
    queryClient.invalidateQueries('clinics')
  }, [clinicNetworkId])

  return (
    <Controller
      control={control}
      name='clinicId'
      render={
        ({ field: { onChange, value, name, ref } }) => (
          <InputSelect
            label={t('name_clinic')}
            ref={ref}
            name={'clinicId'}
            value={value}
            onSelect={e => onChange(e)}
            placeholder='Rol'
            required={isRequired}
            classNameWrapper={classNameWrapper}
          >
            {
              clinics && clinics.length > 0 && clinics.map((clinicItem: any, i: number) => (
                <option key={i} value={clinicItem?.id}>{clinicItem?.name}</option>
              ))
            }
          </InputSelect>
        )
      }
    />
)}

export default SelectClinic