import React from 'react'
import LabelForm from 'components/LabelForm'
import Button from 'components/Button';
import ImgDefault from 'assets/images/user_default.svg';

interface Props {
  watch: any
  isEditable?: boolean
  imgProfile?: any
  handleUploadMultimedia: (e: any) => void
  handleClickDeletePhoto: (e: any) => void
}

const UploadProfilePhoto: React.FC<Props> = ({
  watch,
  isEditable,
  imgProfile,
  handleUploadMultimedia,
  handleClickDeletePhoto
}) => {
  return (
    <div className='mb-[24px] flex'>
      <div className='w-[135px] h-[109px] bg-[white] mr-4'>
        <img src={imgProfile?.img?.src || ImgDefault} className="w-[135px] h-[109px]" />
      </div>
      {
        <div>
          <LabelForm
            label={watch('name') ? `${watch('name')} ${watch('lastname')}` : 'Usuario'}
            className='mt-2 mb-4 font-medium' />
          {
            isEditable &&
            <div className='flex'>
              <div className='relative overflow-hidden inline-block '>
                <input
                  className='w-[400px] h-[400px] top-[-35px] cursor-pointer absolute'
                  type='file'
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={handleUploadMultimedia} />
                <Button type="submit" variant='primary'>Subir nueva foto</Button>
              </div>
              <Button
                onClick={handleClickDeletePhoto}
                type="submit"
                className="ml-2"
                variant='secondary'>Eliminar foto</Button>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default UploadProfilePhoto