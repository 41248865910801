import IconCheck from 'assets/icons/icon_check.svg';

interface CheckBoxProps {
  name: string;
  selected: boolean;
  className?:string;
  classNameCheck?:string;
  onSelected: () => any;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const filterActive = 'invert(100%) sepia(0%) saturate(3451%) hue-rotate(56deg) brightness(115%) contrast(98%)';

  return (
    <div 
      className={`
        cursor-pointer flex items-center justify-start h-full 
        px-[calc(16px-1px)] rounded-[16px] text-grey-600 
        ${true ? '' : 'px-[calc(16px-2px)] border-solid border-[1px] !border-error-main'} 
        ${props.className}
      `}
      onClick={() => props.onSelected()}
    >
      <div className={`
        h-[24px] w-[24px] flex items-center justify-center border-solid border-[2px] border-grey-400 rounded-[3px]  text-body2
        ${props.selected ? '!border-primary-dark bg-primary-dark' : ''} 
        ${props.classNameCheck}
      `}>
        {props.selected && <img className='w-[24px] h-[24px]' src={IconCheck} style={{filter: filterActive}} />}
      </div>
      <span className='ml-[8px]'>{props.name}</span>
    </div>
  )
}

export default CheckBox