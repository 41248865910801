import React from "react";

interface TabsProps {
  items?: any;
  step?: number;
  onClickStep?: (value: number) => void;
}


const Tabs: React.FC<TabsProps> = ({
  items,
  step,
  onClickStep,
}) => {
  return (
    <div className="text-sm  border-b border-gray-200 mt-[32px]">
      <ul className="flex flex-wrap ">
        {items?.map((item: any, i: any) =>
          <li onClick={onClickStep && (() => onClickStep(i))} >
            <div className={`inline-block  py-[8px] px-[5px] cursor-pointer	 text-Fs9  ${step === i && 'text-primary-main border-b-2 border-primary-main'} hover:text-primary-main  hover:border-b-2  hover:border-primary-main `}>
            {item.text}
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Tabs;