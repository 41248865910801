import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import IconSearch from 'assets/icons/icon_search.svg';
import IconCloseCircle from 'assets/icons/icon_close_circle.svg';

interface InputSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: any;
  onClearInput?: () => void;
}

const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(({
  className,
  label,
  value,
  onClearInput,
  required,
  ...props
}, ref) => {

  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    onHasValue(value);
  }, [value])
  
  
  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    onHasValue(e.target.value);
    if (props.onChange) props?.onChange(e);
  }
  
  const onHasValue = (val: any) => {
    val? setHasValue(val.length > 0) : setHasValue(false);
  }
  
  return (
    <div>
      <div
        className='
          min-w-[243px] max-w-full h-[56px] text-body1 relative 
          flex items-center justify-start
        '
      >
        <input
          className={`
            peer 
            px-[16px] w-full h-full rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none 
            hover:px-[calc(16px-1px)] hover:border-solid hover:border-[1px] hover:border-grey-400 
            focus:px-[calc(16px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73] 
            ${isValid ? '' : 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'} 
            ${className}
          `}
          ref={ref}
          {...props}
          value={value}
          autoComplete="none"
          onChange={onChangeField}
        />
        <label
          className={`
            px-[16px] absolute text-gray-600 pointer-events-none 
            ${hasValue ? 'mb-6 text-caption text-neutral-600' : ''} 
            peer-focus:text-caption peer-focus:mb-6 
            ${isValid ? '' : 'text-error-main'}
          `}
        >{label}</label>
        {
          hasValue ? (
            <img
              src={IconCloseCircle}
              onClick={onClearInput}
              className={`
                w-[24px] h-[24px] absolute right-[16px] cursor-pointer pointer-events-auto
              `}
            />
          ) : (
            <img
              src={IconSearch}
              className={`
                w-[24px] h-[24px] absolute right-[16px] pointer-events-none
              `}
            />
          )
        }
      </div>
      {/* {
        isValid ? (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-gray-600 
          '>
            {helperText}
          </p>
        ) : (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-error-main 
          '>
            {hasValue && required ? 'Este campo es obligatorio' : errorText }
          </p>
        )
      } */}
    </div>
  )
});

export default InputSearch
