import axios from 'axios'
import constants from 'configs/constants'
import { Auth } from "aws-amplify";


const signOut = async () => {
  try {
    await Auth.signOut();
    resetHeaders();
    window.location.href = "/";

    
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

const fetcher = axios.create({
  baseURL: constants.API_URL,
})

fetcher.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error.message === 'Network Error' && !error.response) {
      Auth.currentSession();
      return 'Se ha producido un error. Inténtalo de nuevo.';
    } else {      
      return error?.response?.data?.error?.message;
    }
  }
)

export const setHeaders = (token: string) => {
  fetcher.defaults.headers.common['Authorization'] = `${token}`
}
export const setHeadersAccessToken = (accessToken: string) => {
  fetcher.defaults.headers.common['accesstoken'] = `${accessToken}`
}
export const setHeadersContentType = () => {
  fetcher.defaults.headers.common['Content-Type'] = `multipart/form-data`
}
export const resetHeaders = () => {
  delete fetcher.defaults.headers.common['Authorization']
}

export default fetcher
