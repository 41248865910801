import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { onAlert } from 'components/Alert';

import {
  resetHeaders,
  setHeaders,
  setHeadersAccessToken,
} from 'features/shared/utils/fetcher'
import jwt_decode from 'jwt-decode'
import useGetAcceso from '../../hooks/usePostCurrent'

type UserContextState = {
  user: { [index: string]: any } | null
  userCurrent: { [index: string]: any } | null
  tokensLoaded: boolean
  signOut: () => void
}

export const UserContext = createContext<UserContextState | undefined>(
  undefined
)

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState(null)
  const [tokensLoaded, setTokenLoaded] = useState(false)
  const [userCurrent, setUserCurrent] = useState(null)
  const getCurrent = useGetAcceso()

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut()
      resetHeaders()
      window.location.href = '/'
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }, [])

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        return await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
      } catch (error) {
        console.log('auth error: ', error)
        return null
      }
    }
    const setupUser = async (user: UserContextState['user'] = null) => {
      const currentUser = user || (await getCurrentUser())      
      currentUser?.getSession(async (error: any, session: any) => {
        if (error) {
          console.log(error)
          return
        }

        if (session) {
          const token = session.getIdToken().getJwtToken()
          const accessToken = session.accessToken.jwtToken
          setHeaders(token)
          setHeadersAccessToken(accessToken)
          setUser(jwt_decode(token))
          setTokenLoaded(true)

          const dataCurrent = await getCurrent.mutateAsync()

          if (dataCurrent?.data?.id) {
            setUserCurrent(dataCurrent.data)
            sessionStorage.setItem('user', JSON.stringify(dataCurrent.data))
          } else {
            onAlert.warning(dataCurrent);
            setTimeout(() => {
              signOut()
            }, 3000);
          }
        }
      })
    }

    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          setupUser(data.payload.data)
          break
        case 'tokenRefresh':
          setupUser()
          break
        case 'tokenRefresh_failure':
          //signOut()
          break
      }
    }

    Hub.listen('auth', listener)
    setupUser()

    return () => {
      Hub.remove('auth', listener)
    }
    // eslint-disable-next-line
  }, [])

  const value = useMemo(
    () => ({ user, userCurrent, tokensLoaded, signOut }),
    [signOut, tokensLoaded, user, userCurrent]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

