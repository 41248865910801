import { Amplify, I18n } from 'aws-amplify'
import authTranslations from 'locales/es/amplify.auth.json'
import awsExports from './aws-exports'

import '@aws-amplify/ui-react/styles.css'

Amplify.configure(awsExports)

I18n.setLanguage('es')
I18n.putVocabularies({ es: authTranslations })
