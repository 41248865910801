import { FC, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { WorkListContext } from 'features/PatientDashboard/PatientWorkPlan/WorkPlanList'
import InputRadio from 'components/InputRadio'
import TextArea from 'components/TextArea'
import Button from 'components/Button'
import SimpleClosingModal from 'components/SimpleClosingModal'
import ScrollToBottom from 'react-scroll-to-bottom'

const WhyNotTakeMedicineModal: FC = () => {
  const { t } = useTranslation()
  const {
    onCloseWhyNotTakeMedicineModal,
    reasonNotTakeMyMedicine,
    setReasonNotTakeMyMedicine,
    commentNotTakeMyMedicine,
    setCommentNotTakeMyMedicine,
    onMedicineNotCheckedClickNo
  } = useContext(WorkListContext)
  const handleCloseModal = () => {
    console.log('close why not take medicine modal')
    onCloseWhyNotTakeMedicineModal()
  }
  const isSubmitDisabled = ()=>{
    if (reasonNotTakeMyMedicine === null || (reasonNotTakeMyMedicine === t('others') ? commentNotTakeMyMedicine === '' : false) ) {
      return true
    }
    else{
      return false
    }
  }
  const handleSubmit = () => {
    const data = {
      reasonNotTakeMyMedicine,
      commentNotTakeMyMedicine,
    }
    onMedicineNotCheckedClickNo(data)

    setCommentNotTakeMyMedicine('')
    setReasonNotTakeMyMedicine(null)
  }
  return (
    <>
      <SimpleClosingModal
        onClose={handleCloseModal}
        title={<span className="text-left text-Fs8 font-medium">{t('let_us_know_why')}</span>}
      >
        <>
          <ScrollToBottom
            mode={'top'}
            followButtonClassName='ScrollToBottom__btn__top'
            scrollViewClassName=" overflow-y-scroll scrollbar"
            className=" bg-white rounded-lg  w-full h-[160px] 2xl:h-[345px] "
          >
            <InputRadio
              onSelected={(val: any) => {
                setReasonNotTakeMyMedicine(val)
              }}
              selected={reasonNotTakeMyMedicine}
              data={[
                { name: t('i_forgot_to_take_the_medicine'), value: t('i_forgot_to_take_the_medicine') },
                { name: t('i_feel_bad_when_i_take_the_medicine'), value: t('i_feel_bad_when_i_take_the_medicine') },
                {
                  name: t('i_feel_the_medicine_is_producing_me_alergies'),
                  value:  t('i_feel_the_medicine_is_producing_me_alergies'),
                },
                { name: t('the_medicine_went_out'), value: t('the_medicine_went_out') },
                { name: t('others'), value: t('others') },
              ]}
              className="flex-wrap min-h-max"
              classNameInput="w-full"
            />
          </ScrollToBottom>
          {reasonNotTakeMyMedicine === t('others') && 
          <div className="mt-6">
            <TextArea
              label="Comentario"
              maxLength={200}
              rows={2}
              value={commentNotTakeMyMedicine}
              onChange={(e) => setCommentNotTakeMyMedicine(e.target.value)}
            />
          </div> 
          }
          <div className="flex items-center justify-center w-full mt-4">
            <Button
              onClick={handleSubmit}
              variant="primary"
              size="large"
              className="w-[100%]"
              disabled={isSubmitDisabled()}
            >
              {t('send')}
            </Button>
          </div>
        </>
      </SimpleClosingModal>
    </>
  )
}

export default WhyNotTakeMedicineModal

