import fetcher from 'features/shared/utils/fetcher'
import { RoleStatusUPDATE } from '../types/role'

export async function getUserRole(query?: string) {
  let url = '/backoffice/roles'
  if (query) {
    url = `${url}${query}`
  }
  return await fetcher.get(url)
}

export async function getRolesSearchResults(query: string) {
  return await fetcher.get<{data: any}>('/backoffice/roles' + query)
}

export async function getRolesActive() {
  return await fetcher.get('/roles')
}

export async function getUserRoleById(roleId: number) {
  return await fetcher.get(`/backoffice/roles/${roleId}`)
}

export async function createUserRole(data: any) {
  const currentData = {...data}
  const currentPermissions = currentData?.permissions?.filter((per:any) => per.actionRead || per.actionWrite).map((per: any) => {
    if (per?.name) delete per.name
    return({
    ...per,
      actionRead: Boolean(per.actionRead),
      actionWrite: Boolean(per.actionWrite),
  })})
  currentData.permissions = currentPermissions
  return await fetcher.post('/backoffice/roles/create', currentData)
}

export async function updateUserRole(data: any) {
  const formatted = {...data}
  const updatePermissions = data.permissions.filter((per:any) => per.actionRead || per.actionWrite).map((per: any) => {
    delete per.id
    if (per?.name) delete per.name
    return({
      ...per,
      actionRead: Boolean(per.actionRead),
      actionWrite: Boolean(per.actionWrite),
  })})
  data.permissions = updatePermissions
  delete data.id
  return await fetcher.put(`/backoffice/roles/${formatted.id}/edit`, data)
}

export async function updateRoleStatus(data: any) {
  const sendData = {...data}
  delete sendData.id
  return await fetcher.put(`/backoffice/roles/${data.id}/status`, sendData as RoleStatusUPDATE)
}

export async function getRolePermissions() {
  return await fetcher.get('/permissions')
}
