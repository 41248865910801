import fetcher from 'features/shared/utils/fetcher'
import { useMutation, useQuery } from 'react-query'

export interface PlanTodayModel {
  medicaments: PlanTodayMedicamentsModel[];
  recommendations: PlanTodayRecommendationsModel[];
  annotations: PlanTodayAnotationsModel[];
  currentService: PlanTodayCurrentServiceModel;
}

export interface PlanTodayMedicamentsModel {
  dateAndTime: string;
  day: string;
  supplyName: string;
  startTime: string,
  supplyId: number;
  description: string,
}

export interface PlanTodayRecommendationsModel {
  id: number;
  userId: number;
  message: string;
  name: string;
  lastname: string;
  speciality: string;
  createdAt: string;
}

export interface PlanTodayAnotationsModel {
  image:any;
  id: number;
  userId: number;
  message: string;
  name: string;
  lastname: string;
  speciality: string;
  sender: string;
  createdAt: string;
}

export interface PlanTodayCurrentServiceModel {
  id: number | null;
}

/*export function useGetAllPlanToday() {
  return useMutation(() => getAllPlanToday());
}*/

export const useGetAllPlanToday = () => useQuery(['planToday'], () => getAllPlanToday())

async function getAllPlanToday() {
  return await fetcher.get(`/plan/today`);
}
export const useGetIntakeMedication = () => {
  return useMutation((serviceId:any) => getIntakeMedication(serviceId));

}

async function getIntakeMedication(serviceId:any) {
  return await fetcher.get(`/services/${serviceId}/intake-medication`);
}

export const usePostIntakeMedication = () => {
  return useMutation((data:any) => postIntakeMedication(data));

}

async function postIntakeMedication(data:any) {
  return await fetcher.post(`/services/${data?.serviceId}/intake-medication`,data?.body);
}