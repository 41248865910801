import fetcher from 'features/shared/utils/fetcher'

export default function swDev(userId: number) {
  function determineAppServerKey() {
    var vapidPublicKey = 'BAO74ehTK20vPiR2octIDgIE16eUWrONQsejvcY8Id4SQHVr4aL4Oqk_S8T172FAYbRTRfyR-e1boyDxA90WUeM';
    return urlBase64ToUint8Array(vapidPublicKey);
  }

  function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i <rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  let swUrl = `${process.env.PUBLIC_URL}/sw.js`;

  if('serviceWorker' in navigator) {
    navigator.serviceWorker.register(swUrl).then(registration => {
      console.log('register service Worker registration -______', registration);
  
      return registration.pushManager.getSubscription().then(async (subscription: any) => {
          console.log('get subscription response ____________', subscription);
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: determineAppServerKey()
          });
        }).then(async (finalResponse) => {
          const subJSObject = JSON.parse(JSON.stringify(finalResponse));
          const endpoint = subJSObject.endpoint;
          const keyAuth = subJSObject.keys.auth;
          const keyP256dh = subJSObject.keys.p256dh;
  
          const body = { endpoint, keyP256dh, keyAuth, userId };
  
          await fetcher.post(`/add-subscription`, body);
        })
    });
  }else{
    alert('no serviceWorker')
    
  }
}


// this.addEventListener('push', event => {
//   let data = event.data.json();
//   console.log('PUSH NOTIFICATION RESPONSE _______________________________', data);

//   const image = 'https://dev.homehospital.health/static/media/logo.bb9ce21d68a792305218.png';
//   const options = {
//     body: data.options.body,
//     icon: image,
//     actions: [
//       { action: "show-noti", title: "Ver", icon:""}
//     ]
//   };

//   self.registration.showNotification( data.title, options );
